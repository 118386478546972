import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const UpdateBusiness = ({ onClose, modal, setModal }) => {
    const [hideAdd, setHideAdd] = useState(false)
    const [loading, setLoading] = useState(false)

    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
    const txt5 = useRef("")
    const txt6 = useRef("")
  
    async function sendFunc() {
    if(!hideAdd) {
      var a = txt.current.value
      var b = txt2.current.value
      var c = txt3.current.value
      var d = txt4.current.value
      var e = txt5.current.value
      var f = txt6.current.value
      if(a !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "business/admin/profile/complete", {
            businessId: modal.data[0],
            businessName: a,
            registrationNumber: b,
            streetAddress: c,
            city: d,
            state: e,
            teamSize: f,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Business Profile Updated Successfully!",
            onOpen: true,
            data: `You’ve successfully updated this profile`,
          });
        }
        else {
            Toast(res?.message, "error")
        }
        setLoading(false)
      }
      else {
        Toast("Enter the business name", "error")
      }
    }
    else {
        var a = txt.current.value
        var b = txt2.current.value
        var c = txt3.current.value
        var d = txt6.current.value
        if(a !== "") {
          setLoading(true)
          const res = await AuthConnect("post", "business/admin/profile/complete", {
              businessId: modal.data[0],
              businessName: a,
              registrationNumber: b,
              streetAddress: c,
              teamSize: d,
          })
          if(res?.success) {
            onClose();
            return setModal({
              modalId: "SUCCESS",
              messageTitle: "Business Profile Updated Successfully!",
              onOpen: true,
              data: `You’ve successfully updated this profile`,
            });
          }
          else {
              Toast(res?.message, "error")
          }
          setLoading(false)
        }
        else {
          Toast("Enter the business name", "error")
        }
    }
    }


    async function getBusiness() {
        const res = await AuthConnect("get", "business/fetch/"+modal.data[0])
        if(res?.success) {
            const data = res?.data
            if(data?.registrationNumber !== undefined) {
                setHideAdd(true)
            }
            else {
                setHideAdd(false)
            }
            txt.current.value = data?.businessName ?? ""
            txt2.current.value = data?.registrationNumber ?? ""
            txt3.current.value = data?.address ?? ""
            txt6.current.value = data?.teamSize ?? ""
        }
    }

    useEffect(() => {
        getBusiness()
    }, [])

    const nStates = [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
    ]

    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Update Businesss Profile`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    
                    <InputHolderUser label="Business Name" type="text" placeholder="Enter your buisness name" inpRef={txt} />
                    <InputHolderUser label="Registration Number" type="text" placeholder="Enter your registration number" inpRef={txt2} />
                    <InputHolderUser label="Street Address" type="text" placeholder="Enter your street address" inpRef={txt3} />
                    {!hideAdd && 
                        <>
                            <InputHolderUser label="City" type="text" placeholder="Enter your city" inpRef={txt4} />
                            <Text mb="2">State</Text>
                            <select 
                                style={{
                                    width: "100%",
                                    padding: "2.5% 3%",
                                    borderRadius: "5px",
                                    outlineColor: "#49A05C",
                                    border: "1px solid #E3E6EB",
                                    transition: "300ms ease-in-out",
                                    marginBottom: "4%",
                                }}
                                className="slHover rSelect"
                                ref={txt5}
                            >
                                <option value="">Select your state</option>
                                {
                                    nStates.map((item, index) => (
                                        <option value={item}>{item}</option>
                                    ))
                                }
                            </select>
                        </>
                    }
                    <Text mb="2">Team Size</Text>
                    <select 
                        style={{
                            width: "100%",
                            padding: "2.5% 3%",
                            borderRadius: "5px",
                            outlineColor: "#49A05C",
                            border: "1px solid #E3E6EB",
                            transition: "300ms ease-in-out",
                            marginBottom: "4%",
                          }}
                          className="slHover rSelect"
                          ref={txt6}
                    >
                        <option value="">Select your team size</option>
                        <option>1-10</option>
                        <option>11-100</option>
                        <option>101-1000</option>
                        <option>More than 1000</option>
                    </select>
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default UpdateBusiness;
  