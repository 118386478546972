import SubmitRepairCost from "../../components/Modal/submitRepairCost";
import SuccessModal from "../../components/Modal/success.modal";
import RejectClaim from "../../components/Modal/rejectClaim.modal";
import MODALS from "../../constants/modal";
import ConfirmPayment from "../../components/Modal/confirmPayment.modal";
import UpdateCost from "../../components/Modal/updateCost.modal";
import UpdateStatusModal from "../../components/Modal/updateStatus.modal";
import SendRepairCost from "../../components/Modal/submitRepairCost.modal";
import SendClaimRepairCost from "../../components/Modal/submitCost.modal";
import ConfirmRejection from "../../components/Modal/confirmRejection.modal";
import SubmitDiagnosis from "../../components/Modal/submitDiagnosis.modal";
import ReimburseCustomer from "../../components/Modal/reimburseCustomer.modal";
import UserContacted from "../../components/Modal/userContacted";
import ActivationApprove from "../../components/Modal/approveActivation.modal";
import AddNote from "../../components/Modal/addNote";
import ConvertClaimRepair from "../../components/Modal/convertClaimRepair.modal";
import AddAdmin from "../../components/Modal/addAdmin.modal";
import LinkDevice from "../../components/Modal/linkDevice.modal";
import GiftPlan from "../../components/Modal/giftPlan.modal";
import MetaModal from "../../components/Modal/metaModal";
import RepairEstimate from "../../components/Modal/repairEstimate";
import ConvertRepair from "../../components/Modal/convertRepair";
import SuspendRepair from "../../components/Modal/suspendRepair";
import DeleteRepair from "../../components/Modal/deleteRepair";
import RefundRepair from "../../components/Modal/refundRepair";
import CreateRepair from "../../components/Modal/createRepair";
import CreateUser from "../../components/Modal/createUser.modal";
import EditSerialNumber from "../../components/Modal/editSerialNumber.modal";
import EditEmail from "../../components/Modal/editEmail.modal";
import CreateClaim from "../../components/Modal/createClaim.modal";
import UpdateRepairCostClaims from "../../components/Modal/updateRepairCostClaims.modal";

import Error from "../../components/Modal/error.modal";

import ConvertAccount from "../../components/Modal/convertAccount";
import SuspendPlan from "../../components/Modal/suspendPlan";
import RenewPlan from "../../components/Modal/renewPlan";
import EditDeviceDetails from "../../components/Modal/editDeviceDetails.modal";
import CoverageDetails from "../../components/Modal/coverageDetails.modal";
import AssignThirdParty from "../../components/Modal/assignThirdParty.modal";

import AssignAccManager from "../../components/Modal/AssignAccManager";

import ViewUploadFile from "../../components/Modal/viewUploadFile";
import TransferPlan from "../../components/Modal/transferPlan";
import UpdateBusiness from "../../components/Modal/updateBusiness";
import SoldBy from "../../components/Modal/soldBy";
import CreateOrder from "../../components/Modal/createOrder";
import AddDevice from "../../components/Modal/addDevice";
import UpfrontPayment from "../../components/Modal/upfrontPayment";
import OrderRepayment from "../../components/Modal/orderRepayment";
import ConfirmOrder from "../../components/Modal/confrimOrder";
import ConfirmRePayment from "../../components/Modal/confirmRePayment";
import ShareOrder from "../../components/Modal/shareOrder";
import EditDevice from "../../components/Modal/editDevice";
import UpdateOrder from "../../components/Modal/updateOrder";
import RefundOrder from "../../components/Modal/refundOrder";
import LinkMultipleDevices from "../../components/Modal/linkMultipleDevices";
import EditPhoneNumber from "../../components/Modal/editPhoneNumber.modal";
import TransferRepair from "../../components/Modal/transferRepair";
import ChangeRepaymentDate from "../../components/Modal/chanbgeRepaymentDate";
import RenewMonthlyPlan from "../../components/Modal/renewMonthlyPlan";
import ExtendPlan from "../../components/Modal/extendPlan";

import DeleteDevice from "../../components/Modal/deleteDevice";
import UpgradePlan from "../../components/Modal/UpgradePlan";
import TransferActivation from "../../components/Modal/transferActivation";
import EditMonthly from "../../components/Modal/editMonthly";
import ExportData from "../../components/Modal/exportData";
import SubmitVideo from "../../components/Modal/submitVideo";
import UploadFile from "../../components/Modal/uploadFile";
import AddTeam from "../../components/Modal/addTeam";
import VerifyClaimConfirm from "../../components/Modal/verifyClaim";
import RejectClaimNew from "../../components/Modal/rejectClaim";


export const renderModal = (modal, onClose, setModal, version = "v2") => {
  switch (modal.modalId) {
    case MODALS.SUCCESS:
      return (
        <SuccessModal
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ERROR:
      return (
        <Error
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REJECT_PROMPT:
      return (
        <ConfirmRejection
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REIMBURSEMENT_CUSTOMER:
      return (
        <ReimburseCustomer
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.EDIT_DEVICE_DETAILS:
      return (
        <EditDeviceDetails
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.COVERAGE_DETAILS:
      return (
        <CoverageDetails
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ASSIGN_ACCOUNT_MANAGER:
      return (
        <AssignAccManager
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SUBMIT_CLAIM_REPAIR_COST:
      return (
        <SendClaimRepairCost
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPDATE_COST:
      return (
        <UpdateCost
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ASSIGN_THIRD_PARTY:
      return (
        <AssignThirdParty
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPDATE_REPAIR_COST_CLAIMS:
      return (
        <UpdateRepairCostClaims
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CREATE_CLAIM:
      return (
        <CreateClaim
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.GIFT_PLAN:
      return (
        <GiftPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.LINK_DEVICE:
      return (
        <LinkDevice
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REJECT_CLAIM:
      return (
        <RejectClaim
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.EDIT_SERIAL_NUMBER:
      return (
        <EditSerialNumber
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.EDIT_EMAIL:
      return (
        <EditEmail
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CONFIRM_PAYMENT:
      return (
        <ConfirmPayment
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPDATE_STATUS:
      return (
        <UpdateStatusModal
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CONVERT_CLAIM_TO_REPAIR:
      return (
        <ConvertClaimRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ADD_ADMIN:
      return (
        <AddAdmin
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SUBMIT_REPAIR_REPAIR_COST:
      if (version === "v2") {
        return (
          <SubmitRepairCost
            onClose={onClose}
            onOpen={true}
            setModal={setModal}
            modal={modal}
          />
        );
      } else {
        return (
          <SendRepairCost
            onClose={onClose}
            onOpen={true}
            setModal={setModal}
            modal={modal}
          />
        );
      }

    case MODALS.SUBMITDIAGNOSIS:
      return (
        <SubmitDiagnosis
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.USER_CONTACTED:
      return (
        <UserContacted
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ACTIVATION_APPROVE:
      return (
        <ActivationApprove
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ADD_NOTE:
      return (
        <AddNote
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.CREATE_USER:
      return (
        <CreateUser
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.UPGRAGE_PLAN:
      return (
        <UpgradePlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.META_MODAL:
      return (
        <MetaModal
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REPAIR_ESTIMATE:
      return (
        <RepairEstimate
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REPAIR_CONVERT:
      return (
        <ConvertRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SUSPEND_REPAIR:
      return (
        <SuspendRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.DELETE_REPAIR:
      return (
        <DeleteRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REFUND_REPAIR:
      return (
        <RefundRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CREATE_REPAIR:
      return (
        <CreateRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CONVERT_ACCOUNT:
      return (
        <ConvertAccount
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SUSPEND_PLAN:
      return (
        <SuspendPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.RENEW_PLAN:
      return (
        <RenewPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.VIEW_UPLOAD_FILE:
      return (
        <ViewUploadFile
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.TRANSFER_PLAN:
      return (
        <TransferPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SOLD_BY:
      return (
        <SoldBy
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPDATE_BUSINESS_PROFILE:
      return (
        <UpdateBusiness
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CREATE_ORDER:
      return (
        <CreateOrder
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.EDIT_DEVICE:
      return (
        <EditDevice
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ADD_DEVICE:
      return (
        <AddDevice
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPFRONT_PAYMENT:
      return (
        <UpfrontPayment
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.ORDER_REPAYMENT:
      return (
        <OrderRepayment
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CONFIRM_ORDER:
      return (
        <ConfirmOrder
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CONFIRM_REPAYMENT:
      return (
        <ConfirmRePayment
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.SHARE_ORDER:
      return (
        <ShareOrder
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.UPDATE_ORDER:
      return (
        <UpdateOrder
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.REFUND_ORDER:
      return (
        <RefundOrder
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.LINK_MULTIPLE_DEVICES:
      return (
        <LinkMultipleDevices
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.EDIIT_PHONE_NUMBER:
      return (
        <EditPhoneNumber
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.TRANSFER_REPAIR:
      return (
        <TransferRepair
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    case MODALS.CHANGE_REPAYMENT_DATE:
      return (
        <ChangeRepaymentDate
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

    case MODALS.RENEW_MONTHLY_PLAN:
      return (
        <RenewMonthlyPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );

  case MODALS.DELETE_DEVICE:
  return (
    <DeleteDevice
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );


  case MODALS.TRANSFER_ACTIVATION:
  return (
    <TransferActivation
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );

  case MODALS.EDIT_MONTHLY:
  return (
    <EditMonthly
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );

  case MODALS.EXPORT_DATA:
  return (
    <ExportData
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );

  case MODALS.SUBMIT_VIDEO:
  return (
    <SubmitVideo
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );

  case MODALS.UPLOAD_FILE:
  return (
    <UploadFile
      onClose={onClose}
      onOpen={true}
      setModal={setModal}
      modal={modal}
    />
  );

  

    case MODALS.EXTEND_VALIDITY:
      return (
        <ExtendPlan
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
      case MODALS.ADD_TEAM:
      return (
        <AddTeam
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
      case MODALS.VERIFY_CLAIM_CONFIRM:
      return (
        <VerifyClaimConfirm
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
      case MODALS.REJECT_CLAIM_NEW:
      return (
        <RejectClaimNew
          onClose={onClose}
          onOpen={true}
          setModal={setModal}
          modal={modal}
        />
      );
    default:
      break;
  }
};
