import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import check from "../../assets/svgs/success1.svg";

const SuccessModal = ({ modal, setModal, onClose }) => {
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
  const partnerBgSec = sessionStorage.getItem("PartnerSecondaryColor")
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
        fontFamily="Montserrat"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {modal.title}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: partnerBg }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Flex justify="center" align="center" color={partnerBg}>
          <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
            <path d="M31.2781 69.7529C45.9014 69.7529 57.7561 57.8983 57.7561 43.2749C57.7561 28.6515 45.9014 16.7969 31.2781 16.7969C16.6547 16.7969 4.80005 28.6515 4.80005 43.2749C4.80005 57.8983 16.6547 69.7529 31.2781 69.7529Z" fill={partnerBgSec}/>
            <path d="M31.5774 63.7405L31.2415 63.1682C26.1115 54.4277 12.4792 35.879 12.3415 35.6927L12.1449 35.4256L16.7893 30.8356L31.4911 41.1014C40.7478 29.0896 49.3836 20.8393 55.0167 16.0214C61.1789 10.7512 65.19 8.32492 65.2305 8.30167L65.3217 8.24707H73.2L72.4474 8.91727C53.0931 26.1562 32.115 62.7958 31.906 63.1639L31.5774 63.7405Z" fill="currentColor"/>
          </svg>
          </Flex>
          <Flex justify="center" align="center" mb="63px">
            <p className="subtitle is-size-6">
              <Text
                mt="6"
                fontWeight="600"
                mb="8px"
                textAlign="center"
              >
                {modal.messageTitle || "Request sent!"}
              </Text>
              <Text textAlign="center" color="#3A3C40" opacity="0.8">{modal.data}</Text>
            </p>
          </Flex>
          <Button
            bg={partnerBg}
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            // isLoading={loading}
            width={"100%"}
            onClick={() => {
              onClose();
              setModal({ isOpen: false });
            }}
          >
            Ok, got it
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default SuccessModal;

// const x = (
//   <div className="column is-vcentered has-text-centered">
//     <Box p="0.75rem 1.5rem" fontFamily="Montserrat">
//       <Box mb="120px">
//         <Text
//           fontWeight={600}
//           textAlign="left"
//           fontSize="18px"
//           lineHeight="22px"
//         >
//           {modal.title}
//         </Text>
//       </Box>
//       <div
//         style={{
//           width: "5rem",
//           height: "5rem",
//           position: "relative",
//           borderRadius: "50%",
//           margin: "0 auto 37px",
//         }}
//       >
//         <img
//           src={check}
//           alt="check mark"
//           style={{
//             position: "absolute",
//             top: "0",
//             bottom: "0",
//             left: "50%",
//             transform: "translateX(-50%)",
//             margin: "auto",
//           }}
//         />
//       </div>

//       <div className="field is-fullwidth">
//         <div className="control">
//           <button
//             style={{ background: "#49A05C", color: "#fff" }}
//             className={"button  is-fullwidth"}

//           >
//             Got it!
//           </button>
//         </div>
//       </div>
//     </Box>
//   </div>
// );
