import { useEffect } from "react";
import { fetchClaimStatus, fetchRepairStatus, fetchOrderStatus } from "../helpers/request";
import { useAppContext } from "../reducers";
import { setClaimStatus, setRepairStatus, setOrderStatus } from "../reducers/defaultSlice";

const getClaimStatus = async () => {
  const res = await fetchClaimStatus();
  return res;
};

const getRepairStatus = async () => {
  const res = await fetchRepairStatus();
  return res;
};

const getOrderStatus = async () => {
  const res = await fetchOrderStatus();
  return res;
};

export const useFetchStatus = (marker) => {
  const [state, dispatch] = useAppContext();
  useEffect(() => {
    if (marker === "claims") {
      getClaimStatus().then((res) => {
        if (res.message === "Status Fetched Successfully") {
          dispatch(setClaimStatus(res.data));
        }
      });
    }
    if (marker === "repairs") {
      getRepairStatus().then((res) => {
        if (res.message === "Statuses Fetched Successfully") {
          dispatch(setRepairStatus(res.data));
        }
      });
    }
    if (marker === "orders") {
      getOrderStatus().then((res) => {
        if (res.message === "Statuses Fetched Successfully") {
          dispatch(setOrderStatus(res.data));
        }
      });
    }
  }, [marker]);
  return;
};
