import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Image,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import { brands_constants } from "../helper";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const CreateRepair = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
  

    const [cuid, setCuid] = useState("");
    const [uMail, setUMail] = useState("");
    const [uPhone, setUPhone] = useState("");
    const [dt, setDt] = useState("");
    const [db, setDb] = useState("");
    const [dm, setDm] = useState("");
    const [issue, setIssue] = useState("");
    const [desc, setDesc] = useState("");
    const [ad, setAd] = useState("");
    const [soldBy, setSoldBy] = useState("")

    useEffect(() => {
        setCuid("");
        setUMail("");
        setUPhone("");
        setDt("");
        setDb("");
        setDm("");
        setIssue("");
        setDesc("");
        setAd("");
        setSoldBy("")
    }, []);


    async function sendFunc() {
        if(dt !== "" && db !== "" && dm !== "" && issue !== "" && desc !== "" && ad !== "") {
            setLoading(true)
            const res = await AuthConnect('post', 'repair/admin/create', {
            cuid: cuid,
            email: uMail,
            phoneNumber: uPhone,
            deviceType: dt,
            deviceBrand: db,
            deviceModel: dm,
            issue: issue,
            description: desc,
            address: ad,
            notifyCustomer: isNotify,
            soldBy: soldBy,
            });
            if(res?.success) {
            onClose();
            return setModal({
                modalId: "SUCCESS",
                messageTitle: "Repair request submitted successfully!",
                onOpen: true,
                data: `You’ve successfully created a repair`,
            });
            }
            setLoading(false)
        }
        else {
            Toast("Fill all required fields", "error")
        }
      }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Create Repair`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem" w="100%">
          <Box w="100%">
            <InputHolderUser
            type="text"
            label="Customers Uid"
            onChange={(e) => setCuid(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Customers Email"
            onChange={(e) => setUMail(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Customers Phone Number"
            onChange={(e) => setUPhone(e.target.value)}
          />
          <Text mb="2">Select Device Type*</Text>
          <select
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              outlineColor: "#E3E6EB",
              border: "1px solid #E3E6EB",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
              width: "100%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDt(e.target.value)}
          >
            <option value=""></option>
            <option value="phone">Phone</option>
            <option value="laptop">Laptop</option>
            <option value="desktop">Desktop</option>
          </select>

          <Text mb="2">Select Device Brand*</Text>
          <select
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              outlineColor: "#E3E6EB",
              border: "1px solid #E3E6EB",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
              width: "100%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDb(e.target.value)}
          >
            <option value=""></option>
            {dt === "phone"
              ? brands_constants.PHONE_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "laptop"
              ? brands_constants.LAPTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "desktop" &&
                brands_constants.DESKTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>

          <InputHolderUser
            type="text"
            label="Device Model*"
            onChange={(e) => setDm(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Issue*"
            onChange={(e) => setIssue(e.target.value)}
          />

          {/*<Text mb="2">Select Issue*</Text>
          <select
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              outlineColor: "#E3E6EB",
              border: "1px solid #E3E6EB",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
              width: "100%",
            }}
            className="slHover rSelect"
            onChange={(e) => setIssue(e.target.value)}
          >
            <option value=""></option>
            {dt === "phone"
              ? brands_constants.PHONE_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "laptop"
              ? brands_constants.LAPTOP_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "desktop" &&
                brands_constants.DESKTOP_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>*/}

          <InputHolderUser
            type="textarea"
            label="Description*"
            onChange={(e) => setDesc(e.target.value)}
          />

          <InputHolderUser
            type="text"
            label="Address*"
            onChange={(e) => setAd(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Sold By"
            onChange={(e) => setSoldBy(e.target.value)}
          />
           <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>

              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default CreateRepair;
  