import { Box, Text, Flex, ModalCloseButton, ModalBody } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { rejectVideoSubmission } from "../helper";

const RejectPromptModal = ({ modal, setModal, onClose }) => {
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600">
          {`Activations`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          right={"2rem"}
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 2rem 1rem">
          <Box mb="3rem">
            <Text textAlign="center" fontWeight="400" py="2rem">
              Are you sure you want to reject this link device request ?
            </Text>
          </Box>
          <div className="field is-fullwidth">
            <div className="control">
              <button
                style={{ background: "#E90000", color: "#fff" }}
                className={"button  is-fullwidth"}
                onClick={() => {
                  onClose();
                  setModal({ isOpen: false });
                  rejectVideoSubmission(modal.data, setModal);
                }}
              >
                Yes, Reject
              </button>
            </div>
          </div>
        </Box>
      </ModalBody>
    </>
  );
};

export default RejectPromptModal;
