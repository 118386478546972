import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box} from "@chakra-ui/react";



function Settings() {
	const [pageShow, setPageShow] = useState(false)
	setTimeout(() => {
		setPageShow(true)
	}, 100)
	
	return(
		<Flex
			opacity={pageShow ? "1" : "0"}
			transition="opacity .2s ease-in-out"
			direction="column"
		>
			<Flex mb="3%" fontWeight="bold" fontSize="20px">
				Settings
			</Flex>
		</Flex>
	)
}



export default Settings;