import { batch } from "react-redux";
import {
  fetchClaims,
  fetchMoreActivations,
  fetchMoreClaims,
  fetchMoreData,
  fetchMoreExpiredPlans,
  fetchMoreRepairs,
  fetchMoreSales,
  fetchMoreUsers,
  fetchPrevData,
  fetchPreviousActivations,
  fetchPreviousClaims,
  fetchPreviousExpiredPlans,
  fetchPreviousRepairs,
  fetchPrevSales,
  fetchPrevUsers,
  fetchTechniciansNext,
  fetchTechniciansPrev,
  fetchMoreOrders,
  fetchPrevOrders,
  searchClaims,
  searchClaimsMore,
  fetchMoreTransaction,
  searchTransactions,
  handleSorting,
  searchActivationsMore,
} from "../../../util/helpers/request";
import {
  setActivePlans,
  setAdminsCount,
  setAdminUsers,
  setApprovedActivations,
  setApprovedClaims,
  setApprovedCount,
  setApprovedRepairs,
  setBusinessUsers,
  setBusinessUsersCount,
  setExpiredActivations,
  setExpiredPlans,
  setHotlistCount,
  setHotlistRepairs,
  setHotlistOrders,
  setApprovedOrders,
  setProcessedOrders,
  setRejectedOrders,
  setOthersOrders,
  setPendingOrders,
  setIncompleteActivations,
  setNextId,
  setOthersCount,
  setOthersRepairs,
  setPendingActivations,
  setPendingClaims,
  setPendingCount,
  setPendingRepairs,
  setPrevId,
  setProcessedClaims,
  setProcessedCount,
  setProcessedRepairs,
  setRejectedActivations,
  setRejectedClaims,
  setRejectedCount,
  setRejectedRepairs,
  setSales,
  setSalesCount,
  setTechnicians,
  setTechniciansCount,
  setTechnicianUsers,
  setUsersCount,
  setUsersUsers,
  setOngoingCount,
  setOngoingOrders,
  setReviewCount,
  setReviewOrders,
  setPendingTransactions,
  setFailedTransactions,
  setFailedCount,
  setSuccessCount,
  setSuccessTransactions,
  setActiveCount,
  setOngoingClaims,
} from "../../../util/reducers/dashboardSlice";
import Toast from "../../notify";

export const handleFetchMore = async (
  direction,
  ref,
  dispatch,
  type,
  activeTab,
  setLoadingPrev,
  setLoadingNext,
  searchText,
  isSort,
  filterData
) => {
  if (type === "plans") {
    const data = ref
    if (direction === "prev") {
      setLoadingPrev(true);
      let obj = { ...filterData, direction: "previous", next: ref };
      const res = isSort
        ? await handleSorting(obj)
        : await fetchPrevData(data);
      if (res.status) {
        dispatch(setActiveCount({ activeCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setActivePlans(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setActivePlans(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
      }
      setLoadingPrev(false);
    } else if (direction === "next") {
      setLoadingNext(true);
      let obj = { ...filterData, direction: "next", next: ref };
      const res = isSort
        ? await handleSorting(obj)
        : await fetchMoreData(data);

      if (res.status) {
        dispatch(setActiveCount({ activeCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setActivePlans(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setActivePlans(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
      }
      setLoadingNext(false);
    }
  }

  if (type === "claims") {
    const data = {
      id: ref,
      status: activeTab,
    };

    if (direction === "prev") {
      setLoadingPrev(true);
      if (searchText) {
        const data = {
          searchText,
          type: "previous",
          next: ref,
          status: activeTab,
        };
        searchClaimsMore(data).then((res) => {
          const { docs, next, previous: prev } = res.data;

          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (activeTab.toLowerCase() === "rejected" && prev) {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
        });
      } else {
        let obj = { ...filterData, direction: "previous", next: ref };
        const res = isSort
          ? await handleSorting(obj) : await fetchPreviousClaims(data);

        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved" && res.data?.previous) {
            batch(() => {
              dispatch(setApprovedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setApprovedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "pending" && res.data?.previous) {
            batch(() => {
              dispatch(setPendingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setPendingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "processed" && res.data?.previous) {
            batch(() => {
              dispatch(setProcessedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setProcessedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "ongoing" && res.data?.previous) {
            batch(() => {
              dispatch(setOngoingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setOngoingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "rejected" && res.data?.previous) {
            batch(() => {
              dispatch(setRejectedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setRejectedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
        }
      }
      setLoadingPrev(false);
    } else if (direction === "next") {
      setLoadingNext(true);

      if (searchText) {
        const data = {
          searchText,
          type: direction,
          next: ref,
          status: activeTab,
        };
        searchClaimsMore(data).then((res) => {
          const { docs, next, previous: prev } = res.data;

          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: res.data?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (activeTab.toLowerCase() === "rejected" && prev) {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                
              });
            }
          }
        });
      } else {
        let obj = { ...filterData, direction: "next", next: ref };
        const res = isSort
          ? await handleSorting(obj) : await fetchMoreClaims(data);
        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved" && res.data?.previous) {
            batch(() => {
              dispatch(setApprovedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setApprovedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "pending" && res.data?.previous) {
            batch(() => {
              dispatch(setPendingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setPendingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "processed" && res.data?.previous) {
            batch(() => {
              dispatch(setProcessedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setProcessedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "ongoing" && res.data?.previous) {
            batch(() => {
              dispatch(setOngoingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setOngoingClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "rejected" && res.data?.previous) {
            batch(() => {
              dispatch(setRejectedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setRejectedClaims(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
        }
      }
      setLoadingNext(false);
    }
  }





  
  if (type === "activations") {
    const data = {
      id: ref,
      status: activeTab,
    };
    if (direction === "prev") {
      setLoadingPrev(true);
      if (searchText) {
        const data = {
          searchText,
          type: "previous",
          next: ref,
          status: activeTab,
        };
        searchActivationsMore(data).then((res) => {
          if (activeTab.toLowerCase() === "approved" && res.data.previous) {
            batch(() => {
              dispatch(setApprovedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setApprovedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "pending" && res.data.previous) {
            batch(() => {
              dispatch(setPendingActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setPendingActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "expired" && res.data.previous) {
            batch(() => {
              dispatch(setExpiredActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setExpiredActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "rejected" && res.data.previous) {
            batch(() => {
              dispatch(setRejectedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setRejectedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "incomplete" && res.data.previous) {
            batch(() => {
              dispatch(setIncompleteActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setIncompleteActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
        })
      }
      else {
      let obj = { ...filterData, direction: "previous", next: ref };
      const res = isSort
        ? await handleSorting(obj) : await fetchPreviousActivations(data);
      if (res.data?.docs) {
        if (activeTab.toLowerCase() === "approved" && res.data.previous) {
          batch(() => {
            dispatch(setApprovedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setApprovedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
        if (activeTab.toLowerCase() === "pending" && res.data.previous) {
          batch(() => {
            dispatch(setPendingActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setPendingActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
        if (activeTab.toLowerCase() === "expired" && res.data.previous) {
          batch(() => {
            dispatch(setExpiredActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setExpiredActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
        if (activeTab.toLowerCase() === "rejected" && res.data.previous) {
          batch(() => {
            dispatch(setRejectedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setRejectedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
        if (activeTab.toLowerCase() === "incomplete" && res.data.previous) {
          batch(() => {
            dispatch(setIncompleteActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setIncompleteActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
      }
      }
      setLoadingPrev(false);
    }
     else if (direction === "next") {
      setLoadingNext(true);
      if (searchText) {
        const data = {
          searchText,
          type: direction,
          next: ref,
          status: activeTab,
        };
        searchActivationsMore(data).then((res) => {
          if (activeTab.toLowerCase() === "approved" && res.data.previous) {
            batch(() => {
              dispatch(setApprovedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setApprovedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "pending" && res.data.previous) {
            batch(() => {
              dispatch(setPendingActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setPendingActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "expired" && res.data.previous) {
            batch(() => {
              dispatch(setExpiredActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setExpiredActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "rejected" && res.data.previous) {
            batch(() => {
              dispatch(setRejectedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setRejectedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
          if (activeTab.toLowerCase() === "incomplete" && res.data.previous) {
            batch(() => {
              dispatch(setIncompleteActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              dispatch(setPrevId(res.data?.previous));
            });
          } else {
            batch(() => {
              dispatch(setIncompleteActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });
          }
        })
      }
      else {
      let obj = { ...filterData, direction: "next", next: ref };
      const res = isSort
        ? await handleSorting(obj) : await fetchMoreActivations(data);

      if (res.data?.docs) {
        if (activeTab.toLowerCase() === "approved" && res.data?.previous) {
          batch(() => {
            dispatch(setApprovedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        }
        if (activeTab.toLowerCase() === "pending" && res.data?.previous) {
          batch(() => {
            dispatch(setPendingActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        }
        if (activeTab.toLowerCase() === "expired" && res.data?.previous) {
          batch(() => {
            dispatch(setExpiredActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        }
        if (activeTab.toLowerCase() === "rejected" && res.data?.previous) {
          batch(() => {
            dispatch(setRejectedActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        }
        if (activeTab.toLowerCase() === "incomplete" && res.data?.previous) {
          batch(() => {
            dispatch(setIncompleteActivations(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          activeTab.toLowerCase() === "approved" &&
            batch(() => {
              dispatch(setApprovedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
              
            });

          activeTab.toLowerCase() === "pending" &&
            batch(() => {
              dispatch(setPendingActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
            });
          activeTab.toLowerCase() === "expired" &&
            batch(() => {
              dispatch(setExpiredActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
            });
          activeTab.toLowerCase() === "rejected" &&
            batch(() => {
              dispatch(setRejectedActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
            });
          activeTab.toLowerCase() === "incomplete" &&
            batch(() => {
              dispatch(setIncompleteActivations(res.data?.docs));
              dispatch(setNextId(res.data?.next));
            });
        }
      }
      }
      setLoadingNext(false);
    }
  }

  if (type === "repairs") {
    const data = {
      id: ref,
      status: activeTab,
    };

    if (direction === "prev") {
      setLoadingPrev(true);
      const res = await fetchPreviousRepairs(data);
      if (res.data) {
        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setApprovedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setApprovedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setPendingRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setPendingRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setProcessedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setProcessedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setRejectedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setRejectedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "hotlist") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setHotlistRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setHotlistRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOthersRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setOthersRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          setLoadingPrev(false);
        }
      }
    } else if (direction === "next") {
      setLoadingNext(true);
      const res = await fetchMoreRepairs(data);
      if (res.data) {
        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setApprovedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setApprovedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setPendingRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setPendingRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setProcessedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setProcessedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setRejectedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setRejectedRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "hotlist") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setHotlistRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setHotlistRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOthersRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setOthersRepairs(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          setLoadingNext(false);
        }
      }
    }
  }
  if (type === "orders") {
    const data = {
      id: ref,
      status: activeTab,
    };

    if (direction === "prev") {
      setLoadingPrev(true);
      const res = await fetchPrevOrders(data);
      if (res.data) {
        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setApprovedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setApprovedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setPendingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setPendingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setProcessedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setProcessedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "rebview") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setReviewOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setReviewCount({
                    reviewCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setReviewOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setReviewCount({
                    reviewCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOngoingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOngoingCount({
                    ongoingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setOngoingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOngoingCount({
                    ongoingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setRejectedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setRejectedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "hotlist") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setHotlistOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setHotlistOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOthersOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setOthersOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          setLoadingPrev(false);
        }
      }
    } else if (direction === "next") {
      setLoadingNext(true);
      const res = await fetchMoreOrders(data);
      if (res.data) {
        if (res.data.docs) {
          if (activeTab.toLowerCase() === "approved") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setApprovedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setApprovedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setApprovedCount({
                    approvedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setPendingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setPendingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setPendingCount({
                    pendingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setProcessedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setProcessedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setProcessedCount({
                    processedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "review") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setReviewOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setReviewCount({
                    reviewCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setReviewOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setReviewCount({
                    reviewCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOngoingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOngoingCount({
                    ongoingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setOngoingOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOngoingCount({
                    ongoingCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setRejectedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            } else {
              batch(() => {
                dispatch(setRejectedOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setRejectedCount({
                    rejectedCount: res.data?.docs?.length ?? "",
                  })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "hotlist") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setHotlistOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setHotlistOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            if (res.data?.previous) {
              batch(() => {
                dispatch(setOthersOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(setPrevId(res.data?.previous));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            } else {
              batch(() => {
                dispatch(setOthersOrders(res.data?.docs));
                dispatch(setNextId(res.data?.next));
                dispatch(
                  setOthersCount({ othersCount: res.data?.docs?.length ?? "" })
                );
              });
            }
          }
          setLoadingNext(false);
        }
      }
    }
  }
  if (type === "user") {
    const data = {
      id: ref,
      status: activeTab,
    };
    if (direction === "prev") {
      setLoadingPrev(true);
      const res = await fetchPrevUsers(data);
      if (res.status) {
        dispatch(setUsersCount({ usersCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setUsersUsers(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setUsersUsers(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
      }
      setLoadingPrev(false);
    } else if (direction === "next") {
      setLoadingNext(true);
      const res = await fetchMoreUsers(data);

      if (res.status) {
        dispatch(setUsersCount({ usersCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setUsersUsers(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setUsersUsers(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            
          });
        }
      }
      setLoadingNext(false);
    }
  }
  
  if (type === "sales") {
    const data = {
      id: ref,
      status: activeTab,
    };
    if (direction === "prev") {
      setLoadingPrev(true);
      const res = await fetchPrevSales(data);
      if (res.status) {
        dispatch(setSalesCount({ salesCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setSales(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setSales(res.data?.docs));
            dispatch(setNextId(res.data?.next));
          });
        }
      }
      setLoadingPrev(false);
    } else if (direction === "next") {
      setLoadingNext(true);
      const res = await fetchMoreSales(data);

      if (res.status) {
        dispatch(setSalesCount({ salesCount: res.data?.docs?.length ?? "" }));
        if (res.data?.previous) {
          batch(() => {
            dispatch(setSales(res.data?.docs));
            dispatch(setNextId(res.data?.next));
            dispatch(setPrevId(res.data?.previous));
          });
        } else {
          batch(() => {
            dispatch(setSales(res.data?.docs));
            dispatch(setNextId(res.data?.next));
          });
        }
      }
      setLoadingNext(false);
    }
  }
  if (type === "technicians") {
    let data = ref;

    if (direction === "prev") {
      setLoadingPrev(true);
      const res = await fetchTechniciansPrev(data).then((res) => {
        setLoadingPrev(false);
        if (res.status === 200) {
          const { previous, next, docs } = res.data;
          if (previous) {
            batch(() => {
              dispatch(setTechnicians(docs));
              dispatch(setNextId(next));
              dispatch(setPrevId(previous));
            });
          } else {
            batch(() => {
              dispatch(setTechnicians(docs));
              dispatch(setNextId(next));
            });
          }
        }
      });
    } else if (direction === "next") {
      setLoadingNext(true);
      const res = await fetchTechniciansNext(data).then((res) => {
        setLoadingNext(false);
        if (res.status === 200) {
          const { previous, next, docs } = res.data;
          if (previous) {
            batch(() => {
              dispatch(setTechnicians(docs));
              dispatch(setNextId(next));
              dispatch(setPrevId(previous));
            });
          } else {
            batch(() => {
              dispatch(setTechnicians(docs));
              dispatch(setNextId(next));
            });
          }
        }
      });
    }
  }
  if (type === "transactions") {
    const data = {
      id: ref,
      direction: direction === "prev" ? "previous" : "more",
      status: activeTab.toLowerCase(),
    };
    if (direction === "prev") {
      setLoadingPrev(true);
      if (searchText) {
        const data = {
          searchText,
          type: "previous",
          next: ref,
          status: activeTab,
        };

        const res = await searchTransactions(data);
        if (res.data.docs) {
          const { next, previous, docs } = res.data;
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "success") {
            dispatch(
              setSuccessCount({
                successCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "failed") {
            dispatch(
              setFailedCount({
                failedCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
      } else {
        const res = await fetchMoreTransaction(data);
        if (res.data.docs) {
          const { next, previous, docs } = res.data;
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "success") {
            dispatch(
              setSuccessCount({
                successCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "failed") {
            dispatch(
              setFailedCount({
                failedCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
      }

      setLoadingPrev(false);
    } else if (direction === "next") {
      setLoadingNext(true);
      if (searchText) {
        const data = {
          searchText,
          type: direction,
          next: ref,
          status: activeTab,
        };
        const res = await searchTransactions(data);
        if (res.data.docs) {
          const { next, previous, docs } = res.data;
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "success") {
            dispatch(
              setSuccessCount({
                successCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "failed") {
            dispatch(
              setFailedCount({
                failedCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
      } else {
        const res = await fetchMoreTransaction(data);
        if (res.data.docs) {
          const { next, previous, docs } = res.data;
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "success") {
            dispatch(
              setSuccessCount({
                successCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "failed") {
            dispatch(
              setFailedCount({
                failedCount:  res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
      }

      setLoadingNext(false);
    }
  }
};
export const button = {
  text: ["Prev", "Next"],
  logic: handleFetchMore,
};
