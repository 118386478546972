import _ from "lodash";
import {
  confirmCustomerPayment,
  fetchClaimStatus,
  rejectClaim,
  submitClaimCost,
  submitRepairCost,
  submitRepairRepairCost,
  updateClaimStatus,
  updateRepairStatus,
  confirmCustomerPaymentRepairs,
  submitRepairDiagnosis,
  fetchLinkedDeviceVideo,
  acceptVideo,
  rejectVideo,
  convertClaimToRepair,
  addAdmin,
  linkDevice,
  createGiftPlan,
  rejectActivation,
  createUser,
  editSerialNumber,
  editEmail,
  createClaim,
  updateActChecklist,
  editDD,
  verifyClaims,
  uploadVid,
  searchTechnicians,
  assignTechnician,
  updateOrderStatus,
  UpgradePlans,
  sendNoteFn,
} from "../../util/helpers/request";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";
import CreateUser from "./createUser.modal";
import { SearchIcon } from "../Common/helper";

export const rejectReasons = [
  { value: "Select..." },
  {
    value: "Damage Excluded From Customer's Plan Type",
  },
  {
    value: "Customer's plan type doesn't cover keyboard damage",
  },
  {
    value: "Customer's plan type doesn't cover  battery",
  },
  {
    value: "Customer's plan type doesn't cover OS Update",
  },
  {
    value: "Customer's plan type doesn't cover device  theft",
  },
  {
    value: "Claim is invalid",
  },
];

export const claimStatusList = [
  { value: "Select..." },
  {
    value: "Fixed",
  },
  {
    value: "Fixed and Delivered",
  },
  {
    value: "Customer Reimbursed",
  },
];

export const handleSelect = (e, cb) => {
  cb(e.target.value);
};

export const CustomDefaultInput = ({
  title,
  name,
  onChange,
  value,
  placeholder,
  readOnly,
  type,
  id,
  loading,
  isDisabled
}) => {
  const renderSwitch = (
    type,
    name,
    value,
    onChange,
    placeholder,
    readOnly,
    id
  ) => {
    switch (type) {
      case "search":
        return (
          <Flex
            border="1px solid #E3E6EB"
            borderRadius="4px"
            sx={{ ":hover": { border: "1px solid #03A63C" } }}
            h="fit-content"
            zIndex="0"
            w="100%"
          >
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={
                  loading ? (
                    <Spinner
                      color="#fff"
                      emptyColor="lightgrey"
                      w="15px"
                      h="15px"
                    />
                  ) : (
                    <SearchIcon />
                  )
                }
              />
              <Input
                border="none"
                outline="none"
                name={name}
                focusBorderColor="none"
                w="100%"
                placeholder={placeholder}
                fontFamily="Montserrat"
                fontWeight="500"
                fontSize="13px"
                onChange={onChange}
                id={id}
              />
            </InputGroup>
          </Flex>
        );
      default:
        return (
          <Input
            border="1px solid rgb(152, 152, 152)"
            borderRadius="5px"
            className="slHover"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            _focus={{ outline: "none" }}
            _hover={{ outline: "none" }}
            readOnly={readOnly}
            fontWeight="500"
            fontSize="14px"
            disabled={isDisabled}
          />
        );
    }
  };
  return (
    <Flex w="100%" mb="10px">
      <Box w="100%">
        <Flex
          color="#3A3C40"
          fontSize="15px"
          ml="7px"
          lineHeight="25px"
          fontWeight="400"
        >
          {title}
        </Flex>
        <Flex>
          {renderSwitch(type, name, value, onChange, placeholder, readOnly)}
        </Flex>
      </Box>
    </Flex>
  );
};


export const CustomInput = ({
  title,
  name,
  onChange,
  value,
  placeholder,
  readOnly,
}) => {
  return (
    <Flex w="100%" border="1px solid #E3E6EB" borderRadius="5px" mb="10px">
      <Flex w="100%" justify="space-between" align="center" p="1%">
        <Flex
          color="#3A3C40"
          fontSize="14px"
          ml="7px"
          lineHeight="25px"
          fontWeight="600"
        >
          {title}
        </Flex>
        <Flex w="130px">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={"₦"}
              color="#989898"
            />
            <Input
              border="none"
              name={name}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              readOnly={readOnly}
              textAlign="right"
              sx={{
                "::-webkit-input-placeholder": { textAlign: "right" },
                ":-moz-placeholder": { textAlign: "right" },
              }}
              bg="#F2F2F2"
              _focus={{ outline: "none" }}
              _hover={{ outline: "none" }}
            />
          </InputGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const CustomInputNew = ({ title, placeholder, inpRef }) => {
  return (
    <Flex w="100%" border="1px solid #E3E6EB" borderRadius="5px" mb="10px">
      <Flex w="100%" justify="space-between" align="center" p="1%">
        <Flex
          color="#3A3C40"
          fontSize="14px"
          ml="7px"
          lineHeight="25px"
          fontWeight="600"
        >
          {title}
        </Flex>
        <Flex w="130px">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={"₦"}
              color="#989898"
            />
            <Input
              border="none"
              placeholder={placeholder}
              ref={inpRef}
              textAlign="right"
              sx={{
                "::-webkit-input-placeholder": { textAlign: "right" },
                ":-moz-placeholder": { textAlign: "right" },
              }}
              bg="#F2F2F2"
              _focus={{ outline: "none" }}
              _hover={{ outline: "none" }}
            />
          </InputGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const handleCalculation = async (data, cb) => {
  const { repairCost = 0, gampCoverage = 0, deliveryFee = 0 } = data;
  const numbers = [repairCost, gampCoverage, deliveryFee];
  const gampCost = await addGampCosts(...numbers);

  const finalAmount = gampCost;

  return cb(finalAmount);
};

const addGampCosts = async (repairCost, gampCoverage, deliveryFee) => {
  return new Promise((resolve, reject) => {
    let cost = _.toNumber(repairCost);
    let coverage = _.toNumber(gampCoverage);
    let fees = _.toNumber(deliveryFee);
    let afterFees = _.subtract(coverage, fees);

    resolve(_.subtract(cost, afterFees));
  });
};

export const postClaimCost = async (data) => {
  const res = await submitClaimCost(data);
  return res;
};

export const postClaimsRepairCost = async (data) => {
  const res = await submitClaimCost(data);
  return res;
};

export const postRepairCost = async (data) => {
  const res = await submitRepairRepairCost(data);
  return res;
};

export const postRejectClaim = async (data) => {
  const res = await rejectClaim(data);
  return res;
};
export const postRejectActivation = async (data) => {
  const res = await rejectActivation(data);
  return res;
};
export const postOfflinePaymentConfirmation = async (data) => {
  const res = await confirmCustomerPayment(data);
  return res;
};

export const postOfflinePaymentConfirmationRepairs = async (data) => {
  const res = await confirmCustomerPaymentRepairs(data);
  return res;
};

export const postClaimStatus = async (data) => {
  const res = await updateClaimStatus(data);
  return res;
};

export const getClaimStatus = async () => {
  const res = await fetchClaimStatus();
  return res;
};

export const getRepairRepairCost = async (data) => {
  const res = await submitRepairRepairCost(data);
  return res;
};

export const getClaimsRepairCost = async (data) => {
  const res = await submitClaimCost(data);
  return res;
};

export const postRepairStatus = async (data) => {
  const res = await updateRepairStatus(data);
  return res;
};

export const postOrderStatus = async (data) => {
  const res = await updateOrderStatus(data);
  return res;
};

export const postRepairDiagnosis = async (data) => {
  const res = await submitRepairDiagnosis(data);
  return res;
};

export const fetchUploadsConnected = async (data) => {
  const res = await fetchLinkedDeviceVideo(data);
  return res;
};

export const updateActivationChecklist = async (data) => {
  const res = await updateActChecklist(data);
  return res;
};

export const approveActivation = async (data) => {
  const res = await acceptVideo(data);
  return res;
};

export const sendNote = async (data) => {
  const res = await sendNoteFn(data);
  return res;
}

export const rejectActivationVideo = async (data) => {
  const res = await rejectVideo(data);
  return res;
};

export const convertClaimRepair = async (data) => {
  let res;
  res = await convertClaimToRepair(data);
  return res;
};

export const registerAdmin = async (data) => {
  let res;
  res = await addAdmin(data);
  return res;
};

export const linkaDevice = async (data) => {
  let res;
  res = await linkDevice(data);
  return res;
};

export const giftPlan = async (data) => {
  let res;
  res = await createGiftPlan(data);
  return res;
};

export const createNewUser = async (data) => {
  const res = await createUser(data);
  return res;
};

export const editSerialNumberFn = async (data) => {
  const res = await editSerialNumber(data);
  return res;
};

export const editEmailFn = async (data) => {
  const res = await editEmail(data);
  return res;
};

export const createOfflineClaim = async (data) => {
  const res = createClaim(data);
  return res;
};

export const editDeviceDetails = async (data) => {
  const res = editDD(data);
  return res;
};

export const verifyClaim = async (data) => {
  const res = verifyClaims(data);
  return res;
};

export const uploadVideo = async (data) => {
  const res = await uploadVid(data);
  return res;
};

export const searchTechnician = (data) => {
  const res = searchTechnicians(data);
  return res;
};

export const assignThirdParty = async (data) => {
  const res = await assignTechnician(data);
  return res;
};


export const upgradePlan = async (data) => {
  const res = await UpgradePlans(data)
  return res;
}