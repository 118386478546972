import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { capitalizeFirstWord } from "../../../util/helpers";

const renderSwitch = (status, type) => {
  switch (status) {
    case "active":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "approved":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
      case "device replaced":
        return (
          <Flex
            borderRadius="12px"
            justify="center"
            align="center"
            background="#D1FAE5"
            p="0rem 0.5rem"
          >
            <Flex direction="row" align="center">
              <i
                className="mdi mdi-circle"
                style={{
                  height: "17px",
                  fontSize: "6px",
                  color: "#10B981",
                  display: "flex",
                  marginRight: "0.3rem",
                }}
              ></i>
              <Text fontSize="12px" lineheight="16px" color="#10B981">
                {status}
              </Text>
            </Flex>
          </Flex>
        );
      case "delivered":
        return (
          <Flex
            borderRadius="12px"
            justify="center"
            align="center"
            background="#D1FAE5"
            p="0rem 0.5rem"
          >
            <Flex direction="row" align="center">
              <i
                className="mdi mdi-circle"
                style={{
                  height: "17px",
                  fontSize: "6px",
                  color: "#10B981",
                  display: "flex",
                  marginRight: "0.3rem",
                }}
              ></i>
              <Text fontSize="12px" lineheight="16px" color="#10B981">
                {status}
              </Text>
            </Flex>
          </Flex>
        );
        case "in progress":
          return (
            <Flex
              borderRadius="12px"
              justify="center"
              align="center"
              background="#D1FAE5"
              p="0rem 0.5rem"
            >
              <Flex direction="row" align="center">
                <i
                  className="mdi mdi-circle"
                  style={{
                    height: "17px",
                    fontSize: "6px",
                    color: "#10B981",
                    display: "flex",
                    marginRight: "0.3rem",
                  }}
                ></i>
                <Text fontSize="12px" lineheight="16px" color="#10B981">
                  {status}
                </Text>
              </Flex>
            </Flex>
          );
        case "customer refunded":
          return (
            <Flex
              borderRadius="12px"
              justify="center"
              align="center"
              background="#D1FAE5"
              p="0rem 0.5rem"
            >
              <Flex direction="row" align="center">
                <i
                  className="mdi mdi-circle"
                  style={{
                    height: "17px",
                    fontSize: "6px",
                    color: "#10B981",
                    display: "flex",
                    marginRight: "0.3rem",
                  }}
                ></i>
                <Text fontSize="12px" lineheight="16px" color="#10B981">
                  {status}
                </Text>
              </Flex>
            </Flex>
          );
    case "activated":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
      case "paid":
        return (
          <Flex
            borderRadius="12px"
            justify="center"
            align="center"
            background="#D1FAE5"
            p="0rem 0.5rem"
          >
            <Flex direction="row" align="center">
              <i
                className="mdi mdi-circle"
                style={{
                  height: "17px",
                  fontSize: "6px",
                  color: "#10B981",
                  display: "flex",
                  marginRight: "0.3rem",
                }}
              ></i>
              <Text fontSize="12px" lineheight="16px" color="#10B981">
                {status}
              </Text>
            </Flex>
          </Flex>
        );
    case "trial":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "expired":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#fb000030"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#fb0000",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#fb0000">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "inactive":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#fb000030"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#fb0000",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#fb0000">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "rejected":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#fb000030"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#fb0000",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#fb0000">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "suspended":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#fb000030"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#fb0000",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#fb0000">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "awaiting customer payment":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );case "update guarantor":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
      case "awaiting credit assessment":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "partial":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "full":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
      
    case "awaiting payment":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "awaiting your payment":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "processing":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "fixed and delivered":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "fixed":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "customer reimbursed":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "refunded":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "pending":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

      case "awaiting approval":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "incomplete":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    case "in progress":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "full":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#D1FAE5"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#10B981",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#10B981">
              {status}
            </Text>
          </Flex>
        </Flex>
      );
    case "partial":
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#FDEACF"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#FFA500",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#FFA500">
              {status}
            </Text>
          </Flex>
        </Flex>
      );

    default:
      return (
        <Flex
          borderRadius="12px"
          justify="center"
          align="center"
          background="#fb000030"
          p="0rem 0.5rem"
        >
          <Flex direction="row" align="center">
            <i
              className="mdi mdi-circle"
              style={{
                height: "17px",
                fontSize: "6px",
                color: "#fb0000",
                display: "flex",
                marginRight: "0.3rem",
              }}
            ></i>
            <Text fontSize="12px" lineheight="16px" color="#fb0000">
              {capitalizeFirstWord("failed")}
            </Text>
          </Flex>
        </Flex>
      );
  }
};

const Status = ({ status, type }) => {
  return renderSwitch(status, type);
};

export default Status;

export const BoolBadge = ({ bool }) => {
  return bool ? (
    <Flex
      borderRadius="12px"
      justify="center"
      align="center"
      background="#E2E8F0"
      p="0rem 0.5rem"
    >
      <Flex direction="row" align="center">
        <i
          className="mdi mdi-circle"
          style={{
            height: "18px",
            fontSize: "6px",
            color: "#334155",
            display: "flex",
            marginRight: "0.3rem",
          }}
        ></i>
        <Text fontSize="12px" lineheight="16px" color="#334155">
          {capitalizeFirstWord("true")}
        </Text>
      </Flex>
    </Flex>
  ) : (
    <Flex
      borderRadius="12px"
      justify="center"
      align="center"
      background="#D9D9D9"
      p="0rem 0.5rem"
    >
      <Flex direction="row" align="center">
        <i
          className="mdi mdi-circle"
          style={{
            height: "18px",
            fontSize: "6px",
            color: "#5E5E5F",
            display: "flex",
            marginRight: "0.3rem",
          }}
        ></i>
        <Text fontSize="12px" lineheight="16px" color="#5E5E5F">
          {capitalizeFirstWord("false")}
        </Text>
      </Flex>
    </Flex>
  );
};
