import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import _ from "lodash";
import { useAppContext, useDashboardContext } from "../../util/reducers";
import { setNextId, setPrevId } from "../../util/reducers/dashboardSlice";

const TabSelector = ({
  toggleTab,
  activeTab,
  type,
  setModal,
  rightAction,
  isPartner,
}) => {
  const [state] = useDashboardContext();
  const list = (type) => {
    switch (type.toLowerCase()) {
      case "plans":
        return ["All Plans"];
      case "repairs":
        return [
          "Hotlist",
          "Pending",
          "Approved",
          "Processed",
          "Rejected",
          "Others",
        ];
      case "user":
        return ["All Users"];
      case "claims":
        return ["Pending", "Approved", "Ongoing", "Processed", "Rejected"];
      case "activations":
        return ["Pending", "Incomplete", "Approved", "Expired", "Rejected"];
      case "transactions":
        return ["Pending", "Success", "Failed"];
      case "withdrawals":
        return ["Pending", "Paid", "Rejected"];
      case "orders":
        return [
          "Hotlist",
          "Pending",
          "Approved",
          "Ongoing",
          "Review",
          "Rejected",
          "Processed",
          "Others",
        ];
      case "sales":
        return ["All Sales"];
      case "technicians":
        return ["Profile"];
      case "business":
        return;
      default:
        return ["Active", "Expired"];
    }
  };

  const countList = (type) => {
    const {
      approvedCount,
      pendingCount,
      processedCount,
      rejectedCount,
      activeCount,
      expiredCount,
      hotlistCount,
      othersCount,
      usersCount,
      salesCount,
      successCount,
      failedCount,
      paidCount,
      incompleteCount,
      ongoingCount,
      reviewCount,
    } = state;
    switch (type) {
      case "plans":
        return [activeCount];
      case "claims":
        return [pendingCount, approvedCount, ongoingCount, processedCount, rejectedCount];
      case "repairs":
        return [
          hotlistCount,
          pendingCount,
          approvedCount,
          processedCount,
          rejectedCount,
          othersCount,
        ];
      case "user":
        return [usersCount];
      case "sales":
        return [salesCount];
      case "orders":
        return [
          hotlistCount,
          pendingCount,
          approvedCount,
          ongoingCount,
          reviewCount,
          rejectedCount,
          processedCount,
          othersCount,
        ];
      case "activations":
        return [
          pendingCount,
          incompleteCount,
          approvedCount,
          expiredCount,
          rejectedCount,
        ];
      case "transactions":
        return [pendingCount, successCount, failedCount];
      case "withdrawals":
        return [pendingCount, paidCount, rejectedCount];
      default:
        return [];
    }
  };


  
    
  const nArr = JSON.parse(sessionStorage.getItem("PartnerPermissions"))
  const canExportData = nArr.includes("export_data")
  

  const partnerRightList = sessionStorage.getItem("GampRole") === "agency-coordinator" ? (type) => {
     switch (type) {
      case "plans":
        return ["Export Plans"];
      default:
        return [];
    }
  } : sessionStorage.getItem("GampRole") === "claims-manager" ? (type) => {
    switch (type) {
     case "plans":
       return ["Export Plans"];
     case "claims":
       return ["Export Claims"];
     default:
       return [];
   }
 } : (type) => {
    switch (type) {
     case "plans":
       return ["Export Plans"];
     case "claims":
       return ["Export Claims"];
     case "activations":
         return ["Export Protected Devices"];
     case "user":
       return ["Export Users"];
     default:
       return [];
   }
 }

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch(setPrevId(""));
  }, [activeTab]);
  const handleRightList = (e) => {
    switch (e) {
      case "Add Admin":
        return setModal({
          modalId: "ADD_ADMIN",
          onOpen: true,
          data: "",
          state: "users",
          refreshFunc: () => {},
        });
        case "Export Plans":
        return setModal({
          modalId: "EXPORT_DATA",
          onOpen: true,
          data: "",
          state: "plans",
          refreshFunc: () => {},
        });
        
        case "Export Claims":
        return setModal({
          modalId: "EXPORT_DATA",
          onOpen: true,
          data: "",
          state: "claims",
          refreshFunc: () => {},
        });

        case "Export Protected Devices":
        return setModal({
          modalId: "EXPORT_DATA",
          onOpen: true,
          data: "",
          state: "activations",
          refreshFunc: () => {},
        });
        
        case "Export Users":
        return setModal({
          modalId: "EXPORT_DATA",
          onOpen: true,
          data: "",
          state: "users",
          refreshFunc: () => {},
        });
      case "Create Offline Plan":
        return rightAction(e);

      case "Gift Plan":
        return rightAction(e);
      case "Create User":
        return setModal({
          modalId: "CREATE_USER",
          onOpen: true,
          data: "",
          state: "user",
          refreshFunc: () => {},
        });
      case "Create Offline Claim":
        return setModal({
          modalId: "CREATE_CLAIM",
          onOpen: true,
          data: "",
          state: "claims",
          refreshFunc: () => {},
        });
      case "Create Order":
        return setModal({
          modalId: "CREATE_ORDER",
          onOpen: true,
          data: "",
          state: "orders",
          refreshFunc: () => {},
        });
      case "Link Multiple Devices":
        return setModal({
          modalId: "LINK_MULTIPLE_DEVICES",
          onOpen: true,
          data: "",
          state: "activations",
          refreshFunc: () => {},
        });
      default:
        return;
    }
  };

  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  return type === "business" ? (
    <Flex w="100%" boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.14)">
      <Flex bg="#fff" p="1rem" w="100%"></Flex>
    </Flex>
  ) : (
    <Flex
      w="100%"
      direction="row"
      justify={
        type === "user" || type === "plans" || type === "claims"
          ? "space-between"
          : "left"
      }
      align="baseline"
    >
      <Flex justify="left" align="center" flex="1" flexWrap="wrap">
        {list(type).map((item, index) => {
          return (
            <Flex
              key={_.uniqueId("tab-selector")}
              bg="#fff"
              mr="10px"
              id={item}
              cursor="pointer"
              color={
                activeTab === item
                  ? isPartner
                    ? partnerBg
                    : "#03A63C"
                  : "#82868C"
              }
              onClick={(e) => toggleTab(e)}
              borderRadius={isPartner ? "8px":"4px"}
              boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
              p="0.5rem 2rem"
              mb="3"
            >
              {item}
              <Text pointerEvents="none" pl="10px">
                {countList(type)[index] && countList(type)[index] !== 0
                  ? `(${countList(type)[index]})`
                  : countList(type)[index] === 0
                  ? "(0)"
                  : ""}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      {canExportData && <Flex ml={type === "orders" && "auto"} justify="left" align="center"> 
        {
          partnerRightList(type).map((item, index) => {
            return (
              <Box
                key={_.uniqueId("tab-selector")}
                bg={partnerBg}
                mr="10px"
                id={item}
                fontWeight="700"
                fontSize="14px"
                lineheight="16px"
                cursor="pointer"
                color={"#fff"}
                onClick={(e) => handleRightList(item)}
                borderRadius={isPartner ? "8px":"4px"}
                boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                p="0.5rem 2rem"
              >
                {item}
              </Box>
            );
          })
          }
      </Flex>
      }
    </Flex>
  );
};

export default TabSelector;
