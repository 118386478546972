import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { giftPlan } from "./helpers";

const GiftPlan = ({ setModal, modal, onClose }) => {
  const initialValues = {
    uid: "",
    numOfDevices: "",
    duration: "",
    email: "",
    phoneNumber: "",
    product: "",
    planType: "",
    price: "",
    createdAt: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const initialErrorState = {
    state: false,
    msg: "",
  };
  const [errorMsg, setErrorMsg] = useState(initialErrorState);
  const handleValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    setLoading(true);

    giftPlan(values).then((res) => {
      setLoading(false);
      if (!res.status) {
        setErrorMsg({ ...errorMsg, msg: res?.message, state: true });
        onClose();
      }
      return setModal({
        modalId: "SUCCESS",
        title: "Gift Plan Success",
        messageTitle: "Plan Created Successfully!",
        onOpen: true,
        data: res?.message,
      });
    });
  };
  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Gift Plan`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          _focus={{ border: "none", outline: "none" }}
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.7rem 1rem">
          <Flex w="100%" direction="column" overflow="scroll">
            <Box w="100%" mb="1rem">
              <Text>Uid</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  _focus={{ border: "1px solid #49a05c" }}
                  name="uid"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Number of Devices*</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="numOfPhones"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Duration*</Text>
              <Box>
                <select
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  name="duration"
                  className="slHover rSelect"
                  onChange={(e) => handleValues(e)}
                >
                  <option value=""></option>

                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                </select>
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Email</Text>
              <Box>
                <Input
                  type="email"
                  placeholder=""
                  name="email"
                  _focus={{ border: " 1px solid #49a05c" }}
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Phone Number</Text>
              <Box>
                <Input
                  type="number"
                  placeholder=""
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="phoneNumber"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Product*</Text>
              <Box>
                <select
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  name="product"
                  className="slHover rSelect"
                  onChange={(e) => handleValues(e)}
                >
                  <option value=""></option>

                  <option value="device_protection_plan">
                    Device Protection Plan
                  </option>
                </select>
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Plan Type*</Text>
              <Box>
                <select
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  name="planType"
                  className="slHover rSelect"
                  onChange={(e) => handleValues(e)}
                >
                  <option value=""></option>
                  <option value="lite">Lite</option>
                  <option value="basic">Basic</option>
                  <option value="premium">Premium</option>
                  <option value="pro">Pro</option>
                  <option value="custom">Custom</option>
                </select>
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Price*</Text>
              <Box>
                <Input
                  type="number"
                  placeholder=""
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="price"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Date Created</Text>
              <Box>
                <Input
                  type="date"
                  placeholder=""
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="createdAt"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  onChange={handleValues}
                />
              </Box>
            </Box>
            <Flex mt="2rem" justify="space-between">
              <Button
                bg="#49A05C"
                p="1rem"
                border="none"
                color="white"
                _hover={{ cursor: "pointer" }}
                type="submit"
                borderRadius="2px"
                mb="8"
                width="auto"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                bg="#49A05C"
                p="1rem"
                border="none"
                color="white"
                _hover={{ cursor: "pointer" }}
                type="submit"
                borderRadius="2px"
                mb="8"
                isLoading={loading}
                width="auto"
                onClick={handleSubmit}
              >
                Gift Plan
              </Button>
            </Flex>
          </Flex>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default GiftPlan;
