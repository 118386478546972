import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
  Image,
  Spinner,
  Input,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { CustomInput, getRepairRepairCost } from "./helpers";
import _ from "lodash";
import Toast from "../notify";
import Checkbox from "../Common/FormGroup/Checkout";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
  marginTop: "5px",
};

const SubmitRepairCost = ({ onClose, modal, setModal }) => {
  const [totalPrice, setTotalPrice] = useState(_.toNumber(`0.00`));
  const [loading, setLoading] = useState(false);
  const [isNotify, setIsNotify] = useState(true)
  const initialState = {
    repairCost: "",
    discount: "",
    deliveryFee: "",
  };
  const [values, setValue] = useState(initialState);

  const handleInputField = (e) => {
    const { name, value, id } = e.target;
    return setValue({ ...values, [name]: _.toNumber(value) });
  };

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true);
    const { data } = modal;

    const payload = {
      ...values,
      notifyCustomer: isNotify,
      repairId: data,
    };
    getRepairRepairCost(payload).then(async (res) => {
      if (res.status === 200) {
        await modal?.refreshFunc();
        onClose();
        return setModal({
          modalId: "SUCCESS",
          title: "Submit Repair Cost",
          messageTitle: "Repair Cost Submitted Successfully!",
          onOpen: true,
          data: `You’ve successfully submitted the repair cost`,
        });
      } else {
        Toast(res?.message, "error");
      }
      setLoading(false);
    });
  };

  const calulatePrice = async (repairCost, discount, deliveryFee) => {
    return new Promise((resolve, reject) => {
      let cost = _.toNumber(repairCost);
      let gampDiscount = _.toNumber(discount);
      let deliveryFees = _.toNumber(deliveryFee);
      let afterFees = _.add(cost, deliveryFees);

      resolve(_.subtract(afterFees, gampDiscount));
    });
  };
  useEffect(() => {
    calulatePrice(values.repairCost, values.discount, values.deliveryFee).then(
      (total) => {
        setTotalPrice(total);
      }
    );
  }, [values.repairCost, values.discount, values.deliveryFee]);

  return (
    <Flex direction="column" pb="40px" fontFamily="Montserrat">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Submit Repair Cost`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: sessionStorage.getItem("PartnerPrimaryColor") }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem 0rem" mt="1rem">
          <form onSubmit={handleSubmit}>
            <CustomInput
              title="Repair cost"
              value={values.repairCost}
              onChange={handleInputField}
              name="repairCost"
              placeholder={"0.00"}
            />
            <CustomInput
              title="Discount"
              value={values.discount}
              onChange={handleInputField}
              name="discount"
              placeholder={"0.00"}
            />
            <CustomInput
              title="Delivery Fee"
              value={values.deliveryFee}
              onChange={handleInputField}
              name="deliveryFee"
              placeholder={"0.00"}
            />

            <Flex mt="40px" direction="column">
              <CustomInput
                title="Total Charge"
                value={totalPrice ?? "0.00"}
                onChange={""}
                name="amount"
                readOnly
              />
            </Flex>
            <Flex align="center" mt="5" mb="5">
                <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                <Flex flex="1">Notify Customer?</Flex>
              </Flex>
            <Button
              bg="#49A05C"
              p="2%"
              mt="1rem"
              w="100%"
              border="none"
              color="white"
              _hover={{ cursor: "pointer" }}
              type="submit"
              borderRadius="5px"
              isLoading={loading}
              width={"100%"}
            >
              Submit
            </Button>
          </form>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default SubmitRepairCost;
