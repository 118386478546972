import React, { useState, useEffect } from "react";
import { Flex, TableContainer } from "@chakra-ui/react";
import { getUserClaims, getUserLinkedDevices, getUsersPlans } from "../helper";
import Tables from "../Tables";
import { viewDetailsTableHeaderUser } from "../../../pages/NewClaims/helper";
import Toast from "../../notify";
import { useAppContext, useDashboardContext } from "../../../util/reducers";
import Pagination from "../Pagination/Index";
import { fetchLinkedPlanVideo } from "../../../util/helpers/request";

export const Plans = ({ setModal, type, uid }) => {
  const initialState = [];
  const [content, setContent] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      setLoading(true);
      getUsersPlans(uid)
        .then((result) => {
          const data = result?.data;

          function paginate(array, page_size, page_number) {
            // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
            return array.slice(
              (page_number - 1) * page_size,
              page_number * page_size
            );
          }
          setContent(paginate(data, 10, 1));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      sub = false;
    };
  }, []);

  return (
    <Flex w="100%">
      <TableContainer
        w="100%"
        mt="40px"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        <Tables
          type={type}
          tableHeader={viewDetailsTableHeaderUser}
          tableContent={content}
          setModal={setModal}
          loading={loading}
        />
      </TableContainer>
    </Flex>
  );
};

export const LinkedDevices = ({ setModal, type, uid, filter, searchText }) => {
  const initialState = [];
  const [content, setContent] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const header = [
    "Date Activated",
    "Email",
    "Device",
    "Verified",
    "Claimed",
    "Status",
    "Action",
  ];
  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      setLoading(true);
      fetchLinkedPlanVideo(uid)
        .then((result) => {
          setLoading(false);
          setContent(result.data?.docs);
        })
        .catch((err) => {
          Toast(err.message ?? "Ooops, something went wrong!", "error");
        });
    }
    return () => {
      sub = false;
    };
  }, []);
  const [state, dispatch] = useDashboardContext();

  return (
    <Flex w="100%">
      <TableContainer
        w="100%"
        mt="40px"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        <Tables
          type={type}
          tableHeader={header}
          tableContent={content}
          setModal={setModal}
          loading={loading}
          
        />
        <Pagination
          page={type}
          prevId={state.innerPrevId}
          nextId={state.innerNextId}
          dispatch={dispatch}
          activeTab={filter}
          searchText={searchText}
        />
      </TableContainer>
    </Flex>
  );
};

export const Claims = ({ setModal, type, uid }) => {
  const initialState = [];
  const [content, setContent] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const header = [
    "Date Purchased",
    "Email",
    "Plan Type",
    "Device(s)",
    "Activated Device(s)",
    "Status",
    "Action",
  ];
  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      getUserClaims(uid)
        .then((result) => {
          // setContent();
        })
        .catch((err) => {
          Toast(err.message ?? "Ooops, something went wrong!", "error");
        });
    }
    return () => {
      sub = false;
    };
  }, []);
  return (
    <Flex w="100%">
      <TableContainer
        w="100%"
        mt="40px"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        <Tables
          type={type}
          tableHeader={header}
          tableContent={content}
          setModal={setModal}
          loading={loading}
        />
      </TableContainer>
    </Flex>
  );
};
