import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner, Image, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    MenuButton,
    Menu,
    MenuList,
    MenuItem, } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";
import acIcon from "../assets/svgs/acIcon.svg";


export default function ViewDevice({ id, setModal, modal }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    async function loadOrderDevice() {
        setLoading(true)
        const res = await AuthConnect("get", "order/admin/devices/fetch?orderId=" + id)
        setData(res?.data?.docs)
        setLoading(false)
    }
    useEffect(() => {
        loadOrderDevice()
    }, [])
    
    const tableHeader = ["Device Brand and Model", "Qty Ordered", "Class", "Dpp per Unit", "Cost per Unit"]

    return (
        <Flex w="100%">
            <Flex w="100%" overflowX="scroll" className="noScrollBar" direction="column">
                <Flex><Flex 
                    style={{
                        background: "#03A63C",
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: "700",
                        justifyContent: "center",
                        padding: "9px 20px",
                        borderRadius: "4px",
                    }} mb="8" onClick={() => setModal({
                        modalId: "ADD_DEVICE",
                        onOpen: true,
                        data: [id, {loadOrderDevice: loadOrderDevice}],
                        refreshFunc: () => {},
                      })
                    }>Add Device</Flex></Flex>
                <Table w="100%">
                    <Thead>
                        <Tr bg="#FBFBFB">
                        {tableHeader.map((item, index) => (
                            <Th key={index} textTransform="capitalize">{item}</Th>
                        ))
                        }
                        <Th><Image src={acIcon} w="12px" h="12px" /></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            loading ?
                            <Tr><Flex w="100%" h="100%" justify="center" align="center" py="200px">
                                <Spinner size="md" color="#00B600" emptyColor="grey" />
                            </Flex></Tr>
                            :
                            data?.length > 0 ? 
                            data.map((item, index) => (
                                <Tr key={index} background={index % 2 === 0 ? "#fff" : "#FBFBFB"}>
                                    <Td>{item?.device}</Td>
                                    <Td>{item?.qty}</Td>
                                    <Td>{item?.class}</Td>
                                    <Td>{item?.DPPPerUnit}</Td>
                                    <Td>{"₦"+item?.costPerUnit?.toLocaleString()}</Td>
                                    <Td>
                                        <Menu position="relative" w="30%">
                                            <MenuButton
                                                bgColor="transparent"
                                                border="1px solid #E3E6EB"
                                                borderRadius="2px"
                                                h="16px"
                                                transition="all .2s"
                                                _hover={{ cursor: "pointer" }}
                                            >
                                                <i className="mdi mdi-dots-horizontal" style={{ fontSize: "20px" }}></i>
                                            </MenuButton>
                                            <MenuList borderRadius="2px">
                                                <MenuItem
                                                    mb="2"
                                                    px="5"
                                                    py="2"
                                                    onClick={() => {
                                                        return setModal({
                                                            modalId: "EDIT_DEVICE",
                                                            onOpen: true,
                                                            data: [id, {loadOrderDevice: loadOrderDevice}, item],
                                                            refreshFunc: () => {},
                                                        });
                                                    }}
                                                >Edit Device</MenuItem>
                                                <MenuItem
                                                    mb="2"
                                                    px="5"
                                                    py="2"
                                                    onClick={() => {
                                                        return setModal({
                                                            modalId: "DELETE_DEVICE",
                                                            onOpen: true,
                                                            data: [id, {loadOrderDevice: loadOrderDevice}, item],
                                                            refreshFunc: () => {},
                                                        });
                                                    }}
                                                >Delete Device</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                            :
                            <Tr><Flex w="100%" h="100%" justify="center" align="center">
                                <Text ml="-8" fontWeight="700" my="100px">No Devices Yet</Text>
                            </Flex></Tr>
                        }
                    </Tbody>  
                </Table>
            </Flex>
        </Flex>
    )
}