import { Flex, Icon, Image, Spinner, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useMemo } from "react";
import titleIcon from "../../assets/svgs/newTitleIcon.svg";
import notification from "../../assets/svgs/newNotificationIcon.svg";
import profileImage from "../../assets/svgs/newProfileImage.svg";
import { useHistory } from "react-router-dom";
import { pageTitle } from "../Common/helper";
import { AuthConnect } from "../../util/util";

const Header = ({ selected, isPartner }) => {
  const router = useHistory();
  const [path, setPath] = useState();
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setPath(router.location.pathname);
  }, [router.location.pathname]);

  async function logout() {
    setLoading(true);
    const req = await AuthConnect("post", "auth/logout", { logout: true });
    if (req.success === true) {
      sessionStorage.clear();
      localStorage.clear();
      history.replace("/");
    }
  }

  const toggleMenu = () => {
    return setShowMenu(!showMenu);
  };

  return (
    <Flex
      justify="space-between"
      border="1px solid #00000010"
      align="center"
      ml={isPartner ? "0px" : "20px"}
      bg="#fff"
      p={isPartner ? "20px 40px 20px 36px" : "20px 40px 20px 20px"}
      position="sticky"
      top="0"
      w="100%"
      zIndex="1"
    >
      <Flex>
        <Text
          fontWeight="700"
          fontFamily="Montserrat"
          fontSize="21px"
          lineheight="26px"
        >
          {pageTitle(path)}
        </Text>
      </Flex>
      <Flex position="relative">
        {/*<Flex mr="3rem">
          <Image src={notification} />
        </Flex>*/}
        <Flex justify="center" align="center" cursor="pointer" onClick={toggleMenu}>
          <Image src={profileImage} mr="0.5rem" />
          <Flex>
            <Icon
              viewBox="0 0 16 9"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <path
                d="M6 7.5L0 1.87467L2.00094 0L6 3.75066L9.99906 0L12 1.87467L6 7.5Z"
                fill={"#82868C"}
              />
            </Icon>
          </Flex>
        </Flex>
        {showMenu ? (
          <Flex
            position="absolute"
            w="200px"
            h="auto"
            top="60px"
            bg="#fff"
            px="0.5rem"
            right="0px"
            border="1px solid #00000010"
            zIndex="1"
          >
            <Flex p="0.5rem 0.5rem 0.5rem 2rem" w="100%" cursor="pointer">
              {loading ? (
                <Flex w="100%">
                  <Spinner color="#fff" emptyColor="lightgrey" />
                </Flex>
              ) : (
                <Flex w="100%" onClick={logout}>
                  Logout
                </Flex>
              )}
            </Flex>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
