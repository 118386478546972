import { apiRequest } from ".";

// app

export const fetchMoreData = (data) => {
  return apiRequest(`plan/partner/admin/fetch?next=${data}`, "GET", null);
};
export const fetchPrevData = (data) => {
  return apiRequest(`plan/partner/admin/fetch?previous=${data}`, "GET", null);
};

//protection plans
export const fetchProtectionPlans = () =>
  apiRequest("plan/spplan/admin/fetch", "GET", null);
export const handleSorting = (data) =>
  apiRequest(
    data.next
      ? `${data.type}/partner/admin/filter?filter=${data.filter}&${data.direction}=${data.next}`
      : data.previous
      ? `${data.type}/partner/admin/filter?filter=${data.filter}&${data.direction}=${data.previous}`
      : `${data.type}/partner/admin/filter?filter=${data.filter}`,
    "GET",
    null
  );
export const searchPendingPlans = (data) =>
  apiRequest(`plan/partner/admin/fetch?search=${data}`, "GET", null, null, false);
export const fetchActivePlans = () =>
  apiRequest("plan/partner/admin/fetch", "GET", null, null, false);
export const fetchExpiredPlans = () =>
  apiRequest("plan/admin/expired", "GET", null, null, false);
export const fetchPreviousExpiredPlans = (data) =>
  apiRequest(`plan/admin/expired?previous=${data}`, "GET", null);
export const fetchMoreExpiredPlans = (data) =>
  apiRequest(`plan/admin/expired?next=${data}`, "GET", null);
export const createGiftPlan = (data) =>
  apiRequest("plan/admin/create", "POST", data);

export const UpgradePlans = (data) =>
  apiRequest("plan/admin/upgrade", "POST", data);

// claims
export const fetchClaims = () => {
  return apiRequest("claim/partner/admin/fetch", "GET", null);
};

export const verifyClaims = (data) => apiRequest("claim/create", "POST", data);
export const createClaim = (data) => apiRequest("claim/create", "POST", data);
export const searchClaims = (data) =>
  apiRequest(`claim/partner/admin/fetch?search=${data}`, "GET", null, null, false);
export const searchClaimsMore = (data) =>
  apiRequest(
    `claim/partner/admin/fetch?search=${data.searchText}&${data.type}=${data.next}&status=${data.status}`,
    "GET",
    null,
    null,
    false
  );
export const convertClaimToRepair = (data) =>
  apiRequest("claim/admin/convert/repair", "POST", data);
export const fetchMoreClaims = (data) =>
  apiRequest(
    `claim/partner/admin/fetch?next=${data.id}&status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );
export const fetchPreviousClaims = (data) =>
  apiRequest(
    `claim/partner/admin/fetch?previous=${data.id}&status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );
export const submitClaimCost = (data) =>
  apiRequest("claim/admin/submit/cost", "POST", data);
export const rejectClaim = (data) =>
  apiRequest("claim/admin/reject", "POST", data);
export const confirmCustomerPayment = (data) =>
  apiRequest("claim/admin/confirm/payment", "POST", data);
export const updateClaimStatus = (data) =>
  apiRequest("claim/admin/status/update", "POST", data);
export const fetchClaimStatus = () =>
  apiRequest("claim/admin/status/fetch", "GET", null);
export const reimburseCustomer = (data) =>
  apiRequest("claim/admin/payout", "POST", data);
export const fetchClaimsWithPlanId = (data) =>
  apiRequest(`claim/admin/fetch/plan/${data}`, "GET", null, null, false);
// repairs
export const fetchRepairs = () => {
  return apiRequest("repair/admin/fetch", "GET", null);
};
export const searchRepairs = (data) =>
  apiRequest(`repair/admin/search?param=${data}`, "GET", null, null, false);
export const confirmCustomerPaymentRepairs = (data) =>
  apiRequest("repair/admin/confirm/payment", "POST", data);
export const submitRepairRepairCost = (data) =>
  apiRequest("repair/admin/cost/submit", "POST", data);
export const fetchRepairStatus = () =>
  apiRequest("repair/admin/status/fetch", "GET", null);
export const fetchOrderStatus = () =>
  apiRequest("order/admin/status/fetch", "GET", null);
export const updateRepairStatus = (data) =>
  apiRequest("repair/admin/status/update", "POST", data);
export const updateOrderStatus = (data) =>
  apiRequest("order/admin/status/update", "POST", data);
export const genPaymentLink = (data) =>
  apiRequest("repair/fnpl/zilla", "POST", data);
export const submitRepairDiagnosis = (data) =>
  apiRequest("repair/admin/diagnosis", "POST", data, null, null, false);
export const fetchPreviousRepairs = (data) =>
  apiRequest(
    `repair/admin/fetch/previous/${
      data.id
    }?status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );
export const fetchMoreRepairs = (data) =>
  apiRequest(
    `repair/admin/fetch/more/${data.id}?status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );

//activation
export const fetchLinkedDeviceVideo = (data) =>
  apiRequest(`activation/upload/fetch/${data}`, "GET", null, null, false);
export const fetchLinkedPlanVideo = (data) =>
  apiRequest(`activation/admin/fetch/plan/${data}`, "GET", null, null, false);
export const editDD = (data) =>
  apiRequest("activation/admin/update/details", "POST", data);
export const updateActChecklist = (data) =>
  apiRequest("activation/admin/checklist/update", "POST", data);
export const linkDevice = (data) =>
  apiRequest("activation/create", "POST", data);
export const editSerialNumber = (data) =>
  apiRequest("activation/admin/update/uniquenumber", "POST", data);
export const acceptVideo = (data) =>
  apiRequest("activation/admin/approve", "POST", data, null, null, false);
export const sendNoteFn = (data) => apiRequest("note/admin/add", "POST", data);
export const rejectVideo = (data) =>
  apiRequest("activation/admin/reject", "POST", data, null, null, false);
export const fetchActivationData = () =>
  apiRequest("activation/partner/admin/fetch", "GET", null, null, false);
export const rejectActivation = (data) =>
  apiRequest("activation/admin/reject", "POST", data);
export const searchActivation = (data) =>
  apiRequest(`activation/partner/admin/fetch?search=${data}`, "GET", null, null, false);
  export const searchActivationsMore = (data) =>
  apiRequest(
    `activation/partner/admin/fetch?search=${data.searchText}&${data.type}=${data.next}&status=${data.status}`,
    "GET",
    null,
    null,
    false
  );
export const fetchPreviousActivations = (data) =>
  apiRequest(
    `activation/partner/admin/fetch?previous=${
      data.id
    }&status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );
export const fetchMoreActivations = (data) =>
  apiRequest(
    `activation/partner/admin/fetch?next=${
      data.id
    }&status=${data.status.toLowerCase()}`,
    "GET",
    null,
    null,
    false
  );
// transaction

export const fetchMoreTransaction = (data) =>
  apiRequest(
    `transaction/admin/fetch/${data.direction}/${data.id}?status=${data.status}`,
    "GET",
    null,
    null,
    false
  );
export const processTransaction = (data) =>
  apiRequest("transaction/process", "POST", data, null, null, false);
export const searchTransactions = (data) =>
  apiRequest(
    `transaction/admin/search?param=${data}`,
    "GET",
    null,
    null,
    false
  );
// user
export const fetchUserData = () =>
  apiRequest("user/partner/admin/fetch", "GET", null, null, false);
export const addAdmin = (data) =>
  apiRequest("user/register/admin", "POST", data);
export const fetchTransactionData = () =>
  apiRequest("transaction/admin/fetch", "GET", null);
export const fetchWithdrawalsData = () =>
  apiRequest("withdrawal/admin/fetch", "GET", null);
export const updateUserPhoneNumber = (data) =>
  apiRequest("user/admin/update/profile", "POST", data, null, null, false);
export const fetchTechniciansData = () =>
  apiRequest("technician/admin/fetch", "GET", null);
export const fetchUsersPlans = (data) =>
  apiRequest(`plan/spplan/admin/fetch/user/${data}`, "GET", null);
export const fetchPlansActivation = (data) =>
  apiRequest(`activation/admin/fetch/user/${data}`, "GET", null, null, false);
export const searchUsers = (data) =>
  apiRequest(`user/partner/admin/fetch?search=${data}`, "GET", null, null, false);
export const createUser = (data) =>
  apiRequest(`user/admin/create`, "POST", data);
export const editEmail = (data) =>
  apiRequest("user/admin/update/email", "POST", data);
export const fetchMoreUsers = (data) =>
  apiRequest(`user/partner/admin/fetch?next=${data.id}`, "GET", null);
export const fetchPrevUsers = (data) =>
  apiRequest(`user/partner/admin/fetch?previous=${data.id}`, "GET", null);

// sales

export const searchSales = (data) =>
  apiRequest(`sale/admin/search?param=${data}`, "GET", null, null, false);
export const searchOrders = (data) =>
  apiRequest(`order/admin/search?param=${data}`, "GET", null, null, false);
export const fetchSalesData = () => apiRequest("sale/admin/fetch", "GET", null);
export const fetchOrders = () => apiRequest("order/admin/fetch", "GET", null);
export const fetchMoreSales = (data) =>
  apiRequest(`sale/admin/fetch/more/${data.id}`, "GET", null);
export const fetchPrevSales = (data) =>
  apiRequest(`sale/admin/fetch/more/${data.id}`, "GET", null);

export const fetchMoreOrders = (data) =>
  apiRequest(`order/admin/fetch/more/${data.id}`, "GET", null);
export const fetchPrevOrders = (data) =>
  apiRequest(`order/admin/fetch/previous/${data.id}`, "GET", null);

//upload
export const uploadVid = (data) => apiRequest("upload/", "POST", data);

//technician
export const assignTechnician = (data) =>
  apiRequest("claim/admin/technician/assign", "POST", data);
export const searchTechnicians = (data) =>
  apiRequest(`technician/admin/search?param=${data}`, "GET", null, null, false);
export const fetchTechniciansNext = (data) =>
  apiRequest(`technician/admin/fetch?next=${data}`, "GET", null, null, false);
export const fetchTechniciansPrev = (data) =>
  apiRequest(
    `technician/admin/fetch?previous=${data}`,
    "GET",
    null,
    null,
    false
  );

// MFA
export const fetchQR = () =>
  apiRequest(`auth/admin/mfa/qr`, "GET", null, null, false);

export const verifyMFA = (data) =>
  apiRequest("auth/admin/mfa/setup", "POST", data);

// assign account manager
export const assignAccManager = (data) =>
  apiRequest("user/admin/account-manager/assign", "POST", data);
// hotlist summary
export const fetchHotlistSummary = () =>
  apiRequest("metric/partner/hotlist/summary", "GET", null, null, false);
