import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { DropdownIcon, SearchIcon, getUsersPlans } from "../helper";
import { Claims, LinkedDevices, Plans } from "./Tables";
import { useAppContext } from "../../../util/reducers";
import { useGetData } from "../../../pages/protectionPlans/helper";

const renderSwitch = (type, setModal, uid, filter, searchText) => {
  switch (type) {
    case "plans":
      return <Plans setModal={setModal} type={type} uid={uid} />;
    case "linked-device":
      return (
        <LinkedDevices
          setModal={setModal}
          type={"activations"}
          uid={uid}
          filter={filter}
          searchText={searchText}
        />
      );
    case "claims":
      return <Claims setModal={setModal} type={type} uid={uid} />;
    default:
      return;
  }
};

const ViewDetailsTable = ({ type, setModal, uid }) => {
  const {
    loading,
    handleSearchInput,
    searchText,
    handleSort,
    setLoading,
    filter,
  } = useGetData("linked-device", "default", uid);
  return (
    <Flex w="100%" direction="column" bg="#fff">
      <Flex>
        <Flex direction="row" w="100%" align="center" bg="#fff" top="0">
          <Flex
            border="1px solid #E3E6EB"
            borderRadius="4px"
            w="fit-content"
            sx={{ ":hover": { border: "1px solid #03A63C" } }}
            h="fit-content"
          >
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon />}
              />
              <Input
                border="none"
                outline="none"
                id="searchBox"
                focusBorderColor="none"
                w="283px"
                placeholder="Search..."
                fontFamily="Montserrat"
                fontWeight="500"
                fontSize="13px"
                // onChange={(e) => handleSearchInput(e)}
              />
            </InputGroup>
          </Flex>
          {/*<Flex ml="60px" justify="center" align="center">
            <Text mr="10px">Sort:</Text>
            <Flex
              borderRadius="4px"
              sx={{ ":hover": { border: "1px solid #03A63C" } }}
              border="1px solid #E3E6EB"
            >
              <Select
                w="183px"
                border="none"
                outline="none"
                focusBorderColor="none"
                fontFamily="Montserrat"
                fontWeight="500"
                fontSize="13px"
                lineheight="15px"
                defaultValue={"approved"}
                onChange={(e) => handleSort(e)}
                icon={<DropdownIcon fill={"#3A3C40"} />}
              >
                {type === "linked-device" ? (
                  ["approved", "expired", "pending", "rejected"].map(
                    (item, index) => {
                      return <option>{item}</option>;
                    }
                  )
                ) : (
                  <option>Most Recent</option>
                )}
              </Select>
            </Flex>
          </Flex>*/}
        </Flex>
      </Flex>
      <Flex h='100%'> 
        {loading ? (
          <Flex w="100%" py='2rem' justify="center" align="center">
            <Spinner color="#00B600" emptyColor="grey" />
          </Flex>
        ) : (
          renderSwitch(type, setModal, uid, filter, searchText)
        )}
      </Flex>
    </Flex>
  );
};

export default ViewDetailsTable;
