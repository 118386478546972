import { fetchQR, verifyMFA } from "../../util/helpers/request";

export const fetchQRCode = async (data) => {
  const res = await fetchQR();
  return res;
};

export const verifyCode = async (data) => {
  const res = await verifyMFA(data);
  return res;
};
