import React, { useEffect, useState } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { button, handleFetchMore } from "./helper";

const Pagination = ({
  page,
  prevId,
  nextId,
  dispatch,
  activeTab,
  searchText,
  isSort,
  filterData,
  isPartner
}) => {
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);

  useEffect(() => {
    if (loadingNext) {
      setLoadingPrev(false);
    } else if (loadingPrev) {
      setLoadingNext(false);
    }
  }, [loadingNext, loadingPrev]);
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  return (
    <Flex w="100%" mt="20px" justify="flex-end">
      {(prevId && prevId !== nextId) ? (
        <Flex
          bg={partnerBg}
          p="0.5rem 2rem"
          borderRadius="4px"
          cursor="pointer"
          mr="8"
          onClick={() =>
            handleFetchMore(
              "prev",
              prevId,
              dispatch,
              page,
              activeTab,
              setLoadingPrev,
              setLoadingNext,
              searchText,
              isSort,
              filterData
            )
          }
        >
          {loadingPrev ? (
            <Spinner color="#fff" emptyColor="grey" w="20px" h="20px" />
          ) : (
            <Text color="#fff" fontWeight="600">
              <Text mr="2" as="span"><i className="mdi mdi-arrow-left"></i></Text> {button.text[0]}
            </Text>
          )}
        </Flex>
      ) : (
        <Flex w="20px"></Flex>
      )}
      {nextId ? (
        <Flex
        bg={partnerBg}
          p="0.5rem 2rem"
          borderRadius="4px"
          cursor="pointer"
          onClick={() =>
            handleFetchMore(
              "next",
              nextId,
              dispatch,
              page,
              activeTab,
              setLoadingPrev,
              setLoadingNext,
              searchText,
              isSort,
              filterData
            )
          }
        >
          {loadingNext ? (
            <Spinner color="#fff" emptyColor="grey" w="20px" h="20px" />
          ) : (
            <Text color="#fff" fontWeight="600">
              {button.text[1]} <Text ml="2" as="span"><i className="mdi mdi-arrow-right"></i></Text> 
            </Text>
          )}
        </Flex>
      ) : (
        <Flex w="20px"></Flex>
      )}
    </Flex>
  );
};

export default Pagination;
