import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const EditDevice = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
    const txt5 = useRef("")

    useEffect(() => {
        txt.current.value = modal.data[2]?.device
        txt2.current.value = modal.data[2]?.qty
        txt3.current.value = modal.data[2]?.class
        txt4.current.value = modal.data[2]?.DPPPerUnit
        txt5.current.value = modal.data[2]?.costPerUnit
    }, [])
  
    async function sendFunc() {
        var a = txt.current.value
        var b = txt2.current.value
        var c = txt3.current.value
        var d = txt4.current.value
        var e = txt5.current.value
        if(a !== "" && b !== "" && c !== "" && d !== "" && e !== "") {
            const nArr = {deviceId: modal.data[2]?.deviceId, device: a, qty: parseInt(b), class: c, DPPPerUnit: parseInt(d), costPerUnit: parseInt(e)}
            setLoading(true)
            const res = await AuthConnect("post", "order/admin/devices/update", {
                orderId: modal.data[0],
                devices: [nArr],
            })
            if(res?.success) {
            modal.data[1]?.loadOrderDevice()
            onClose();
            return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully updated this device`,
            });
            }
            setLoading(false)
        }
        else {
            Toast("Kindly enter the device(s) details", "error")
        }
    }

    
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Edit Device`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            
            <Box>
                <Box>
                    <Flex w="100%" align="center">   
                        <Flex flex="1"><InputHolderUser label="Device Brand & Model" type="text" placeholder="Enter the device brand and model" inpRef={txt} /></Flex>
                        <Flex flex="1" mx="4"><InputHolderUser label="Quantity Ordered" type="text" placeholder="Enter the quantity" inpRef={txt2} /></Flex>
                        <Flex flex="1" direction="column">
                            <Text mb="2">Device Class</Text>
                            <select
                                style={{
                                    width: "100%",
                                    padding: "2.5% 3%",
                                    borderRadius: "5px",
                                    outlineColor: "#49A05C",
                                    border: "1px solid #E3E6EB",
                                    transition: "300ms ease-in-out",
                                    marginBottom: "5%",
                                }}
                                ref={txt3}
                                className="slHover rSelect"
                            >
                                <option>Select the device class</option>
                                {
                                    ["new", "pre-owned"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))
                                }
                            </select>
                        </Flex>
                    </Flex>
                    <Flex w="100%" align="center">   
                        <Flex flex="1" mr="4"><InputHolderUser label="Cost per Unit" type="text" placeholder="Enter the cost per unit" inpRef={txt5} /></Flex>
                        <Flex flex="1"><InputHolderUser label="Dpp per Unit" type="text" placeholder="Enter the dpp per unit" inpRef={txt4} /></Flex>
                    </Flex>
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  px="25%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default EditDevice;
  