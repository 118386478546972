import {
	Flex,
	ModalCloseButton,
	Text,
	ModalBody,
	Box,
} from '@chakra-ui/react';
import React from 'react';

const MetaModal = ({ onClose, modal, setModal }) => {

	return (
		<>
			<Flex
				w='100%'
				justifyContent='space-between'
				alignItems='center'
				p='1rem 2rem'
				borderBottom='none'>
				<Text w='100%' fontWeight='600' fontSize='18px' lineHeight='22px'>
					{`Metadata (Object)`}
				</Text>
				<ModalCloseButton
					outline='none'
					bg='#fff'
					border='none'
					w='fit-content'
					position='relative'
					right='0rem'
					top='0rem'
					_focus={'none'}
					_hover={{ bg: "none", color: "#03A63C" }}
				/>
			</Flex>
			<ModalBody>
				<Box p='1rem 0.5rem'>
                    <Flex px="12">
                        <pre style={{ background: "#fff"}}>
                        {
                            JSON.stringify(modal?.data, null, 2)
                        }
                        </pre>
                    </Flex>
                    <Flex w="100%" justify="center" align="center" mt="3">
                        <Flex
                            w="auto"
                            bg={sessionStorage.getItem("PartnerPrimaryColor")}
                            borderRadius="5px"
                            py="2"
                            px="9"
                            color="#fff"
                            cursor="pointer"
                            onClick={() => onClose()}
                            border={"1px solid "+sessionStorage.getItem("PartnerPrimaryColor")}
                        >
                            Got It!
                        </Flex>
                    </Flex>
				</Box>
			</ModalBody>
		</>
	);
};

export default MetaModal;
