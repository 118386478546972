import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewType: "plans",
  viewDetails: {},
  id: "",
  history: {
    pathname: "",

  },
};

const viewDetailsSlice = createSlice({
  name: "viewDetailsStore",
  initialState,
  reducers: {
    setViewType: (state, { payload }) => {
      state.viewType = payload;
    },
    setViewDetails: (state, { payload }) => {
      state.viewDetails = payload;
    },
    setId: (state, { payload }) => {
      state.id = payload;
    },
    setHistory: (state, { payload }) => {
      state.history.pathname = payload.pathname;
    },
  },
});

export const { setViewType, setViewDetails, setId, setHistory } =
  viewDetailsSlice.actions;

export default viewDetailsSlice.reducer;
