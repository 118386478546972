import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Checkbox from "../Common/FormGroup/Checkout";
import Toast from "../notify";
import { CustomInput, postClaimsRepairCost } from "./helpers";

const UpdateRepairCostClaims = ({ onClose, modal, setModal }) => {
  const { claimId } = modal.data;
  const initialState = {
    gampCoverage: "",
    repairCost: "",
    deliveryFee: "",
  };
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [totalPrice, setTotalPrice] = useState(_.toNumber(`0.00`));
  const [values, setValue] = useState(initialState);
  const handleInputField = (e) => {
    const { name, value, id } = e.target;
    if (id === "notifyCustomer") {
      setToggle(!toggle);
    } else {
      setValue({ ...values, [name]: _.toNumber(value) });
    }
  };

  const calulatePrice = async (repairCost, discount, deliveryFee) => {
    return new Promise((resolve, reject) => {
      let cost = _.toNumber(repairCost);
      let gampDiscount = _.toNumber(discount);
      let deliveryFees = _.toNumber(deliveryFee);
      let afterFees = _.add(cost, deliveryFees);

      resolve(_.subtract(afterFees, gampDiscount));
    });
  };

  useEffect(() => {
    if ((values.repairCost, values.gampCoverage, values.deliveryFee)) {
      calulatePrice(
        values.repairCost,
        values.gampCoverage,
        values.deliveryFee
      ).then((total) => {
        setTotalPrice(total);
      });
    }
  }, [values.repairCost, values.gampCoverage, values.deliveryFee]);

  const handleSubmit = () => {
    const data = {
      ...values,
      notifyCustomer: toggle,
      claimId,
    };
    postClaimsRepairCost(data).then((res) => {
      if (res.status) {
        return setModal({
          modalId: "SUCCESS",
          title: "Success",
          onOpen: true,
          data: res.message,
        });
      } else {
        Toast(res.message, "error");
      }
    });
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Update Repair Cost`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <CustomInput
            title="Repair Cost"
            value={values.repairCost}
            onChange={handleInputField}
            name="repairCost"
            placeholder={"0.00"}
          />
          <CustomInput
            title="Gamp Coverage"
            value={values.gampCoverage}
            onChange={handleInputField}
            name="gampCoverage"
            placeholder={"0.00"}
          />

          <CustomInput
            title="Delivery Fee"
            value={values.deliveryFee}
            onChange={handleInputField}
            name="deliveryFee"
            placeholder={"0.00"}
          />

          <Flex mt="40px" direction="column">
            <CustomInput
              title="Total"
              value={totalPrice ?? "0.00"}
              onChange={""}
              name="amount"
              readOnly
            />
            <Checkbox
              text={`Do you want to notify the customer?`}
              handleCheck={handleInputField}
              id={`notifyCustomer`}
              toggle={toggle}
              fontSize="14px"
              mt="0.5rem"
              mb="1rem"
            />
          </Flex>
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default UpdateRepairCostClaims;
