import { Box, Flex, Text, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    Input,
    InputGroup,
    InputLeftElement,
    Spinner, Image, } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthConnect } from "../../util/util";
import acc from "../../assets/svgs/acc.svg";
import bus from "../../assets/svgs/bus.svg";
import pur from "../../assets/svgs/pur.svg";
import usr from "../../assets/svgs/usr.svg";
import g1 from "../../assets/svgs/g1.svg";
import g2 from "../../assets/svgs/g2.svg";
import g3 from "../../assets/svgs/g3.svg";
import g4 from "../../assets/svgs/g4.svg";
import rev from "../../assets/svgs/rev.svg";
import clm from "../../assets/svgs/clm.svg";
import rep from "../../assets/svgs/rep.svg";
import lnk from "../../assets/svgs/lnk.svg";



export default function MetricsDashboard({ setModal }) {
    const [loading, setLoading] = useState(false)
    const [metricsData, setMetricsData] = useState([])
    const [activeTab, setActiveTab] = useState("Total")
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

    async function loadData() {
        setLoading(true)
        const res = await AuthConnect("get", "/metric/partner/overall")
        setMetricsData(res?.data)
        setLoading(false)
    }

    useEffect(() => {
        if(sessionStorage.getItem("PartnerAdminAccess") === "true") {
            loadData()
        }
        const nArr = JSON.parse(sessionStorage.getItem("PartnerPermissions"))
        console.log(nArr)
    }, [])

    const overview = [
        {
            name: "Protection Plans",
            name2: [
                {
                    name: "Total device protected",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.totalProtectedDevices,
                },
                {
                    name: "Total active plans",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.activePlans,
                },
                {
                    name: "Total expired plans",
                    graph: "r",
                    data: !metricsData ? "0" : metricsData?.expiredPlans,
                },
            ]
        },
        {
            name: "Activations",
            name2: [
                {
                    name: "Total activations",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.totalActivations,
                },
                {
                    name: "Total verified activations",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.approvedActivations,
                },
                {
                    name: "Total rejected activations",
                    graph: "y",
                    data: !metricsData ? "0" : metricsData?.rejectedActivations,
                },
            ]
        },
        {
            name: "Claims",
            name2: [
                {
                    name: "Total claim filed",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.totalClaims,
                },
                {
                    name: "Total approved claims",
                    graph: "g",
                    data: !metricsData ? "0" : metricsData?.approvedClaims,
                },
                {
                    name: "Total rejected claims",
                    graph: "r",
                    data: !metricsData ? "0" : metricsData?.rejectedClaims,
                },
            ]
        },
    ]

    return ( <>
        {
        sessionStorage.getItem("PartnerAdminAccess") === "true" ?
        <Flex flex="1" minH="90vh" direction="column">
            {!loading && <Flex
                w="100%"
                direction="row"
                align="baseline"
            >
                <Flex justify="left" align="center" flex="1" flexWrap="wrap">
                    {["Total"].map((item, index) => {
                    return (
                        <Flex
                            bg="#fff"
                            mr="10px"
                            cursor={item === "API Keys" ? "not-allowed" : "pointer"}
                            opacity={item === "API Keys" ? ".5" : "1"}
                            color={
                                activeTab === item
                                ? partnerBg
                                : "#82868C"
                            }
                            key={index}
                            onClick={() => item !== "API Keys" && setActiveTab(item)}
                            borderRadius="8px"
                            boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                            p="0.5rem 2rem"
                            mb="3"
                        >
                            {item}
                        </Flex>
                    );
                    })}
                </Flex>
            </Flex>}


            <Flex mt="12" flexWrap="wrap" w="100%" justify="space-between">
                {
                    loading ?
                    <Flex w="100%" mt="10%" align="center" justify="center"><Spinner w="60px" h="60px" color={partnerBg} emptyColor="lightgrey" /></Flex>
                    :
                    overview.map((item, index) => (
                        <Flex borderRadius="8px" boxShadow="0px 0px 10px rgba(0, 0, 0, 0.14)" bg="#fff" pt="6" pb="4" pr="6" pl="2" w="48.7%" key={index} mb="6" align="flex-start">
                            <Image mt="-2" w="45px" src={index === 0 ? pur : index === 1 ? usr : index === 2 ? lnk : index === 3 ? clm : index === 4 ? bus : rep} />
                            <Flex flex="1" direction="column" p="0">
                                <Text fontWeight="bold" fontSize="16px" mb="5">{item.name}</Text>
                                {
                                    item.name2.map((item, index) => (
                                        <Flex align="center" flex="1" mb="3" key={index}>
                                            <Flex w="58%" fontSize="14px" color="#82868C">{item.name}</Flex>
                                            <Flex w="30%" fontSize="20px" fontWeight="bold">{item.data}</Flex>
                                            <Flex w="12%"><Image w="49px" src={index === 0 ? g1 : index === 1 ? g2 : g3} /></Flex>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </Flex>
                    ))
                }
            </Flex>
        </Flex>
        :
        <Flex w="100%" justify="center" align="center" borderRadius="8px" bg="#fff" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.14)" py="12" px="6" h="80vh" mb="12">
            <Text textAlign="center" fontWeight="bold">You are not authorized to access the dashboard</Text>
        </Flex>
        }
    </>
    )
}