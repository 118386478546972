import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner, Image, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    MenuButton,
    Menu,
    MenuList,
    Button,
    MenuItem, } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";
import acIcon from "../assets/svgs/acIcon.svg";
import iconPdf from "../assets/svgs/iconPdf.svg"
import iconVideo from "../assets/svgs/iconVideo.svg"
import iconImg from "../assets/svgs/iconImg.svg"


export default function ViewUpload({ tid, uid, setModal, modal, type }) {   
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");

    async function loadUploadDetails() {
        setLoading(true)
        const res = await AuthConnect("get", type === "claims" ? "upload/admin/fetch/claim/" + tid : "upload/fetch/" + tid)
        setData(res?.data?.docs)
        if (res?.data?.next !== undefined) {
          setNext(res?.data?.next);
        } else {
          setNext("");
        }
        if (res?.data?.previous !== undefined) {
          setPrevious(res?.data?.previous);
        } else {
          setPrevious("");
        }
        setLoading(false)
    }


    async function nextAction() {
        setLoading2(true);
        const res = await AuthConnect(
          "get",
          type === "claims" ? "upload/admin/fetch/claim/" + tid + "?next=" + next : "upload/fetch/" + tid + "?next=" + next
        );
        setData(res?.data?.docs);
    
        if (res?.data?.next !== undefined) {
          setNext(res?.data?.next);
        } else {
          setNext("");
        }
        if (res?.data?.previous !== undefined) {
          setPrevious(res?.data?.previous);
        } else {
          setPrevious("");
        }
        setLoading2(false);
      }
    
      async function prevAction() {
        setLoading3(true);
        const res = await AuthConnect(
          "get",
          type === "claims" ? "upload/admin/fetch/claim/" + tid + "?previous=" + next : "upload/fetch/" + tid + "?previous=" + next
        );
        setData(res?.data?.docs);
        if (res?.data?.next !== undefined) {
          setNext(res?.data?.next);
        } else {
          setNext("");
        }
        if (res?.data?.previous !== undefined) {
          setPrevious(res?.data?.previous);
        } else {
          setPrevious("");
        }
        setLoading3(false);
      }


    useEffect(() => {
        loadUploadDetails()
    }, [])
    
    const tableHeader = ["Date Uploaded", "Upload Type", "Scope", "Upload ID"]

    function getExtension(path) {
        let baseName = path.split(/[\\/]/).pop(), // extracts file name from full path
          // (supports separators `\\` and `/`)
          pos = baseName.lastIndexOf("."); // gets the last position of `.`
        if (baseName === "" || pos < 1) // if the file name is empty or ...
          return ""; // the dot not found (-1) or comes first (0)
        return baseName.slice(pos + 1); // extracts extension ignoring "."
      }

    return (
        <Flex w="100%">
            <Flex w="100%" overflowX="scroll" className="noScrollBar" direction="column">
                {/*<Flex><Flex 
                    style={{
                        background: partnerBg,
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: "700",
                        justifyContent: "center",
                        padding: "9px 20px",
                        borderRadius: "4px",
                    }} mb="8" onClick={() => setModal({
                        modalId: "UPLOAD_FILE",
                        onOpen: true,
                        data: [tid, uid, {loadUploadDetails: loadUploadDetails}, type],
                        refreshFunc: () => {},
                      })
                    }>Upload File</Flex></Flex>*/}
                <Table w="100%">
                    <Thead>
                        <Tr bg="#FBFBFB">
                        {tableHeader.map((item, index) => (
                            <Th key={index} textTransform="capitalize">{item}</Th>
                        ))
                        }
                        <Th><Image src={acIcon} w="12px" h="12px" /></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            loading ?
                            <Tr><Flex w="100%" h="100%" justify="center" align="center" py="200px">
                                <Spinner size="md" color={partnerBg} emptyColor="grey" />
                            </Flex></Tr>
                            :
                            data?.length > 0 ? 
                            data.map((item, index) => (
                                <Tr key={index} background={index % 2 === 0 ? "#fff" : "#FBFBFB"}>
                                    <Td>
                                        <Flex maxW="300px" align="center">
                                            <Image mt="1" src={getExtension(item.url) === "png" || getExtension(item.url) === "jpg" || getExtension(item.url) === "jpeg" || getExtension(item.url) === "webp" || getExtension(item.url) === "gif" ? iconImg : getExtension(item.url) === "mp4" || getExtension(item.url)?.toLowerCase() === "mov" || getExtension(item.url)?.toLowerCase() === "avi" ? iconVideo : iconPdf} mr="3" w="36px" />
                                            <Flex flex="1" flexWrap="wrap" wordBreak="break-all">{item?.createdAt.substr(0, 10)}</Flex>
                                        </Flex>
                                    </Td>
                                    <Td textTransform="capitalize">{item?.type}</Td>
                                    <Td textTransform="capitalize">{item?.scope}</Td>
                                    <Td><Text w="250px">{item?.uploadId}</Text></Td>
                                    <Td>
                                        <Menu position="relative" w="30%">
                                            <MenuButton
                                                bgColor="transparent"
                                                border="1px solid #E3E6EB"
                                                borderRadius="2px"
                                                h="16px"
                                                transition="all .2s"
                                                _hover={{ cursor: "pointer" }}
                                            >
                                                <i className="mdi mdi-dots-horizontal" style={{ fontSize: "20px" }}></i>
                                            </MenuButton>
                                            <MenuList borderRadius="2px">
                                                <MenuItem
                                                    mb="2"
                                                    px="5"
                                                    py="2"
                                                    onClick={() => setModal({
                                                        modalId: "VIEW_UPLOAD_FILE",
                                                        onOpen: true,
                                                        data: [item.url, item?.type === "verification_video" ? "mp4" : getExtension(item.url)],
                                                        refreshFunc: () => {},
                                                      })
                                                    }
                                                >View File</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                            :
                            <Tr><Flex w="100%" h="100%" justify="center" align="center">
                                <Text ml="-8" fontWeight="700" my="100px">No Uploads Yet</Text>
                            </Flex></Tr>
                        }
                    </Tbody>  
                </Table>
             <Flex mt="auto"></Flex>   
            <Flex mt="4" mb="6" w="100%" justify="space-between">
              {previous !== "" && (
                <Button
                  bg={partnerBg}
                  color="#fff"
                  px="20px"
                  py="9px"
                  fontWeight="bold"
                  borderRadius="4px"
                  fontSize="13px"
                  onClick={prevAction}
                  border="none"
                  _hover={{ cursor: "pointer" }}
                >
                  {loading3 ? <Spinner emptyColor="#fff" color="lightgrey" /> : "Previous"}
                </Button>
              )}
              {next !== "" && (
                <Button
                  bg={partnerBg}
                  color="#fff"
                  px="20px"
                  py="9px"
                  ml="auto"
                  fontWeight="bold"
                  borderRadius="4px"
                  fontSize="13px"
                  onClick={nextAction}
                  border="none"
                  _hover={{ cursor: "pointer" }}
                >
                  {loading2 ? <Spinner emptyColor="#fff" color="lightgrey" /> : "Next"}
                </Button>
              )}
            </Flex>
            </Flex>
        </Flex>
    )
}