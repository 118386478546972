import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./index";
import NotFound from "./not-found";
import Dashboard from "./dashboard";
import Modal from "../components/Modal/index";
import Auth from "../util/auth";
import "../styles/mdi/css/materialdesignicons.css";
import "../styles/global.scss";
import { renderModal } from "./_app/helper";
import { AuthProvider } from "../util/auth";
import { ChakraProvider, useDisclosure, Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../assets/pngs/mlogo.png"
import mhide from "../assets/pngs/mhide.svg"
import Toast from "../components/notify";


function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = sessionStorage.getItem("GampAuth");
  const [modal, setModal] = useState({
    modalId: "",
    onOpen: false,
    title: "",
    data: "",
    state: "",
    messageTitle: "",
    refreshFunc: "",
  });
  useEffect(() => {
    if (!isOpen && modal.onOpen) {
      onOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const calModalSize = (key) => {
    switch (key) {
      case "ACTIVATION_APPROVE":
        return "4xl";
      case "COVERAGE_DETAILS":
        return "3xl";
      case "ADD_DEVICE":
        return "5xl";
      case "EDIT_DEVICE":
        return "5xl";
      default:
        break;
    }
  };

  return (
    // <AuthProvider>
    <ChakraProvider>
      <Flex
        direction="column"
        fontFamily="'Nunito Sans', sans-serif"
        h="100vh"
        overflow="hidden"
        className="noScrollBar"
        display={["none", "flex"]}
      >
        <Router>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={calModalSize(modal.modalId)}
          >
            {renderModal(modal, onClose, setModal)}
          </Modal>
          <Switch>
            <Route path="/" component={Index} exact />
            <Route
              path="/partner/dashboard"
              render={() => (
                <Dashboard setModal={setModal} modal={modal} auth={auth} />
              )}
            />
            <Route path="/auth" component={Auth} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Flex>



      <Flex display={["flex", "none"]} w="100%" h="100vh" justify="center" align="center" fontSize="15px" direction="column">
        <Image src={logo} w="66px" mt="-12" />
        <Image src={mhide} mt="12" w="82px" />
        <Text mt="6" fontWeight="600" px="15%" textAlign="center">This app is optimized for desktop view only</Text>
        <Text mt="5" textAlign="center">Kindly use a PC to access this app</Text>
        <Flex mt="50px" cursor="pointer" bg="#118E3E" color="#fff" padding="12px 36px" borderRadius="30px" fontWeight="600" onClick={() => Toast("Kindly close this tab and use a PC", "success")}>Got it!</Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
