import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { CustomDefaultInput } from "./helpers";
import { assignAccManager } from "../../util/helpers/request";
const AssignAccManager = ({ onClose, modal, setModal }) => {
  const initialState = {
    email: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState);
  const handleValues = (e) => {
    const { name, value } = e.target;
    return setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    let data = { uid: modal.data.uid, accountManager: values.email };
    setLoading(true);
    assignAccManager(data).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        return setModal({
          modalId: "SUCCESS",
          title: "SUCCESS",
          onOpen: true,
          data: res.message,
        });
      } else {
        return setModal({
          modalId: "ERROR",
          title:
            res.code === 403 || res.code === 400
              ? "Request Denied"
              : "Request Failed",
          onOpen: true,
          data: res.message,
        });
      }
    });
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Assign Account Manager`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Box>
            <CustomDefaultInput
              title="Account Manager Email"
              value={values.email}
              onChange={handleValues}
              name="email"
              placeholder={"Please enter an email address"}
            />
          </Box>
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default AssignAccManager;
