import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/react";
const toastDefaults = {
  position: "top",
  duration: 3000,
  isClosable: true,
};

const apiBaseUrl = process.env.REACT_APP_SERVER_URL;
const toast = createStandaloneToast();

const logRequests = () => {
  return (
    process.env.NODE_ENV === "development" &&
    (axios.interceptors.request.use((request) => {
      return request;
    }),
    axios.interceptors.response.use((response) => {
      return response;
    }))
  );
};

logRequests();

export async function apiRequest(
  path,
  method = "GET",
  data,
  params = null,
  shouldNotReload = true
) {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  try {
    const response = await axios.request({
      url: `${apiBaseUrl}/${path}`,
      method,
      headers: {
        "Content-Type": "application/json",
        accesstoken: sessionStorage.getItem("GampToken"),
      },
      params: {
        ...params,
      },
      cancelToken: source.token,
      data,
    });

    const {
      data: { message },
      status,
    } = response;

    return {
      status,
      data: response.data.data,
      message,
      response,
    };
  } catch (err) {
    if (
      err.response?.status === 401 &&
      shouldNotReload === false &&
      path !== "/auth?type=signin&next=dashboard/overview"
    ) {
      toast({
        ...toastDefaults,
        status: "error",
        desciption: "Please login again.",
        title: "Session Expired, Please Login Again",
      });
      const currentPathname = window.location.pathname.slice(1);
      return (window.location = `/auth?type=signin&next=${currentPathname}`);
    }
    if (err.response?.status === 401) {
      toast({
        ...toastDefaults,
        status: "error",
        desciption: "Please login again.",
        title: "Session Expired, Please Login Again",
      });

      return (window.location = `/auth?type=signin`);
    }

    if (err.response?.status === 401) {
      toast({
        ...toastDefaults,
        status: "error",
        desciption: "Please login again.",
        title: "Session Expired, Please Login Again",
      });
      const currentPathname = window.location.pathname.slice(1);
      return (window.location = `/auth?type=signin`);
    }

    return {
      status: false,
      message: err.response?.data?.message || err.message,
      error: err,
      code: err.response?.status,
    };
  }
}

export function formatDate(dateString) {
  var date = new Date(dateString);

  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function capitalizeFirstWord(string) {
  string = string.split(" ");
  const firstWord = string[0];
  const capitalizedFirstWord =
    firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
  return capitalizedFirstWord;
}
