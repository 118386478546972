import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { brands_constants } from "../helper";
import Toast from "../notify";
import { createOfflineClaim, CustomDefaultInput } from "./helpers";

const CreateClaim = ({ onClose, modal, setModal }) => {
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
  const deviceType = ["phone", "laptop", "desktop"];
  const initialState = {
    issue: "Screen damage",
    description: "",
    deviceType: "",
    activationId: "",
    address: "",
    partnerLocation: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValue] = useState(initialState);
  const handleInputField = (e) => {
    const { name, value } = e.target;
    return setValue({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    if(values.description !== "" && values.issue !== "" && values.address !== "") {
    let data;
    if (modal.state === "claims") {
      const { activationId, description, issue } = values;
      data = { activationId, description, issue };
      setLoading(true);
      createOfflineClaim(data).then((res) => {
        setLoading(false);
        if (res.status) {
          return setModal({
            modalId: "SUCCESS",
            title: "SUCCESS",
            onOpen: true,
            data: res.message,
          });
        } else {
          Toast(res.message, "error");
        }
      });
    } else {
      const { description, issue, address, partnerLocation } = values;
      data = { description, issue, address, partnerLocation, activationId: modal.data.activationId };
      setLoading(true);
      createOfflineClaim(data).then((res) => {
        setLoading(false);
        if (res.status) {
          return setModal({
            modalId: "SUCCESS",
            title: "SUCCESS",
            onOpen: true,
            data: res.message,
          });
        } else {
          Toast(res.message, "error");
        }
      });
    }
  }
  else {
    Toast("Kindly fill all fields")
  }
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Create Claim`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: sessionStorage.getItem("PartnerPrimaryColor") }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          {modal.state === "claims" ? (
            <>
              <CustomDefaultInput
                title="ActivationId"
                value={values.phoneNumber}
                onChange={handleInputField}
                name="activationId"
                // placeholder={"Please enter a valid email"}
              />
              <Box>
                <Text
                  color="#3A3C40"
                  fontSize="14px"
                  ml="7px"
                  lineHeight="25px"
                  fontWeight="400"
                >
                  Device Type
                </Text>
                <Select
                  onChange={handleInputField}
                  name="deviceType"
                  border="1px solid #E3E6EB"
                  borderRadius="5px"
                  mb="10px"
                  _focus={{ outline: "none" }}
                  _hover={{ outline: "none" }}
                >
                  <option value={"select"}>Select</option>
                  {deviceType.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box>
            <CustomDefaultInput
                title={modal.state === "claims" ? (
                  <>Issue
                  <Text as="span" fontSize="12px" color="#3A3C4080">
                  (Choose a device type first)
                  </Text>
                  </>
                ) : (
                  <>Issue</>
                )}
                name="issue"
                value={values.issue}
                isDisabled={true}
              />
          </Box>
          <>
            <Text
              color="#3A3C40"
              fontSize="14px"
              ml="7px"
              lineHeight="25px"
              fontWeight="400"
            >
              Description
            </Text>
            <textarea
              style={{
                padding: "2.5% 3%",
                borderRadius: "5px",
                border: "1px solid #989898",
                transition: "300ms ease-in-out",
                marginBottom: "4%",
                resize: "none",
                height: "158px",
                width: "100%",
              }}
              className="slHover"
              name="description"
              onChange={handleInputField}
            ></textarea>
          </>
          <Box mt="-2">
            <CustomDefaultInput
                title="Address"
                name="address"
                onChange={handleInputField}
              />
          </Box>
          <Box>
            <CustomDefaultInput
                title="Technician’s address"
                name="partnerLocation"
                onChange={handleInputField}
                placeholder="e.g Slot Ikeja"
              />
          </Box>

          <Button
            bg={partnerBg}
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default CreateClaim;
