import react, { useEffect, useState, useRef } from "react";
import { Flex, Text, Spinner, Button } from "@chakra-ui/react";
import { AuthConnect } from "../util/util";

export default function ViewNotes({ tid }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [loading4, setLoading4] = useState(false);
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  const inp = useRef("");

  async function loadNotes() {
    const res = await AuthConnect("get", "note/admin/fetch?tid=" + tid);
    setData([res?.data?.docs]);

    if (res?.data?.next !== undefined) {
      setNext(res?.data?.next);
    } else {
      setNext("");
    }
    if (res?.data?.previous !== undefined) {
      setPrevious(res?.data?.previous);
    } else {
      setPrevious("");
    }
    setLoading(false);
  }

  useEffect(() => {
    loadNotes();
  }, []);

  async function nextAction() {
    setLoading2(true);
    const res = await AuthConnect(
      "get",
      "note/admin/fetch?tid=" + tid + "&next=" + next
    );
    setData([res?.data?.docs]);

    if (res?.data?.next !== undefined) {
      setNext(res?.data?.next);
    } else {
      setNext("");
    }
    if (res?.data?.previous !== undefined) {
      setPrevious(res?.data?.previous);
    } else {
      setPrevious("");
    }
    setLoading2(false);
  }

  async function prevAction() {
    setLoading3(true);
    const res = await AuthConnect(
      "get",
      "note/admin/fetch?tid=" + tid + "&previous=" + previous
    );
    setData([res?.data?.docs]);
    if (res?.data?.next !== undefined) {
      setNext(res?.data?.next);
    } else {
      setNext("");
    }
    if (res?.data?.previous !== undefined) {
      setPrevious(res?.data?.previous);
    } else {
      setPrevious("");
    }
    setLoading3(false);
  }

  function fDt(data) {
    var nMonth = "";
    var nDay = "";
    const date = new Date(data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (month === 1) {
      nMonth = "Jan";
    } else if (month === 2) {
      nMonth = "Feb";
    } else if (month === 3) {
      nMonth = "Mar";
    } else if (month === 4) {
      nMonth = "Apr";
    } else if (month === 5) {
      nMonth = "May";
    } else if (month === 6) {
      nMonth = "Jun";
    } else if (month === 7) {
      nMonth = "Jul";
    } else if (month === 8) {
      nMonth = "Aug";
    } else if (month === 9) {
      nMonth = "Sep";
    } else if (month === 10) {
      nMonth = "Oct";
    } else if (month === 11) {
      nMonth = "Nov";
    } else {
      nMonth = "Dec";
    }

    if (day.length === 1) {
      nDay = "0" + day;
    } else {
      nDay = day;
    }

    return "(" + nMonth + " " + nDay + ", " + year + ")";
  }

  function fTm(data) {
    const date = new Date(data);
    var hr =
      new String(date.getHours()).length === 1
        ? "0" + new String(date.getHours())
        : new String(date.getHours());
    var mn =
      new String(date.getMinutes()).length === 1
        ? "0" + new String(date.getMinutes())
        : new String(date.getMinutes());
    return hr + ":" + mn;
  }

  return (
    <Flex w="100%" h="100%" direction="column">
      {loading ? (
        <Flex w="100%" h="100%" justify="center" align="center">
          <Spinner color={partnerBg} emptyColor="grey" />
        </Flex>
      ) : (
        Array.isArray(data) &&
        (data[0]?.length > 0 ? (
          <Flex w="100%" h="100%" direction="column" px="3">
            {data[0]?.map((item, index) => (
              <Flex key={index} w="100%" pr="10%" pb="8" direction="column">
                <Flex align="center">
                  <Flex
                    w="32px"
                    h="32px"
                    fontWeight="600"
                    align="center"
                    justify="center"
                    color="#fff"
                    borderRadius="5px"
                    fontSize="13px"
                    bg={(index + 1) % 2 == 0 ? partnerBg : "#7D6BBF"}
                    textTransform="capitalize"
                  >
                    {item?.writtenBy?.substr(0, 1)}
                  </Flex>

                  <Text
                    color="#3A3C40"
                    fontSize="13px"
                    ml="8px"
                    fontWeight="600"
                  >
                    {item?.writtenBy}
                    <Text
                      as="span"
                      ml="2"
                      color="#82868C"
                      fontSize="12px"
                      fontWeight="500"
                    >
                      {fDt(item.createdAt)}
                    </Text>
                    <Text
                      as="span"
                      ml="2"
                      color="#82868C"
                      fontWeight="500"
                      fontSize="11px"
                    >
                      {fTm(item.createdAt)}
                    </Text>
                  </Text>
                </Flex>
                <Text
                  mt="3"
                  pl="40px"
                  fontSize="14px"
                  color="#3A3C40"
                  fontWeight="500"
                >
                  {item?.note}
                </Text>
              </Flex>
            ))}
            <Flex mt="auto"></Flex>
            <Flex mt="4" mb="6" w="100%" justify="space-between">
              {previous !== "" && (
                <Button
                  bg={partnerBg}
                  color="#fff"
                  px="20px"
                  py="9px"
                  fontWeight="bold"
                  borderRadius="4px"
                  fontSize="13px"
                  onClick={prevAction}
                  border="none"
                  _hover={{ cursor: "pointer" }}
                >
                  {loading3 ? <Spinner emptyColor="#fff" color="lightgrey" /> : "Previous"}
                </Button>
              )}
              {next !== "" && (
                <Button
                  bg={partnerBg}
                  color="#fff"
                  px="20px"
                  py="9px"
                  fontWeight="bold"
                  ml="auto"
                  borderRadius="4px"
                  fontSize="13px"
                  onClick={nextAction}
                  border="none"
                  _hover={{ cursor: "pointer" }}
                >
                  {loading2 ? <Spinner emptyColor="#fff" color="lightgrey" /> : "Next"}
                </Button>
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex w="100%" h="100%" justify="center" align="center" pb="150px">
            <Text fontWeight="700">No Notes</Text>
          </Flex>
        ))
      )}

      <Flex w="100%" mt="auto" direction="column">
        <Flex w="100%" bg="#edf1f7" py="3" borderRadius="8px 8px 0 0"></Flex>
        <Flex
          w="100%"
          direction="column"
          pr="3"
          pl="5"
          py="3"
          borderRadius="0 0 8px 8px"
          border="1px solid #E3E6EB"
        >
          <textarea
            style={{ width: "100%", border: "none", resize: "none" }}
            placeholder="Enter a note..."
            ref={inp}
          ></textarea>

          <Flex mt="2" justify="flex-end">
            <Flex
              cursor="pointer"
              bg={partnerBg}
              color="#fff"
              fontWeight="700"
              align="center"
              padding="9px 20px"
              borderRadius="4px"
              onClick={async () => {
                var txt = inp.current.value;
                if (txt !== "") {
                  setLoading4(true);
                  const res = await AuthConnect("post", "note/admin/add", {
                    tid: tid,
                    note: txt,
                  });
                  if (res?.success) {
                    setLoading4(false);
                    inp.current.value = "";
                    await loadNotes();
                    document
                      .getElementById("upArea")
                      .scrollIntoView({ behavior: "smooth" });
                  } else {
                    setLoading4(false);
                  }
                }
              }}
            >
              <Text fontSize="12px">
                {loading4 ? (
                  <Spinner size="xs" color="#fff" emptyColor="lightgrey" />
                ) : (
                  "Add Note"
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
