import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import {
  CustomInput,
  postOfflinePaymentConfirmation,
  postOfflinePaymentConfirmationRepairs,
} from "./helpers";
import _ from "lodash";
import Toast from "../notify";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";



const ConfirmPayment = ({ onClose, setModal, modal }) => {
  const [adminConfirm, setAdminConfirm] = useState(false);
  const [amount, setAmount] = useState("0.00");
  const [loading, setLoading] = useState(false);
  const [panel, setPanel] = useState(0);
  const [isNotify, setIsNotify] = useState(true)
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: sessionStorage.getItem("PartnerPrimaryColor"),
    color: "#fff",
    marginTop: "5px",
  };
  const initialState = {
    amount: modal.data[1].customerCoverage ?? "",
  };
  const [value, setValue] = useState(initialState);
  const onChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };
  const handleAdminConfirm = () => {
    setAdminConfirm(true);
  };

  const handleNext = () => {
    return setPanel(1);
  };

  const handleSubmit = () => {
    setLoading(true);
    const { data, state } = modal;

    if (_.toNumber(value.amount) === modal.data[1]?.customerCoverage) {
      switch (state) {
        case "claims":
          return postOfflinePaymentConfirmation({
            claimId: data[0],
            amountPaid: _.toNumber(value.amount),
            notifyCustomer: isNotify,
          }).then((res) => {
            console.log(res)
            if (res.status) {
              setLoading(false);
              return setModal({
                modalId: "SUCCESS",
                title: "Confirm Payment",
                onOpen: true,
                messageTitle: "Payment Confirmed Successfully!",
                data: `You’ve successfully confirmed the payment`,
              });
            }
          });
        default:
          return;
      }
    } else if (_.toNumber(value.amount) === modal.data[1]?.totalCharge) {
      switch (state) {
        case "repairs":
          return postOfflinePaymentConfirmationRepairs({
            repairId: data[0],
            amountPaid: _.toNumber(value.amount),
            notifyCustomer: isNotify,
          }).then(async (res) => {
            if (res.status) {
              await modal?.refreshFunc();

              setLoading(false);
              return setModal({
                modalId: "SUCCESS",
                title: "Confirm Payment",
                onOpen: true,
                messageTitle: "Payment Confirmed Successfully!",
                data: `You’ve successfully confirmed the payment`,
              });
            }
          });

        default:
          return;
      }
    } else {
      Toast(
        "Amount entered must be equal to the total charge on this item",
        "error"
      );
      setPanel(0);
      setLoading(false);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        fontFamily="Montserrat"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px">
          {`Confirm Payment`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _focus={"none"}
          _hover={{ bg: "none", color: sessionStorage.getItem("PartnerPrimaryColor") }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem 3rem">
          <form>
            {panel === 0  ? (
              <>
                <CustomInput
                  title="Amount Paid"
                  value={value.amount}
                  onChange={onChange}
                  name="amount"
                  placeholder={"0.00"}
                />
                <Flex align="center" mt="5" mb="5">
                  <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                  <Flex flex="1">Notify Customer?</Flex>
                </Flex>
  
                <Flex>
                  <Button
                    style={buttonStyles}
                    textAlign="center"
                    width={"100%"}
                    isLoading={loading}
                    onClick={handleNext}
                  >
                    Submit
                  </Button>
                </Flex>
              </>
            ) : (panel === 1) ? (
              <>
                <Text
                  textAlign="center"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="17px"
                >
                  Please click “Yes, Proceed” to confirm N{value.amount?.toLocaleString()} as
                  amount paid by customer
                </Text>
                <Flex mt="30px">
                  <Button
                    style={buttonStyles}
                    textAlign="center"
                    width={"100%"}
                    isLoading={loading}
                    onClick={handleSubmit}
                  >
                    Yes, Proceed
                  </Button>
                </Flex>
              </>
            ) : null}
          </form>
        </Box>
      </ModalBody>
    </>
  );
};

export default ConfirmPayment;
