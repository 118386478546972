import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import Toast from "../../components/notify";
import { encodeQueryString } from "../../util/helpers/encodeEmail";
import {
  fetchActivationData,
  fetchActivePlans,
  fetchClaims,
  fetchExpiredPlans,
  fetchOrders,
  fetchRepairs,
  fetchSalesData,
  fetchTechniciansData,
  fetchTransactionData,
  fetchUserData,
  fetchWithdrawalsData,
  handleSorting,
  searchActivation,
  searchClaims,
  searchOrders,
  searchPendingPlans,
  searchRepairs,
  searchSales,
  searchTransactions,
  searchUsers,
} from "../../util/helpers/request";
import { useDashboardContext } from "../../util/reducers";
import {
  setActiveCount,
  setActivePlans,
  setAdminUsers,
  setAdminsCount,
  setApprovedActivations,
  setApprovedClaims,
  setApprovedCount,
  setApprovedOrders,
  setApprovedRepairs,
  setBusinessUsers,
  setBusinessUsersCount,
  setExpiredActivations,
  setExpiredCount,
  setExpiredPlans,
  setFailedCount,
  setFailedTransactions,
  setHotlistCount,
  setHotlistOrders,
  setHotlistRepairs,
  setIncompleteActivations,
  setIncompleteCount,
  setNextId,
  setOthersCount,
  setOthersOrders,
  setOthersRepairs,
  setPaidCount,
  setPaidWithdrawals,
  setPendingActivations,
  setPendingClaims,
  setPendingCount,
  setPendingOrders,
  setPendingRepairs,
  setPendingTransactions,
  setPendingWithdrawals,
  setPrevId,
  setProcessedClaims,
  setProcessedCount,
  setProcessedOrders,
  setProcessedRepairs,
  setProtectionPlans,
  setRejectedActivations,
  setRejectedClaims,
  setRejectedCount,
  setRejectedOrders,
  setRejectedRepairs,
  setRejectedWithdrawals,
  setSales,
  setSalesCount,
  setSuccessCount,
  setSuccessTransactions,
  setTechnicianUsers,
  setTechnicians,
  setTechniciansCount,
  setUsersCount,
  setUsersUsers,
  setOngoingCount,
  setOngoingOrders,
  setReviewCount,
  setReviewOrders,
  setInnerNextId,
  setInnerPrevId,
  setOngoingClaims,
} from "../../util/reducers/dashboardSlice";
import { getUserLinkedDevices } from "../../components/Common/helper";

export const sortList = (type) => {
  if (type === "repairs") {
    return [
      "",
      "pending",
      "rejected",
      "awaiting your payment",
      "in progress",
      "fixed",
      "fixed and delivered",
      "completed",
      "suspended",
      "refunded",
      "repair cost accepted",
    ];
  }
  if (type === "plans") {
    return ["", "suspended"];
  }
  if (type === "orders") {
    return [
      "",
      "pending",
      "awaiting approval",
      "rejected",
      "awaiting kyc info",
      "credit assessment ongoing",
      "awaiting payment",
      "in progress",
      "delivered",
      "awaiting repayment",
      "suspended",
      "customer refunded",
      "completed",
    ];
  }
  if (type === "activations") {
    return ["", "pending", "approved", "expired", "rejected", "incomplete"];
  }
  if (type === "user") {
    return [""];
  }
  if (type === "claims") {
    return [
      "",
      "pending",
      "rejected",
      "awaiting your payment",
      "processing",
      "fixed",
      "fixed and delivered",
      "device replaced",
      "claim paid out",
      "approved",
    ];
  }
  if (type === "transactions") {
    return [""];
  }
  if (type === "withdrawals") {
    return [""];
  }
  if (type === "sales") {
    return [""];
  }
  if (type === "technicians") {
    return [""];
  }
};

async function getInnerTableData(filter, dispatch, setLoading, uid) {
  if (!uid) return;

  const res = await getUserLinkedDevices(uid);
  if (res.status) {
    const { docs = [], next, previous } = res?.data[`${filter.toLowerCase()}`];
    const obj = res.data;

    for (const key in obj) {
      if (key === "approved") {
        dispatch(
          setApprovedCount({
            approvedCount: obj[key]?.docs?.length ?? "",
          })
        );
        if (previous) {
          batch(() => {
            dispatch(setApprovedActivations(docs));
            dispatch(setInnerNextId(next));
            dispatch(setInnerPrevId(previous));
          });
        }
        {
          batch(() => {
            dispatch(setApprovedActivations(docs));
            dispatch(setInnerNextId(next));
          });
        }
      }
      if (key === "pending") {
        dispatch(
          setPendingCount({
            pendingCount: obj[key]?.docs?.length ?? "",
          })
        );
        if (previous) {
          batch(() => {
            dispatch(setPendingActivations(docs));
            dispatch(setInnerNextId(next));
            dispatch(setInnerPrevId(previous));
          });
        }
        {
          batch(() => {
            dispatch(setPendingActivations(docs));
            dispatch(setNextId(next));
          });
        }
      }
      if (key === "expired") {
        dispatch(
          setExpiredCount({
            expiredCount: obj[key]?.docs?.length ?? "",
          })
        );
        if (previous) {
          batch(() => {
            dispatch(setExpiredActivations(docs));
            dispatch(setInnerNextId(next));
            dispatch(setInnerPrevId(previous));
          });
        }
        {
          batch(() => {
            dispatch(setExpiredActivations(docs));
            dispatch(setNextId(next));
          });
        }
      }
      if (key === "rejected") {
        dispatch(
          setRejectedCount({
            rejectedCount: obj[key]?.docs?.length ?? "",
          })
        );
        if (previous) {
          batch(() => {
            dispatch(setRejectedActivations(docs));
            dispatch(setInnerNextId(next));
            dispatch(setInnerPrevId(previous));
          });
        }
        {
          batch(() => {
            dispatch(setRejectedActivations(docs));
            dispatch(setNextId(next));
          });
        }
      }
      if (key === "incomplete") {
        dispatch(
          setIncompleteCount({
            incompleteCount: obj[key]?.docs?.length ?? "",
          })
        );
        if (previous) {
          batch(() => {
            dispatch(setIncompleteActivations(docs));
            dispatch(setInnerNextId(next));
            dispatch(setInnerPrevId(previous));
          });
        }
        {
          batch(() => {
            dispatch(setIncompleteActivations(docs));
            dispatch(setInnerNextId(next));
          });
        }
      }
    }
    setLoading(false);
  }
  return;
}

export async function getData(
  dashboardState,
  dispatch,
  type,
  searchText,
  setLoading,
  activeTab
) {
  if (!activeTab) return;
  
  
  if (type === "plans") {
    const res = await fetchActivePlans();
    if (searchText === "") {
      if (res.data) {
        const plans = res.data;
        dispatch(setActiveCount({ activeCount: res.data?.docs?.length ?? "" }));
        if (plans && plans?.previous) {
          batch(() => {
            dispatch(setActivePlans(plans?.docs));
            dispatch(setNextId(plans?.next));
            dispatch(setPrevId(plans?.previous));
          });
        } else {
          batch(() => {
            dispatch(setActivePlans(plans?.docs));
            dispatch(setNextId(plans?.next));
          });
        }
        setLoading(false);
      }
    } else {
      searchPendingPlans(searchText).then((res) => {
        const plans = res.data;
        if (res.data) {
          dispatch(setActiveCount({ activeCount: res.data?.docs?.length ?? "" }));
          if (plans && plans?.previous) {
            batch(() => {
              dispatch(setActivePlans(plans?.docs));
              dispatch(setNextId(plans?.next));
              dispatch(setPrevId(plans?.previous));
            });
          } else {
            batch(() => {
              dispatch(setActivePlans(plans?.docs));
              dispatch(setNextId(plans?.next));
            });
          }
          setLoading(false);
        } else {
          Toast("Failed to connect, Please check your internet connection.");
          setLoading(false);
        }
      });
    }
  }


  
  if (type === "activations") {
    if (searchText === "") {
      const res = await fetchActivationData();

      if (res.status) {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "expired") {
            dispatch(
              setExpiredCount({
                expiredCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "incomplete") {
            dispatch(
              setIncompleteCount({
                incompleteCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }

        setLoading(false);
      } else {
        Toast(res.message, "error");
        setLoading(false);
      }
    } else {
      searchActivation(searchText).then((res) => {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "expired") {
            dispatch(
              setExpiredCount({
                expiredCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "incomplete") {
            dispatch(
              setIncompleteCount({
                incompleteCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            }
            {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }

        setLoading(false);
      });
    }
  }
  
  if (type === "user") {
    const res = await fetchUserData();

    if (searchText === "") {
      if (res.data) {
        const users = res.data;
        dispatch(setUsersCount({ usersCount: res.data?.docs?.length ?? "" }));
        if (users && users?.previous) {
          batch(() => {
            dispatch(setUsersUsers(users?.docs));
            dispatch(setNextId(users?.next));
            dispatch(setPrevId(users?.previous));
          });
        } else {
          batch(() => {
            dispatch(setUsersUsers(users?.docs));
            dispatch(setNextId(users?.next));
          });
        }
        setLoading(false);
      }
    } else {
      searchUsers(searchText).then((res) => {
        const users = res.data;
        if (res.data) {
          dispatch(setUsersCount({ usersCount: res.data?.docs?.length ?? "" }));
          if (users && users?.previous) {
            batch(() => {
              dispatch(setUsersUsers(users?.docs));
              dispatch(setNextId(users?.next));
              dispatch(setPrevId(users?.previous));
            });
          } else {
            batch(() => {
              dispatch(setUsersUsers(users?.docs));
              dispatch(setNextId(users?.next));
            });
          }
          setLoading(false);
        } else {
          Toast("Failed to connect, Please check your internet connection.");
          setLoading(false);
        }
      });
    }
  }


  if (type === "claims") {
    const res = await fetchClaims();
    if (searchText === "") {
      if (res.data) {
        const {
          docs,
          next,
          previous: prev,
        } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (activeTab.toLowerCase() === "rejected" && prev) {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }

        setLoading(false);
      }
    } else {
      searchClaims(searchText).then((res) => {
        const {
          docs,
          next,
          previous: prev,
        } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (prev) {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (activeTab.toLowerCase() === "rejected" && prev) {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(prev));
              });
            } else {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      });
    }
  }
  if (type === "repairs") {
    const res = await fetchRepairs();
    if (searchText === "") {
      if (res.data) {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "hotlist") {
            dispatch(
              setHotlistCount({ hotlistCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "others") {
            dispatch(
              setOthersCount({ othersCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      }
    } else {
      searchRepairs(searchText).then((res) => {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "hotlist") {
            dispatch(
              setHotlistCount({ hotlistCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "others") {
            dispatch(
              setOthersCount({ othersCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      });
    }
  }
  if (type === "orders") {
    const res = await fetchOrders();
    if (searchText === "") {
      if (res.data) {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "hotlist") {
            dispatch(
              setHotlistCount({ hotlistCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "review") {
            dispatch(
              setReviewCount({
                reviewCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "others") {
            dispatch(
              setOthersCount({ othersCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      }
    } else {
      searchOrders(searchText).then((res) => {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;

        for (const key in obj) {
          if (key === "hotlist") {
            dispatch(
              setHotlistCount({ hotlistCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "review") {
            dispatch(
              setReviewCount({
                reviewCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "others") {
            dispatch(
              setOthersCount({ othersCount: obj[key]?.docs?.length ?? "" })
            );
            if (previous) {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      });
    }
  }
  if (type === "transactions") {
    const res = await fetchTransactionData();

    if (searchText === "") {
      if (res.status) {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;
        for (const key in obj) {
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "success") {
            dispatch(
              setSuccessCount({
                successCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setSuccessTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "failed") {
            dispatch(
              setFailedCount({
                failedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setFailedTransactions(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      } else {
        Toast(res.message, "error");
      }
    } else {
      searchTransactions(searchText).then((res) => {
        try {
          if (res.status === 200 && res.data) {
            let docs, next, previous;
            ({ docs, next, previous } = res.data);

            if (activeTab.toLowerCase() === "pending") {
              dispatch(
                setPendingCount({
                  pendingCount: res.data?.docs?.length ?? "",
                })
              );
              if (previous) {
                batch(() => {
                  dispatch(setPendingTransactions(docs));
                  dispatch(setNextId(next));
                  dispatch(setPrevId(previous));
                });
              } else {
                batch(() => {
                  dispatch(setPendingTransactions(docs));
                  dispatch(setNextId(next));
                });
              }
            }
            if (activeTab.toLowerCase() === "success") {
              dispatch(
                setSuccessCount({
                  successCount: res.data?.docs?.length ?? "",
                })
              );
              if (previous) {
                batch(() => {
                  dispatch(setSuccessTransactions(docs));
                  dispatch(setNextId(next));
                  dispatch(setPrevId(previous));
                });
              } else {
                batch(() => {
                  dispatch(setSuccessTransactions(docs));
                  dispatch(setNextId(next));
                });
              }
            }
            if (activeTab.toLowerCase() === "failed") {
              dispatch(
                setFailedCount({
                  failedCount: res.data?.docs?.length ?? "",
                })
              );
              if (previous) {
                batch(() => {
                  dispatch(setFailedTransactions(docs));
                  dispatch(setNextId(next));
                  dispatch(setPrevId(previous));
                });
              } else {
                batch(() => {
                  dispatch(setFailedTransactions(docs));
                  dispatch(setNextId(next));
                });
              }
            }
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  if (type === "withdrawals") {
    const res = await fetchWithdrawalsData();

    if (searchText === "") {
      if (res.status) {
        const { docs, next, previous } = res.data[`${activeTab.toLowerCase()}`];
        const obj = res.data;
        for (const key in obj) {
          if (key === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingWithdrawals(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingWithdrawals(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "paid") {
            dispatch(
              setPaidCount({
                paidCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPaidWithdrawals(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPaidWithdrawals(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (key === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: obj[key]?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedWithdrawals(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedWithdrawals(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        setLoading(false);
      } else {
        Toast(res.message, "error");
      }
    } else {
    }
  }


  if (type === "sales") {
    const res = await fetchSalesData();

    if (searchText === "") {
      if (res.data) {
        const sales = res.data;
        dispatch(setSalesCount({ salesCount: sales?.res.data?.count ?? 0 }));
        if (sales && sales?.previous) {
          batch(() => {
            dispatch(setSales(sales?.docs));
            dispatch(setNextId(sales?.next));
            dispatch(setPrevId(sales?.previous));
          });
        } else {
          batch(() => {
            dispatch(setSales(sales?.docs));
            dispatch(setNextId(sales?.next));
          });
        }
        setLoading(false);
      }
    } else {
      searchSales(searchText).then((res) => {
        const sales = res.data;
        if (res.data) {
          dispatch(setSalesCount({ salesCount: sales?.res.data?.count ?? 0 }));
          if (sales && sales?.previous) {
            batch(() => {
              dispatch(setSales(sales?.docs));
              dispatch(setNextId(sales?.next));
              dispatch(setPrevId(sales?.previous));
            });
          } else {
            batch(() => {
              dispatch(setSales(sales?.docs));
              dispatch(setNextId(sales?.next));
            });
          }
          setLoading(false);
        } else {
          Toast("Failed to connect, Please check your internet connection.");
          setLoading(false);
        }
      });
    }
  }

  if (type === "technicians") {
    const res = await fetchTechniciansData();

    if (res.data) {
      const technicians = res.data;

      if (technicians && technicians?.previous) {
        batch(() => {
          dispatch(setTechnicians(technicians?.docs));
          dispatch(setNextId(technicians?.next));
          dispatch(setPrevId(technicians?.previous));
        });
      } else {
        batch(() => {
          dispatch(setTechnicians(technicians?.docs));
          dispatch(setNextId(technicians?.next));
        });
      }
      setLoading(false);
    }
  }
}

export const useGetData = (type, activeTab, state) => {
  const [searchText, setSearchText] = useState("");
  const [dashboardState, dispatch] = useDashboardContext();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [isSort, setIsSort] = useState(false);
  let timer; // Timer identifier
  const waitTime = 500; // Wait time in milliseconds

  const handleSearchInput = (e) => {
    // Listen for `keyup` event
    const input = document.querySelector("#searchBox");
    input.addEventListener("keyup", (e) => {
      const text = e.currentTarget.value;

      // Clear timer
      clearTimeout(timer);

      // Wait for X ms and then process the request
      timer = setTimeout(() => {
        setSearchText(encodeQueryString(text));
      }, waitTime);
    });
  };

  const handleSort = (e, filtr) => {
    if (activeTab === "default") {
      setLoading(true);
      setFilter(e?.target?.value ?? filtr);
      return getInnerTableData(
        e?.target?.value ?? filtr,
        dispatch,
        setLoading,
        state
      );
    } else {
      setFilterData({
        filter: e?.target?.value,
        previous: "",
        next: "",
        direction: "",
        type: type?.slice(0, -1),
      });
      if (e?.target?.value === "Sort By") return;
      setLoading(true);
      setIsSort(true);
      setFilter(e?.target?.value ?? "approved");
      return handleSorting({
        filter: e?.target?.value,
        previous: "",
        next: "",
        direction: "",
        type: type?.slice(0, -1),
      }).then((res) => {
        const docs = res?.data?.docs;
        const next = res?.data?.next;
        const previous = res?.data?.previous;
        setLoading(false);
        if (type === "repairs") {
          if (activeTab.toLowerCase() === "hotlist") {
            dispatch(setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" }));
            if (previous) {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            dispatch(setOthersCount({ othersCount: res.data?.docs?.length ?? "" }));
            if (previous) {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersRepairs(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        if (type === "plans") {
            dispatch(
              setActiveCount({
                activeCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setActivePlans(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setActivePlans(docs));
                dispatch(setNextId(next));
              });
            }
        }
        if (type === "activations") {
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "expired") {
            dispatch(
              setExpiredCount({
                expiredCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setExpiredActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "incomplete") {
            dispatch(
              setIncompleteCount({
                incompleteCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setIncompleteActivations(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        if (type === "claims") {
          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOngoingClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedClaims(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
        if (type === "orders") {
          if (activeTab.toLowerCase() === "hotlist") {
            dispatch(setHotlistCount({ hotlistCount: res.data?.docs?.length ?? "" }));
            if (previous) {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setHotlistOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "approved") {
            dispatch(
              setApprovedCount({
                approvedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setApprovedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "pending") {
            dispatch(
              setPendingCount({
                pendingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setPendingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "processed") {
            dispatch(
              setProcessedCount({
                processedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setProcessedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "ongoing") {
            dispatch(
              setOngoingCount({
                ongoingCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOngoingOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "review") {
            dispatch(
              setReviewCount({
                reviewCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setReviewOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "rejected") {
            dispatch(
              setRejectedCount({
                rejectedCount: res.data?.docs?.length ?? "",
              })
            );
            if (previous) {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setRejectedOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
          if (activeTab.toLowerCase() === "others") {
            dispatch(setOthersCount({ othersCount: res.data?.docs?.length ?? "" }));
            if (previous) {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
                dispatch(setPrevId(previous));
              });
            } else {
              batch(() => {
                dispatch(setOthersOrders(docs));
                dispatch(setNextId(next));
              });
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      setLoading(true);
      getData(
        dashboardState,
        dispatch,
        type,
        searchText,
        setLoading,
        activeTab
      );
      if (activeTab === "default") handleSort("", "approved");
    }
    return () => {
      sub = false;
    };
  }, [type, searchText, activeTab]);

  return {
    loading,
    handleSearchInput,
    searchText,
    handleSort,
    setLoading,
    filter,
    isSort,
    filterData,
  };
};
