import { Box, Flex, Text, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    Spinner, Image, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthConnect } from "../../util/util";
import walletImg from "../../assets/pngs/wallet.png"


export default function Settings({ setModal }) {
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
    const list = ["Team", "Wallet", "API Keys"]
    const [activeTab, setActiveTab] = useState("Team")
    const [team, setTeam] = useState([])
    const [wallet, setWallet] = useState([])
    const [walletAmt, setWalletAmt] = useState(0)
    const [teamNext, setTeamNext] = useState("")
    const [teamPrev, setTeamPrev] = useState("")
    const [walletNext, setWalletNext] = useState("")
    const [walletPrev, setWalletPrev] = useState("")
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    
    async function loadTeam() {
        setLoading1(true)
        const res = await AuthConnect("get", "sale/partner/admin/salesforce/fetch")
        setTeam(res?.data?.docs)
        if (res?.data?.next !== undefined) {
            setTeamNext(res?.data?.next);
        } else {
            setTeamNext("");
        }
        if (res?.data?.previous !== undefined) {
            setTeamPrev(res?.data?.previous);
        } else {
            setTeamPrev("");
        }
        setLoading1(false)
    }

    async function loadWallet() {
        setLoading2(true)
        const res = await AuthConnect("get", "wallet-history/partner/admin/fetch")
        if(res?.success) {
            setWallet(res?.data?.docs)
            if (res?.data?.next !== undefined) {
                setWalletNext(res?.data?.next);
            } else {
            setWalletNext("");
            }
            if (res?.data?.previous !== undefined) {
            setWalletPrev(res?.data?.previous);
            } else {
            setWalletPrev("");
            }
        }
        setLoading2(false)
    }

    async function nextAction() {
        setLoading3(true);
        setLoading4(false)
        const res = await AuthConnect("get", "wallet-history/partner/admin/fetch?next="+walletNext)
        if(res?.success) {
            setWallet(res?.data?.docs)
            if (res?.data?.next !== undefined) {
                setWalletNext(res?.data?.next);
            } else {
            setWalletNext("");
            }
            if (res?.data?.previous !== undefined) {
            setWalletPrev(res?.data?.previous);
            } else {
            setWalletPrev("");
            }
        }
        setLoading3(false);
    }
    
    async function prevAction() {
        setLoading4(true);
        setLoading3(false)
        const res = await AuthConnect("get", "wallet-history/partner/admin/fetch?previous="+walletPrev)
        if(res?.success) {
            setWallet(res?.data?.docs)
            if (res?.data?.next !== undefined) {
                setWalletNext(res?.data?.next);
            } else {
            setWalletNext("");
            }
            if (res?.data?.previous !== undefined) {
            setWalletPrev(res?.data?.previous);
            } else {
            setWalletPrev("");
            }
        }
        setLoading4(false);
    }


    async function pageAction(id, setNewData, setPaginationNext, setPaginationPrev, setLoader, direction) {
        setLoader(true)
        const res = await AuthConnect("get", "sale/partner/admin/salesforce/fetch?"+direction+"="+id)
        if(res?.success) {
            setNewData(res?.data?.docs)
            if (res?.data?.next !== undefined) {
                setPaginationNext(res?.data?.next);
            } else {
                setPaginationNext("");
            }
            if (res?.data?.previous !== undefined) {
                setPaginationPrev(res?.data?.previous);
            } else {
                setPaginationPrev("");
            }
        }
        setLoader(false);
    }

    async function loadWalletData() {
        const res = await AuthConnect("get", "wallet/partner/admin/fetch")
        if(res?.success) {
            setWalletAmt(res?.data?.balance !== undefined ? res?.data?.balance : 0)
        }
    }

        
    
    const nArr = JSON.parse(sessionStorage.getItem("PartnerPermissions"))
    const walletPermit = nArr.includes("wallets")
    const canAddTeam = nArr.includes("add_users")

    useEffect(() => {
        if(walletPermit) {
            loadTeam()
            loadWallet()
            loadWalletData()
        }
    }, [])


    return (
        <>
        {
        walletPermit === true ?
        <Flex flex="1" minH="90vh" direction="column">
            <Flex
                w="100%"
                direction="row"
                align="baseline"
                >
                <Flex justify="left" align="center" flex="1" flexWrap="wrap">
                    {list.map((item, index) => {
                    return (
                        <Flex
                            bg="#fff"
                            mr="10px"
                            display={item === "API Keys" ? "none" : "flex"}
                            cursor={item === "API Keys" ? "not-allowed" : "pointer"}
                            opacity={item === "API Keys" ? ".5" : "1"}
                            color={
                                activeTab === item
                                ? partnerBg
                                : "#82868C"
                            }
                            key={index}
                            onClick={() => item !== "API Keys" && setActiveTab(item)}
                            borderRadius="8px"
                            boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                            p="0.5rem 2rem"
                            mb="3"
                        >
                            {item}
                        </Flex>
                    );
                    })}
                </Flex>
                {
                    activeTab === "Team" && canAddTeam && 
                    <Flex justify="left" align="center"> 
                        <Box
                            bg={partnerBg}
                            mr="10px"
                            fontWeight="700"
                            fontSize="14px"
                            lineheight="16px"
                            cursor="pointer"
                            color={"#fff"}
                            onClick={() => {
                                setModal({
                                    modalId: "ADD_TEAM",
                                    onOpen: true,
                                    data: "",
                                    refreshFunc: () => {},
                                });
                            }}
                            borderRadius="8px"
                            boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                            p="0.5rem 2rem"
                        >
                            Add team member
                        </Box>
                    </Flex>
                }
            </Flex>

            {
                activeTab === "Wallet" &&
                <Flex mt="30px" w="50%" border="1px solid #EBEBE6" boxShadow="0px 24px 48px 0px rgba(47, 51, 59, 0.18)" borderRadius="8px" px="4" pt="5" pb="10" align="center">
                    <Flex flex="1" direction="column">
                        <Text fontSize="13px" fontWeight="500">Wallet balance</Text>
                        <Text fontSize="32px" fontWeight="700">₦{parseInt(walletAmt)?.toLocaleString()}.00</Text>
                    </Flex>
                    <Flex ml="5"><Image src={walletImg} w="87px" /></Flex>
                </Flex>
            }

            <Flex
                direction="column"
                bg="#fff"
                mt="30px"
                borderRadius= "15px"
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
                boxShadow="0px 24px 48px 0px rgba(47, 51, 59, 0.18)"
                p="16px 15px 20px"
                flex="1"
                mb="12"
            >
                {
                    activeTab === "Team" ?
                    <>
                    <Flex w="100%" direction="column" flex="1">
                        <Table size="md" w="100%">
                            <Thead>
                                <Tr bg="#fff">
                                {["Date Added", "Email", "Full Name", "Role", "Phone Number"].map((item, index) => (
                                    <Th key={index}>{item}</Th>
                                ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading1 ? <Flex minH="50vh" align="center" justify="center"><Spinner color={partnerBg} emptyColor="lightgrey" /></Flex> : 
                                Array.isArray(team) && team.map((item, index) => (
                                    <Tr
                                    key={index}
                                    background={index % 2 === 0 ? "#FBFBFB" : "#fff"}
                                    >
                                        <Td isNumeric>
                                            {item.createdAt ? (
                                            item.createdAt.substr(0, 10)
                                            ) : (
                                            <Text color="rgb(215,215,215)">---- -- --</Text>
                                            )}
                                        </Td>
                                        <Td><Text wordBreak="break-all">{item?.email}</Text></Td>
                                        <Td>{item?.firstName + " " + item?.lastName}</Td>
                                        <Td>{item?.role}</Td>
                                        <Td><Text wordBreak="break-all">{item?.phoneNumber}</Text></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Flex>
                    <Flex mt="8" mb="6" w="100%" justify="flex-end">
                        {teamPrev !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            mr="8"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(teamPrev, setTeam, setTeamNext, setTeamPrev, setLoading5, "previous")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading5 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text><Text mr="2" as="span"><i className="mdi mdi-arrow-left"></i></Text> Previous</Text>}
                            </Button>
                        )}
                        {teamNext !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(teamNext, setTeam, setTeamNext, setTeamPrev, setLoading6, "previous")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading6 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text>Next <Text ml="2" as="span"><i className="mdi mdi-arrow-right"></i></Text> </Text>}
                            </Button>
                        )}
                    </Flex>
                    </>
                    :
                    activeTab === "Wallet" ? <>
                    <Flex w="100%" direction="column" flex="1">
                        <Table size="md" w="100%">
                            <Thead>
                                <Tr bg="#fff">
                                {["Date", "Transaction Type", "Amount (₦)", "Narration"].map((item, index) => (
                                    <Th key={index}>{item}</Th>
                                ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading2 ? <Flex minH="50vh" align="center" justify="center"><Spinner color={partnerBg} emptyColor="lightgrey" /></Flex> : 
                                Array.isArray(wallet) && wallet.map((item, index) => (
                                    <Tr
                                    key={index}
                                    background={index % 2 === 0 ? "#FBFBFB" : "#fff"}
                                    >
                                        <Td isNumeric>
                                            {item.createdAt ? (
                                            item.createdAt.substr(0, 10)
                                            ) : (
                                            <Text color="rgb(215,215,215)">---- -- --</Text>
                                            )}
                                        </Td>
                                        <Td><Text wordBreak="break-all">{item?.type}</Text></Td>
                                        <Td>{item?.amount ? item?.amount?.toLocaleString() : 0}</Td>
                                        <Td><Text wordBreak="break-all">{item?.narration}</Text></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Flex>
                    <Flex mt="8" mb="6" w="100%" justify="flex-end">
                        {walletPrev !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            mr="8"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => prevAction()}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading4 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text><Text mr="2" as="span"><i className="mdi mdi-arrow-left"></i></Text> Previous</Text>}
                            </Button>
                        )}
                        {walletNext !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => nextAction()}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading3 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text>Next <Text ml="2" as="span"><i className="mdi mdi-arrow-right"></i></Text></Text>}
                            </Button>
                        )}
                    </Flex>
                    </>
                    :
                    <></>
                }
            </Flex>
        </Flex>
        :
        <Flex w="100%" justify="center" align="center" borderRadius="8px" bg="#fff" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.14)" py="12" px="6" h="80vh" mb="12">
            <Text textAlign="center" fontWeight="bold">You can't access this section</Text>
        </Flex>
        }
    </>
    )
}