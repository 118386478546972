import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Text,
  TableContainer,
} from "@chakra-ui/react";
import React, { memo, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import Tables from "./Tables";
import { DropdownIcon, SearchIcon } from "./helper";
import { sortList } from "../../pages/protectionPlans/helper";

// stage 1 of filter
const filterState = (arr, type) => {
  let state;
  state = [];
  if (type === "expired") {
    arr.filter((item) => {
      if (item.status === "expired" || item.status === undefined)
        state.push(item);
    });
  } else if (type === "active") {
    arr.filter((item) => {
      if (item.status !== "expired" && item.status !== undefined)
        state.push(item);
    });
  }
  return state;
};

const tabSwitch = (tab, state, tableHeader, type, setModal) => {
  switch (tab) {
    case "Pending":
      return (
        <ActiveTab
          state={state.pending}
          tableHeader={tableHeader}
          tab={tab}
          type={type}
          setModal={setModal}
        />
      );
    case "Approved":
      return (
        <ActiveTab
          state={state.approved}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Processed":
      return (
        <ActiveTab
          state={state.processed}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Rejected":
      return (
        <ActiveTab
          state={state.rejected}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Hotlist":
      return (
        <ActiveTab
          state={state.hotlist}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Review":
      return (
        <ActiveTab
          state={state.review}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Ongoing":
      return (
        <ActiveTab
          state={state.ongoing}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Others":
      return (
        <ActiveTab
          state={state.others}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Success":
      return (
        <ActiveTab
          state={state.success}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Failed":
      return (
        <ActiveTab
          state={state.failed}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Paid":
      return (
        <ActiveTab
          state={state.paid}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "All Sales":
      return (
        <ActiveTab
          state={state.sale}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Profile":
      return (
        <ActiveTab
          state={state.technician}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
        />
      );
    case "Active":
      return (
        <ActiveTab
          state={state.active}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
      case "All Plans":
        return (
          <ActiveTab
            state={state.plan}
            tableHeader={tableHeader}
            type={type}
            setModal={setModal}
            tab={tab}
          />
        );
    case "Expired":
      return (
        <ActiveTab
          state={state.expired}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Rejected":
      return <></>;
    case "User":
      return (
        <ActiveTab
          state={state}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Admins":
      return (
        <ActiveTab
          state={state.admins}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Business Users":
      return (
        <ActiveTab
          state={state.business}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Technicians":
      return (
        <ActiveTab
          state={state.technicians}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "All Users":
      return (
        <ActiveTab
          state={state.users}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Incomplete":
      return (
        <ActiveTab
          state={state.incomplete}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    case "Business":
      return (
        <ActiveTab
          state={state.business}
          tableHeader={tableHeader}
          type={type}
          setModal={setModal}
          tab={tab}
        />
      );
    // case 'Users':
    // 	return (
    // 		<ActiveTab
    // 			state={filterState(state, 'expired')}
    // 			tableHeader={tableHeader}
    // 			type={type}
    // 			setModal={setModal}
    // 		/>
    // 	);
    default:
      return <></>;
  }
};

const TabContainer = ({
  activeTab,
  state,
  handleSearchInput,
  loading,
  tableHeader,
  type,
  setModal,
  handleSort,
  isPartner,
}) => {
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
  return (
    <Flex w="100%" direction="column" bg="#fff">
      <Flex>
        <Flex direction="row" w="100%" align="center" bg="#fff" top="0">
          <Flex
            border="1px solid #E3E6EB"
            borderRadius={isPartner ? "6px" : "4px"}
            w="fit-content"
            sx={{ ":hover": { border: isPartner ? "1px solid "+partnerBg : "1px solid #03A63C" } }}
            h="fit-content"
            zIndex="0"
          >
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon />}
              />
              <Input
                border="none"
                outline="none"
                id="searchBox"
                focusBorderColor="none"
                w="283px"
                placeholder="Search ID, phone no, email"
                fontFamily="Montserrat"
                fontWeight="500"
                fontSize="13px"
                onChange={(e) => handleSearchInput(e)}
              />
            </InputGroup>
          </Flex>
          {/*<Flex ml="60px" justify="center" align="center">
            <Text mr="10px">Sort:</Text>
            <Flex
              borderRadius={isPartner ? "6px" : "4px"}
              sx={{ ":hover": { border: isPartner ? "1px solid "+partnerBg : "1px solid #03A63C" } }}
              border="1px solid #E3E6EB"
            >
              <Select
                w="183px"
                border="none"
                outline="none"
                focusBorderColor="none"
                fontFamily="Montserrat"
                fontWeight="500"
                fontSize="13px"
                lineheight="15px"
                onChange={(e) => handleSort(e)}
                icon={<DropdownIcon fill={"#3A3C40"} />}
              >
                {sortList(type)?.map((item, index) => {
                  return (
                    <option key={_.uniqueId("tab-container1")}>
                      {item === "" ? "Sort By" : item}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            </Flex>*/}
        </Flex>
      </Flex>
      <Flex
        minH="558px"
        overflowX="scroll"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
        w="100%"
        fontSize="12px"
      >
        {!loading ? (
          tabSwitch(activeTab, state, tableHeader, type, setModal)
        ) : (
          <Flex justify="center" align="center" w="100%">
            <Spinner w="60px" h="60px" color={partnerBg} emptyColor="grey" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TabContainer;

const ActiveTab = ({ state = [], tableHeader, type, setModal, tab }) => {
  return (
    <Flex w="100%">
      <TableContainer
        w="100%"
        mt="40px"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        <Tables
          type={type}
          tableHeader={tableHeader}
          tableContent={state}
          setModal={setModal}
          tab={tab}
        />
      </TableContainer>
    </Flex>
  );
};
