import { Box, Flex, Text, Button, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    Input,
    InputGroup,
    InputLeftElement,
    Spinner, Image, } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthConnect } from "../../util/util";
import walletImg from "../../assets/pngs/wallet.png"
import { SearchIcon } from "@chakra-ui/icons";


export default function Voucher({ setModal }) {
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
    const list = ["Used", "Sold"]
    const [activeTab, setActiveTab] = useState("Used")
    const [soldData, setSoldData] = useState([])
    const [usedData, setUsedData] = useState([])
    const [soldNext, setSoldNext] = useState("")
    const [soldPrev, setSoldPrev] = useState("")
    const [usedNext, setUsedNext] = useState("")
    const [usedPrev, setUsedPrev] = useState("")
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    
    async function loadSold() {
        setLoading1(true)
        const res = await AuthConnect("get", "voucher/partner/admin/fetch?status=sold")
        setSoldData(res?.data?.docs)
        setLoading1(false)
        if (res?.data?.next !== undefined) {
            setSoldNext(res?.data?.next);
        } else {
            setSoldNext("");
        }
        if (res?.data?.previous !== undefined) {
            setSoldPrev(res?.data?.previous);
        } else {
            setSoldPrev("");
        }
    }

    async function loadUsed() {
        setLoading2(true)
        const res = await AuthConnect("get", "voucher/partner/admin/fetch?status=used")
        setUsedData(res?.data?.docs)
        setLoading2(false)
        if (res?.data?.next !== undefined) {
            setUsedNext(res?.data?.next);
        } else {
            setUsedNext("");
        }
        if (res?.data?.previous !== undefined) {
            setUsedPrev(res?.data?.previous);
        } else {
            setUsedPrev("");
        }
    }

    async function pageAction(id, status, setNewData, setPaginationNext, setPaginationPrev, setLoader, direction) {
        setLoader(true)
        const res = await AuthConnect("get", "voucher/partner/admin/fetch?status="+status+"&"+direction+"="+id)
        if(res?.success) {
            setNewData(res?.data?.docs)
            if (res?.data?.next !== undefined) {
                setPaginationNext(res?.data?.next);
            } else {
                setPaginationNext("");
            }
            if (res?.data?.previous !== undefined) {
                setPaginationPrev(res?.data?.previous);
            } else {
                setPaginationPrev("");
            }
        }
        setLoader(false);
    }



    async function handleSearchInput(e) {
        if(e.target.value !== "") {
            const status = activeTab === "Sold" ? "sold" : "used"
            activeTab === "Sold" ? setLoading1(true) : setLoading2(true)
            const res = await AuthConnect("get", "voucher/partner/admin/fetch?status="+status+"&search="+e.target.value)
            activeTab === "Sold" ? setSoldData(res?.data?.docs) : setUsedData(res?.data?.docs)
            activeTab === "Sold" ? setLoading1(false) : setLoading2(false)
        }
        else {
            activeTab === "Sold" ? loadSold() : loadUsed()
        }
    }


    useEffect(() => {
        loadSold()
        loadUsed()
    }, [])

    return (
        <Flex flex="1" minH="90vh" direction="column">
            <Flex
                w="100%"
                direction="row"
                align="baseline"
                >
                <Flex justify="left" align="center" flex="1" flexWrap="wrap">
                    {list.map((item, index) => {
                    return (
                        <Flex
                            bg="#fff"
                            mr="10px"
                            cursor={item === "API Keys" ? "not-allowed" : "pointer"}
                            opacity={item === "API Keys" ? ".5" : "1"}
                            color={
                                activeTab === item
                                ? partnerBg
                                : "#82868C"
                            }
                            key={index}
                            onClick={() => item !== "API Keys" && setActiveTab(item)}
                            borderRadius="8px"
                            boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                            p="0.5rem 2rem"
                            mb="3"
                        >
                            {item}
                        </Flex>
                    );
                    })}
                </Flex>
            </Flex>

            <Flex
                direction="column"
                bg="#fff"
                mt="30px"
                borderRadius= "15px"
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
                boxShadow="0px 24px 48px 0px rgba(47, 51, 59, 0.18)"
                p="16px 15px 20px"
                flex="1"
                mb="12"
            >
                <Flex
                    border="1px solid #E3E6EB"
                    borderRadius="6px"
                    w="fit-content"
                    sx={{ ":hover": { border:"1px solid "+partnerBg } }}
                    h="fit-content"
                    zIndex="0"
                    mb="5"
                >
                    <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon />}
                    />
                    <Input
                        border="none"
                        outline="none"
                        id="searchBox"
                        focusBorderColor="none"
                        w="283px"
                        placeholder="Search Voucher code, Serial no"
                        fontFamily="Montserrat"
                        fontWeight="500"
                        fontSize="13px"
                        onChange={(e) => handleSearchInput(e)}
                    />
                    </InputGroup>
                </Flex>
                {
                    activeTab === "Sold" ?
                    <>
                    <Flex w="100%" direction="column" flex="1">
                        <Table size="md" w="100%">
                            <Thead>
                                <Tr bg="#FBFBFB">
                                    {["Date Sold", "Voucher Code", "Serial No", "Sold By"].map((item, index) => (
                                        <Th key={index}>{item}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading1 ? <Flex minH="50vh" align="center" justify="center"><Spinner color={partnerBg} emptyColor="lightgrey" /></Flex> : 
                                Array.isArray(soldData) && soldData.map((item, index) => (
                                    <Tr
                                    key={index}
                                    background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
                                    >
                                        <Td isNumeric>
                                            {item.dateSold ? (
                                            item.dateSold.substr(0, 10)
                                            ) : (
                                            <Text color="rgb(215,215,215)">---- -- --</Text>
                                            )}
                                        </Td>
                                        <Td><Text wordBreak="break-all">{item?.voucherCode}</Text></Td>
                                        <Td><Text wordBreak="break-all">{item?.serialNo}</Text></Td>
                                        <Td>{(item?.soldBy !== "" && item?.soldBy !== undefined) ? item?.soldBy : "N/A"}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Flex>
                    <Flex mt="8" mb="6" w="100%" justify="flex-end">
                        {soldPrev !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            mr="8"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(soldPrev, "sold", setSoldData, setSoldNext, setSoldPrev, setLoading4, "previous")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading4 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text><Text mr="2" as="span"><i className="mdi mdi-arrow-left"></i></Text> Previous</Text>}
                            </Button>
                        )}
                        {soldNext !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(soldNext, "sold", setSoldData, setSoldNext, setSoldPrev, setLoading5, "next")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading3 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text>Next <Text ml="2" as="span"><i className="mdi mdi-arrow-right"></i></Text> </Text>}
                            </Button>
                        )}
                    </Flex>
                    </>
                    :
                    <>
                    <Flex w="100%" direction="column" flex="1">
                        <Table size="md" w="100%">
                            <Thead>
                                <Tr bg="#FBFBFB">
                                    {["Date Used", "Voucher Code", "Serial No", "Sold By"].map((item, index) => (
                                        <Th key={index}>{item}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading2 ? <Flex minH="50vh" align="center" justify="center"><Spinner color={partnerBg} emptyColor="lightgrey" /></Flex> : 
                                Array.isArray(usedData) && usedData.map((item, index) => (
                                    <Tr
                                    key={index}
                                    background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
                                    >
                                        <Td isNumeric>
                                            {item.usedAt ? (
                                            item.usedAt.substr(0, 10)
                                            ) : (
                                            <Text color="rgb(215,215,215)">---- -- --</Text>
                                            )}
                                        </Td>
                                        <Td><Text wordBreak="break-all">{item?.voucherCode}</Text></Td>
                                        <Td><Text wordBreak="break-all">{item?.serialNo}</Text></Td>
                                        <Td>{(item?.soldBy !== "" && item?.soldBy !== undefined) ? item?.soldBy : "N/A"}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Flex>
                    <Flex mt="8" mb="6" w="100%" justify="flex-end">
                        {usedPrev !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            mr="8"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(usedPrev, "used", setUsedData, setUsedNext, setUsedPrev, setLoading5, "previous")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading5 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text><Text mr="2" as="span"><i className="mdi mdi-arrow-left"></i></Text> Previous</Text>}
                            </Button>
                        )}
                        {usedNext !== "" && (
                            <Button
                            bg={partnerBg}
                            color="#fff"
                            px="20px"
                            py="9px"
                            fontWeight="bold"
                            borderRadius="4px"
                            fontSize="13px"
                            onClick={() => pageAction(usedNext, "used", setUsedData, setUsedNext, setUsedPrev, setLoading6, "next")}
                            border="none"
                            _hover={{ cursor: "pointer" }}
                            >
                            {loading6 ? <Spinner emptyColor="#fff" color="lightgrey" /> : <Text>Next <Text ml="2" as="span"><i className="mdi mdi-arrow-right"></i></Text> </Text>}
                            </Button>
                        )}
                    </Flex>
                    </>
                }
            </Flex>
        </Flex>
    )
}