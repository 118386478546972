import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { getRepairRepairCost } from "./helpers";
import _ from "lodash";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#49A05C",
  color: "#fff",
};

const SendRepairCost = ({ onClose, modal, setModal }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const cost = watch("repairCost");
  const discount = watch("discount");
  const deliveryFee = watch("deliveryFee");
  const [totalPrice, setTotalPrice] = useState(_.toNumber(`0.00`));
  const [loading, setLoading] = useState(false);

  const onSubmit = (formData) => {
    setLoading(true);
    const { data } = modal;
    const { repairCost, discount, deliveryFee } = formData;

    const payload = {
      repairCost: parseFloat(repairCost),
      discount: parseFloat(discount),
      deliveryFee: parseFloat(deliveryFee),
      repairId: data,
    };
    getRepairRepairCost(payload).then(async (res) => {
      if (res.message === "Repair Cost Submitted Successfully") {
        await modal?.refreshFunc();
        onClose();
        return setModal({
          modalId: "SUCCESS",
          title: "Success",
          onOpen: true,
          data: res.message,
        });
      }
      setLoading(false);
    });
  };

  const calulatePrice = async (repairCost, discount, deliveryFee) => {
    return new Promise((resolve, reject) => {
      let cost = _.toNumber(repairCost);
      let gampDiscount = _.toNumber(discount);
      let deliveryFees = _.toNumber(deliveryFee);
      let afterFees = _.add(cost, deliveryFees);

      resolve(_.subtract(afterFees, gampDiscount));
    });
  };

  useEffect(() => {
    if ((cost, discount, deliveryFee)) {
      calulatePrice(cost, discount, deliveryFee).then((total) => {
        setTotalPrice(total);
      });
    }
  }, [cost, discount, deliveryFee]);

  return (
    <Flex direction="column" pb="3rem">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600">
          {`Submit Repair Cost`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={"none"}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box py="2rem 0.5rem 1rem" mt="1rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label has-text-weight-light ">
                Input repair cost <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  ref={register({ required: true })}
                  name="repairCost"
                />
              </div>
              {errors.repairCost && (
                <p className="help is-danger">Please enter the repair cost</p>
              )}
            </div>
            <div className="field">
              <label className="label has-text-weight-light ">
                Discount (₦) <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  ref={register({ required: true })}
                  name="discount"
                />
              </div>
              {errors.gampCoverage && (
                <p className="help is-danger">Please enter the discount (₦)</p>
              )}
            </div>

            <div className="field">
              <label className="label has-text-weight-light ">
                Delivery fee <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  ref={register({ required: true })}
                  name="deliveryFee"
                />
              </div>
              {errors.deliveryFee && (
                <p className="help is-danger">Please enter the delivery fee</p>
              )}
            </div>

            <Box w="100%" bg="#c5c5c5" h="44px" mt="2rem" borderRadius="5px">
              <Flex
                w="100%"
                h="100%"
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                p="0rem 2rem 0rem 1rem"
              >
                <Box>
                  <Text>Total Price:</Text>
                </Box>
                <Box>
                  <Text fontSize="1.15rem" fontWeight="600">
                    ₦{totalPrice}
                  </Text>
                </Box>
              </Flex>
            </Box>

            <Box w="100%" mt="2.3rem">
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Box>
                  <Button
                    style={buttonStyles}
                    textAlign="center"
                    onClick={onClose}
                    width={"100%"}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    style={buttonStyles}
                    textAlign="center"
                    width={"100%"}
                  >
                    {loading ? (
                      <Spinner color="#fff" emptyColor="lightgrey" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default SendRepairCost;
