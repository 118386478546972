import { batch } from "react-redux";
import {
  fetchLinkedDeviceVideo,
  acceptVideo,
  rejectVideo,
} from "../util/helpers/request";
import { setViewDetails, setViewType } from "../util/reducers/viewDetailsSlice";

const fetchLinkedDeviceVid = async (activationId) => {
  const res = await fetchLinkedDeviceVideo(activationId);
  return res;
};

const sortForOne = (
  type,
  tokenId,
  state,
  dispatch,
  values,
  setValues,
  setLoading
) => {
  if (type === "plans") {
    const arr = state.protectionPlans;
    const sortedArr = [];
    if (!Array.isArray(arr)) return;
    arr.filter((item) => {
      item.planId === tokenId && sortedArr.push(item);
    });
    setValues({ ...values, details: sortedArr[0], viewType: type });
    batch(() => {
      dispatch(setViewType(type));
      dispatch(setViewDetails(sortedArr[0]));
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return;
  }
  if (type === "claims") {
    const arr = state.protectionPlans;
    const sortedArr = [];
    if (!Array.isArray(arr)) return;
    arr.filter((item) => {
      item.claimId === tokenId && sortedArr.push(item);
    });
    setValues({ ...values, details: sortedArr[0], viewType: type });
    batch(() => {
      dispatch(setViewType(type));
      dispatch(setViewDetails(sortedArr[0]));
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return;
  }
  if (type === "repairs") {
    const arr = state.protectionPlans;
    const sortedArr = [];
    if (!Array.isArray(arr)) return;
    arr.filter((item) => {
      item.repairId === tokenId && sortedArr.push(item);
    });
    return sortedArr;
  }
};

const acceptVideoSubmission = async (data, setModal) => {
  const payload = {
    activationId: data,
  };
  const res = await acceptVideo(payload);
  if (res && res?.message === "Activation Approved Successfully") {
    setModal({
      modalId: "SUCCESS",
      title: "Activations",
      onOpen: true,
      data: "You’ve successfully approved this “Link Device Request”",
      messageTitle: "Activation approved!",
    });
  }
};

const rejectVideoSubmission = async (data, setModal) => {
  const payload = {
    activationId: data,
  };
  const res = await rejectVideo(payload);
  if (res && res?.message === "Activation Rejected Successfully") {
    setModal({
      modalId: "SUCCESS",
      title: "Activations",
      onOpen: true,
      data: "You’ve successfully rejected this “Link Device Request”",
      messageTitle: "Activation rejected!",
    });
  }
};

const brands_constants = {
  PHONE_BRANDS: [
    "Acer",
    "Alcatel",
    "Allview",
    "Amazon",
    "Asus",
    "AT&T",
    "Blu",
    "Celkon",
    "Coolpad",
    "Dell",
    "Ericsson",
    "Gionee",
    "Google",
    "HTC",
    "Huawei",
    "Iphone",
    "I-Mate",
    "I-Mobile",
    "Icemobile",
    "Infinix",
    "Itel",
    "Lenovo",
    "LG",
    "Microsoft",
    "Motorola",
    "Nokia",
    "OPPO",
    "Panasonic",
    "Qmobile",
    "Samsung",
    "Sony",
    "Sony Ericsson",
    "T-Mobile",
    "Tecno",
    "Toshiba",
    "Vivo",
    "Vodafone",
    "Xiaomi",
    "Zte",
  ],

  PHONE_ISSUES: [
    "Software",
    "Broken screen",
    "Broken body part",
    "Back housing/ cover",
    "Charging",
    "Bad Battery",
    "Water damage",
    "Faulty camera",
    "Won’t turn on",
    "I don’t know",
    "I would also like a FREE battery test",
    "Others",
  ],

  LAPTOP_BRANDS: [
    "Macbook",
    "Dell",
    "HP",
    "Lenovo",
    "Samsung",
    "Acer",
    "Asus",
    "Chromebook",
    "Sony",
    "Microsoft Surface",
  ],

  LAPTOP_ISSUES: [
    "Software",
    "Cracked/ bad Screen",
    "Broken body part",
    "Water Damage",
    "Damaged Charging Port",
    "Speaker Not Working",
    "Bad Battery",
    "Bad keyboard",
    "No Signal",
    "I don’t know",
  ],

  DESKTOP_BRANDS: ["iMac", "Dell", "HP", "Lenovo", "Samsung", "Acer", "Asus"],

  DESKTOP_ISSUES: [
    "Software",
    "Cracked/ bad Screen",
    "Broken body part",
    "Water Damage",
    "Damaged Charging Port",
    "Speaker Not Working",
    "Bad Battery",
    "Bad keyboard",
    "No Signal",
    "I don’t know",
    "Others",
  ],

  ISSUES: [
    "Software",
    "Broken screen",
    "Broken body part",
    "Back housing/ cover",
    "Charging",
    "Speaker Not Working",
    "Battery",
    "Water damage",
    "Faulty camera",
    "Bad keyboard",
    "Won’t turn on",
    "No Signal",
    "I would also like a FREE battery test",
    "I don’t know",
    "Others",
  ],

  COVERAGE: [
    {
      name: "Apple",
      link: "https://checkcoverage.apple.com/ng/en/;jsessionid=node0kd9ua5vc2g4z1pyyzp2rxsc23126120.node0 ",
    },
    {
      name: "Samsung",
      link: "https://account.samsung.com/accounts/v1/CyberService/signInGate?response_type=code&client_id=661924lxg8&locale=en_KE&countryCode=KE&redirect_uri=https:%2F%2Fwww.samsung.com%2Fafrica_en%2Fsupport%2Fyour-service%2FidenCallback&state=9565057057014bb2a49075822722d759&goBackURL=https:%2F%2Fwww.samsung.com%2Fafrica_en%2Fsupport%2Fyour-service%2FidenCallback&scope=&redirect_menu=check-warranty ",
    },
    { name: "Google", link: "https://store.google.com/us/repair " },
    { name: "Tecno", link: "https://www.tecno-mobile.com/ng/warranty/#/" },
    {
      name: "Infinix",
      link: "https://www.imei.info/news/infinix-warranty-activation-status/",
    },
    {
      name: "Itel",
      link: "https://www.imei.info/news/infinix-warranty-activation-status/",
    },
    {
      name: "Huawei",
      link: "https://consumer.huawei.com/ng/support/warranty-query/ ",
    },
    { name: "Oppo", link: "https://support.oppo.com/ng/warranty-check/ " },
    {
      name: "Nokia",
      link: "https://www.nokia.com/phones/en_ng/support/topics/imei-checker",
    },
    { name: "Xiaomi", link: "https://www.mi.com/mea/service/warranty " },
    {
      name: "Sony",
      link: "https://services.sony.ie/supportmvc/en/Repair/WarrantyChecker ",
    },
    { name: "HTC", link: "https://www.htc.com/mea-en/support/contactus/" },
    { name: "Dell", link: "https://www.dell.com/support/home/en-ng " },
    { name: "HP", link: "https://support.hp.com/us-en/checkwarranty " },
    {
      name: "Acer",
      link: "https://www.acer.com/ac/en/US/content/system-information ",
    },
    {
      name: "Asus",
      link: "https://www.asus.com/us/support/warranty-status-inquiry/",
    },
    {
      name: "Microsoft",
      link: "https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1620410749&rver=7.0.6738.0&wp=SAPI&wreply=https%3A%2F%2Faccount.microsoft.com%2Fauth%2Fcomplete-signin%3Fru%3Dhttps%253A%252F%252Faccount.microsoft.com%252Fdevices%253Frefd%253Dsupport.microsoft.com&lc=1033&id=292666&lw=1&fl=easi2",
    },
    {
      name: "Lenovo",
      link: "https://support.lenovo.com/us/en/warrantylookup#/ ",
    },
  ],
};

export {
  fetchLinkedDeviceVid,
  sortForOne,
  acceptVideoSubmission,
  rejectVideoSubmission,
  brands_constants,
};
