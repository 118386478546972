import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
 
  const RejectClaimNew = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const txt = useRef("")
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
    const buttonStyles = {
        outline: "none",
        border: "none",
        padding: "1rem 2rem",
        borderRadius: "5px",
        background: partnerBg,
        color: "#fff",
      };
    async function sendFunc() {
        if(txt.current.value !== "") {
            setLoading(true)
            const res = await AuthConnect("post", "claim/admin/reject", {
                claimId: modal.data,
                notifyCustomer: isNotify,
            })
            if(res?.success) {
                onClose();
                return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully rejected this claim`,
                });
            }
            setLoading(false)
        }
        else {
            Toast("Kindly enter a reason", "error")
        }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Reject Claim`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    <Text mb="2">Reason</Text>
                    <Textarea
                    padding="25px 20px"
                    ref={txt}
                    borderColor="#E3E6EB"
                    _focus={{ border: "1px solid "+partnerBg }}
                    _hover={{ border: "1px solid "+partnerBg }}
                    sx={{ "::placeholder": { color: "#989898" } }}
                    outlineColor="#E3E6EB"
                    w="100%"
                    resize="none"
                    transition="300ms ease-in-out"
                    borderRadius="5px"
                    ></Textarea>
                </Box>
                <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"bb
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default RejectClaimNew;
  