import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import iconPdf from "../../assets/svgs/iconPdf.svg"
  import iconVideo from "../../assets/svgs/iconVideo.svg"
  import iconImg from "../../assets/svgs/iconImg.svg"

  
  export default function ViewUploadFile({ onClose, modal, setModal }) {  
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
    return (
        <>
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            p="1rem 2rem"
            borderBottom="none"
          >
            <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
              {`View File`}
            </Text>
            <ModalCloseButton
              outline="none"
              bg="#fff"
              border="none"
              w="fit-content"
              position="relative"
              right="0rem"
              top="0rem"
              _focus={"none"}
              _hover={{ bg: "none", color: "#03A63C" }}
            />
          </Flex>
          <ModalBody>
            <Box p="0 0.5rem 1rem 0.5rem">
                <Flex w='100%' direction="column" mb="5">
                    {
                        modal.data[1] === "png" || modal.data[1] === "jpg" || modal.data[1] === "jpeg" || modal.data[1] === "webp" || modal.data[1] === "gif" ?
                        <Image w="100%" src={modal.data[0]} borderRadius="10px" border="1px solid #E3E6EB" />
                        :
                        modal.data[1] === "mp4" || modal.data[1]?.toLowerCase() === "mov" || modal.data[1]?.toLowerCase() === "avi" ?
                        <video 
                            src={modal.data[0]}
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                maxHeight: "397px",
                                border: "1px solid #E3E6EB",
                            }}
                            controls
                            playsInline
                        ></video>
                        :
                        <Flex justify="center" my="4"><Flex 
                        style={{
                            background: partnerBg,
                            cursor: "pointer",
                            color: "#fff",
                            fontWeight: "700",
                            justifyContent: "center",
                            padding: "9px 20px",
                            borderRadius: "4px",
                        }} onClick={() => window.open(modal.data[0], "_BLANK")}>Open File</Flex></Flex>
                    }
                </Flex>
            </Box>
          </ModalBody>
        </>
    )
}