import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";


export default function ViewHistory({id, amt }) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

    useEffect(async () => {
        const res = await AuthConnect("get", "action/admin/fetch?tid=" + id)
        setData([res?.data?.docs])

        setLoading(false)
    }, [])


    function fDt(data) {
        var nMonth = "";
        var nDay = "";
        const date = new Date(data);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        if (month === 1) {
        nMonth = "Jan";
        } else if (month === 2) {
        nMonth = "Feb";
        } else if (month === 3) {
        nMonth = "Mar";
        } else if (month === 4) {
        nMonth = "Apr";
        } else if (month === 5) {
        nMonth = "May";
        } else if (month === 6) {
        nMonth = "Jun";
        } else if (month === 7) {
        nMonth = "Jul";
        } else if (month === 8) {
        nMonth = "Aug";
        } else if (month === 9) {
        nMonth = "Sep";
        } else if (month === 10) {
        nMonth = "Oct";
        } else if (month === 11) {
        nMonth = "Nov";
        } else {
        nMonth = "Dec";
        }

        if (day.length === 1) {
        nDay = "0" + day;
        } else {
        nDay = day;
        }


        return "(" + nMonth + " " + nDay + ", " + year + ")"
    }

    function fTm(data) {
        const date = new Date(data);
        var hr =
        new String(date.getHours()).length === 1
            ? "0" + new String(date.getHours())
            : new String(date.getHours());
        var mn =
        new String(date.getMinutes()).length === 1
            ? "0" + new String(date.getMinutes())
            : new String(date.getMinutes());
        return  hr + ":" + mn
    }


    return (
        <Flex w="100%" h="100%">
            {
                loading ?
                <Flex w="100%" h="100%" justify="center" align="center" pb="150px">
                    <Spinner color={partnerBg} emptyColor="grey" />
                </Flex>
                :
                Array.isArray(data) && (
                    data[0]?.length > 0 ?
                    <Flex w="100%" direction="column" px="3">
                        {
                            data[0]?.map((item, index) => (
                                <Flex key={index} w="100%" pr="20%" pl="28px" borderLeft="1px solid rgba(130, 134, 140, 0.7)" pb="8" direction="column">
                                    <Flex align="center">
                                        <Flex border="4px solid #fff" ml="-44px" w="32px" h="32px" fontWeight="600" align="center" justify="center" color="#fff" borderRadius="8px" fontSize="13px" bg={(index+1) % 2 == 0 ? "#FE9280" : "#1A55B5"} textTransform="capitalize">{item?.actionBy?.substr(0,1)}</Flex>

                                        <Text color="#3A3C40" fontSize="13px" ml="13px" fontWeight="600">
                                            {item?.actionBy}
                                            <Text as="span" ml="2" color="#82868C" fontSize="12px">{fDt(item.createdAt)}</Text>
                                            <Text as="span" ml="2" color="#82868C" fontWeight="500" fontSize="11px">{fTm(item.createdAt)}</Text>
                                        </Text>
                                    </Flex>
                                    <Text mt="3" color="#3A3C40" fontWeight="bold">
                                        {item?.action}
                                    </Text>
                                    <Text mt="2" fontSize="13px" color="#3A3C40">
                                        {amt !== undefined && (
                                        <>
                                            <Text as="span"> Amount: ₦</Text>
                                        </>
                                        )}
                                        {amt !== undefined && amt?.toLocaleString()}{" "}
                                        {item?.reason !== "" && (
                                        <>
                                            <Text as="span"> Reason: </Text>
                                        </>
                                        )}{" "}
                                        {item?.reason !== "" && item?.reason}
                                    </Text>
                                </Flex>
                            ))
                        }
                    </Flex>
                    :
                    <Flex w="100%" h="100%" justify="center" align="center" pb="150px">
                        <Text fontWeight="700">No History</Text>
                    </Flex>
                )
            }
        </Flex>
    )
}