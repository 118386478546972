import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { routes } from "../routes";
import logo from "../assets/svgs/logo.svg";
import CloseIcon from "../assets/svgs/closeIcon.svg";
import CheckIcon from "../assets/svgs/checkIcon.svg";
import dashes from "../assets/svgs/dashes.svg";
import { useHistory, useLocation } from "react-router-dom";
import InputHolderUser from "./inputHolderUser";
import { brands_constants } from "./helper";
import { AuthConnect } from "../util/util";
import Toast from "./notify";
import adminUpload from "../assets/svgs/adminUpload.svg";
import videoModalIcon from "../assets/svgs/videoModalIcon.svg";
import right from "../assets/svgs/ArrowRight.svg";
import left from "../assets/svgs/ArrowLeft.svg";
import ReactPlayer from "react-player";
import Checkbox from "./Common/FormGroup/Checkout";
import {
  approveActivation,
  rejectActivationVideo,
  sendNote,
  updateActivationChecklist,
} from "./Modal/helpers";
import checkBoxOn from "../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../assets/svgs/newCheckboxOff.svg";

export function UserModal({
  active,
  setFirstname,
  setLastname,
  setEmail,
  setPhone,
  setPass,
  setActive,
  save,
  processing,
}) {
  return active ? (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      background="rgba(0,0,0,0.7)"
      align="center"
      justify="center"
      zIndex="1"
      direction="column"
    >
      <Flex
        width="30%"
        maxHeight="85vh"
        bgColor="white"
        pt="2%"
        pb="3%"
        borderRadius="5px"
        direction="column"
      >
        <Box
          px="5%"
          borderBottom="1px solid lightgrey"
          mb="3%"
          pb="3%"
          fontWeight="bold"
        >
          Add New Admin
        </Box>

        <Box px="5%" mb="3%" overflowY="scroll">
          <InputHolderUser
            type="text"
            label="First Name"
            onChange={(e) => setFirstname(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Last Name"
            onChange={(e) => setLastname(e.target.value)}
          />
          <InputHolderUser
            type="email"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputHolderUser
            type="number"
            label="Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
          <InputHolderUser
            type="password"
            label="Password"
            onChange={(e) => setPass(e.target.value)}
          />
        </Box>

        <Flex px="5%">
          <Button
            bg="#49A05C"
            py="3%"
            px="13%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="4px"
            onClick={() => setActive(false)}
          >
            Cancel
          </Button>
          <Spacer />
          <Button
            bg="#49A05C"
            py="3%"
            px="13%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="4px"
            isLoading={processing}
            onClick={() => save()}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function PriceModal({
  active,
  setPrice,
  setActive,
  save,
  processing,
  type,
}) {
  return active ? (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      background="rgba(0,0,0,0.7)"
      align="center"
      justify="center"
      zIndex="1"
      direction="column"
    >
      <Flex
        width="30%"
        maxHeight="85vh"
        bgColor="white"
        pt="2%"
        pb="3%"
        borderRadius="5px"
        direction="column"
      >
        <Box
          px="5%"
          borderBottom="1px solid lightgrey"
          mb="3%"
          pb="3%"
          fontWeight="bold"
        >
          {type === "price" ? "Price Submission" : "Status Update"}
        </Box>

        <Box px="5%" mb="3%">
          <InputHolderUser
            type="number"
            label={type === "price" ? "Enter Price" : "Enter Status No"}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Box>

        <Flex px="5%">
          <Button
            bg="#49A05C"
            py="3%"
            px="13%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="4px"
            onClick={() => setActive(false)}
          >
            Cancel
          </Button>
          <Spacer />
          <Button
            bg="#49A05C"
            py="3%"
            px="13%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="4px"
            isLoading={processing}
            onClick={() => save()}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function WithdrawModal({
  active,
  setActive,
  wType,
  setWAmt,
  setWReason,
  processing,
  approvePayment,
  rejectPayment,
  amTo,
}) {
  return active ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setActive(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            {wType === "confirm" ? "Confirm Payment" : "Decline Payment"}
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          {wType === "confirm" ? (
            <InputHolderUser
              type="number"
              label="Input the amount paid to confirm payment"
              onChange={(e) => setWAmt(e.target.value)}
            />
          ) : (
            <InputHolderUser
              type="text"
              label="Reason why withdrawal is rejected (optional)"
              onChange={(e) => setWReason(e.target.value)}
            />
          )}
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setActive(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={wType === "confirm" ? approvePayment : rejectPayment}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : wType === "confirm" ? (
                "Confirm"
              ) : (
                "Decline"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function SuccessModal({
  isOpen,
  setIsOpen,
  title,
  subTitle,
  additionalText,
  btnText,
}) {
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            {title}
          </Text>
          <Image
            src={CloseIcon}
            w={["13px", "15px"]}
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          />
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="10"
          px={["6", "8"]}
          direction="column"
        >
          <Flex justify="center" w="100%" mt="5" mb="12">
            <Image w="120px" h="120px" src={CheckIcon} />
          </Flex>
          <Text
            px="8%"
            fontWeight="bold"
            fontSize="18px"
            mb="3"
            textAlign="center"
          >
            {subTitle}
          </Text>
          <Text px="8%" fontSize="17px" mb="10" textAlign="center">
            {additionalText}
          </Text>
          <Flex
            w="100%"
            bg="#49A05C"
            borderRadius="5px"
            py="2"
            justify="center"
            color="#fff"
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          >
            {btnText}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function UserContactedModal({
  isOpen,
  setIsOpen,
  processing2,
  sendUserContacted,
}) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            User Contacted
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Reason (optional)"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => sendUserContacted(rsn)}
            >
              {processing2 ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Save"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function LinkDeviceModal({
  isOpen,
  setIsOpen,
  processing,
  token,
  linkDeviceFunc,
}) {
  const [deviceType, setDeviceType] = useState("");
  const [deviceBrand, setDeviceBrand] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [uniqueDeviceNumber, setUniqueDeviceNumber] = useState("");
  useEffect(() => {
    setDeviceType("");
    setDeviceBrand("");
    setDeviceModel("");
    setUniqueDeviceNumber("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Link a Device
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          overflowY="scroll"
          direction="column"
        >
          <Text mb="2">Select Device Type*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDeviceType(e.target.value)}
          >
            <option value=""></option>
            <option value="phone">Phone</option>
            <option value="laptop">Laptop</option>
            <option value="desktop">Desktop</option>
          </select>

          <Text mb="2">Select Device Brand*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDeviceBrand(e.target.value)}
          >
            <option value=""></option>
            {deviceType === "phone"
              ? brands_constants.PHONE_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : deviceType === "laptop"
              ? brands_constants.LAPTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : deviceType === "desktop" &&
                brands_constants.DESKTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>

          <InputHolderUser
            type="text"
            label="Device Model*"
            onChange={(e) => setDeviceModel(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Unique Device Number*"
            onChange={(e) => setUniqueDeviceNumber(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() =>
                linkDeviceFunc(
                  token,
                  deviceType,
                  deviceBrand,
                  deviceModel,
                  uniqueDeviceNumber
                )
              }
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Link Device"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function OfflineDpp({
  dppModal,
  setDppModal,
  dppLoading,
  createOfflineDpp,
  isGift,
}) {
  const [uid, setUid] = useState("");
  const [nDevices, setNDevices] = useState("");
  const [duration, setDuration] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [product, setProduct] = useState("");
  const [pType, setPType] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [fullName, setFullName] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  useEffect(() => {
    setUid("");
    setNDevices("");
    setDuration("");
    setEmail("");
    setPhone("");
    setProduct("");
    setPType("");
    setPrice("");
    setDate("");
    setSoldBy("");
    setFullName("");
    setAmountPaid("");
  }, [dppModal]);

  return dppModal ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setDppModal(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
          justify="space-between"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            {isGift ? "Gift Plan" : "Create Plan for Offline DPP Purchase"}
          </Text>
          <Image
            src={CloseIcon}
            w={["13px", "15px"]}
            cursor="pointer"
            onClick={() => setDppModal(false)}
          />
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          overflowY="scroll"
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Uid"
            onChange={(e) => setUid(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Full Name"
            onChange={(e) => setFullName(e.target.value)}
          />
          <InputHolderUser
            type="number"
            label="Number of Devices*"
            onChange={(e) => setNDevices(e.target.value)}
          />
          <Text mb="2">Duration*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDuration(e.target.value)}
          >
            <option value=""></option>
            <option value="6">6 months</option>
            <option value="12">12 months</option>
          </select>
          <InputHolderUser
            type="email"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputHolderUser
            type="tel"
            label="Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
          <Text mb="2">Product*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",

              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setProduct(e.target.value)}
          >
            <option value=""></option>
            <option value="device_protection_plan">
              Device protection plan
            </option>
          </select>
          <Text mb="2">Plan Type*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",

              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setPType(e.target.value)}
          >
            <option value=""></option>
            <option>lite</option>
            <option>basic</option>
            <option>custom</option>
            <option>premium</option>
            <option>pro</option>
          </select>
          <InputHolderUser
            type="number"
            label="Price*"
            onChange={(e) => setPrice(e.target.value)}
          />
          <InputHolderUser
            type="date"
            label="Date Created"
            onChange={(e) => setDate(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Amount Paid"
            onChange={(e) => setAmountPaid(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Price"
            onChange={(e) => setPrice(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Sold By"
            onChange={(e) => setSoldBy(e.target.value)}
          />

          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setDppModal(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() =>
                createOfflineDpp(
                  uid,
                  nDevices,
                  duration,
                  email,
                  phone,
                  product,
                  pType,
                  price,
                  date,
                  soldBy,
                  fullName,
                  amountPaid
                )
              }
            >
              {dppLoading ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : isGift ? (
                "Gift Plan"
              ) : (
                "Create Plan"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function AddNotes({ isOpen, setIsOpen, setMopen, tid, loadNotes }) {
  const [note, setNote] = useState("");
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setNote("");
  }, [isOpen]);

  const addNoteFunc = async () => {
    if (note !== "") {
      setProcessing(true);
      const res = await AuthConnect("post", "note/admin/add", {
        tid: tid,
        note: note,
      });
      if (res?.success) {
        if (loadNotes !== undefined) {
          await loadNotes();
          Toast("Note Added Successfully", "success");
          setProcessing(false);
          setIsOpen(false);
          setMopen(true);
        } else {
          Toast("Note Added Successfully", "success");
          setProcessing(false);
          setIsOpen(false);
          setMopen(true);
        }
      } else {
        setProcessing(false);
        setIsOpen(false);
      }
    } else {
      Toast("Kindly add a note", "error");
    }
  };

  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
          justify="space-between"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Add Note
          </Text>
          <Image
            src={CloseIcon}
            w={["13px", "15px"]}
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          />
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <Text mb="2">Kindly type your note in the box below</Text>
          <textarea
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              border: "1px solid #989898",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
              resize: "none",
              height: "158px",
            }}
            className="slHover"
            onChange={(e) => setNote(e.target.value)}
          ></textarea>

          <Flex w="100%" justify="flex-end" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
              mr="8"
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => addNoteFunc()}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Add Note"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function RejectLinkDevice({ isOpen, setIsOpen, rejectRun, processing }) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Reject
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Reason (optional)"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => rejectRun(rsn)}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Reject"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function SuspendRepair({
  isOpen,
  setIsOpen,
  suspendRepairFunc,
  processing,
}) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Suspend Repair
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Reason for suspending the repair request*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (rsn === "") {
                  Toast("Input a reason for suspending this repair", "error");
                } else {
                  suspendRepairFunc(rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Suspend"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function DeleteRepair({
  isOpen,
  setIsOpen,
  deleteRepairFunc,
  processing,
}) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Delete Duplicate Repair
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Reason for deleting the repair request*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              No, Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (rsn === "") {
                  Toast("Input a reason for deleting this repair", "error");
                } else {
                  deleteRepairFunc(rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Yes, Delete"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function SuspendClaim({
  isOpen,
  setIsOpen,
  suspendClaimFunc,
  processing,
}) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Suspend Claim
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Reason for suspending the claim*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (rsn === "") {
                  Toast("Input a reason for suspending this claim", "error");
                } else {
                  suspendClaimFunc(rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Suspend"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function AdminUploadVideo({
  isOpen,
  setIsOpen,
  actId,
  setModal,
  vidFunc,
  viewVideo,
  videoUrl,
  videoList,
  viewChecklist,
  setValue,
  value,
  uniqueDeviceNumber,
  uid,
}) {
  const [vidSelected, setVidSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [defaultDate, setDate] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [step, setStep] = useState(0);
  const checklist = [
    { text: "Screen is not damaged", id: "screenNotDamaged" },
    { text: "Device is Charging", id: "isCharging" },
    { text: "Device was Purchased New", id: "purchasedNewOrWithinRange" },
    { text: "Chassis is not Damaged ", id: "chassissNotDamaged" },
  ];
  const [isNotify, setIsNotify] = useState(true);

  useEffect(() => {
    setVidSelected(false);
    setSelectedFile("");
    setProcessing(false);
    if (viewChecklist) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isOpen]);

  // useEffect(() => {
  // let date = document.getElementById("datePurchased");
  // if (date && !viewChecklist) {
  // date.valueAsDate = new Date();
  // setDate(new Date());
  // }
  // }, [isOpen]);

  function handleFileSelect(event) {
    const normalSize = 600000000;
    if (event.target.files[0].size > normalSize) {
      Toast("File size is large! Should not be more than 60mb.", "error");
    } else {
      setSelectedFile(event.target.files[0]);
      setVidSelected(true);
    }
  }

  async function addVideo() {
    setProcessing(true);
    /*const newFileName = Math.floor(Date.now() / 1000) + selectedFile?.name;

    const target = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: newFileName,
      Body: selectedFile,
    };
    const credentials = {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
    };

    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: process.env.REACT_APP_AWS_S3_REGION,
        credentials,
      }),
      params: target,
      tags: [
      ], // optional tags
      queueSize: 4, // optional concurrency configuration
      partSize: 1024 * 1024 * 60, // optional size of each part, in bytes, at least 5MB
      leavePartsOnError: false, // optional manually handle dropped parts
    });

    const uploadLink = await parallelUploads3.done();
    const { Location } = uploadLink;

    const res = await AuthConnect("post", "activation/upload/link", {
      activationId: actId,
      url: Location,
    });*/
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("file", selectedFile);
    formData.append("tid", actId);
    formData.append("scope", "activation");
    formData.append("type", "verification_video");
    const res = await AuthConnect("post", "upload", formData);
    if (res?.success) {
      await vidFunc(actId);
      setProcessing(false);
      setIsOpen(false);
      setModal({
        modalId: "SUCCESS",
        title: "Upload Video",
        onOpen: true,
        data: "Video Uploaded Successfully!",
        messageTitle: "",
      });
    }
    setProcessing(false);
  }

  const handleCheck = (e) => {
    const { id, value: values } = e.target;
    if (disabled) return;
    setValue({
      ...value,
      [id]: id === "reason" || id === "datePurchased" ? values : !value[id],
    });
  };

  const handleVideoPagination = (e) => {
    const { id } = e.target;
    if (id === "right " && step <= videoList.length - 1) {
      setStep((prev) => prev + 1);
    } else if (id === "left " && step <= videoList.length - 1) {
      setStep((prev) => prev - 1);
    }
  };

  const handleApprove = async (e) => {
    const { id } = e.target;
    let data, note;
    data = {
      screenNotDamaged: value.screenNotDamaged,
      purchasedNewOrWithinRange: value.purchasedNewOrWithinRange,
      chassissNotDamaged: value.chassissNotDamaged,
      isCharging: value.isCharging,
      datePurchased: value.datePurchased,
      reason: value.reason,
      activationId: actId,
      notifyCustomer: isNotify,
    };
    note = {
      tid: actId,
      note: value.reason,
    };
    if (id === "approve") {
      if (value.datePurchased === "" && value.purchasedNewOrWithinRange) {
        Toast("Please provide a date", "error");
      } else {
        setLoading(true);
        await approveActivation(data).then((res1) => {
          sendNote(note).then((res2) => {
            setLoading(false);
            if (res1.status == 200 || res2.status == 200) {
              return setModal({
                modalId: "SUCCESS",
                title: "Success",
                onOpen: true,
                data: res1.message,
              });
            }
            if (res1.code == 403 || 500 || 400) {
              return setModal({
                modalId: "ERROR",
                title: "Request Denied",
                onOpen: true,
                data: res1.message,
              });
            }
          });
        });
      }
    } else if (id === "edit") {
      if (disabled) {
        setDisabled(!disabled);
      } else {
        //send update
        setLoading(true);
        updateActivationChecklist(data).then((res) => {
          setLoading(false);
          setDisabled(!disabled);
          if (res.status == 200) {
            return setModal({
              modalId: "SUCCESS",
              title: "Success",
              onOpen: true,
              data: res.message,
            });
          }
          if (res.code == 403) {
            return setModal({
              modalId: "ERROR",
              title: "Request Denied",
              onOpen: true,
              data: res.message,
            });
          }
        });
      }
    }
  };

  const handleReject = async (e) => {
    const { id } = e.target;
    if (id === "reject") {
      const data = {
        activationId: actId,
        notifyCustomer: true,
        reason: value.reason,
      };

      await rejectActivationVideo(data).then((res) => {
        if (res.code == 403) {
          return setModal({
            modalId: "ERROR",
            title: "Request Denied",
            onOpen: true,
            data: res.message,
          });
        }
        if (res.status == 200) {
          return setModal({
            modalId: "SUCCESS",
            title: "Success",
            onOpen: true,
            data: res.message,
          });
        }
      });
    } else {
      setIsOpen(false);
    }
  };

  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", viewVideo ? "80%" : "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
          justify="space-between"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            {viewVideo ? "Watch Video" : "Upload Video"}
          </Text>
          <Image
            src={CloseIcon}
            w={["13px", "15px"]}
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          />
        </Flex>
        {viewVideo ? (
          <WatchPanel
            viewVideo={viewVideo}
            videoUrl={videoUrl}
            videoList={videoList}
            checklist={checklist}
            handleCheck={handleCheck}
            value={value}
            handleVideoPagination={handleVideoPagination}
            step={step}
            handleApprove={handleApprove}
            handleReject={handleReject}
            viewChecklist={viewChecklist}
            disabled={disabled}
            loading={loading}
            uniqueDeviceNumber={uniqueDeviceNumber}
            isNotify={isNotify}
            setIsNotify={setIsNotify}
          />
        ) : (
          <UploadPanel
            vidSelected={vidSelected}
            adminUpload={adminUpload}
            selectedFile={selectedFile}
            processing={processing}
            addVideo={addVideo}
            handleFileSelect={handleFileSelect}
          />
        )}
      </Flex>
    </Flex>
  ) : null;
}

const WatchPanel = ({
  viewVideo,
  videoUrl,
  videoList,
  checklist,
  handleCheck,
  value,
  step,
  handleVideoPagination,
  handleApprove,
  handleReject,
  viewChecklist,
  disabled,
  loading,
  uniqueDeviceNumber,
  isNotify,
  setIsNotify,
}) => {
  return (
    <Flex w="100%" overflowY="scroll" px="3%" py="2%">
      <Flex w="100%" flex="1" textTransform="capitalize">
        <Flex
          borderRadius="4px"
          bg={!viewVideo ? "#000" : "white"}
          px="3%"
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          {videoUrl ? (
            <Box>
              <Box>
                <ReactPlayer
                  className="react-player"
                  url={videoList[step].url ?? ""}
                  controls={true}
                  width="758px"
                  height="550px"
                />
              </Box>
              <Flex pt="1rem" maxWidth="758px" w="100%" justify="space-between">
                <Flex>
                  <Image src={videoModalIcon} width="36px" height="38px" />
                  <Box ml="10px">
                    <Text>Upload ID: {videoList[step].uploadId}</Text>
                    <Text>IMEI: {uniqueDeviceNumber}</Text>
                  </Box>
                </Flex>
                <Flex>
                  {videoList.length - 1 !== 0 && (
                    <Flex
                      justify="center"
                      align="center"
                      h="40px"
                      w="40px"
                      bg="rgba(217, 217, 217, 1)"
                      mr="15px"
                      id="left"
                      borderRadius="50%"
                      onClick={handleVideoPagination}
                    >
                      <Image src={left} pointerEvents="none" />
                    </Flex>
                  )}
                  <Flex
                    justify="center"
                    align="center"
                    h="40px"
                    w="40px"
                    id="right"
                    bg="rgba(217, 217, 217, 1)"
                    borderRadius="50%"
                    onClick={handleVideoPagination}
                  >
                    <Image src={right} pointerEvents="none" />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          ) : (
            <Text>No Video Found.</Text>
          )}
        </Flex>
      </Flex>
      <Flex w="30%" fontFamily="Montserrat" pl="1.5rem" pr="1rem">
        <Box>
          <Text fontWeight="600" fontSize="18px" lineHeight="21px">
            Device verification checklist
          </Text>
          <Text fontWeight="500" fontSize="15px" lineHeight="25px" mt="0.75rem">
            Please mark the checkbox (√) if the corresponding item is in good
            working condition.
          </Text>
          <Box mt="1.5rem">
            {checklist.map((item, index) => {
              return (
                <Checkbox
                  type="checklist"
                  key={index}
                  disabled={disabled ? value[item.id] : false}
                  handleCheck={handleCheck}
                  text={item.text}
                  toggle={value[item.id]}
                  id={item.id}
                  mb="0rem"
                  mt="0.5rem"
                  viewChecklist={viewChecklist}
                />
              );
            })}
          </Box>
          <Box mt="15px">
            <Text
              color="rgba(58, 60, 64, 1)"
              fontSize="15px"
              lineHeight="25px"
              fontWeight="500"
            >
              Date Purchased
            </Text>

            <InputHolderUser
              type="date"
              id="datePurchased"
              defaultValue={value.datePurchased}
              onChange={handleCheck}
              disabled={disabled}
              bg={viewChecklist ? "rgba(250, 250, 247, 1)" : "#fff"}
            />
          </Box>
          <Box>
            <InputHolderUser
              disabled={disabled}
              type="textarea"
              id="reason"
              defaultValue={value.reason}
              placeholder="Enter a note..."
              style={{
                padding: "2.5% 3%",
                borderRadius: "5px",
                border: "1px solid rgba(227, 230, 235, 1)",
                transition: "300ms ease-in-out",
                marginBottom: "4%",
                resize: "none",
                width: "100%",
                height: "100px",
              }}
              className="slHover"
              onChange={handleCheck}
              bg={viewChecklist ? "rgba(250, 250, 247, 1)" : "#fff"}
            />
          </Box>
          <Flex align="center" mb="6">
            <Image
              cursor="pointer"
              onClick={() =>
                isNotify ? setIsNotify(false) : setIsNotify(true)
              }
              src={isNotify ? checkBoxOn : checkBoxOff}
              w="20px"
              h="20px"
              mr="2.5"
            />
            <Flex flex="1">Notify Customer?</Flex>
          </Flex>
          <Flex justify="space-between">
            {viewChecklist ? (
              <>
                <Box
                  bg={
                    videoUrl ? "rgba(17, 142, 62, 1)" : "rgba(17, 142, 62, .65)"
                  }
                  color="#fff"
                  fontWeight="600"
                  fontSize="16px"
                  p=".4rem 1rem"
                  onClick={handleReject}
                  borderRadius="40px"
                  id="back"
                  cursor="pointer"
                >
                  Back
                </Box>
                <Flex
                  bg={
                    videoUrl ? "rgba(17, 142, 62, 1)" : "rgba(17, 142, 62, .65)"
                  }
                  color="#fff"
                  fontWeight="600"
                  fontSize="16px"
                  p=".4rem 1rem"
                  justify="center"
                  align="center"
                  cursor="pointer"
                  id="edit"
                  onClick={handleApprove}
                  borderRadius="40px"
                >
                  {disabled ? (
                    "Edit"
                  ) : loading ? (
                    <Spinner
                      color="#fff"
                      emptyColor="lightgrey"
                      w="15px"
                      h="15px"
                    />
                  ) : (
                    "Save"
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Flex
                  bg={"rgba(233, 0, 0, 1)"}
                  color="#fff"
                  fontWeight="600"
                  fontSize="16px"
                  p=".4rem 1rem"
                  id="reject"
                  onClick={handleReject}
                  borderRadius="40px"
                  cursor="pointer"
                  mb="12"
                >
                  Reject
                </Flex>
                <Flex
                  bg={"rgba(17, 142, 62, 1)"}
                  color="#fff"
                  fontWeight="600"
                  fontSize="16px"
                  p=".4rem 1rem"
                  cursor="pointer"
                  justify="center"
                  align="center"
                  id="approve"
                  onClick={handleApprove}
                  borderRadius="40px"
                  mb="12"
                >
                  {loading ? (
                    <Spinner
                      color="#fff"
                      emptyColor="lightgrey"
                      w="15px"
                      h="15px"
                    />
                  ) : (
                    "Approve"
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const UploadPanel = ({
  vidSelected,
  adminUpload,
  selectedFile,
  processing,
  addVideo,
  handleFileSelect,
}) => {
  return (
    <Flex
      borderBottom="1px solid #D9D9D9"
      pt={["5", "8"]}
      pb="8"
      px={["6", "8"]}
      direction="column"
      align="center"
    >
      <Image src={adminUpload} w="90px" h="86px" />
      {vidSelected ? (
        <>
          <Text textAlign="center" fontSize="16px" mt="4">
            {selectedFile?.name}
          </Text>
          <Flex
            w="auto"
            bg="#03A63C"
            borderRadius="5px"
            py="2"
            px="9"
            color="#fff"
            cursor="pointer"
            mt="6"
            onClick={() => addVideo()}
          >
            {processing ? (
              <Spinner color="#fff" emptyColor="lightgrey" />
            ) : (
              "Upload"
            )}
          </Flex>
          <Flex
            w="auto"
            bg="#fff"
            border="1px solid #03A63C"
            borderRadius="5px"
            py="2"
            px="9"
            color="#03A63C"
            cursor="pointer"
            mt="6"
            onClick={() => document.getElementById("vidHolder").click()}
          >
            Select New File
          </Flex>
        </>
      ) : (
        <>
          <Text textAlign="center" fontWeight="bold" mt="4" fontSize="18px">
            Select the file you want to upload
          </Text>
          <Text textAlign="center" fontSize="13px" mt="2">
            Kindly use another device to record a 10 sec video of the device you
            want to link, showing the device’s serial/IMEI number. Please,
            upload the video below.
          </Text>
        </>
      )}
      <input
        type="file"
        accept="video/*"
        style={{ width: "0", height: "0", visbility: "hidden" }}
        id="vidHolder"
        onChange={(e) => handleFileSelect(e)}
      />
      {vidSelected ? (
        <></>
      ) : (
        <Flex
          w="auto"
          bg="#03A63C"
          borderRadius="5px"
          py="2"
          px="9"
          color="#fff"
          cursor="pointer"
          mt="8"
          onClick={() => document.getElementById("vidHolder").click()}
        >
          Select File
        </Flex>
      )}
    </Flex>
  );
};

export function ConvertClaim({
  isOpen,
  setIsOpen,
  convertClaimFunc,
  processing,
}) {
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Convert Claim to Repair
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <Text mb="3">
            Are you sure you want to convert this claim to a repair?
          </Text>
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              No
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                convertClaimFunc();
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Yes"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function ConvertRepair({
  isOpen,
  setIsOpen,
  convertRepairFunc,
  processing,
}) {
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Convert Repair to Claim
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Enter the activation id*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (rsn === "") {
                  Toast("Input the activation ID", "error");
                } else {
                  convertRepairFunc(rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Convert"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function RefundRepair({
  isOpen,
  setIsOpen,
  refundRepairFunc,
  processing,
}) {
  const [rsn, setRsn] = useState("");
  const [refundAmount, setRefundAmount] = useState(0);
  useEffect(() => {
    setRsn("");
    setRefundAmount(0);
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Refund Repair
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="number"
            label="Refund Amount*"
            onChange={(e) => setRefundAmount(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reason for refunding the repair*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (rsn === "") {
                  Toast("Input a reason for refunding this repair", "error");
                } else {
                  refundRepairFunc(rsn, refundAmount);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Refund"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function EditLinkedDevice({
  isOpen,
  setIsOpen,
  editLinkedFunc,
  processing,
}) {
  const [deviceType, setDeviceType] = useState("");
  const [deviceBrand, setDeviceBrand] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setDeviceType("");
    setDeviceBrand("");
    setDeviceModel("");
    setSerialNo("");
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Edit Linked Device
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <Text mb="2">Select Device Type</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDeviceType(e.target.value)}
          >
            <option value=""></option>
            <option value="phone">Phone</option>
            <option value="laptop">Laptop</option>
            <option value="desktop">Desktop</option>
          </select>

          <Text mb="2">Select Device Brand</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDeviceBrand(e.target.value)}
          >
            <option value=""></option>
            {deviceType === "phone"
              ? brands_constants.PHONE_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : deviceType === "laptop"
              ? brands_constants.LAPTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : deviceType === "desktop" &&
                brands_constants.DESKTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>

          <InputHolderUser
            type="text"
            label="Device Model"
            onChange={(e) => setDeviceModel(e.target.value)}
          />

          <InputHolderUser
            type="text"
            label="The new unique device number*"
            onChange={(e) => setSerialNo(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reason for this change*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (serialNo === "") {
                  Toast(
                    "Input the new unique device number of the device",
                    "error"
                  );
                } else if (rsn === "") {
                  Toast("Input a reason for this change", "error");
                } else {
                  editLinkedFunc(
                    deviceType,
                    deviceBrand,
                    deviceModel,
                    serialNo,
                    rsn
                  );
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Save"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function EditLinkedDeviceUnique({
  isOpen,
  setIsOpen,
  editLinkedFuncUnique,
  processing,
}) {
  const [serialNo, setSerialNo] = useState("");
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setSerialNo("");
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Edit Unique Number
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="The new unique device number*"
            onChange={(e) => setSerialNo(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reason for this change*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (serialNo === "") {
                  Toast(
                    "Input the new unique device number of the device",
                    "error"
                  );
                } else if (rsn === "") {
                  Toast("Input a reason for this change", "error");
                } else {
                  editLinkedFuncUnique(serialNo, rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Save"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function UpdateEmail({
  isOpen,
  setIsOpen,
  updateEmailFunc,
  processing,
  oldM,
}) {
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [rsn, setRsn] = useState("");
  useEffect(() => {
    setOldEmail(oldM);
    setNewEmail("");
    setRsn("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Update User Email
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Old email*"
            onChange={(e) => setOldEmail(e.target.value)}
            value={oldM}
          />
          <InputHolderUser
            type="text"
            label="New email*"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reason for this update*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (oldEmail === "") {
                  Toast("Input the old email address", "error");
                } else if (newEmail === "") {
                  Toast("Input the new email address", "error");
                } else if (rsn === "") {
                  Toast("Enter reason for this update", "error");
                } else {
                  updateEmailFunc(oldEmail, newEmail, rsn);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Update"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function CreateOfflineRepair({
  isOpen,
  setIsOpen,
  saveRepairFunc,
  processing,
}) {
  const [cuid, setCuid] = useState("");
  const [uMail, setUMail] = useState("");
  const [uPhone, setUPhone] = useState("");
  const [dt, setDt] = useState("");
  const [db, setDb] = useState("");
  const [dm, setDm] = useState("");
  const [issue, setIssue] = useState("");
  const [desc, setDesc] = useState("");
  const [ad, setAd] = useState("");

  useEffect(() => {
    setCuid("");
    setUMail("");
    setUPhone("");
    setDt("");
    setDb("");
    setDm("");
    setIssue("");
    setDesc("");
    setAd("");
  }, [isOpen]);

  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Create Offline Repair
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
          overflowY="scroll"
        >
          <InputHolderUser
            type="text"
            label="Customers Uid"
            onChange={(e) => setCuid(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Customers Email"
            onChange={(e) => setUMail(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Customers Phone Number"
            onChange={(e) => setUPhone(e.target.value)}
          />
          <Text mb="2">Select Device Type*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDt(e.target.value)}
          >
            <option value=""></option>
            <option value="phone">Phone</option>
            <option value="laptop">Laptop</option>
            <option value="desktop">Desktop</option>
          </select>

          <Text mb="2">Select Device Brand*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setDb(e.target.value)}
          >
            <option value=""></option>
            {dt === "phone"
              ? brands_constants.PHONE_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "laptop"
              ? brands_constants.LAPTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "desktop" &&
                brands_constants.DESKTOP_BRANDS.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>

          <InputHolderUser
            type="text"
            label="Device Model*"
            onChange={(e) => setDm(e.target.value)}
          />

          <Text mb="2">Select Issue*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setIssue(e.target.value)}
          >
            <option value=""></option>
            {dt === "phone"
              ? brands_constants.PHONE_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "laptop"
              ? brands_constants.LAPTOP_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : dt === "desktop" &&
                brands_constants.DESKTOP_ISSUES.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>

          <InputHolderUser
            type="text"
            label="Description*"
            onChange={(e) => setDesc(e.target.value)}
          />

          <InputHolderUser
            type="text"
            label="Address*"
            onChange={(e) => setAd(e.target.value)}
          />
        </Flex>
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          pt={["5", "3"]}
          pb="5"
          px={["6", "8"]}
        >
          <Flex
            w="auto"
            bg="#49A05C"
            borderRadius="5px"
            py="2"
            px="9"
            color="#fff"
            cursor="pointer"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Flex>
          <Flex
            w="auto"
            bg="#49A05C"
            borderRadius="5px"
            py="2"
            px="9"
            color="#fff"
            cursor="pointer"
            onClick={() => {
              if (
                dt === "" ||
                db === "" ||
                dm === "" ||
                issue === "" ||
                desc === "" ||
                ad === ""
              ) {
                Toast("Kindly fill all the required input fields", "error");
              } else {
                saveRepairFunc(
                  cuid,
                  uMail,
                  uPhone,
                  dt,
                  db,
                  dm,
                  issue,
                  desc,
                  ad
                );
              }
            }}
          >
            {processing ? (
              <Spinner color="#fff" emptyColor="lightgrey" />
            ) : (
              "Save"
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function TransferPlan({
  isOpen,
  setIsOpen,
  transferPlanFunc,
  processing,
}) {
  const [planId, setPlanId] = useState("");
  const [rsn, setRsn] = useState("");
  const [newId, setNewId] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  useEffect(() => {
    setPlanId("");
    setRsn("");
    setNewId("");
    setEmail("");
    setPhoneNo("");
  }, [isOpen]);
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Gift Plan
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Plan Id*"
            onChange={(e) => setPlanId(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reason*"
            onChange={(e) => setRsn(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reciever's uid"
            onChange={(e) => setNewId(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reciever's email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputHolderUser
            type="text"
            label="Reciever's phone number"
            onChange={(e) => setPhoneNo(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (planId === "") {
                  Toast("Input the plan Id to be gifted", "error");
                } else if (rsn === "") {
                  Toast("Input a reason for gifting", "error");
                } else {
                  transferPlanFunc(planId, rsn, newId, email, phoneNo);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Gift"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function CreateClaim({ isOpen, setIsOpen, update }) {
  const [actId, setActId] = useState("");
  const [issue, setIssue] = useState("");
  const [desc, setDesc] = useState("");
  const [processing, setProcessing] = useState("");

  useEffect(() => {
    setActId("");
    setIssue("");
    setDesc("");
    setProcessing("");
  }, [isOpen]);

  const createClaimFunc = async () => {
    setProcessing(true);
    const res = await AuthConnect("post", "claim/create", {
      activationId: actId,
      issue: issue,
      description: desc,
    });
    if (res?.success) {
      await update();
      Toast("Claim Created Successfully", "success");
      setProcessing(false);
      setIsOpen(false);
    } else {
      setProcessing(false);
      setIsOpen(false);
    }
  };

  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Create Offline Claim
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <InputHolderUser
            type="text"
            label="Activation Id*"
            onChange={(e) => setActId(e.target.value)}
          />

          <Text mb="2">Select Device Issue*</Text>
          <select
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #959595",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setIssue(e.target.value)}
          >
            <option value=""></option>
            <option value="Broken screen">Broken Screen</option>
            <option value="Liquid Spill">Liquid Spill</option>
            <option value="Charging Port">Charging Port</option>
            <option value="Charger">Charger</option>
            <option value="Battery">Battery</option>
            <option value="Motherboard">Motherboard</option>
            <option value="HDD replacement">HDD replacement</option>
            <option value="Speaker">Speaker</option>
            <option value="Keyboard">Keyboard</option>
            <option value="Others">Others</option>
          </select>

          <InputHolderUser
            type="text"
            label="Description"
            onChange={(e) => setDesc(e.target.value)}
          />
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                if (actId === "") {
                  Toast("Input the activation Id", "error");
                } else if (issue === "") {
                  Toast("Select a device issue", "error");
                } else {
                  createClaimFunc(actId, issue, desc);
                }
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Create"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}

export function DeleteUser({ isOpen, setIsOpen, deleteUserFunc, processing }) {
  return isOpen ? (
    <Flex
      position="fixed"
      w="100%"
      h="100%"
      margin="0"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justify="center"
      align="center"
      direction="column"
      className="mdOverlap"
      style={{ zIndex: "1000" }}
      fontFamily="Nunito Sans"
    >
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        bg="#000000"
        opacity="0.4"
        onClick={() => setIsOpen(false)}
        style={{ zIndex: "10000" }}
      ></Flex>

      <Flex
        bg="#fff"
        position="absolute"
        borderRadius={["10px", "5px"]}
        direction="column"
        w={["90%", "30%"]}
        maxHeight="95vh"
        style={{ zIndex: "1000000" }}
      >
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="3"
          px={["6", "8"]}
          align="center"
        >
          <Text w="100%" fontSize="16px" fontWeight="bold">
            Delete User
          </Text>
        </Flex>
        <Flex
          borderBottom="1px solid #D9D9D9"
          pt={["5", "8"]}
          pb="8"
          px={["6", "8"]}
          direction="column"
        >
          <Text mb="3">Are you sure you want to delete this user?</Text>
          <Flex w="100%" justify="space-between" align="center" mt="3">
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Flex>
            <Flex
              w="auto"
              bg="#49A05C"
              borderRadius="5px"
              py="2"
              px="9"
              color="#fff"
              cursor="pointer"
              onClick={() => {
                deleteUserFunc();
              }}
            >
              {processing ? (
                <Spinner color="#fff" emptyColor="lightgrey" />
              ) : (
                "Delete"
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
}
