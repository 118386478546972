import React, { useEffect, useState, useRef } from "react";
import { Flex, Input, Button, Box, Text, Spacer } from "@chakra-ui/react";
import InputHolder from "./InputHolder";
import { Link, useHistory } from "react-router-dom";
import { AuthConnect, SignIn } from "../util/util";
import Toast from "./notify";
import { useRouter } from "../util/router";
import { jwtDecode } from "jwt-decode";

function AuthSection(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [phone, setPhone] = useState("")

  const history = useHistory();
  const router = useRouter()

  const ref1 = useRef("")
  const ref2 = useRef("")
  const ref3 = useRef("")
  const ref4 = useRef("")

  //SIGN IN ACTION
  async function signIn() {
    const res = await SignIn("post", "auth/partner/admin/login", {
      email: email,
      password: pass,
    });
    if (res?.success) {
      Toast("WELCOME BACK", "success");
      sessionStorage.setItem("GampToken", res?.data?.accesstoken);
      sessionStorage.setItem("GampAuth", true);
      sessionStorage.setItem("GampRole", res?.data?.role);
      sessionStorage.setItem("GampUid", res?.data?.uid);
      sessionStorage.setItem("GampBid", res?.data?.businessId);
      sessionStorage.setItem("GampEnabledMFA", res?.data?.enabledMFA);
      sessionStorage.setItem("PartnerName", res?.data?.themeConfig?.partner);
      sessionStorage.setItem("PartnerLogo", res?.data?.themeConfig?.logoUrl);
      sessionStorage.setItem("PartnerPrimaryColor", res?.data?.themeConfig?.primaryColor);
      sessionStorage.setItem("PartnerSecondaryColor", res?.data?.themeConfig?.secondaryColor);
      if(res?.data?.partnerAdminAccess === true) {
        sessionStorage.setItem("PartnerAdminAccess", "true");
      }
      const newRes = await AuthConnect("get", "/user/fetch")
      const nArr = JSON.stringify(newRes?.data?.partnerPermissions)
      sessionStorage.setItem("PartnerPermissions", nArr)
      history.replace("/partner/dashboard/");
    } else {
      setLoading(false);
    }
  }

  //FORGOT PASSWORD
  async function forgotpass() {
    const res = await AuthConnect("post", "auth/account/recovery/initiate", {
      email: email,
    });
    if (res?.success) {
      Toast(res?.message, "success");
      history.replace("/auth?type=signin");
    }
    setLoading(false);
  }


  async function changepassfunc() {
    if(router.query.token !== undefined) {
      const res = await AuthConnect("post", "auth/account/recovery/change/password", {
        token: router.query.token,
        newPassword: pass,
      });
      if (res?.success) {
        Toast(res?.message, "success");
        history.replace("/auth?type=signin");
      }
    }
    else {
      Toast("Recovery token does not exists!", "error");
    }
    setLoading(false);
  }


  async function completesignupfunc() {
    if(router.query.payload !== undefined) {
      const res = await AuthConnect("post", "user/register", {
        firstName: fname,
        lastName: lname,
        email: email,
        phoneNumber: phone,
        password: pass,
        partner: router.query?.partner,
      });
      if (res?.success) {
        Toast(res?.message, "success");
        history.replace("/auth?type=signin");
      }
    }
    else {
      Toast("Account setup token does not exists!", "error");
    }
    setLoading(false);
  }



  //FORM HANDLER
  const handleForm = (type) => {
    setLoading(true);
    if (type === "signin") signIn();
    if (type === "forgotpass") forgotpass();
    if (type === "changepass") changepassfunc();
    if (type === "complete_signup") completesignupfunc();
  };


  useEffect(async () => {
    if(props.type === "complete_signup") {
      if(router.query.payload !== undefined) {
        const res = await jwtDecode(router.query.payload)
        setTimeout(function() {
          if(res.email) {
            ref1.current.value = res?.email
            setEmail(res?.email)
          }
          if(res.phoneNumber) {
            ref2.current.value = res?.phoneNumber
            setPhone(res?.phoneNumber)
          }
          if(res.firstName) {
            ref3.current.value = res?.firstName
            setFname(res?.firstName)
          }
          if(res.lastName) {
            ref4.current.value = res?.lastName
            setLname(res?.lastName)
          }
        }, 100)
      }
    }
  }, [])


  return (
    <Flex direction="column" w="100%">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleForm(props.type);
        }}
      >
        {["complete_signup"].includes(props.type) && (
          <Box>
            <Text fontWeight="500" color="#161C26" mb="1.5">Firstname</Text>
            <InputHolder
              type="text"
              placeholder="Enter firstname"
              w="100%"
              inpRef={ref3}
              onInput={(e) => setFname(e.target.value)}
            />
          </Box>
        )}

        {["complete_signup"].includes(props.type) && (
          <Box>
            <Text fontWeight="500" color="#161C26" mb="1.5">Lastname</Text>
            <InputHolder
              type="text"
              placeholder="Enter lastname"
              w="100%"
              inpRef={ref4}
              onInput={(e) => setLname(e.target.value)}
            />
          </Box>
        )}

        {["signin", "forgotpass", "complete_signup"].includes(props.type) && (
          <Box>
            <Text fontWeight="500" color="#161C26" mb="1.5">Email</Text>
            <InputHolder
              type="text"
              placeholder="Enter Email"
              w="100%"
              inpRef={ref1}
              onInput={(e) => setEmail(e.target.value)}
            />
          </Box>
        )}

        {["complete_signup"].includes(props.type) && (
          <Box>
            <Text fontWeight="500" color="#161C26" mb="1.5">Phone number</Text>
            <InputHolder
              type="text"
              placeholder="Enter phone number"
              w="100%"
              inpRef={ref2}
              onInput={(e) => setPhone(e.target.value)}
            />
          </Box>
        )}

        {["signin", "changepass", "complete_signup"].includes(props.type) && (
          <Box>
            <Text fontWeight="500" color="#161C26" mb="1.5">Password</Text>
            <InputHolder
              password
              placeholder="Enter Password"
              w="100%"
              onInput={(e) => setPass(e.target.value)}
            />
          </Box>
        )}

        {["signin"].includes(props.type) && (
          <Flex w="100%" justify="flex-end" mb="5">
            <Link to="/auth?type=forgotpass">
              <Text textDecoration="underline" fontSize="14px" color="#000000" textAlign="center">
                Forgot Password?
              </Text>
            </Link>
          </Flex>
        )}

        <Button
          bg="rgb(239, 239, 239)"
          _focus={{ bg: "rgb(239, 239, 239)" }}
          _hover={{ bg: "rgb(239, 239, 239)" }}
          w="100%"
          borderRadius="30px"
          border="none"
          color="#000"
          cursor="pointer"
          type="submit"
          mb="8"
          h="50px"
          isLoading={loading}
        >
          {props.type === "forgotpass" ? "Reset Password" : props.type === "complete_signup" ? "Sign up" : "Continue"}
        </Button>

        {["complete_signup"].includes(props.type) && (
          <Flex w="100%" mb="3" justify="center" align="center">
            Already have an account?
            <Link to="/auth?type=signin">
              <Text ml="2" fontWeight="bold" textDecoration="underline" fontSize="14px" color="#000000" textAlign="center">
                Sign in
              </Text>
            </Link>
          </Flex>
        )}
      </form>
    </Flex>
  );
}

export default AuthSection;
