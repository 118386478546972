import react, { useEffect, useState, useRef } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Image,
} from "@chakra-ui/react";
import $ from "jquery";
import { fetchLinkedDeviceVid } from "./helper";
import { AdminUploadVideo } from "./modal";
import { approveActivation } from "./Modal/helpers";
import { VerifyTransactionNew } from "../util/util";
import Toast from "./notify";
import { useViewDetailsContext } from "../util/reducers";
import {
  setHistory,
  setId,
  setViewDetails,
  setViewType,
} from "../util/reducers/viewDetailsSlice";
import { useHistory } from "react-router-dom";

export const status = (data) => {
  return (
    <Flex>
      <Flex
        py="1"
        px="8"
        bg={
          data === "active" ||
          data === "fixed and delivered" ||
          data === "fixed" ||
          data === "full" ||
          data === "processing" ||
          data === "approved" ||
          data === "refunded" ||
          data === "customer refunded" ||
          data === "paid" ||
          data === "delivered" ||
          data === "device replaced" ||
          data === "in progress" ||
          data === "awaiting payment" ||
          data === "awaiting your payment" ||
          data === "success"
            ? "#D1FAE5"
            : data === "pending" ||
              data === "awaiting approval" ||
              data === "trial" ||
              data === "awaiting credit assessment" ||
              data === "credit assessment ongoing" ||
              data === "update guarantor" ||
              data === "incomplete" ||
              data === "in progress"
            ? "#FDEACF"
            : "#FE928033"
        }
        color={
          data === "active" ||
          data === "fixed and delivered" ||
          data === "fixed" ||
          data === "full" ||
          data === "processing" ||
          data === "approved" ||
          data === "refunded" ||
          data === "paid" ||
          data === "delivered" ||
          data === "device replaced" ||
          data === "in progress" ||
          data === "awaiting payment" ||
          data === "customer refunded" ||
          data === "awaiting your payment" ||
          data === "success"
            ? "#10B981"
            : data === "pending" ||
            data === "awaiting approval" ||
              data === "trial" ||
              data === "awaiting credit assessment" ||
              data === "credit assessment ongoing" ||
              data === "update guarantor" ||
              data === "incomplete" ||
              data === "in progress"
            ? "#FFA500"
            : "#FF0000"
        }
        borderRadius="12px"
        align="center"
        fontSize="12px"
        textTransform="capitalize"
        w="auto"
      >
        <Flex
          w="6px"
          h="6px"
          bg={
            data === "active" ||
            data === "fixed and delivered" ||
            data === "fixed" ||
            data === "full" ||
            data === "processing" ||
            data === "approved" ||
            data === "refunded" ||
            data === "customer refunded" ||
            data === "paid" ||
            data === "delivered" ||
            data === "device replaced" ||
            data === "in progress" ||
            data === "awaiting payment" || 
            data === "awaiting your payment" ||
            data === "success"
              ? "#10B981"
              : data === "pending" ||
              data === "awaiting approval" ||
                data === "trial" ||
                data === "awaiting credit assessment" ||
                data === "credit assessment ongoing" ||
                data === "update guarantor" ||
                data === "incomplete" ||
                data === "in progress"
              ? "#FFA500"
              : "#FF0000"
          }
          borderRadius="100%"
          mr="2"
        ></Flex>
        {data}
      </Flex>
    </Flex>
  );
};


export default function ViewOverview({
  viewType,
  details,
  setModal,
  modal = {},
  type,
  isPartner,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activationId, setActivationId] = useState("");
  const [refCode, setRefCode] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videoList, setVideoList] = useState([]);
  const [viewVideo, setVideo] = useState(false);
  const [state, dispatch] = useViewDetailsContext();
  const [viewChecklist, setViewChecklist] = useState(false);
  const inpr = useRef();
  const inpd = useRef();

  async function loadLinkVideo2(data) {
    await fetchLinkedDeviceVid(data).then((res) => {
      setVideoList(res?.data?.docs);
      setVideoUrl(res?.data?.docs[0]?.url);
      setVideoId(res?.data?.docs[0]?.uploadId);
    });
  }
  const router = useHistory();

  const initialState = {
    screenNotDamaged: false,
    purchasedNewOrWithinRange: false,
    chassissNotDamaged: false,
    isCharging: false,
    datePurchased: "",
    reason: "",
  };
  const [value, setValue] = useState(initialState);
  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      loadLinkVideo2(details.planId);
    }
    return () => {
      sub = false;
    };
  }, []);

  useEffect(() => {
    let sub;
    sub = true;
    if (sub && modal.modalId === "SUCCESS") {
      setIsOpen(false);
    }
    return () => {
      sub = false;
    };
  }, [modal]);

  const handleViewChecklist = () => {
    setIsOpen(true);
    setVideo(true);
    setActivationId(details?.activationId);
    if (details?.checklist) {
      setViewChecklist(true);
      setValue({ ...value, ...details?.checklist });
    } else {
      setViewChecklist(false);
      setValue({ ...value });
    }
    return;
  };

  const watchVid = () => {
    setIsOpen(true);
    setVideo(true);
    setViewChecklist(false);
    setValue({ ...initialState, datePurchased: "", reason: "" });
    setActivationId(details?.activationId);
    return;
  };

  const handleUploadVid = () => {
    setVideo(false);
    setIsOpen(true);
    setValue({ ...initialState, datePurchased: "", reason: "" });
    setViewChecklist(false);
    setActivationId(details?.activationId);
    return;
  };

  const handleDeviceCoverage = () => {
    return setModal({
      onOpen: true,
      modalId: "COVERAGE_DETAILS",
      title: "Device Coverage",
      data: {
        ...details?.coverage,
        planType: details?.planType,
        gampCoverage: details?.gampCoverage,
      },
    });
  };

  const handleHyperlink = (data, link) => {
    if (!link) return;
    for (const [key, value] of Object.entries(details)) {
      if (value === data) {
        if (key === "uid" || key === "sortBy") {
          dispatch(setViewType("users"));
          dispatch(setId(value));
          dispatch(setViewDetails(details));
          dispatch(
            setHistory({ pathname: `/partner/dashboard/${viewType}`, forwarded: true })
          );
          $("#disp242424area2").show();

          document.getElementById("testKz").click();
          router.push("/partner/dashboard/users");
        }
        if (key === "businessId") {
          dispatch(setViewType("businessId"));
          dispatch(setId(value));
          dispatch(setViewDetails(details));
          dispatch(
            setHistory({ pathname: `/partner/dashboard/${viewType}`, forwarded: true })
          );
          $("#disp242424area2").show();

          document.getElementById("testKz").click();
          router.push("/partner/dashboard/users");
        }
        if (key === "planId") {
          dispatch(setViewType("plans"));
          dispatch(setId(value));
          dispatch(setViewDetails(details));
          dispatch(
            setHistory({ pathname: `/partner/dashboard/${viewType}`, forwarded: true })
          );
          $("#disp242424area2").show();

          document.getElementById("testKz").click();
          router.push("/partner/dashboard/plans");
        }
        if (key === "claimId") {
          dispatch(setViewType("claims"));
          dispatch(setId(value));
          dispatch(setViewDetails(details));
          dispatch(
            setHistory({ pathname: `/partner/dashboard/${viewType}`, forwarded: true })
          );
          $("#disp242424area2").show();

          document.getElementById("testKz").click();
          router.push("/partner/dashboard/claims");
        }
        if (key === "activationId") {
          dispatch(setViewType("activations"));
          dispatch(setId(value));
          dispatch(setViewDetails(details));
          dispatch(
            setHistory({ pathname: `/partner/dashboard/${viewType}`, forwarded: true })
          );
          $("#disp242424area2").show();

          document.getElementById("testKz").click();
          router.push("/partner/dashboard/activations");
        }
      }
    }
  };
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  const title = (data) => {
    return (
      <Text
        w="25%"
        bg="#FBFBFB"
        fontSize="14px"
        fontWeight="600"
        py="3"
        pl="7"
        pr="7"
      >
        {data}
      </Text>
    );
  };

  const body = (data, id, link) => {
    return (
      <Flex
        direction={"row"}
        w="25%"
        align="center"
        py="3"
        mb="4"
        pl="7"
        pr={id ? "0" : "7"}
        justifyContent="left"
      >
        <Text
          bg="#fff"
          fontSize="14px"
          fontWeight="500"
          flexWrap="wrap"
          wordBreak="break-all"
          w="fit-content"
          textDecoration={link ? "underline" : ""}
          cursor={link ? "pointer" : ""}
          onClick={() => handleHyperlink(data, link)}
          _hover={{ color: link ? sessionStorage.getItem("PartnerPrimaryColor") : "" }}
        >
          {data !== undefined && data !== "" ? data : "N/A"}
        </Text>
        {id && data !== undefined && data !== "" && (
          <>
          <Text 
            onClick={() => {
              navigator.clipboard.writeText(data);
              Toast("Copied successfully", "success");
            }}
            ml="3"
            cursor="pointer">
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.085V8.5C0.999902 9.13472 1.24123 9.74571 1.67504 10.209C2.10885 10.6724 2.70265 10.9534 3.336 10.995L3.5 11H7.914C7.81061 11.2924 7.61913 11.5456 7.3659 11.7247C7.11267 11.9038 6.81016 12 6.5 12H3C2.20435 12 1.44129 11.6839 0.87868 11.1213C0.316071 10.5587 2.06237e-07 9.79565 2.06237e-07 9V3.5C-0.00016248 3.18967 0.0959278 2.88694 0.275028 2.63351C0.454129 2.38008 0.707422 2.18844 1 2.085ZM8.5 0C8.89782 0 9.27936 0.158035 9.56066 0.43934C9.84196 0.720644 10 1.10218 10 1.5V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H3.5C3.10218 10 2.72064 9.84196 2.43934 9.56066C2.15804 9.27936 2 8.89782 2 8.5V1.5C2 1.10218 2.15804 0.720644 2.43934 0.43934C2.72064 0.158035 3.10218 0 3.5 0H8.5Z" fill={partnerBg} />
              </svg>
          </Text>
          </>
        )}
      </Flex>
    );
  };
  const button = (data) => {
    return (
      <Flex
        bg={sessionStorage.getItem("PartnerPrimaryColor")}
        color="#fff"
        w="fit-content"
        fontWeight="600"
        fontSize="12px"
        cursor="pointer"
        borderRadius="20px"
        p=".4rem 1rem"
        onClick={data.action}
      >
        {data.text}
      </Flex>
    );
  };

  const plans = () => {
    return details?.monthlyCharge ? (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Plan ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.planId, true)}
          {body(details?.uid, true, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Plan Type")}
          {title("Amount Paid")}
          {title("Plan Duration")}
          {title("Expiry Date")}
        </Flex>
        <Flex w="100%">
          {body(details?.planType)}
          {body(details?.price?.toLocaleString())}
          {body(details?.duration)}
          {body(details?.expiryDate?.substr(0, 10))}
        </Flex>
        <Flex w="100%">
          {title("Number of Devices")}
          {title("Activated Devices")}
          {title("Number of Claims")}
          {title("Purchase Source")}
        </Flex>
        <Flex w="100%">
          {body(details?.numOfDevices)}
          {body(details?.activatedDevices)}
          {body(details?.totalClaims)}
          {body(details?.purchaseSource)}
        </Flex>
        <Flex w="100%">
          {title("Payment Reference")}
          {title("Sold By")}
          {title("Token")}
          {title("Amount Paid")}
        </Flex>
        <Flex w="100%">
          {body(details?.paymentReference)}
          {body(details?.soldBy)}
          {body(details?.token, true)}
          {body(details?.amountPaid)}
        </Flex>
        <Flex w="100%">
          {title("Monthly Charge")}
          {title("Next Due Date")}
          {title("Last Due Date")}
          {title("Cycle Expiry(Date)")}
        </Flex>
        <Flex w="100%">
          {body(details?.monthlyCharge)}
          {body(details?.soldBy)}
          {body(details?.token)}
          {body(details?.amountPaid)}
        </Flex>
      </Flex>
    ) : (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Plan ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.planId, true)}
          {body(details?.uid, true, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Plan Type")}
          {title("Amount Paid")}
          {title("Plan Duration")}
          {title("Expiry Date")}
        </Flex>
        <Flex w="100%">
          {body(details?.planType)}
          {body(details?.price?.toLocaleString())}
          {body(details?.duration)}
          {body(details?.expiryDate?.substr(0, 10))}
        </Flex>
        <Flex w="100%">
          {title("Number of Devices")}
          {title("Activated Devices")}
          {title("Number of Claims")}
          {title("Purchase Source")}
        </Flex>
        <Flex w="100%">
          {body(details?.numOfDevices)}
          {body(details?.activatedDevices)}
          {body(details?.totalClaims)}
          {body(details?.purchaseSource)}
        </Flex>
        <Flex w="100%">
          {title("Payment Reference")}
          {title("Sold By")}
          {title("Token")}
          {title("")}
        </Flex>

        <Flex w="100%">
          {body(details?.paymentReference)}
          {body(details?.soldBy, true, true)}
          {body(details?.token, true)}
          {body(<></>)}
        </Flex>
      </Flex>
    );
  };

  const repairs = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Repair ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.repairId, true)}
          {body(details?.uid, true, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Device Type")}
          {title("Brand")}
          {title("Model")}
          {title("Issue")}
        </Flex>
        <Flex w="100%">
          {body(details?.deviceType)}
          {body(details?.deviceBrand)}
          {body(details?.deviceModel)}
          {body(details?.issue)}
        </Flex>
        <Flex w="100%">
          {title("Description")}
          {title("Address")}
          {title("Repair Cost")}
          {title("Delivery Cost")}
        </Flex>
        <Flex w="100%">
          {body(
            details?.description?.length > 60 ? (
              <Text>
                <Text ref={inpr}>{details?.description?.substr(0, 60)}</Text>
                <Text
                  mt="2"
                  cursor="pointer"
                  color={sessionStorage.getItem("PartnerPrimaryColor")}
                  textDecoration="underline"
                  onClick={() => {
                    inpr.current.innerHTML = details?.description;
                  }}
                >
                  Read More
                </Text>
              </Text>
            ) : (
              details?.description
            )
          )}
          {body(details?.address)}
          {body(details?.repairCost?.toLocaleString())}
          {body(details?.deliveryCost)}
        </Flex>
        <Flex w="100%">
          {title("Shipment Method")}
          {title("Discount(%)")}
          {title("Total Charge")}
          {title("Device Diagnostics")}
        </Flex>
        <Flex w="100%">
          {body(details?.shipmentMethod)}
          {body(details?.discount)}
          {body(details?.totalCharge?.toLocaleString())}
          {body(
            details?.diagnosis?.length > 60 ? (
              <Text>
                <Text ref={inpd}>{details?.diagnosis?.substr(0, 60)}</Text>
                <Text
                  mt="2"
                  cursor="pointer"
                  color={sessionStorage.getItem("PartnerPrimaryColor")}
                  textDecoration="underline"
                  onClick={() => {
                    inpd.current.innerHTML = details?.diagnosis;
                  }}
                >
                  Read More
                </Text>
              </Text>
            ) : (
              details?.diagnosis
            )
          )}
        </Flex>
        <Flex w="100%">
          {title("Payment Link")}
          {title("Sold By")}
          {title("Technician Diagnosis")}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(details?.paymentLink, true)}
          {body(details?.soldBy)}
          {body(details?.technicianDiagnosis)}
          {body(<></>)}
        </Flex>
        <Flex w="100%">{title("Metadata (object)")}</Flex>
        <Flex w="100%">
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  const orders = () => {
    return details?.type === "lease-to-own" ||
      details?.type === "lease to own" ? (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Order ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.orderId, true)}
          {body(details?.uid, true, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Order Type")}
          {title("Device Type")}
          {title("Upfront Payment (₦)")}
          {title("VAT Rate")}
        </Flex>
        <Flex w="100%">
          {body(details?.type)}
          {body(details?.deviceType)}
          {body(details?.upfrontPayment?.toLocaleString())}
          {body(details?.vatRate?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Device Brand")}
          {title("Device Model")}
          {title("Address")}
          {title("Description")}
        </Flex>
        <Flex w="100%">
          {body(details?.deviceBrand)}
          {body(details?.deviceModel)}
          {body(details?.address)}
          {body(details?.description)}
        </Flex>
        <Flex w="100%">
          {title("Duration")}
          {title("Monthly Fee (₦)")}
          {title("Monthly Rate")}
          {title("Amount Paid (₦)")}
        </Flex>
        <Flex w="100%">
          {body(details?.duration)}
          {body(details?.monthlyFee?.toLocaleString())}
          {body(details?.monthlyRate?.toLocaleString())}
          {body(details?.amountPaid?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Delivery Cost")}
          {title("Discount(%)")}
          {title("Total Charge (₦)")}
          {title("Amount Left (₦)")}
        </Flex>
        <Flex w="100%">
          {body(details?.deliveryCost)}
          {body(details?.discount)}
          {body(details?.totalCharge?.toLocaleString())}
          {body(details?.amountLeft?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Repayment status")}
          {title("Metadata (object)")}
          {title(<></>)}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(
            details?.repaymentStatus !== undefined
              ? status(details?.repaymentStatus)
              : "N/A"
          )}
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
          {body(<></>)}
          {body(<></>)}
        </Flex>
      </Flex>
    ) : details?.type === "trade-in" ? (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Order ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.orderId, true)}
          {body(details?.uid, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("Order Type")}
          {title("Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.type)}
          {body(details?.name)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Device Type")}
          {title("Current Device(s)")}
          {title("Desired Device(s)")}
          {title("VAT Rate")}
        </Flex>
        <Flex w="100%">
          {body(details?.deviceType)}
          {body(details?.currentDevices)}
          {body(details?.desiredDevices)}
          {body(details?.vatRate?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Trade-In Valuation")}
          {title("Description")}
          {title("Delivery Cost")}
          {title("Discount(%)")}
        </Flex>
        <Flex w="100%">
          {body(details?.tradeInValuation)}
          {body(details?.description)}
          {body(details?.deliveryCost)}
          {body(details?.discount)}
        </Flex>
        <Flex w="100%">
          {title("Total Charge (₦)")}
          {title("Address")}
          {title("Metadata (object)")}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(details?.totalCharge?.toLocaleString())}
          {body(details?.address)}
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
          {body(<></>)}
        </Flex>
      </Flex>
    ) : (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Order ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.orderId, true)}
          {body(details?.uid, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("Order Type")}
          {title("Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.type)}
          {body(details?.name)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Device Type")}
          {title("Device Brand")}
          {title("Device Model")}
          {title("VAT Rate")}
        </Flex>
        <Flex w="100%">
          {body(details?.deviceType)}
          {body(details?.deviceBrand)}
          {body(details?.deviceModel)}
          {body(details?.vatRate?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Address")}
          {title("Description")}
          {title("Delivery Cost")}
          {title("Discount(%)")}
        </Flex>
        <Flex w="100%">
          {body(details?.address)}
          {body(details?.description)}
          {body(details?.deliveryCost)}
          {body(details?.discount)}
        </Flex>
        <Flex w="100%">
          {title("Total Charge (₦)")}
          {title("Metadata (object)")}
          {title(<></>)}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(details?.totalCharge?.toLocaleString())}
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
          {body(<></>)}
          {body(<></>)}
        </Flex>
      </Flex>
    );
  };

  const claims = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Claim ID")}
          {title("Uid")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.claimId, true)}
          {body(details?.uid, true, true)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Device Type")}
          {title("Brand")}
          {title("Model")}
          {title("Issue")}
        </Flex>
        <Flex w="100%">
          {body(details?.deviceType)}
          {body(details?.deviceBrand)}
          {body(details?.deviceModel)}
          {body(details?.issue)}
        </Flex>
        <Flex w="100%">
          {title("Description")}
          {title("Address")}
          {title("Repair Cost")}
          {title("Coverage")}
        </Flex>
        <Flex w="100%">
          {body(
            details?.description?.length > 60 ? (
              <Text>
                <Text ref={inpr}>{details?.description?.substr(0, 60)}</Text>
                <Text
                  mt="2"
                  cursor="pointer"
                  color={sessionStorage.getItem("PartnerPrimaryColor")}
                  textDecoration="underline"
                  onClick={() => {
                    inpr.current.innerHTML = details?.description;
                  }}
                >
                  Read More
                </Text>
              </Text>
            ) : (
              details?.description
            )
          )}
          {body(details?.address)}
          {body(details?.repairCost?.toLocaleString())}
          {body(details?.gampCoverage?.toLocaleString())}
        </Flex>
        <Flex w="100%">
          {title("Customer Coverage")}
          {title("Platform/Partner Fee")}
          {title("Plan ID")}
          {title("Plan Type")}
        </Flex>
        <Flex w="100%">
          {body(details?.customerCoverage?.toLocaleString())}
          {body(details?.platformFee?.toLocaleString())}
          {body(details?.planId, true, true)}
          {body(details?.planType)}
        </Flex>
        <Flex w="100%">
          {title("Technical Partner")}
          {title("Activation ID")}
          {title("Technician")}
          {title("Technician Address")}
        </Flex>
        <Flex w="100%">
          {body(details?.technicalPartner)}
          {body(details?.activationId, true, true)}
          {body(details?.preferThirdParty ? "Third Party" : "Slot")}
          {body(details?.partnerLocation)}
        </Flex>
        <Flex w="100%">
          {title("Sold By")}
        </Flex>
        <Flex w="100%">
          {body(details?.soldBy)}
        </Flex>
      </Flex>
    );
  };

  const users = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Uid")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.uid, true, true)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Business Name")}
          {title("Business ID")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.businessName)}
          {body(details?.businessId, true)}
        </Flex>
        <Flex w="100%">
          {title("Role")}
          {title("Repairs")}
          {title("Device Protection")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.role) ?? "N/A"}
          {body(
            Array.isArray(details?.metric) ? details?.metric[0]?.repairs : ""
          ) ?? "N/A"}
          {body(
            Array.isArray(details?.metric)
              ? details?.metric[0]?.deviceProtectionPlans
              : ""
          ) ?? "N/A"}
          {body(details?.status) ?? "N/A"}
        </Flex>
        <Flex w="100%">
          {title("Signup Status")}
          {title("Referral Code")}
          {title("Referred By")}
          {title("Address")}
        </Flex>
        <Flex w="100%">
          {body(details?.signUpStatus)}
          {body(details?.referralCode, true)}
          {body(details?.repairCost)}
          {body(details?.deliveryCost)}
        </Flex>
        <Flex w="100%">
          {title("Upline Manager")}
          {title("")}
          {title("")}
          {title("")}
        </Flex>
        <Flex w="100%">
          {body(details?.upline)}
          {body(<></>)}
          {body(<></>)}
          {body(<></>)}
        </Flex>
      </Flex>
    );
  };

  const activations = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created at")}
          {title("Activation ID")}
          {title("Uid")}
          {title("Plan ID")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.activationId ?? "N/A", true)}
          {body(details?.uid ?? "N/A", true, true)}
          {body(details?.planId ?? "N/A", true, true)}
        </Flex>
        <Flex w="100%">
          {title("First Name")}
          {title("Last Name")}
          {title("Email")}
          {title("Phone Number")}
        </Flex>
        <Flex w="100%">
          {body(details?.firstName)}
          {body(details?.lastName)}
          {body(details?.email)}
          {body(details?.phoneNumber)}
        </Flex>
        <Flex w="100%">
          {title("Plan Type")}
          {title("Device Model")}
          {title("Device Type")}
          {title("Device Brand")}
        </Flex>
        <Flex w="100%">
          {body(details?.planType)}
          {body(details?.deviceModel)}
          {body(details?.deviceType)}
          {body(details?.deviceBrand)}
        </Flex>
        <Flex w="100%">
          {title("Unique Device No")}
          {title("Status")}
          {details?.coverage ? title("Device Coverage Details") : ""}
          {title("")}
          {/*details?.checklist ? title("Verification Checklist") : title("")*/}
        </Flex>
        <Flex w="100%">
          {body(details?.uniqueDeviceNumber, true)}
          {body(status(details?.status))}{" "}
          {details?.coverage
            ? body(button({ text: "View", action: handleDeviceCoverage }))
            : ""}
          {body(<></>)}
          {/*details?.checklist
            ? body(button({ text: "View", action: handleViewChecklist }))
            : body(<></>)*/}
        </Flex>
      </Flex>
    );
  };

  const sales = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Business Email Address")}
          {title("Account Type")}
          {title("Phone Number")}
          {title("Sales")}
        </Flex>
        <Flex w="100%">
          {body(details[0]?.email ?? " ")}
          {body(details[2]?.role ?? " ")}
          {body(details[2]?.phoneNumber ?? " ")}
          {body(details[1]?.sales?.toLocaleString() ?? " ")}
        </Flex>
        <Flex w="100%">
          {title("Sales Volume")}
          {title("Commission")}
          {title("Business ID")}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(details[1]?.salesVolume?.toLocaleString() ?? " ")}
          {body(details[0]?.commission?.toLocaleString() ?? " ")}
          {body(details[0]?.businessId ?? " ")}
          {body(<></>)}
        </Flex>
        <Flex w="100%"> {title("Metadata (object)")}</Flex>
        <Flex w="100%">
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  const withdrawals = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Uid")}
          {title("Withdrawal ID")}
          {title("Withdrawal Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.uid, true, true)}
          {body(details?.withdrawalId, true)}
          {body(status(details?.status))}
        </Flex>
        {/*<Flex w='100%'>
					{title('First Name')}
					{title('Last Name')}
					{title('Email')}
					{title('Phone Number')}
				</Flex>
				<Flex w='100%'>
					{body(details?.accountName)}
					{body(details?.lastName)}
					{body(details?.email)}
					{body(details?.phoneNumber)}
				</Flex>*/}
        <Flex w="100%">
          {title("Account No")}
          {title("Account Name")}
          {title("Bank")}
          {title("Amount (₦)")}
        </Flex>
        <Flex w="100%">
          {body(details?.accountNumber)}
          {body(details?.accountName)}
          {body(details?.bankName)}
          {body(details?.amount?.toLocaleString())}
        </Flex>
        <Flex w="100%"> {title("Metadata (object)")}</Flex>
        <Flex w="100%">
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };
  const transactions = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Payment Reference")}
          {title("Uid")}
          {title("Email")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.metadata?.paymentReference)}
          {body(details?.uid, true, true)}
          {body(details?.email)}
        </Flex>
        <Flex w="100%">
          {title("Amount (₦)")}
          {title("Purpose")}
          {title("Provider")}
          {title("Status")}
        </Flex>
        <Flex w="100%">
          {body(details?.amount?.toLocaleString())}
          {body(details?.metadata?.purpose)}
          {body(details?.provider)}
          {body(status(details?.status))}
        </Flex>
        <Flex w="100%">
          {title("Narration")}
          {title("First Name")}
          {title("Last Name")}
          {title("Metadata (object)")}
        </Flex>
        <Flex w="100%">
          {body(details?.narration ?? "None")}
          {body(details?.firstName ?? "None")}
          {body(details?.lastName ?? "None")}
          {body(
            <Text
              color={sessionStorage.getItem("PartnerPrimaryColor")}
              cursor="pointer"
              textDecoration="underline"
              onClick={() =>
                setModal({
                  modalId: "META_MODAL",
                  onOpen: true,
                  data: details,
                  refreshFunc: () => {},
                })
              }
            >
              view metadata
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  const technicians = () => {
    return (
      <Flex w="100%" direction="column">
        <Flex w="100%">
          {title("Created At")}
          {title("Technician’s Email")}
          {title("Technician’s Name")}
          {title("Account Type")}
        </Flex>
        <Flex w="100%">
          {body(details?.createdAt?.substr(0, 10))}
          {body(details?.email)}
          {body(details?.firstName + " " + details?.lastName)}
          {body(details?.role)}
        </Flex>
        <Flex w="100%">
          {title("User ID")}
          {title("Phone Number")}
          {title("Sales Volume")}
          {title("Sales")}
        </Flex>
        <Flex w="100%">
          {body(details?.uid, true, true)}
          {body(details?.phoneNumber)}
          {body(details?.metric[0]?.sales ?? "N/A")}
          {body(details?.metric[0]?.sales ?? "N/A")}
        </Flex>
        <Flex w="100%">
          {title("Commission")}
          {title("Repair Unit")}
          {title("Repair Wallet")}
          {title("Amount Paid")}
        </Flex>
        <Flex w="100%">
          {body(details?.metric[0]?.commission ?? "N/A")}
          {body(details?.metric[0]?.repairs ?? "N/A")}
          {body(details?.metric[0]?.repairWallet ?? "N/A")}
          {body(details?.metric[0]?.cost?.toLocaleString() ?? "N/A")}
        </Flex>
        <Flex w="100%">
          {title("Specialization")}
          {title(<></>)}
          {title(<></>)}
          {title(<></>)}
        </Flex>
        <Flex w="100%">
          {body(details?.specialization ?? "N/A")}
          {body(<></>)}
          {body(<></>)}
          {body(<></>)}
        </Flex>
      </Flex>
    );
  };
  




  const nArr = JSON.parse(sessionStorage.getItem("PartnerPermissions"))
  const claimsPermit = nArr.includes("approve_claims")
  const claimsManage = nArr.includes("manage_claims")
  const actionList1 = [
    "Submit Video",
  ];
  const actionList2 = [
    "Submit Video",
    "Reject Claim",
    "Add Repair Cost",
  ];
  const actionList2b = [
    "Submit Video",
    "Add Repair Cost",
  ];
  const actionList3 = [
    "Submit Video",
    "Approve Claim",
    "Reject Claim",
    "Add Repair Cost",
  ];
  const actionList3b = [
    "Submit Video",
    "Add Repair Cost",
  ];
  const actionList4 = [
    "Submit Video",
    "Add Repair Cost",
    "Confirm Payment",
    "Update Status"
  ];


  const actionMenuList = (viewType, type, details) => {
    switch (viewType) {
      case "claims":
        return (!claimsManage || details?.status === "incomplete" || details?.status === "rejected") ? actionList1 : (details?.status === "pending") ? claimsPermit ? actionList2 : actionList2b : (details?.status === "awaiting approval") ? claimsPermit ? actionList3 : actionList3b : actionList4
        case "activations":
        return (details?.status === "approved") ? ["Create Claim"] : []
      default:
        return [];
    }
  };



  const handleMenuAction = (e, item, details, viewType) => {
    const { activationId, claimId, repairId } = details;

    switch (item) {
      case "Approve":
        return watchVid();

      case "Convert Claim to Repair":
        return setModal({
          modalId: "CONVERT_CLAIM_TO_REPAIR",
          onOpen: true,
          data: { activationId, claimId },
          state: "claims",
          refreshFunc: () => {},
        });
      case "Watch Video":
        return handleViewChecklist();

      case "Upload Video":
        return handleUploadVid();

      case "Device Coverage Details":
        return handleDeviceCoverage();

      case "User Contacted":
        return setModal({
          modalId: "USER_CONTACTED",
          onOpen: true,
          data: [
            viewType === "repairs"
              ? details?.repairId
              : viewType === "orders"
              ? details?.orderId
              : details.claimId,
            viewType,
          ],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Reject":
        return setModal({
          modalId: "REJECT_CLAIM",
          onOpen: true,
          data: viewType === "claims" ? details.claimId : details.activationId,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Update Status":
        return setModal({
          modalId: "UPDATE_STATUS",
          onOpen: true,
          data:
            viewType === "repairs"
              ? details?.repairId
              : viewType === "orders"
              ? details?.orderId
              : details?.claimId,
          state: viewType,
          refreshFunc: () => {},
        });
        case "Create Claim":
          return setModal({
            modalId: "CREATE_CLAIM",
            onOpen: true,
            data: details,
            state: viewType,
            refreshFunc: () => {},
          });
          case "Submit Video":
            return setModal({
              modalId: "SUBMIT_VIDEO",
              onOpen: true,
              data: details,
              state: viewType,
              refreshFunc: () => {},
            });
            case "Add Repair Cost":
              if (viewType === "claims") {
                return setModal({
                  modalId: "SUBMIT_CLAIM_REPAIR_COST",
                  onOpen: true,
                  data: details,
                  state: viewType,
                  refreshFunc: () => {},
                });
              } else {
                return setModal({
                  modalId: "SUBMIT_REPAIR_REPAIR_COST",
                  onOpen: true,
                  data: details?.repairId,
                  state: viewType,
                  refreshFunc: () => {},
                });
              }
      case "Confirm Payment":
        return setModal({
          modalId: "CONFIRM_PAYMENT",
          onOpen: true,
          data: [
            viewType === "repairs" ? details?.repairId : details.claimId,
            details,
          ],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Submit Repair Cost":
        if (viewType === "claims") {
          return setModal({
            modalId: "SUBMIT_CLAIM_REPAIR_COST",
            onOpen: true,
            data: claimId,
            state: viewType,
            refreshFunc: () => {},
          });
        } else {
          return setModal({
            modalId: "SUBMIT_REPAIR_REPAIR_COST",
            onOpen: true,
            data: repairId,
            state: viewType,
            refreshFunc: () => {},
          });
        }
      case "Confirm Price":
        return setModal({
          modalId: "CONFIRM_PAYMENT",
          onOpen: true,
          data: [details?.repairId, details],
          state: "repairs",
          refreshFunc: () => {},
        });

      case "Submit Repair Estimate":
        return setModal({
          modalId: "REPAIR_ESTIMATE",
          onOpen: true,
          data: details?.repairId,
          refreshFunc: () => {},
        });

      case "Suspend Repair":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: details?.repairId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Renew Plan":
        return setModal({
          modalId: "RENEW_PLAN",
          onOpen: true,
          data: [details?.planId, details],
          state: viewType,
          refreshFunc: () => {},
        });

      case "Renew Monthly Plan":
        return setModal({
          modalId: "RENEW_MONTHLY_PLAN",
          onOpen: true,
          data: [details?.planId, details],
          state: viewType,
          refreshFunc: () => {},
        });

      case "Extend Validity":
        return setModal({
          modalId: "EXTEND_VALIDITY",
          onOpen: true,
          data: [details?.planId, details],
          state: viewType,
          refreshFunc: () => {},
        });

      case "Delete Repair":
        return setModal({
          modalId: "DELETE_REPAIR",
          onOpen: true,
          data: details?.repairId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Suspend Order":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: details?.orderId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Suspend Claim":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: details?.claimId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Delete Order":
        return setModal({
          modalId: "DELETE_REPAIR",
          onOpen: true,
          data: details?.orderId,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Change Repayment Date":
        return setModal({
          modalId: "CHANGE_REPAYMENT_DATE",
          onOpen: true,
          data: details?.orderId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Upfront Payment":
        return setModal({
          modalId: "UPFRONT_PAYMENT",
          onOpen: true,
          data: details?.orderId,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Update Order":
        return setModal({
          modalId: "UPDATE_ORDER",
          onOpen: true,
          data: [details?.orderId, details],
          state: "orders",
          refreshFunc: () => {},
        });

      case "Refund Payment":
        return setModal({
          modalId: "REFUND_ORDER",
          onOpen: true,
          data: details?.orderId,
          state: "orders",
          refreshFunc: () => {},
        });

        case "Edit Monthly Rate":
          return setModal({
            modalId: "EDIT_MONTHLY",
            onOpen: true,
            data: details?.orderId,
            state: "orders",
            refreshFunc: () => {},
          });

      case "Order Repayment":
        return setModal({
          modalId: "ORDER_REPAYMENT",
          onOpen: true,
          data: [details?.orderId, details?.repayId],
          state: "orders",
          refreshFunc: () => {},
        });

      case "Confirm Order Payment":
        return setModal({
          modalId: "CONFIRM_ORDER",
          onOpen: true,
          data: details?.orderId,
          state: "orders",
          refreshFunc: () => {},
        });
      case "Share Order pricing":
        return setModal({
          modalId: "SHARE_ORDER",
          onOpen: true,
          data: details?.orderId,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Confirm Order RePayment":
        return setModal({
          modalId: "CONFIRM_REPAYMENT",
          onOpen: true,
          data: details?.orderId,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Convert Repair to Claim":
        return setModal({
          modalId: "REPAIR_CONVERT",
          onOpen: true,
          data: details?.repairId,
          refreshFunc: () => {},
        });

      case "Update Price":
        return setModal({
          modalId: "UPDATE_COST",
          onOpen: true,
          data: [
            viewType === "repairs" ? details?.repairId : details?.claimId,
            details,
          ],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Update Device Diagnosis":
        return setModal({
          modalId: "SUBMIT_DIAGNOSIS",
          onOpen: true,
          data: viewType === "repairs" ? details?.repairId : details?.claimId,
          state: viewType,
          refreshFunc: () => {},
        });
        case "Approve Claim":
        return setModal({
          modalId: "VERIFY_CLAIM_CONFIRM",
          onOpen: true,
          data: details?.claimId,
          refreshFunc: () => {},
        });
        case "Reject Claim":
        return setModal({
          modalId: "REJECT_CLAIM_NEW",
          onOpen: true,
          data: details?.claimId,
          refreshFunc: () => {},
        });

      case "Refund Repair":
        return setModal({
          modalId: "REFUND_REPAIR",
          onOpen: true,
          data: details?.repairId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Transfer Repair":
        return setModal({
          modalId: "TRANSFER_REPAIR",
          onOpen: true,
          data: details?.repairId,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Verify Transaction":
        return VerifyTransactionNew(details?.reference, setModal, setLoading);
      default:
        return;
    }
  };

  return (
    <Flex w="100%" h="100%" direction="column">
      <AdminUploadVideo
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        actId={activationId}
        setModal={setModal}
        vidFunc={loadLinkVideo2}
        viewVideo={viewVideo}
        videoUrl={videoUrl}
        videoList={videoList}
        videoId={videoId}
        viewChecklist={viewChecklist}
        setValue={setValue}
        value={value}
        uniqueDeviceNumber={details?.uniqueDeviceNumber}
        uid={details?.uid}
      />
      <Flex mb="12" justify="space-between" display={["activations", "claims"].includes(viewType) ? "flex" : "none"}>
        <Menu>
          <MenuButton
            as="button"
            style={{
              background: sessionStorage.getItem("PartnerPrimaryColor"),
              cursor: "pointer",
              color: "#fff",
              fontWeight: "700",
              justifyContent: "center",
              padding: "9px 20px",
              borderRadius: "4px",
            }}
          >
            <Flex>
              <Text fontSize="12px" mr="2">
                Action
              </Text>
              <i className="mdi mdi-chevron-down"></i>
            </Flex>
          </MenuButton>
          <MenuList>
            {actionMenuList(viewType, details?.type, details).map((item, index) => {
              return (
                <MenuItem
                  onClick={(e) => handleMenuAction(e, item, details, viewType)}
                  key={index}
                >
                  {item}
                </MenuItem>
              );
            })}
          </MenuList>
          </Menu>
      </Flex>

      {viewType === "plans" ? (
        plans()
      ) : viewType === "activations" ? (
        activations()
      ) : viewType === "repairs" ? (
        repairs()
      ) : viewType === "claims" ? (
        claims()
      ) : viewType === "users" ? (
        users()
      ) : viewType === "sales" ? (
        sales()
      ) : viewType === "orders" ? (
        orders()
      ) : viewType === "withdrawals" ? (
        withdrawals()
      ) : viewType === "technicians" ? (
        technicians()
      ) : viewType === "transactions" ? (
        transactions()
      ) : (
        <></>
      )}
    </Flex>
  );
}
