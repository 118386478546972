import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { convertClaimRepair } from "./helpers";

const rightButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#49A05C",
  color: "#fff",
  marginTop: "5px",
  width: "50%",
  marginLeft: "75px",
};
const leftButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#FF0000",
  color: "#fff",
  marginTop: "5px",
  width: "50%",
};

const ConvertClaimRepair = ({ onClose, setModal, modal }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    let data;
    data = modal.data;
    return convertClaimRepair(data).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        onClose();
        setLoading(false);
        return setModal({
          modalId: "SUCCESS",
          title: "Success",
          onOpen: true,
          messageTitle: "Claim converted successfully!",
          data: `You’ve successfully converted your claim request to a repair request.`,
        });
      }
    });
  };
  const handleCancel = () => {
    setModal({ onOpen: false });
    onClose();
  };

  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Convert Claim to Repair`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          _focus={{ border: "none", outline: "none" }}
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.7rem 1rem">
          <Text fontSize="15px" fontWeight="400" lineHeight="24px">
            Do you want to convert this claim to a repair request?
          </Text>
          <Flex mt="2rem">
            <Button
              style={leftButtonStyles}
              textAlign="center"
              width={"100%"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              style={rightButtonStyles}
              textAlign="center"
              width={"100%"}
              isLoading={loading}
              onClick={handleSubmit}
            >
              Yes, Proceed
            </Button>
          </Flex>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default ConvertClaimRepair;
