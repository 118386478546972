import React from "react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

const ModalComponent = ({ children, isOpen, onClose, size }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior={"inside"}
      size={size}
      w="100%"
    >
      {isOpen ? <ModalOverlay bg="rgba(0, 0, 0, 0.4)" w="100%" /> : <></>}
      <ModalContent
        w="100%"
        // maxW="509px"
        h="max-content"
        m="0 auto"
        bg="#fff"
        borderRadius="10px"
        position="relative"
        overflow="auto"
        py="4"
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
