import { Box, Flex } from "@chakra-ui/react";
import React, { memo, useState } from "react";
import RightPanelContainer from "../../components/Common/RightPanelContainer";
import TabSelector from "../../components/Common/TabSelector";
import { useDashboardContext } from "../../util/reducers";
import { getPlans, useGetData } from "./helper";
import TabContainer from "../../components/Common/TabContainer";
import Pagination from "../../components/Common/Pagination/Index";
import { tableHeaderProtectionPlans } from "../NewClaims/helper";
import { OfflineDpp } from "../../components/modal";
import { AuthConnect } from "../../util/util";
import Toast from "../../components/notify";
import Plans from "../plans";

const ProtectionPlan = ({ type, setModal, isPartner = false }) => {
  const [activeTab, setActiveTab] = useState("All Plans");
  const [dppModal, setDppModal] = useState(false);
  const [dppLoading, setDppLoading] = useState(false);
  const [isGift, setIsGift] = useState(false);
  const [state, dispatch] = useDashboardContext();
  const {
    loading,
    handleSearchInput, searchText, handleSort, isSort,
    filterData,
  } = useGetData(type, activeTab, state.plans);
  const toggleTab = (e) => {
    return setActiveTab(e.target.id);
  };

  const createOfflineDpp = async (
    uid,
    nDevices,
    duration,
    email,
    phone,
    product,
    pType,
    price,
    date,
    soldBy,
    fullName,
    amountPaid
  ) => {
    if (
      nDevices !== "" &&
      duration !== "" &&
      product !== "" &&
      pType !== "" &&
      price !== ""
    ) {
      setDppLoading(true);
      const res = await AuthConnect("post", "plan/admin/create", {
        uid: uid,
        numOfDevices: nDevices,
        duration: duration,
        email: email,
        phoneNumber: phone,
        product: product,
        planType: pType,
        price: price,
        dateCreated: date,
        isGift: isGift,
        soldBy: soldBy,
        fullName: fullName,
        amountPaid: amountPaid,
      });
      if (res?.success) {
        Toast(
          isGift ? "Plan Gifted Successfully" : "Plan Created Successfully",
          "success"
        );
        setDppLoading(false);
        setDppModal(false);
        return setModal({
          modalId: "SUCCESS",
          title: "Success",
          onOpen: true,
          data: res.message,
        });
      } else {
        Toast(res?.message, "error");
        setDppLoading(false);
        setDppModal(false);
      }
    } else {
      Toast("Kindly fill all the required fields", "error");
    }
  };

  const rightAction = (e) => {
    if (type === "plans") {
      if (e === "Gift Plan") {
        setIsGift(true);
        setDppModal(true);
      } else {
        setIsGift(false);
        setDppModal(true);
      }
    }
  };

  return (
    <RightPanelContainer>
      <OfflineDpp
        dppModal={dppModal}
        setDppModal={setDppModal}
        dppLoading={dppLoading}
        createOfflineDpp={createOfflineDpp}
        isGift={isGift}
      />
      <TabSelector
        toggleTab={toggleTab}
        activeTab={activeTab}
        type={type}
        setModal={setModal}
        rightAction={rightAction}
        isPartner={isPartner}
      />
      <Flex
        direction="column"
        bg="#fff"
        mt="30px"
        borderRadius={isPartner ? "15px 15px 0px 0px" : "4px 4px 0px 0px"}
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
        boxShadow={
          isPartner
            ? "0px 24px 48px 0px rgba(47, 51, 59, 0.18)"
            : "0px 7px 20px rgba(0, 0, 0, 0.2)"
        }
        p="16px 15px 20px"
      >
        <Box minH="680px">
          <TabContainer
            type={type}
            tableHeader={tableHeaderProtectionPlans}
            activeTab={activeTab}
            state={state.plans}
            handleSearchInput={handleSearchInput}
            loading={loading}
            setModal={setModal}
            handleSort={handleSort}
            isPartner={isPartner}
          />
          <Pagination
            page={type}
            prevId={state.prevId}
            nextId={state.nextId}
            dispatch={dispatch}
            activeTab={activeTab}
            searchText={searchText}
            isSort={isSort}
            filterData={filterData}
            isPartner={isPartner}
          />
        </Box>
      </Flex>
    </RightPanelContainer>
  );
};

export default ProtectionPlan;
