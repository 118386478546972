import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { encodeQueryString } from "../../util/helpers/encodeEmail";
import Toast from "../notify";

import {
  assignThirdParty,
  CustomDefaultInput,
  searchTechnician,
} from "./helpers";

const AssignThirdParty = ({ onClose, modal, setModal }) => {
  const initialState = {
    fullName: "",
    businessName: "",
    email: "",
    phoneNumber: "",
    address: "",
    claimId: "",
    technicianId: "",
  };
  const [value, setValue] = useState(initialState);
  const [searchResult, setSearchResult] = useState([]);
  const handleValue = (e) => {
    const { name, value: values } = e.target;
    return setValue({ ...value, [name]: values });
  };
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  useEffect(() => {
    let waitTime, timer, sub;
    waitTime = 2000;
    sub = true;
    if (sub) {
      const input = document.getElementsByName("assignTechniciansSearch");
      input[0].addEventListener("keyup", (e) => {
        const text = e.currentTarget.value;
        // Clear timer
        clearTimeout(timer);
        // Wait for X ms and then process the request
        timer = setTimeout(() => {
          setSearchLoading(true);
          searchTechnician(text).then((res) => {
            if (res.status) {
              if (res.data?.docs.length === 0) {
                Toast("No technician found!", "info");
              }
              setSearchResult(res.data?.docs);
              setSearchLoading(false);
            }
          });
        }, waitTime);
      });
    }

    return () => {
      sub = false;
    };
  }, []);

  const handleSelectResult = (item) => {
    setValue({
      ...value,
      fullName: `${item.firstName} ${item.lastName}` ?? "",
      businessName: item.businessName ?? "",
      email: item.email ?? "",
      phoneNumber: item.phoneNumber ?? "",
      address: item.address ?? "",
      claimId: modal.data.claimId ?? "",
      technicianId: item.businessId ?? "",
    });
    return setSearchResult([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data = { ...value, notifyCustomer: true };

    assignThirdParty(data).then((res) => {
      if (res.status === 200) {
        return setModal({
          modalId: "SUCCESS",
          title: "SUCCESS",
          onOpen: true,
          data: res.message,
        });
      } else {
        return setModal({
          modalId: "ERROR",
          title:
            res.code === 403 || res.code === 400
              ? "Request Denied"
              : "Request Failed",
          onOpen: true,
          data: res.message,
        });
      }
    });
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Assign to 3rd Party`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Box position="relative">
            <CustomDefaultInput
              title="Search Technician"
              value={""}
              onChange={null}
              name="assignTechniciansSearch"
              placeholder={"Technician id, email, phone number"}
              type="search"
              loading={searchLoading}
            />

            {searchResult.length ? (
              <Flex
                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                p="1rem 0.5rem"
                position="absolute"
                bg="#fff"
                zIndex="1000"
                w="100%"
                direction="column"
              >
                {searchResult.map((item, index) => {
                  return (
                    <Text
                      px="1.5rem"
                      color="rgba(0, 0, 0, 0.6)"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      onClick={() => handleSelectResult(item)}
                      mb="5px"
                      _focus={{ outline: "none" }}
                      _hover={{ border: "1px solid #49A05C" }}
                      cursor="pointer"
                    >
                      {`${item.businessName ?? "N/A"} - ${item.firstName} ${
                        item.lastName
                      }`}
                    </Text>
                  );
                })}
              </Flex>
            ) : (
              <></>
            )}
          </Box>

          <CustomDefaultInput
            title="Full name"
            value={value.fullName}
            onChange={handleValue}
            name="fullName"
            placeholder={"Enter technician full name"}
          />
          <CustomDefaultInput
            title="Business name"
            value={value.businessName}
            onChange={handleValue}
            name="businessName"
            placeholder={"Enter business name"}
          />
          <CustomDefaultInput
            title="Email address"
            value={value.email}
            onChange={handleValue}
            name="email"
            placeholder={"Enter email address"}
          />
          <CustomDefaultInput
            title="Phone number"
            value={value.phoneNumber}
            onChange={handleValue}
            name="phoneNumber"
            placeholder={"Enter phone number"}
          />
          <CustomDefaultInput
            title="Address"
            value={value.address}
            onChange={handleValue}
            name="address"
            placeholder={"Enter address"}
          />
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default AssignThirdParty;
