import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import checkBoxOn from "../../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../../assets/svgs/newCheckboxOff.svg";
import checklistOn from "../../../assets/svgs/checkList_checkBox_on.svg";
import checklistOff from "../../../assets/svgs/checkList_checkBox_off.svg";
import disabledIcon from "../../../assets/svgs/checkList_checkBox_disabled.svg";
const Checkbox = ({
  text,
  handleCheck,
  id,
  toggle,
  mt = "1.5rem",
  mb = "1.5rem",
  fontSize = "1rem",
  required,
  type,
  disabled,
  viewChecklist,
}) => {
  return type === "checklist" ? (
    <Flex
      direction="row"
      justify="left"
      align="baseline"
      borderRadius="10px"
      p="0.5rem 0rem 0.5rem 0.5rem"
      pointerEvents={disabled ? "none" : ""}
      bg={disabled || viewChecklist ? "rgba(250, 250, 247, 1)" : "#fff"}
      border={
        disabled
          ? "1px solid rgba(227, 230, 235, 1)"
          : toggle
          ? "1px solid rgba(17, 142, 62, 1)"
          : "1px solid rgba(227, 230, 235, 1)"
      }
      onClick={handleCheck}
      id={id}
      mt={mt}
      mb={mb}
    >
      <Box cursor="pointer" pointerEvents="none">
        <Image
          src={disabled ? disabledIcon : toggle ? checklistOn : checklistOff}
          w="20px"
          h="20px"
          _hover={{ boxShadow: "0px 0px 0px 1px #118E3E inset" }}
          mb="-1"
        />
      </Box>
      <Box pointerEvents="none">
        <Text
          fontSize={fontSize}
          fontWeight="400"
          textAlign="left"
          color="#3A3C40"
          ml="7px"
          lineHeight="25px"
        >
          {text}
        </Text>
      </Box>
    </Flex>
  ) : (
    <Flex
      direction="row"
      justify="left"
      align="baseline"
      onClick={handleCheck}
      id={id}
      mt={mt}
      mb={mb}
      ml="0.3rem"
    >
      <Box cursor="pointer" pointerEvents="none">
        <Image
          src={toggle ? checkBoxOn : checkBoxOff}
          w="20px"
          h="20px"
          _hover={{ boxShadow: "0px 0px 0px 1px #118E3E inset" }}
          mb="-1"
          mr="2.5"
        />
      </Box>
      <Box pointerEvents="none">
        <Text
          fontSize={fontSize}
          fontWeight="400"
          textAlign="left"
          color="#3A3C40"
          ml="7px"
          lineHeight="25px"
        >
          {text}
          {required && <span className="has-text-danger">*</span>}{" "}
        </Text>
      </Box>
    </Flex>
  );
};

export default Checkbox;
