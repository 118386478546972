import React, { useState } from "react";
import { Flex, Input, Button, Box, Text, Spacer} from "@chakra-ui/react"
import InputHolder from "./InputHolder";
import { Link, useHistory } from 'react-router-dom';
import { AuthConnect } from "../util/util";
import Toast from "./notify";

function AuthRecovery({ token }) {
  const [loading, setLoading] = useState(false)
  const [pass, setPass] = useState("")

  const history = useHistory()


  //CHANGE PASSWORD ACTION
  async function changePassword() {
    const res = await AuthConnect("post","auth/account/recovery/change/password", {
      token: token,
      newPassword: pass,
    })
    if (res?.success) {
      Toast(
        "Password has been changed successfully",
        "success"
      )
      history.replace("/auth/signin")
    }
    else {
      setLoading(false)
    }
  }




  //FORM HANDLER
  const handleForm = (type) => {
    setLoading(true)
    changePassword()
  }

  return (
    <Flex direction="column" w="100%">

      <form onSubmit={e => {
            e.preventDefault()
            handleForm()
          }
        }
      > 
        <Text mb="10" color="grey" mt="10">
          <i
          className="mdi mdi-account-lock-outline"
          style={{ color: "grey", marginRight: "4px" }}></i>
          Reset your Password
        </Text>

        <Box mb="5">
          <InputHolder password placeholder="New Password" w="100%" onChange={e => setPass(e.target.value)} />
        </Box>

        <Button
          bg="#49A05C"
          p="2%" w="100%"
          border="none"
          color="white"
          _hover={{ cursor: "pointer" }}
          type="submit"
          borderRadius="2px"
          mb="20"
          isLoading={loading}
        >
          Change Password
        </Button>
      </form>

      
      <Text color="rgb(160,160,160)" mb="20" textAlign="center" fontWeight="bold">OR</Text>


      <Link to="/auth/signin">
        <Text fontWeight="bold" color="#000000" textAlign="center">Sign In</Text>
      </Link>
    </Flex>
  )
}

export default AuthRecovery;