import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table, } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";
import { async } from 'analytics/lib/analytics.cjs';


export default function ViewSalesHistory({id}) {
    const [data, setData] = useState()
    const [next, setNext] = useState("")
    const [prev, setPrev] = useState("")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)

    useEffect(async () => {
        setLoading(true)
        const res = await AuthConnect("get", "sale/admin/fetch?uid="+id)
        setData(res?.data?.docs)
        setLoading(false)
        if(res?.data?.next !== undefined) {
            setNext(res?.data?.next)
        }
        else {
            setNext("")
        }
    }, [])

    async function goNext() {
        setLoading2(true)
        const res = await AuthConnect("get", "sale/admin/fetch/more/"+next+"?uid="+id)
        setData(res?.data?.docs)
        setLoading2(false)
        if(res?.data?.next !== undefined) {
            setNext(res?.data?.next)
        }
        else {
            setNext("")
        }

        if(res?.data?.previous !== undefined) {
            setPrev(res?.data?.previous)
        }
        else {
            setPrev("")
        }
    }

    async function goPrev() {
        setLoading3(true)
        const res = await AuthConnect("get", "sale/admin/fetch/previous/"+prev+"?uid="+id)
        setData(res?.data?.docs)
        setLoading3(false)
        if(res?.data?.next !== undefined) {
            setNext(res?.data?.next)
        }
        else {
            setNext("")
        }
        if(res?.data?.previous !== undefined) {
            setPrev(res?.data?.previous)
        }
        else {
            setPrev("")
        }
    }

    const tableHeader = ["Created at", "Customer’s Email", "Business Email Address", "Plan Type", "Amount", "Commission"]

    return (
        <>
            <Flex w="100%" overflowX="scroll" className="noScrollBar" direction="column">
            <Table size="md" w="100%" fontSize="13px">
                <Thead>
                    <Tr bg="#FBFBFB">
                    {tableHeader.map((item, index) => (
                        <Th key={index}>{item}</Th>
                    ))
                    }
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        loading ?
                        <Flex w="100%" h="100%" justify="center" align="center" py="200px">
                            <Spinner size="md" color="#00B600" emptyColor="grey" />
                        </Flex>
                        :
                        Array.isArray(data) ? 
                        data.map((item, index) => (
                            <Tr key={index} background={index % 2 === 0 ? "#fff" : "#FBFBFB"}>
                                <Td isNumeric>
                                    {item?.createdAt ? (
                                    item?.createdAt.substr(0, 10)
                                    ) : (
                                    <Text color="rgb(215,215,215)">---- -- --</Text>
                                    )}
                                </Td>
                                <Td>{item?.customerEmail}</Td>
                                <Td>{item?.email}</Td>
                                <Td>{item?.planType}</Td>
                                <Td>{"₦"+item?.amount?.toLocaleString()}</Td>
                                <Td>{"₦"+item?.commission?.toLocaleString()}</Td>
                            </Tr>
                        ))
                        :
                        <Flex w="100%" h="100%" justify="center" align="center">
                            <Text fontWeight="700">No History</Text>
                        </Flex>
                    }
                </Tbody>  
            </Table>
            
            <Flex w="100%" align="center" mt="12">
                {
                    prev !== "" &&
                    <Flex
                        w="auto"
                        bg="#03A63C"
                        borderRadius="5px"
                        py="2"
                        px="9"
                        color="#fff"
                        cursor="pointer"
                        onClick={() => goPrev()}
                        border="1px solid #03A63C"
                    >
                        {loading3 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Previous"}
                    </Flex>
                }
                {
                    next !== "" &&
                    <Flex
                        w="auto"
                        bg="#03A63C"
                        borderRadius="5px"
                        py="2"
                        px="9"
                        color="#fff"
                        cursor="pointer"
                        onClick={() => goNext()}
                        border="1px solid #03A63C"
                        ml="auto"
                    >
                        {loading2 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Next"}
                    </Flex>
                }
            </Flex>
            </Flex>
        </>
    )
}