import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomDefaultInput, editDeviceDetails } from "./helpers";
import { brands_constants } from "../helper";

const EditDeviceDetails = ({ onClose, modal, setModal }) => {
  const initialState = {
    deviceType: "",
    deviceBrand: "",
    deviceModel: "",
    uniqueDeviceNumber: "",
    reason: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState);
  const handleValues = (e) => {
    const { name, value } = e.target;
    return setValues({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    const data = { ...values, activationId: modal.data?.activationId };
    setLoading(true);
    editDeviceDetails(data).then((res) => {
      // if (res.code == 403 || 500 || 400) {
      //   return setModal({
      //     modalId: "ERROR",
      //     title: res.code === 403 || 400 ? "Request Denied" : "Request Failed",
      //     onOpen: true,
      //     data: res.message,
      //   });
      // }
      if (res.status === 200) {
        setLoading(false);
        return setModal({
          modalId: "SUCCESS",
          title: "SUCCESS",
          onOpen: true,
          data: res.message,
        });
      }
    });
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Edit Device Details`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Box>
            <CustomDefaultInput
              title="Enter Device Type"
              value={values.deviceType}
              onChange={handleValues}
              name="deviceType"
              placeholder={"Please enter the device type"}
            />
            <CustomDefaultInput
              title="Enter Device Brand"
              value={values.deviceBrand}
              onChange={handleValues}
              name="deviceBrand"
              placeholder={"Please enter the device brand"}
            />
            <CustomDefaultInput
              title="New Device Model"
              value={values.deviceModel}
              onChange={handleValues}
              name="deviceModel"
              placeholder={"Please enter a model for this device"}
            />
            <CustomDefaultInput
              title="New IMEI Number"
              value={values.uniqueDeviceNumber}
              onChange={handleValues}
              name="uniqueDeviceNumber"
              placeholder={"Please enter an IMEI number for this device"}
            />
            <>
              <Text
                color="#3A3C40"
                fontSize="14px"
                ml="7px"
                lineHeight="25px"
                fontWeight="400"
              >
                Reason
              </Text>
              <textarea
                style={{
                  padding: "2.5% 3%",
                  borderRadius: "5px",
                  border: "1px solid rgba(227, 230, 235, 1)",
                  transition: "300ms ease-in-out",
                  marginBottom: "4%",
                  resize: "none",
                  height: "158px",
                  width: "100%",
                }}
                placeholder="Please state a reason for this change/update."
                onChange={handleValues}
                className="slHover"
                name="reason"
              ></textarea>
            </>
          </Box>
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default EditDeviceDetails;
