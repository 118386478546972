import React, { useState } from "react";
import { Input, Flex } from "@chakra-ui/react"

function InputHolder(props) {
  const [passType, setPassType] = useState(true)

  if (props.password) {
    return (
      <Flex w="100%" align="center" position="relative">
        <Input
          type={passType ? "password" : "text"}
          required
          p="2%"
          pr="8%"
          mb="7"
           _focus={{ borderColor: "#00008F" }}
          {...props}
          outlineColor="#00008F"
          boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.07)"
        />
        <i
          className={`mdi ${ passType ? "mdi-eye-off-outline" : "mdi-eye-outline" }`}
          style={{ color: "rgb(220,220,230)", marginRight: "2%", marginBottom: "6%", cursor: "pointer", position: "absolute", right: 0, }}
          onClick={() => 
            passType ?
            setPassType(false)
            :
            setPassType(true)
          }
        ></i>
      </Flex>
    )
  }
  else if (props.type != "select") {
    return (
      <Input
        type={props.type}
        required
        p="2%"
        mb="7"
        _focus={{ borderColor: "#00008F" }}
        ref={props.inpRef}
        {...props}
        outlineColor="#00008F"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.07)"
      />
    )
  }
  else {
    return (
      <select required p="2%" mb="7" _focus={{ borderColor: "#00008F", boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)" }} outlineColor="#00008F" {...props}>
        {
          props.choose && 
          (<>
            <option></option>
            {props?.choose.map(
              (item, index) => 
              <option key={index}>
                {item.name}
              </option>
            )
            }
          </>
          )
        }
      </select>
    )
  }
}

export default InputHolder;