import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { reimburseCustomer } from "../../util/helpers/request";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#49A05C",
  color: "#fff",
};

const ReimburseCustomer = ({ onClose, modal, setModal }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const reimburse = watch("reimburse") ?? 0;
  const onSubmit = () => {
    const data = {
      claimId: modal.data,
      amountPaidToCust: reimburse,
    };
    reimburseCustomer(data).then(async (res) => {
      if (res.status) {
        await modal?.refreshFunc();
        onClose();
        return setModal({
          modalId: "SUCCESS",
          title: "Success",
          onOpen: true,
          data: res.message,
        });
      }
      setLoading(false);
    });
  };
  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Reimburse Customer`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.7rem 1rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label has-text-weight-light ">
                Amount to reimburse <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder=""
                  ref={register({ required: true })}
                  name="reimburse"
                />
              </div>
              {errors.reimburse && (
                <p className="help is-danger">
                  Please enter the reimbursement amount
                </p>
              )}
            </div>
            <Box w="100%" mt="2.3rem">
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Box>
                  <Button
                    style={buttonStyles}
                    textAlign="center"
                    onClick={onClose}
                    width={"100%"}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    style={buttonStyles}
                    textAlign="center"
                    width={"100%"}
                  >
                    {loading ? (
                      <Spinner color="#fff" emptyColor="lightgrey" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default ReimburseCustomer;
