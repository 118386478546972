import React, { useState, useEffect, useRef } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { handleSelect, postClaimStatus, postRepairStatus, postOrderStatus } from "./helpers";
import { useAppContext } from "../../util/reducers";
import { capitalizeFirstWord } from "../../util/helpers";
import { useFetchStatus } from "../../util/hooks/useFetchStatus";
import Toast from "../notify";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";



const UpdateStatusModal = ({ onClose, setModal, modal }) => {
  const { register } = useForm();
  const [selectedValue, setSelectedValue] = useState([]);
  const [state, dispatch] = useAppContext();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [isNotify, setIsNotify] = useState(true)

  useFetchStatus(modal.state);
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: partnerBg,
    color: "#fff",
    marginTop: "5px",
  };
  
  const selectStyle = {
    webkitAppearance: "none",
    mozAppearance: "none",
    background: "transparent",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "99%",
    backgroundPositionY: "13px",
    border: "1px solid #dfdfdf",
    borderRadius: "2px",
    padding: "1rem 0.5rem",
    width: "100%",
  };

  useEffect(() => {
    const list = [];
    setStatusLoading(true);
    for (const [key, value] of Object.entries(
      modal.state === "repairs" ? state.repairStatus : modal.state === "orders" ? state.orderStatus : state.claimStatus
    )) {
      list.push([key, value]);
    }
    setList(list);
    setStatusLoading(false);
  }, [state]);

  const handleSubmit = () => {
    setLoading(true);
    const { data } = modal;
    let split = selectedValue.split(",");
    switch (modal.state) {
      case "claims":
        return postClaimStatus({
          claimId: data,
          status: split[1],
          statusNo: split[0],
          notifyCustomer: isNotify,
        }).then((res) => {
          if (res.status) {
            onClose();
            return setModal({
              modalId: "SUCCESS",
              title: "Success",
              onOpen: true,
              data: res.message,
            });
          }
        });

      case "repairs":
        return postRepairStatus({
          repairId: data,
          status: split[1],
          statusNo: split[0],
          notifyCustomer: isNotify,
        }).then(async (res) => {
          if (res.status) {
            await modal?.refreshFunc();
            onClose();
            return setModal({
              modalId: "SUCCESS",
              title: "Success",
              onOpen: true,
              data: res.message,
            });
          }
        });

        case "orders":
        return postOrderStatus({
          orderId: data,
          status: split[1],
          statusNo: split[0],
          notifyCustomer: isNotify,
        }).then(async (res) => {
          if (res.status) {
            await modal?.refreshFunc();
            onClose();
            return setModal({
              modalId: "SUCCESS",
              title: "Success",
              onOpen: true,
              data: res.message,
            });
          }
          else {
            Toast(res?.message, "error")
            setLoading(false)
          }
        });

      default:
        break;
    }
    setLoading(false);
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        fontFamily="Montserrat"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Update Status`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          top="0rem"
          right="0rem"
          _hover={{ bg: "none", color: sessionStorage.getItem("PartnerPrimaryColor") }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem 40px">
          <Text
            color="#3A3C40"
            fontSize="15px"
            lineHeight="25px"
            fontWeight="500"
          >
            {capitalizeFirstWord(modal.state)} Status
          </Text>
          {statusLoading ? (
            "loading..."
          ) : (
            <select
              onChange={(e) => handleSelect(e, setSelectedValue)}
              {...register("rejectStatus")}
              style={selectStyle}
            >
              <option value={"select"}>select...</option>
              {list.map((item, i) => {
                return (
                  <>
                    <option style={{ display: modal.state === "claims" ? item[1] === "fixed" ? "block" : "none" : "block" }} value={[item[0], item[1]]}>{item[1]}</option>
                  </>
                );
              })}
            </select>
          )}

          <Flex align="center" mt="5" mb="5">
            <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
            <Flex flex="1">Notify Customer?</Flex>
          </Flex>

          <Box w="100%" mt="15px">
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Button
                style={buttonStyles}
                textAlign="center"
                width={"100%"}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Flex>
          </Box>
        </Box>
      </ModalBody>
    </>
  );
};

export default UpdateStatusModal;
