const MODALS = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  SUBMIT_CLAIM_REPAIR_COST: "SUBMIT_CLAIM_REPAIR_COST",
  REJECT_CLAIM: "REJECT_CLAIM",
  CONFIRM_PAYMENT: "CONFIRM_PAYMENT",
  UPDATE_COST: "UPDATE_COST",
  UPDATE_STATUS: "UPDATE_STATUS",
  SUBMIT_REPAIR_REPAIR_COST: "SUBMIT_REPAIR_REPAIR_COST",
  REJECT_PROMPT: "REJECT_PROMPT",
  SUBMITDIAGNOSIS: "SUBMIT_DIAGNOSIS",
  REIMBURSEMENT_CUSTOMER: "REIMBURSEMENT_CUSTOMER",
  USER_CONTACTED: "USER_CONTACTED",
  ACTIVATION_APPROVE: "ACTIVATION_APPROVE",
  ADD_NOTE: "ADD_NOTE",
  META_MODAL: "META_MODAL",
  REPAIR_ESTIMATE: "REPAIR_ESTIMATE",
  CONVERT_CLAIM_TO_REPAIR: "CONVERT_CLAIM_TO_REPAIR",
  ADD_ADMIN: "ADD_ADMIN",
  LINK_DEVICE: "LINK_DEVICE",
  GIFT_PLAN: "GIFT_PLAN",
  REPAIR_CONVERT: "REPAIR_CONVERT",
  SUSPEND_REPAIR: "SUSPEND_REPAIR",
  DELETE_REPAIR: "DELETE_REPAIR",
  REFUND_REPAIR: "REFUND_REPAIR",
  CREATE_REPAIR: "CREATE_REPAIR",
  CREATE_USER: "CREATE_USER",
  EDIT_SERIAL_NUMBER: "EDIT_SERIAL_NUMBER",
  EDIT_EMAIL: "EDIT_EMAIL",
  CREATE_CLAIM: "CREATE_CLAIM",
  UPDATE_REPAIR_COST_CLAIMS: "UPDATE_REPAIR_COST_CLAIMS",
  CONVERT_ACCOUNT: "CONVERT_ACCOUNT",
  SUSPEND_PLAN: "SUSPEND_PLAN",
  RENEW_PLAN: "RENEW_PLAN",
  VERIFY_CLAIM: "VERIFY_CLAIM",
  EDIT_DEVICE_DETAILS: "EDIT_DEVICE_DETAILS",
  COVERAGE_DETAILS: "COVERAGE_DETAILS",
  ASSIGN_THIRD_PARTY: "ASSIGN_THIRD_PARTY",
  ASSIGN_ACCOUNT_MANAGER: "ASSIGN_ACCOUNT_MANAGER",
  EDIIT_PHONE_NUMBER: "EDIIT_PHONE_NUMBER",
  VIEW_UPLOAD_FILE: "VIEW_UPLOAD_FILE",
  UPLOAD_FILE: "UPLOAD_FILE",
  TRANSFER_PLAN: "TRANSFER_PLAN",
  UPDATE_BUSINESS_PROFILE: "UPDATE_BUSINESS_PROFILE",
  SOLD_BY: "SOLD_BY",
  CREATE_ORDER: "CREATE_ORDER",
  ADD_DEVICE: "ADD_DEVICE",
  EDIT_DEVICE: "EDIT_DEVICE",
  UPFRONT_PAYMENT: "UPFRONT_PAYMENT",
  ORDER_REPAYMENT: "ORDER_REPAYMENT",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  CONFIRM_REPAYMENT: "CONFIRM_REPAYMENT",
  SHARE_ORDER: "SHARE_ORDER",
  UPDATE_ORDER: "UPDATE_ORDER",
  REFUND_ORDER: "REFUND_ORDER",
  LINK_MULTIPLE_DEVICES: "LINK_MULTIPLE_DEVICES",
  EDIIT_PHONE_NUMBER: "EDIIT_PHONE_NUMBER",
  TRANSFER_REPAIR: "TRANSFER_REPAIR",
  CHANGE_REPAYMENT_DATE: "CHANGE_REPAYMENT_DATE",
  EXTEND_VALIDITY: "EXTEND_VALIDITY",
  RENEW_MONTHLY_PLAN: "RENEW_MONTHLY_PLAN",

  DELETE_DEVICE: "DELETE_DEVICE",
  UPGRAGE_PLAN: "UPGRAGE_PLAN",
  TRANSFER_ACTIVATION: "TRANSFER_ACTIVATION",
  EDIT_MONTHLY: "EDIT_MONTHLY",
  EXPORT_DATA: "EXPORT_DATA",
  SUBMIT_VIDEO: "SUBMIT_VIDEO",
  ADD_TEAM: "ADD_TEAM",
  VERIFY_CLAIM_CONFIRM: "VERIFY_CLAIM_CONFIRM",
  REJECT_CLAIM_NEW: "REJECT_CLAIM_NEW",
};


export default MODALS;
