import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  claimStatus: {},
  repairStatus: {},
  orderStatus: {},
  hotlistSummary: {},
};

const defaultSlice = createSlice({
  name: "defaultAppStore",
  initialState,
  reducers: {
    setClaimStatus: (state, { payload }) => {
      state.claimStatus = { ...payload };
    },
    setRepairStatus: (state, { payload }) => {
      state.repairStatus = { ...payload };
    },
    setOrderStatus: (state, { payload }) => {
      state.orderStatus = { ...payload };
    },
    setHotlistSummary: (state, { payload }) => {
      state.hotlistSummary = { ...payload };
    },
  },
});

export const {
  setClaimStatus,
  setRepairStatus,
  setOrderStatus,
  setHotlistSummary,
} = defaultSlice.actions;

export default defaultSlice.reducer;
