import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import on from "../../assets/svgs/checkboxGreen.svg";
import off from "../../assets/svgs/checkboxRed.svg";
import { capitalizeFirstWord } from "../../util/helpers";

const CoverageDetails = ({ onClose, modal, setModal }) => {
  const coverageList1 = [
    {
      name: "Repair Coverage up to",
      status: {
        lite_plus: "N50,000",
        lite: "N10,000",
        basic: "N24,000",
        premium: "N90,000",
        custom: `N${modal.data?.gampCoverage.toLocaleString()}`,
        pro: "N600,000",
      },
    },
    { name: "Broken Screen", status: modal.data?.damagedScreen },
    { name: "Charging port", status: modal.data?.chargingPort },
    { name: "Liquid spill cleanup", status: modal.data?.liquidSpillCleanup },
    { name: "Broken device case", status: modal.data?.chassis },
    { name: "Charger", status: modal.data?.charger },
  ];
  const coverageList2 = [
    { name: "OS support", status: modal.data?.OSSupport },
    { name: "Battery", status: modal.data?.battery },
    { name: "Speaker", status: modal.data?.speaker },
    { name: "Keyboard", status: modal.data?.keyboard },
    { name: "HDD Replacement", status: modal.data?.HDDReplacement },
    { name: "Motherboard", status: modal.data?.motherboard },
  ];
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
        fontFamily="Montserrat"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {modal.title}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem 2rem">
          <Flex
            bg="rgba(240, 239, 239, 1)"
            w="100%"
            borderRadius="0 0 15px 15px"
            direction="column"
          >
            <Flex w="100%" p=".75rem 1rem">
              <Text
                w="100%"
                textAlign="center"
                fontWeight="600"
                fontSize="16px"
                lineHeight="19px"
              >
                Device plan coverage{" "}
                {`(${capitalizeFirstWord(modal.data?.planType)})`}
              </Text>
            </Flex>
            <Flex direction="row" m="2px">
              <Flex
                bg="#fff"
                w="100%"
                borderRadius="0 0 14px 14px"
                align="center"
              >
                <Flex
                  bg="#fff"
                  direction="column"
                  w="100%"
                  p="1.5rem 1rem"
                  borderRadius="0 0 0 13px"
                >
                  {coverageList1.map((item, index) => {
                    return (
                      <Box my=".75rem">
                        {item.name === "Repair Coverage up to" ? (
                          <Flex align="center">
                            <Flex mr="15px">
                              <Image src={on} />
                            </Flex>
                            <Text
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="17px"
                            >{`${item.name} ${
                              item.status[modal.data?.planType]
                            }`}</Text>
                          </Flex>
                        ) : (
                          <Flex align="center">
                            <Flex mr="15px">
                              {item.status ? (
                                <Flex>
                                  <Image src={on} />
                                </Flex>
                              ) : (
                                <Flex>
                                  <Image src={off} />
                                </Flex>
                              )}
                            </Flex>
                            <Text
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="17px"
                            >{`${item.name}`}</Text>
                          </Flex>
                        )}
                      </Box>
                    );
                  })}
                </Flex>
                <Flex bg="rgba(240, 239, 239, 1)" w="3px" h="80%"></Flex>
                <Flex
                  bg="#fff"
                  direction="column"
                  w="100%"
                  p="1.5rem 1rem 1.5rem 2rem"
                  borderRadius="0 0 13px 0"
                >
                  {coverageList2.map((item, index) => {
                    return (
                      <Box my=".75rem">
                        <Flex align="center">
                          <Flex mr="15px">
                            {item.status ? (
                              <Flex>
                                <Image src={on} />
                              </Flex>
                            ) : (
                              <Flex>
                                <Image src={off} />
                              </Flex>
                            )}
                          </Flex>
                          <Text
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="17px"
                          >{`${item.name}`}</Text>
                        </Flex>
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default CoverageDetails;
