import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import Pagination from "../../components/Common/Pagination/Index";
import RightPanelContainer from "../../components/Common/RightPanelContainer";
import TabContainer from "../../components/Common/TabContainer";
import TabSelector from "../../components/Common/TabSelector";
import { useDashboardContext } from "../../util/reducers";
import { useGetData } from "../protectionPlans/helper";
import { tableHeaderClaims } from "./helper";

const Claims = ({ type, setModal, isPartner = false }) => {
  const [activeTab, setActiveTab] = useState("Pending");
  const [state, dispatch] = useDashboardContext();

  const toggleTab = (e) => {
    return setActiveTab(e.target.id);
  };

  const {loading, handleSearchInput, searchText, handleSort,  
    isSort,
    filterData, } = useGetData(
    type,
    activeTab,
    state.claims
  );

  return (
    <RightPanelContainer>
      <TabSelector
        toggleTab={toggleTab}
        activeTab={activeTab}
        type={type}
        setModal={setModal}
        isPartner={isPartner}
      />

      <Flex
        direction="column"
        bg="#fff"
        mt="30px"
        borderRadius={isPartner ? "15px 15px 0px 0px" : "4px 4px 0px 0px"}
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
        boxShadow={
          isPartner
            ? "0px 24px 48px 0px rgba(47, 51, 59, 0.18)"
            : "0px 7px 20px rgba(0, 0, 0, 0.2)"
        }
        p="16px 15px 20px"
      >
        <Box minH="680px">
          <TabContainer
            tableHeader={tableHeaderClaims}
            activeTab={activeTab}
            state={state.claims}
            handleSearchInput={handleSearchInput}
            loading={loading}
            type={type}
            setModal={setModal}
            handleSort={handleSort}
            isPartner={isPartner}
          />
          <Pagination
            page={type}
            prevId={state.prevId}
            nextId={state.nextId}
            dispatch={dispatch}
            activeTab={activeTab}
            searchText={searchText}
            isSort={isSort}
            filterData={filterData}
            isPartner={isPartner}
          />
        </Box>
      </Flex>
    </RightPanelContainer>
  );
};

export default Claims;