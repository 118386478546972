import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const SuspendRepair = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const txt = useRef("")
  
    async function sendFunc() {
      if(txt.current.value !== "") {
        setLoading(true)
        const res = modal.state === "orders" ?  await AuthConnect("post", "order/admin/suspend", {
          orderId: modal.data,
          reason: txt.current.value,
        }) : modal.state === "claims" ?  await AuthConnect("post", "claim/admin/suspend", {
          claimId: modal.data,
          reason: txt.current.value,
        }) : await AuthConnect("post", "repair/admin/suspend", {
          repairId: modal.data,
          reason: txt.current.value,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: res?.message,
            onOpen: true,
            data: modal.state === "orders" ? `You’ve successfully suspended this order` :  modal.state === "claims" ? `You’ve successfully suspended this claim` : `You’ve successfully suspended this repair`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Enter the reason", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {modal.state === "orders" ? `Suspend Order` : modal.state === "claims" ? `Suspend Claim` : `Suspend Repair`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
              <Text py="20px" fontWeight="bold">Are you sure you want to suspend this {modal.state === "orders" ? "order" : modal.state === "claims" ? "Claim" : "repair"}?</Text>
              
                <Box>
                    <Text mb="2">Reason</Text>
                    <Textarea
                    padding="25px 20px"
                    ref={txt}
                    borderColor="#E3E6EB"
                    _focus={{ border: "1px solid #49A05C" }}
                    _hover={{ border: "1px solid #49A05C" }}
                    sx={{ "::placeholder": { color: "#989898" } }}
                    outlineColor="#E3E6EB"
                    w="100%"
                    resize="none"
                    transition="300ms ease-in-out"
                    borderRadius="5px"
                    ></Textarea>
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="auto"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default SuspendRepair;
  