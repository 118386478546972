import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  approveActivation,
  fetchUploadsConnected,
  rejectActivationVideo,
  sendNote,
} from "./helpers";
import VideoCover from "../../assets/svgs/adminVidCover.svg";
import check from "../../assets/svgs/success1.svg";
import rejectedIcon from "../../assets/svgs/cancel.svg";
import Toast from "../notify";

const prevButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
};

const approveButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
};
const rejectButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#E90000",
  color: "#fff",
};

const ActivationApprove = ({ modal, setModal }) => {
  const initialState = {
    step: 0,
    type: "success",
  };
  const [url, setUrl] = useState("");
  const [toggle, setToggle] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [step, setStep] = useState(initialState);
  useEffect(() => {
    fetchUploadsConnected(modal.data).then((result) => {
      if (result) {
        setUrl(result.data.docs[0]?.url);
        result.data.docs.length > 1 ? setToggle(true) : setToggle(false);
        result.data.docs.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
      }
    });
  }, []);

  const handleApproveVideo = () => {
    let data, note;
    data = {
      activationId: modal.data,
      notifyCustomer: true,
    };
    note = {
      tid: modal.data,
    }
    approveActivation(data)
      .then((result) => {
        if (result.status === 200) {
          setStep({ ...step, step: 1, type: "success" });
        }
      })
      .catch((err) =>
        Toast(err.message ?? "Ooops, something went wrong!", "error")
      );
    sendNote(note)
      .then((res) => {})
      .catch((err) =>
        Toast(err.message ?? "Ooops, something went wrong!", "error")
      );
  };

  const handleRejectVideo = () => {
    let data;
    data = {
      activationId: modal.data,
      notifyCustomer: true,
      reason:
        "Video was rejected by an admin. Please contact support for more help.",
    };
    rejectActivationVideo(data)
      .then((result) => {
        if (result.status === 200) {
          setStep({ ...step, step: 1, type: "rejected" });
        }
      })
      .catch((err) =>
        Toast(err.message ?? "Ooops, something went wrong!", "error")
      );
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
        fontFamily="Montserrat"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Watch Video`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem 1rem">
          {step.step === 0 ? (
            <>
              <form>
                <Box>
                  <video
                    src={url}
                    style={{
                      width: "100%",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      maxHeight: "70vh",
                    }}
                    controls
                    playsInline
                    autoPlay
                    poster={VideoCover}
                    type="video/mp4"
                  ></video>
                </Box>

                <Box w="100%" mt="15px">
                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex w="20%">
                      {toggle ? (
                        <Button
                          type="button"
                          _hover={{ background: "#03A63C10" }}
                          style={prevButtonStyles}
                          textAlign="center"
                          width={"100%"}
                        >
                          Previous
                        </Button>
                      ) : null}
                    </Flex>
                    <Flex w="35%">
                      <Button
                        type="button"
                        _hover={{ background: "#03A63C10" }}
                        style={approveButtonStyles}
                        textAlign="center"
                        width={"100%"}
                        disabled={isEmpty}
                        onClick={handleApproveVideo}
                        mr="23px"
                      >
                        Approve
                      </Button>{" "}
                      <Button
                        type="button"
                        _hover={{ background: "#E9000010" }}
                        style={rejectButtonStyles}
                        textAlign="center"
                        width={"100%"}
                        disabled={isEmpty}
                        onClick={handleRejectVideo}
                      >
                        Reject
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </form>
            </>
          ) : (
            <Flex h="500px">
              {step.type === "success" ? (
                <>
                  <Flex direction="column" margin="0 auto">
                    <div
                      style={{
                        width: "5rem",
                        height: "5rem",
                        position: "relative",
                        borderRadius: "50%",
                        margin: "121px auto 37px",
                      }}
                    >
                      <img
                        src={check}
                        alt="check mark"
                        style={{
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          left: "50%",
                          transform: "translateX(-50%)",
                          margin: "auto",
                        }}
                      />
                    </div>
                    <Flex w="100%" justify="center">
                      <Text
                        textAlign="center"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="24px"
                        mb="10px"
                      >
                        Video Approved!
                      </Text>
                    </Flex>
                    <Text fontWeight="500" fontSize="14px" lineHeight="24px">
                      You’ve successfully approved the video
                    </Text>
                    <Flex justify="center" mt="70px">
                      <Button
                        type="button"
                        _hover={{ background: "#03A63C10" }}
                        style={approveButtonStyles}
                        textAlign="center"
                        disabled={isEmpty}
                        onClick={() => {
                          return setModal({ isOpen: false });
                        }}
                      >
                        Got it!
                      </Button>
                    </Flex>
                  </Flex>
                </>
              ) : step.type === "rejected" ? (
                <>
                  <Flex direction="column" margin="0 auto">
                    <div
                      style={{
                        width: "5rem",
                        height: "5rem",
                        position: "relative",
                        borderRadius: "50%",
                        margin: "121px auto 37px",
                      }}
                    >
                      <img
                        src={rejectedIcon}
                        alt="check mark"
                        style={{
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          left: "50%",
                          transform: "translateX(-50%)",
                          margin: "auto",
                        }}
                      />
                    </div>
                    <Flex w="100%" justify="center">
                      <Text
                        textAlign="center"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="24px"
                        mb="10px"
                      >
                        Video Rejected!
                      </Text>
                    </Flex>

                    <Flex justify="center" mt="70px">
                      <Button
                        type="button"
                        _hover={{ background: "#03A63C10" }}
                        style={approveButtonStyles}
                        textAlign="center"
                        disabled={isEmpty}
                        onClick={() => {
                          return setModal({ isOpen: false });
                        }}
                      >
                        Got it!
                      </Button>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <></>
              )}
            </Flex>
          )}
        </Box>
      </ModalBody>
    </>
  );
};

export default ActivationApprove;
