import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plans: {
    plan: [],
  },
  claims: {
    approved: [],
    pending: [],
    rejected: [],
    expired: [],
    ongoing: [],
  },
  repairs: {
    hotlist: [],
    pending: [],
    approved: [],
    processed: [],
    rejected: [],
    others: [],
  },
  orders: {
    hotlist: [],
    pending: [],
    approved: [],
    processed: [],
    ongoing: [],
    review: [],
    rejected: [],
    others: [],
  },
  users: {
    users: [],
  },
  business: {},
  activations: {
    approved: [],
    pending: [],
    incomplete: [],
    expired: [],
    rejected: [],
  },
  transactions: {
    pending: [],
    success: [],
    failed: [],
  },
  withdrawals: {
    pending: [],
    paid: [],
    rejected: [],
  },
  sales: {
    sale: [],
  },
  technicians: {
    technician: [],
  },
  nextId: null,
  prevId: null,
  innerNextId: null,
  innerPrevId: null,
  approvedCount: "",
  pendingCount: "",
  processedCount: "",
  rejectedCount: "",
  activeCount: "",
  expiredCount: "",
  hotlistCount: "",
  othersCount: "",
  usersCount: "",
  adminsCount: "",
  paidCount: "",
  successCount: "",
  failedCount: "",
  businessUsersCount: "",
  techniciansCount: "",
  salesCount: "",
  incompleteCount: "",
  reviewCount: "",
  ongoingCount: "",
};

const dashboardSlice = createSlice({
  name: "dashboardStore",
  initialState,
  reducers: {
    setActivePlans: (state, { payload }) => {
      state.plans.plan = payload;
    },
    setExpiredPlans: (state, { payload }) => {
      state.plans.plan = payload;
    },
    setActiveCount: (state, { payload }) => {
      state.activeCount = payload.activeCount;
    },
    setSalesCount: (state, { payload }) => {
      state.salesCount = payload.salesCount;
    },
    setUsersCount: (state, { payload }) => {
      state.usersCount = payload.usersCount;
    },
    setAdminsCount: (state, { payload }) => {
      state.adminsCount = payload.adminsCount;
    },
    setPaidCount: (state, { payload }) => {
      state.paidCount = payload.paidCount;
    },
    setSuccessCount: (state, { payload }) => {
      state.successCount = payload.successCount;
    },
    setFailedCount: (state, { payload }) => {
      state.failedCount = payload.failedCount;
    },
    setTechniciansCount: (state, { payload }) => {
      state.techniciansCount = payload.techniciansCount;
    },
    setBusinessUsersCount: (state, { payload }) => {
      state.businessUsersCount = payload.businessUsersCount;
    },
    setExpiredCount: (state, { payload }) => {
      state.expiredCount = payload.expiredCount;
    },
    setApprovedCount: (state, { payload }) => {
      state.approvedCount = payload.approvedCount;
    },
    setPendingCount: (state, { payload }) => {
      state.pendingCount = payload.pendingCount;
    },
    setProcessedCount: (state, { payload }) => {
      state.processedCount = payload.processedCount;
    },
    setRejectedCount: (state, { payload }) => {
      state.rejectedCount = payload.rejectedCount;
    },
    setHotlistCount: (state, { payload }) => {
      state.hotlistCount = payload.hotlistCount;
    },
    setOthersCount: (state, { payload }) => {
      state.othersCount = payload.othersCount;
    },
    setOngoingCount: (state, { payload }) => {
      state.ongoingCount = payload.ongoingCount;
    },
    setReviewCount: (state, { payload }) => {
      state.reviewCount = payload.reviewCount;
    },
    setIncompleteCount: (state, { payload }) => {
      state.incompleteCount = payload.incompleteCount;
    },
    setApprovedClaims: (state, { payload }) => {
      state.claims.approved = payload;
    },
    setPendingClaims: (state, { payload }) => {
      state.claims.pending = payload;
    },
    setRejectedClaims: (state, { payload }) => {
      state.claims.rejected = payload;
    },
    setProcessedClaims: (state, { payload }) => {
      state.claims.processed = payload;
    },
    setOngoingClaims: (state, { payload }) => {
      state.claims.ongoing = payload;
    },
    setAdminUsers: (state, { payload }) => {
      state.users.admins = payload;
    },
    setBusinessUsers: (state, { payload }) => {
      state.users.business = payload;
    },
    setTechnicianUsers: (state, { payload }) => {
      state.users.technicians = payload;
    },
    setUsersUsers: (state, { payload }) => {
      state.users.users = payload;
    },
    setHotlistRepairs: (state, { payload }) => {
      state.repairs.hotlist = payload;
    },
    setPendingRepairs: (state, { payload }) => {
      state.repairs.pending = payload;
    },
    setApprovedRepairs: (state, { payload }) => {
      state.repairs.approved = payload;
    },
    setProcessedRepairs: (state, { payload }) => {
      state.repairs.processed = payload;
    },
    setRejectedRepairs: (state, { payload }) => {
      state.repairs.rejected = payload;
    },
    setOthersRepairs: (state, { payload }) => {
      state.repairs.others = payload;
    },
    setHotlistOrders: (state, { payload }) => {
      state.orders.hotlist = payload;
    },
    setPendingOrders: (state, { payload }) => {
      state.orders.pending = payload;
    },
    setApprovedOrders: (state, { payload }) => {
      state.orders.approved = payload;
    },
    setProcessedOrders: (state, { payload }) => {
      state.orders.processed = payload;
    },
    setRejectedOrders: (state, { payload }) => {
      state.orders.rejected = payload;
    },
    setOthersOrders: (state, { payload }) => {
      state.orders.others = payload;
    },
    setOngoingOrders: (state, { payload }) => {
      state.orders.ongoing = payload;
    },
    setReviewOrders: (state, { payload }) => {
      state.orders.review = payload;
    },
    setApprovedActivations: (state, { payload }) => {
      state.activations.approved = payload;
    },
    setPendingActivations: (state, { payload }) => {
      state.activations.pending = payload;
    },
    setRejectedActivations: (state, { payload }) => {
      state.activations.rejected = payload;
    },
    setExpiredActivations: (state, { payload }) => {
      state.activations.expired = payload;
    },
    setIncompleteActivations: (state, { payload }) => {
      state.activations.incomplete = payload;
    },
    setPendingTransactions: (state, { payload }) => {
      state.transactions.pending = payload;
    },
    setSuccessTransactions: (state, { payload }) => {
      state.transactions.success = payload;
    },
    setFailedTransactions: (state, { payload }) => {
      state.transactions.failed = payload;
    },
    setPendingWithdrawals: (state, { payload }) => {
      state.withdrawals.pending = payload;
    },
    setPaidWithdrawals: (state, { payload }) => {
      state.withdrawals.paid = payload;
    },
    setRejectedWithdrawals: (state, { payload }) => {
      state.withdrawals.rejected = payload;
    },
    setSales: (state, { payload }) => {
      state.sales.sale = payload;
    },
    setTechnicians: (state, { payload }) => {
      state.technicians.technician = payload;
    },
    setNextId: (state, { payload }) => {
      state.nextId = payload;
    },
    setPrevId: (state, { payload }) => {
      state.prevId = payload;
    },
    setInnerNextId: (state, { payload }) => {
      state.innerNextId = payload;
    },
    setInnerPrevId: (state, { payload }) => {
      state.innerPrevId = payload;
    },
  },
});

export const {
  setActivePlans,
  setExpiredPlans,
  setApprovedClaims,
  setPendingClaims,
  setRejectedClaims,
  setAdminUsers,
  setBusinessUsers,
  setTechnicianUsers,
  setUsersUsers,
  setProcessedClaims,
  setOngoingClaims,
  setHotlistRepairs,
  setPendingRepairs,
  setApprovedRepairs,
  setProcessedRepairs,
  setRejectedRepairs,
  setOthersRepairs,
  setHotlistOrders,
  setPendingOrders,
  setApprovedOrders,
  setProcessedOrders,
  setRejectedOrders,
  setOthersOrders,
  setApprovedActivations,
  setPendingActivations,
  setExpiredActivations,
  setRejectedActivations,
  setIncompleteActivations,
  setPendingTransactions,
  setSuccessTransactions,
  setFailedTransactions,
  setPendingWithdrawals,
  setPaidWithdrawals,
  setRejectedWithdrawals,
  setSales,
  setTechnicians,
  setNextId,
  setPrevId,
  setApprovedCount,
  setPendingCount,
  setSuccessCount,
  setFailedCount,
  setPaidCount,
  setProcessedCount,
  setRejectedCount,
  setActiveCount,
  setExpiredCount,
  setHotlistCount,
  setOthersCount,
  setUsersCount,
  setAdminsCount,
  setBusinessUsersCount,
  setTechniciansCount,
  setSalesCount,
  setIncompleteCount,
  setOngoingCount,
  setOngoingOrders,
  setReviewCount,
  setReviewOrders,
  setInnerNextId,
  setInnerPrevId,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
