import {
  Flex,
  ModalCloseButton,
  Text,
  ModalBody,
  Box,
  Button,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { AuthConnect } from "../../util/util";
import { useState, useRef, useEffect } from "react";
import Toast from "../notify";
import InputHolderUser from "../inputHolderUser";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
};

const ConvertAccount = ({ onClose, modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const [newAcc, setNewAcc] = useState("");
  const [txt3, setTxt3] = useState("");
  const [txt4, setTxt4] = useState("");
  const txt = useRef("");
  const txt3Inp = useRef("");
  const txt4Inp = useRef("");

  useEffect(() => {
    txt.current.value = modal.data[1]?.email ?? "";
    setTxt3(modal.data[1]?.businessName ?? "");
    setTxt4(modal.data[1]?.businessId ?? "");
  }, []);

  async function sendFunc() {
    if (newAcc !== "") {
      setLoading(true);
      const res =
      newAcc === "sales-supervisor"
        ?  await AuthConnect("post", "sale/convert/supervisor", {
          email: txt.current.value,
          managerId: modal.data[1]?.email,
      })
      :
      newAcc === "sales-manager"
        ?  await AuthConnect("post", "sale/convert/manager", {
          email: txt.current.value,
      })
      : await AuthConnect("post", "user/admin/convert/account", {
          email: txt.current.value,
          role: newAcc,
          businessName: txt3,
          businessId: txt4,
      })
      if (res?.success) {
        onClose();
        return setModal({
          modalId: "SUCCESS",
          messageTitle: "Account Type Converted Successfully",
          onOpen: true,
          data: `You’ve successfully converted the account type`,
        });
      }
      setLoading(false);
    } else {
      Toast("Kindly select the new role and business name", "error");
    }
  }

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Convert Account Type`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _focus={"none"}
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem">
          <InputHolderUser
            label="Email"
            type="text"
            placeholder=""
            inpRef={txt}
            value={txt.current.value}
          />
          <Text mb="2">New Account Type</Text>
          <select
            style={{
              width: "100%",
              padding: "2.5% 3%",
              borderRadius: "5px",
              outlineColor: "#49A05C",
              border: "1px solid #E3E6EB",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
            }}
            className="slHover rSelect"
            onChange={(e) => setNewAcc(e.target.value)}
          >
            <option value="">Select account type</option>
            {[
              "corporate-user",
              "corporate-admin",
              "retailer-admin",
              "retailer-user",
              "sales-manager",
              "agent",
              "user",
              "head-of-sales",
              "sales-supervisor",
            ].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          {newAcc !== "sales-manager" && newAcc !== "sales-supervisor" && (
            <>
              <InputHolderUser
                label="Business Name"
                type="text"
                placeholder=""
                inpRef={txt3Inp}
                value={txt3}
                onInput={(e) => setTxt3(e.target.value)}
              />
              <InputHolderUser
                label="Business ID"
                type="text"
                placeholder=""
                inpRef={txt4Inp}
                value={txt4}
                onInput={(e) => setTxt4(e.target.value)}
              />
            </>
          )}
          <Box w="100%" mt="15px">
            <Flex w="100%" justify="center">
              <Button
                _hover={{ background: "#03A63C" }}
                style={buttonStyles}
                textAlign="center"
                width="100%"
                onClick={() => sendFunc()}
              >
                {loading ? (
                  <Spinner color="#fff" emptyColor="lightgrey" />
                ) : (
                  "Yes, Proceed"
                )}
              </Button>
            </Flex>
          </Box>
        </Box>
      </ModalBody>
    </>
  );
};

export default ConvertAccount;
