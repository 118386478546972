import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import rejectedIcon from "../../assets/svgs/cancel.svg";

const rejectButtonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#E90000",
  color: "#fff",
};

const Error = ({ onClose, setModal, modal }) => {
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
        fontFamily="Montserrat"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {modal.title}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Flex justify="center" align="center" mt="2rem">
            <img src={rejectedIcon} alt="check mark" />
          </Flex>
          <Flex w="100%" justify="center" direction="column" align="center">
            <Flex justify="center" align="center" mb="63px">
              <p className="subtitle is-size-6">
                <Text
                  className="has-text-weight-bold mt-4"
                  mb="8px"
                  textAlign="center"
                >
                  {modal.messageTitle || "Request failed!"}
                </Text>
                {modal.data}
              </p>
            </Flex>
            <Button
              bg="#49A05C"
              p="2%"
              mt="1rem"
              w="100%"
              border="none"
              color="white"
              _hover={{ cursor: "pointer" }}
              type="submit"
              borderRadius="5px"
              // isLoading={loading}
              width={"100%"}
              onClick={() => {
                onClose();
                setModal({ isOpen: false });
              }}
            >
              Got it!
            </Button>
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default Error;
