import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import Pagination from "../../components/Common/Pagination/Index";
import RightPanelContainer from "../../components/Common/RightPanelContainer";
import TabContainer from "../../components/Common/TabContainer";
import TabSelector from "../../components/Common/TabSelector";
import { useDashboardContext } from "../../util/reducers";
import { useGetData } from "../protectionPlans/helper";
import ExtraActionThIcon from "../../components/Common/ExtraActionThIcon";

const Technicians = ({ type, setModal }) => {
  const [activeTab, setActiveTab] = useState("Profile");
  const [state, dispatch] = useDashboardContext();

  const toggleTab = (e) => {
    return setActiveTab(e.target.id);
  };
  const {loading, handleSearchInput} = useGetData(
    type,
    activeTab,
    state.technicians
  );

  const tableHeaderTechnicians = [
    "Created at",
    "Technician's Email",
    "Technician's Name",
    "Account Type",
    "Phone Number",
    "Action",
  ];

  return (
    <RightPanelContainer>
      <TabSelector
        toggleTab={toggleTab}
        activeTab={activeTab}
        type={type}
        setModal={setModal}
      />
      <Flex
        direction="column"
        bg="#fff"
        mt="30px"
        borderRadius="4px 4px 0px 0px"
        sx={{ "::-webkit-scrollbar": { display: "none" } }}
        boxShadow="0px 7px 20px rgba(0, 0, 0, 0.2)"
        p="16px 15px 20px"
      >
        <Box minH="680px">
          <TabContainer
            tableHeader={tableHeaderTechnicians}
            activeTab={activeTab}
            state={state.technicians}
            handleSearchInput={handleSearchInput}
            loading={loading}
            type={type}
            setModal={setModal}
          />
          <Pagination
            page={type}
            prevId={state.prevId}
            nextId={state.nextId}
            dispatch={dispatch}
          />
        </Box>
      </Flex>
    </RightPanelContainer>
  );
};

export default Technicians;
