import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomDefaultInput, upgradePlan } from "./helpers";

const UpgradePlan = ({ modal, setModal, onClose }) => {
  const plans = ["Select plan", "lite", "basic", "premium", "pro"];
  const duration = ["Select duration", 6, 12];
  const initialState = {
    oldPrice: "",
    newPrice: "",
    planType: "",
    duration: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValue] = useState(initialState);
  const handleInputField = (e) => {
    const { name, value } = e.target;

    return setValue({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    const data = {
      planId: modal.data[0],
      planType: values.planType,
      duration: values.duration,
      newPrice: values.newPrice,
    };
    setLoading(true);
    upgradePlan(data).then((res) => {
      if (res.status) {
        setLoading(false);
        return setModal({
          modalId: "SUCCESS",
          title: "SUCCESS",
          onOpen: true,
          messageTitle: "Plan Upgraded!",
          data: res.message,
        });
      }
    });
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Upgrade Plan`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <Box>
            <Text
              color="#3A3C40"
              fontSize="14px"
              ml="7px"
              lineHeight="25px"
              fontWeight="400"
            >
              Choose New Plan
            </Text>
            <Select
              onChange={handleInputField}
              name="planType"
              border="1px solid #E3E6EB"
              borderRadius="5px"
              mb="10px"
              _focus={{ outline: "none" }}
              _hover={{ outline: "none" }}
            >
              {plans.map((item, i) => {
                return <option value={item}>{item}</option>;
              })}
            </Select>
          </Box>
          <Box>
            <Text
              color="#3A3C40"
              fontSize="14px"
              ml="7px"
              lineHeight="25px"
              fontWeight="400"
            >
              Duration
            </Text>
            <Select
              onChange={handleInputField}
              name="duration"
              border="1px solid #E3E6EB"
              borderRadius="5px"
              mb="10px"
              _focus={{ outline: "none" }}
              _hover={{ outline: "none" }}
            >
              {duration.map((item, i) => {
                return <option value={item}>{item}</option>;
              })}
            </Select>
          </Box>

          <CustomDefaultInput
            title="Old Price"
            value={values.oldPrice}
            onChange={handleInputField}
            name="oldPrice"
            placeholder={"Please enter the old price"}
          />

          <CustomDefaultInput
            title="New Price"
            value={values.newPrice}
            onChange={handleInputField}
            name="newPrice"
            placeholder={"Please enter the new price"}
          />

          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default UpgradePlan;
