import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Toast from "../notify";
import { createNewUser, CustomDefaultInput } from "./helpers";

const selectStyle = {
  webkitAppearance: "none",
  mozAppearance: "none",
  background: "transparent",
  backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "99%",
  backgroundPositionY: "13px",
  border: "1px solid #dfdfdf",
  borderRadius: "2px",
  padding: "1rem 0.5rem",
  width: "100%",
};

const CreateUser = ({ modal, setModal, onClose }) => {
  const role = ["Select role", "agent", "corporate", "retailer", "user", "technician"];
  const initialState = {
    email: "",
    phoneNumber: "",
    fullName: "",
    role: "",
    businessName: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValue] = useState(initialState);
  const handleInputField = (e) => {
    const { name, value } = e.target;

    return setValue({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    return createNewUser(values)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          return setModal({
            modalId: "SUCCESS",
            title: "SUCCESS",
            onOpen: true,
            messageTitle: "User Created Successfully!",
            data: res.message,
          });
        }
      })
      .then((err) =>
        Toast(err.message ?? "Ooops, something went wrong!", "error")
      );
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Create ${modal.state}`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <CustomDefaultInput
            title="Email"
            value={values.email}
            onChange={handleInputField}
            name="email"
            // placeholder={"Please enter a valid email"}
          />
          <CustomDefaultInput
            title="Phone Number"
            value={values.phoneNumber}
            onChange={handleInputField}
            name="phoneNumber"
            // placeholder={"Please enter a valid email"}
          />
          <CustomDefaultInput
            title="Full Name"
            value={values.fullName}
            onChange={handleInputField}
            name="fullName"
            // placeholder={"Please enter a valid email"}
          />
          <Box>
            <Text
              color="#3A3C40"
              fontSize="14px"
              ml="7px"
              lineHeight="25px"
              fontWeight="400"
            >
              Role
            </Text>
            <Select
              onChange={handleInputField}
              name="role"
              border="1px solid #E3E6EB"
              borderRadius="5px"
              mb="10px"
              _focus={{ outline: "none" }}
              _hover={{ outline: "none" }}
            >
              {role.map((item, i) => {
                return <option value={item}>{item}</option>;
              })}
            </Select>
          </Box>
          <CustomDefaultInput
            title="Business Name"
            value={values.businessName}
            onChange={handleInputField}
            name="businessName"
            // placeholder={"Please enter a valid email"}
          />
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default CreateUser;
