import {
  Flex,
  ModalCloseButton,
  Text,
  ModalBody,
  Box,
  Button,
  Spinner,
  Textarea,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { AuthConnect } from "../../util/util";
import { useState, useRef, useEffect } from "react";
import Toast from "../notify";
import InputHolderUser from "../inputHolderUser";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";



const SubmitRepairCost = ({ onClose, modal, setModal }) => {
  const [loading, setLoading] = useState(false)
  const [isNotify, setIsNotify] = useState(true)
  const [repCost, setRepCost] = useState(0)
  const [planCov, setPlanCov] = useState(0)
  const txt1 = useRef(0)
  const txt2 = useRef(0)
  const txt5 = useRef("")
  const txt6 = useRef("")

  async function sendFunc() {
    var a = txt1.current.value
    var b = txt2.current.value
    if(a !== "" && b !== "") {
      setLoading(true)
      const res = await AuthConnect("post", "claim/admin/submit/cost", {
        claimId: modal.data?.claimId,
        repairCost: parseInt(repCost),
        gampCoverage: parseInt(planCov),
        planCoverage: parseInt(planCov),
        technicalPartner: txt6.current.value,
      })
      if(res?.success) {
        onClose();
        return setModal({
          modalId: "SUCCESS",
          messageTitle: res?.message,
          onOpen: true,
          data: `You’ve successfully added the repair cost`,
        });
      }
      setLoading(false)
    }
    else {
      Toast("Enter the Repair Cost and Plan Coverage", "error")
    }
  }

  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: sessionStorage.getItem("PartnerPrimaryColor"),
    color: "#fff",
  };

  useEffect(() => {
    if(repCost !== "" && planCov !== "") {
      const amt = parseInt(repCost) - parseInt(planCov)
      txt5.current.value = amt
    }
    else if(repCost !== "") {
      txt5.current.value = repCost
    }
  }, [planCov, repCost ])

  
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          Submit Repair Cost
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _focus={"none"}
          _hover={{ bg: "none", color: sessionStorage.getItem("PartnerPrimaryColor") }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem">
          <Box>
              <Box>
                  
                  <InputHolderUser label="Repair Cost (₦)" placeholder="Enter the repair cost" inpRef={txt1} type="number" onInput={(e) => setRepCost(e.target.value)} />
                  <InputHolderUser label="Plan Coverage (₦)" placeholder="Enter the plan coverage" inpRef={txt2} type="number" onInput={(e) => setPlanCov(e.target.value)} />
                  <InputHolderUser label="Technical Partner" placeholder="e.g gamp or Slot Ikeja" inpRef={txt6} type="text" />
                  <InputHolderUser label="Amount to Pay (₦)" inpRef={txt5} type="text" disabled={true} />
              </Box>
            <Flex align="center" mt="5" mb="5">
                <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                <Flex flex="1">Notify Customer?</Flex>
              </Flex>
            <Box w="100%" mt="15px">
              <Flex
                w="100%"
                justify="center"
              >
                  <Button
                    _hover={{ background: sessionStorage.getItem("PartnerPrimaryColor") }}
                    style={buttonStyles}
                    textAlign="center"
                    width="100%"
                    onClick={() => sendFunc()}
                  >
                    {loading ? (
                      <Spinner color="#fff" emptyColor="lightgrey" />
                    ) : (
                      "Yes, Proceed"
                    )}
                  </Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </ModalBody>
    </>
  );
};

export default SubmitRepairCost;
