import {
  Box,
  Checkbox,
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Table,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { boolBadgeSwitch, status } from "./helper";
import _ from "lodash";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { batch } from "react-redux";
import {
  useDashboardContext,
  useViewDetailsContext,
} from "../../util/reducers";
import {
  setId,
  setViewDetails,
  setViewType,
} from "../../util/reducers/viewDetailsSlice";

import { approveActivation } from "../Modal/helpers";
import { AdminUploadVideo } from "../modal";
import { VerifyTransactionNew } from "../../util/util";
import Toast from "../notify";
import $ from "jquery";
import { setNextId, setPrevId } from "../../util/reducers/dashboardSlice";

const renderSwitch = (
  type,
  tableHeader,
  tableContent,
  setModal,
  handleButton,
  historyRef,
  disableActionList,
  loading
) => {
  switch (type) {
    case "plans":
      return (
        <Plans
          type={type}
          tableContent={tableContent}
          tableHeader={tableHeader}
          setModal={setModal}
          handleButton={handleButton}
          historyRef={historyRef}
          disableActionList={disableActionList}
        />
      );
    case "claims":
      return (
        <Claims
          type={type}
          tableContent={tableContent}
          tableHeader={tableHeader}
          setModal={setModal}
          handleButton={handleButton}
          historyRef={historyRef}
          disableActionList={disableActionList}
        />
      );
    case "user":
      return (
        <Users
          type={type}
          tableContent={tableContent}
          tableHeader={tableHeader}
          setModal={setModal}
          handleButton={handleButton}
          historyRef={historyRef}
          disableActionList={disableActionList}
        />
      );
    case "activations":
      return (
        <Activations
          type={type}
          tableContent={tableContent}
          tableHeader={tableHeader}
          setModal={setModal}
          handleButton={handleButton}
          historyRef={historyRef}
          disableActionList={disableActionList}
          loading={loading}
        />
      );
    default:
      return;
  }
};

const Tables = ({
  type,
  tableHeader,
  tableContent,
  setModal,
  disableActionList,
  loading,
}) => {
  const historyRef = useRef([]);
  const generateState = (arr = []) => {
    const obj = {};
    arr.forEach((item, index) => {
      obj[`toggle${index}`] = false;
    });
    return obj;
  };

  const initialState = generateState([]);
  const [toggle, setToggle] = useState(initialState);

  const handleButton = (index) => {
    let id = `toggle${index}`;
    setToggle({ ...toggle, ["toggle" + index]: !toggle[id] });
    historyRef.current.push(id);
  };

  return (
    <div>
      {renderSwitch(
        type,
        tableHeader,
        tableContent,
        setModal,
        handleButton,
        historyRef,
        disableActionList,
        loading
      )}
    </div>
  );
};

export default Tables;

const Plans = ({
  type,
  tableHeader,
  tableContent,
  setModal,
  handleButton,
  historyRef,
}) => {
  const pname = sessionStorage.getItem("PartnerName")
  const actionList = [
    "View Details",
  ];

  return (
    <Table size="md" w="100%">
      <Thead>
        <Tr bg="#FBFBFB">
          {tableHeader.map((item, index) => {
            return <Th key={_.uniqueId("claims-table-head")}>{item}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>
        {tableContent.map((item, index) => {
          return (
            <Tr
              key={_.uniqueId("claims-table")}
              background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
            >
              <Td isNumeric>
                {item.createdAt ? (
                  item.createdAt.substr(0, 10)
                ) : (
                  <Text color="rgb(215,215,215)">---- -- --</Text>
                )}
              </Td>
              <Td>{item.email}</Td>
              <Td>{item.planType}</Td>
              <Td>{item.numOfDevices ?? 0}</Td>
              <Td>{item.activatedDevices ?? 0}</Td>
              <Td>{status(item.status, type)}</Td>
              <Td>
                <ActionMenu
                  list={pname === "axa" ? ["View Details"] : actionList}
                  index={index}
                  setModal={setModal}
                  handleButton={handleButton}
                  historyRef={historyRef}
                  viewDetails={item}
                  viewType="plans"
                  id={item.planId}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const Claims = ({
  type,
  tableHeader,
  tableContent,
  setModal,
  handleButton,
  historyRef,
}) => {
  const nArr = JSON.parse(sessionStorage.getItem("PartnerPermissions"))
  const claimsPermit = nArr.includes("approve_claims")
  const claimsManage = nArr.includes("manage_claims")
  const actionList1 = [
    "View Details",
    "Submit Video",
  ];

  const actionList2 = [
    "View Details",
    "Submit Video",
    "Reject Claim",
    "Add Repair Cost",
  ];

  const actionList2b = [
    "View Details",
    "Submit Video",
    "Add Repair Cost",
  ];


  const actionList3 = [
    "View Details",
    "Submit Video",
    "Approve Claim",
    "Reject Claim",
    "Add Repair Cost",
  ];

  const actionList3b = [
    "View Details",
    "Submit Video",
    "Add Repair Cost",
  ];


  const actionList4 = [
    "View Details",
    "Submit Video",
    "Add Repair Cost",
    "Confirm Payment",
    "Update Status"
  ];

  return (
    <Table size="md" w="100%">
      <Thead>
        <Tr bg="#FBFBFB">
          {tableHeader.map((item, index) => {
            return <Th key={_.uniqueId("claims-table-head")}>{item}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>
        {tableContent.map((item, index) => {
          return (
            <Tr
              key={_.uniqueId("claims-table")}
              background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
            >
              <Td isNumeric>
                {item.createdAt ? (
                  item.createdAt.substr(0, 10)
                ) : (
                  <Text color="rgb(215,215,215)">---- -- --</Text>
                )}
              </Td>
              <Td>{item.email}</Td>
              <Td>{item.deviceBrand + " " + item.deviceModel}</Td>
              <Td>{item.customerCoverage?.toLocaleString() ?? 0}</Td>
              <Td>{item.technicalPartner ?? "N/A"}</Td>
              <Td>{status(item.status, type)}</Td>
              <Td>
                <ActionMenu
                  list={(!claimsManage || item.status === "incomplete" || item.status === "rejected") ? actionList1 : (item.status === "pending") ? claimsPermit ? actionList2 : actionList2b : (item.status === "awaiting approval") ? claimsPermit ? actionList3 : actionList3b : actionList4}
                  index={index}
                  setModal={setModal}
                  id={item.claimId}
                  handleButton={handleButton}
                  historyRef={historyRef}
                  viewDetails={item}
                  viewType="claims"
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const Activations = ({
  type,
  tableHeader,
  tableContent,
  setModal,
  handleButton,
  historyRef,
  disableActionList,
  loading,
}) => {
  const pname = sessionStorage.getItem("PartnerName")
  const actionList1 = [
    "View Details",
    "Create Claim",
  ];
  const actionList2 = [
    "View Details",
  ];
  const role = sessionStorage.getItem("GampRole");

  return (
    <>
      <Table size="md" w="100%">
        <Thead>
          <Tr bg="#FBFBFB">
            {tableHeader.map((item, index) => {
              return <Th key={_.uniqueId("activation-table-head")}>{item}</Th>;
            })}
          </Tr>
        </Thead>

        {Array.isArray(tableContent) && tableContent.length ? (
          tableContent.map((item, index) => {
            return (
              <Tbody>
                <Tr
                  key={_.uniqueId("activation-table")}
                  background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
                >
                  <Td isNumeric>
                    {item.createdAt ? (
                      item.createdAt.substr(0, 10)
                    ) : (
                      <Text color="rgb(215,215,215)">---- -- --</Text>
                    )}
                  </Td>
                  <Td>{item.email}</Td>
                  <Td>{item.deviceBrand + " " + item.deviceModel}</Td>
                  <Td>{boolBadgeSwitch(item.verified)}</Td>
                  <Td>
                    {boolBadgeSwitch(item.claims.length === 0 ? false : true)}
                  </Td>
                  <Td>{status(item.status, type)}</Td>
                  {disableActionList ? (
                    ""
                  ) : (
                    <Td display="flex" style={{ justifyContent: "center" }}>
                      <ActionMenu
                        list={item.status === "approved" ? actionList1 : actionList2}
                        index={index}
                        setModal={setModal}
                        id={item.activationId}
                        handleButton={handleButton}
                        historyRef={historyRef}
                        viewDetails={item}
                        viewType="activations"
                      />
                    </Td>
                  )}
                </Tr>
              </Tbody>
            );
          })
        ) : (
          <></>
        )}
      </Table>
    </>
  );
};

const Users = ({
  type,
  tableHeader,
  tableContent,
  setModal,
  handleButton,
  historyRef,
}) => {
  const pname = sessionStorage.getItem("PartnerName")
  const actionList = [
    "View Details",
  ];

  return (
    <Table size="md" w="100%">
      <Thead>
        <Tr bg="#FBFBFB">
          {tableHeader.map((item, index) => {
            return <Th key={_.uniqueId("claims-table-head")}>{item}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>
        {tableContent.map((item, index) => {
          return (
            <Tr
              key={_.uniqueId("claims-table")}
              background={index % 2 === 0 ? "#fff" : "#FBFBFB"}
            >
              <Td isNumeric>
                {item.createdAt ? (
                  item.createdAt.substr(0, 10)
                ) : (
                  <Text color="rgb(215,215,215)">---- -- --</Text>
                )}
              </Td>
              <Td>{item.email}</Td>
              <Td>
                {item.phoneNumber ? (
                  item.phoneNumber
                ) : (
                  <Text color="rgb(215,215,215)">---- -- --</Text>
                )}
              </Td>
              <Td>{`${item.firstName} ${item.lastName}`}</Td>
              <Td>{item.role}</Td>
              <Td>{status(item.signUpStatus, type)}</Td>
              <Td>
                <ActionMenu
                  list={pname === "axa" ? ["View Details"] : actionList}
                  index={index}
                  setModal={setModal}
                  id={item.id}
                  handleButton={handleButton}
                  historyRef={historyRef}
                  viewDetails={item}
                  viewType="users"
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};


const ActionMenu = ({ list = [], setModal, id, viewDetails, viewType }) => {
  const history = useHistory();
  const [state, dispatch] = useViewDetailsContext();
  const [loading, setLoading] = useState("");
  const { token, activationId, uid, email, phoneNumber, planId } = viewDetails;

  const handleMenuAction = (e, index, id) => {
    switch (index) {
      case "View Details":
        batch(() => {
          dispatch(setViewType(viewType));
          dispatch(setViewDetails(viewDetails));
          // dispatch(setPrevId(""));
          // dispatch(setNextId(""));
          // dispatch(setId(planId));
        });
        $("#disp242424area1").hide();
        $("#disp242424area2").css("display", "flex");
        document.getElementById("testKz").click();
        return;
      case "User Contacted":
        return setModal({
          modalId: "USER_CONTACTED",
          onOpen: true,
          data: [id, viewType],
          refreshFunc: () => {},
        });
      case "Upgrade Plan":
        return setModal({
          modalId: "UPGRAGE_PLAN",
          onOpen: true,
          data: [id],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Assign Account Manager":
        return setModal({
          modalId: "ASSIGN_ACCOUNT_MANAGER",
          onOpen: true,
          data: { uid },
          refreshFunc: () => {},
        });
      case "Update Business Profile":
        return setModal({
          modalId: "UPDATE_BUSINESS_PROFILE",
          onOpen: true,
          data: [viewDetails?.businessId, viewDetails],
          refreshFunc: () => {},
        });
      case "Verify Claim":
        return setModal({
          modalId: "VERIFY_CLAIM",
          onOpen: true,
          data: { claimId: id, type: viewType, details: viewDetails },
          refreshFunc: () => {},
        });
      case "Assign to 3rd Party":
        return setModal({
          modalId: "ASSIGN_THIRD_PARTY",
          onOpen: true,
          data: { claimId: id, type: viewType, details: viewDetails },
          refreshFunc: () => {},
        });
      case "Edit Device Details":
        return setModal({
          modalId: "EDIT_DEVICE_DETAILS",
          onOpen: true,
          data: { activationId, viewType },
          refreshFunc: () => {},
        });
      case "Approve Claim":
      return setModal({
        modalId: "VERIFY_CLAIM_CONFIRM",
        onOpen: true,
        data: id,
        refreshFunc: () => {},
      });
      case "Reject Claim":
      return setModal({
        modalId: "REJECT_CLAIM_NEW",
        onOpen: true,
        data: id,
        refreshFunc: () => {},
      });
      case "Edit Email":
        return setModal({
          modalId: "EDIT_EMAIL",
          onOpen: true,
          data: { uid, email },
          state: viewType,
          refreshFunc: () => {},
        });
      case "Edit Phone Number":
        return setModal({
          modalId: "EDIIT_PHONE_NUMBER",
          onOpen: true,
          data: { uid, phoneNumber },
          state: viewType,
          refreshFunc: () => {},
        });
      case "Link Device":
        return setModal({
          modalId: "LINK_DEVICE",
          onOpen: true,
          data: token,
          state: "plans",
          refreshFunc: () => {},
        });
      case "Edit Serial Number":
        return setModal({
          modalId: "EDIT_SERIAL_NUMBER",
          onOpen: true,
          data: activationId,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Create Claim":
        return setModal({
          modalId: "CREATE_CLAIM",
          onOpen: true,
          data: viewDetails,
          state: viewType,
          refreshFunc: () => {},
        });
    case "Submit Video":
      return setModal({
        modalId: "SUBMIT_VIDEO",
        onOpen: true,
        data: viewDetails,
        state: viewType,
        refreshFunc: () => {},
      });
      case "Add Repair Cost":
        if (viewType === "claims") {
          return setModal({
            modalId: "SUBMIT_CLAIM_REPAIR_COST",
            onOpen: true,
            data: viewDetails,
            state: viewType,
            refreshFunc: () => {},
          });
        } else {
          return setModal({
            modalId: "SUBMIT_REPAIR_REPAIR_COST",
            onOpen: true,
            data: id,
            state: viewType,
            refreshFunc: () => {},
          });
        }
      case "Update Repair Cost":
        return setModal({
          modalId: "UPDATE_REPAIR_COST_CLAIMS",
          onOpen: true,
          data: viewDetails,
          state: viewType,
        });
      case "Reject":
        return setModal({
          modalId: "REJECT_CLAIM",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Update Status":
        return setModal({
          modalId: "UPDATE_STATUS",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Confirm Payment":
        return setModal({
          modalId: "CONFIRM_PAYMENT",
          onOpen: true,
          data: [id, viewDetails],
          state: "claims",
          refreshFunc: () => {},
        });
      case "Upfront Payment":
        return setModal({
          modalId: "UPFRONT_PAYMENT",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Update Order":
        return setModal({
          modalId: "UPDATE_ORDER",
          onOpen: true,
          data: [id, viewDetails],
          state: "orders",
          refreshFunc: () => {},
        });

      case "Refund Payment":
        return setModal({
          modalId: "REFUND_ORDER",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });
        
        case "Edit Monthly Rate":
        return setModal({
          modalId: "EDIT_MONTHLY",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Order Repayment":
        return setModal({
          modalId: "ORDER_REPAYMENT",
          onOpen: true,
          data: [id, viewDetails?.repayId],
          state: "orders",
          refreshFunc: () => {},
        });

      case "Confirm Order Payment":
        return setModal({
          modalId: "CONFIRM_ORDER",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });
      case "Share Order pricing":
        return setModal({
          modalId: "SHARE_ORDER",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Confirm Order RePayment":
        return setModal({
          modalId: "CONFIRM_REPAYMENT",
          onOpen: true,
          data: id,
          state: "orders",
          refreshFunc: () => {},
        });

      case "Confirm Price":
        return setModal({
          modalId: "CONFIRM_PAYMENT",
          onOpen: true,
          data: [id, viewDetails],
          state: "repairs",
          refreshFunc: () => {},
        });

      case "Approve":
        // let data;
        // data = {
        //   activationId: viewDetails.activationId,
        //   notifyCustomer: true,
        // };
        // approveActivation(data)
        //   .then((result) => {
        //     if (result.status === 200) {
        //       return setModal({
        //         modalId: "SUCCESS",
        //         title: "Success",
        //         onOpen: true,
        //         data: result.message,
        //       });
        //     }
        //   })
        //   .catch((err) => console.log(err));
        return;

      case "Add a Note":
        return setModal({
          modalId: "ADD_NOTE",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Submit Repair Estimate":
        return setModal({
          modalId: "REPAIR_ESTIMATE",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Suspend Repair":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Suspend Order":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Suspend Claim":
        return setModal({
          modalId: "SUSPEND_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Delete Repair":
        return setModal({
          modalId: "DELETE_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Delete Order":
        return setModal({
          modalId: "DELETE_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Convert Repair to Claim":
        return setModal({
          modalId: "REPAIR_CONVERT",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Update Price":
        return setModal({
          modalId: "UPDATE_COST",
          onOpen: true,
          data: [id, viewDetails],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Update Device Diagnosis":
        return setModal({
          modalId: "SUBMIT_DIAGNOSIS",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });

      case "Refund Repair":
        return setModal({
          modalId: "REFUND_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Transfer Repair":
        return setModal({
          modalId: "TRANSFER_REPAIR",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Transfer Linked Device":
        return setModal({
          modalId: "TRANSFER_ACTIVATION",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Change Repayment Date":
        return setModal({
          modalId: "CHANGE_REPAYMENT_DATE",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Convert Account":
        return setModal({
          modalId: "CONVERT_ACCOUNT",
          onOpen: true,
          data: [id, viewDetails],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Suspend Plan":
        return setModal({
          modalId: "SUSPEND_PLAN",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Transfer Plan":
        return setModal({
          modalId: "TRANSFER_PLAN",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Sold By":
        return setModal({
          modalId: "SOLD_BY",
          onOpen: true,
          data: id,
          state: viewType,
          refreshFunc: () => {},
        });
      case "Renew Plan":
        return setModal({
          modalId: "RENEW_PLAN",
          onOpen: true,
          data: [id, viewDetails],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Renew Monthly Plan":
        return setModal({
          modalId: "RENEW_MONTHLY_PLAN",
          onOpen: true,
          data: [id, viewDetails],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Extend Validity":
        return setModal({
          modalId: "EXTEND_VALIDITY",
          onOpen: true,
          data: [id, viewDetails],
          state: viewType,
          refreshFunc: () => {},
        });
      case "Verify Transaction":
        return VerifyTransactionNew(id, setModal, setLoading);

      default:
        return;
    }
  };

  return (
    <>
      <Menu position="relative" w="30%">
        <MenuButton
          bgColor="transparent"
          border="1px solid #E3E6EB"
          borderRadius="2px"
          h="16px"
          transition="all .2s"
          _hover={{ cursor: "pointer" }}
        >
          {loading === id ? (
            <i
              className="mdi mdi-loading mdi-spin"
              style={{ fontSize: "20px" }}
            ></i>
          ) : (
            <i
              className="mdi mdi-dots-horizontal"
              style={{ fontSize: "20px" }}
            ></i>
          )}
        </MenuButton>
        <MenuList borderRadius="2px">
          {list.map((item, index, list) => {
            return (
              <MenuItem
                key={_.uniqueId("action-menu")}
                mb="2"
                px="5"
                py="2"
                onClick={(e) => handleMenuAction(e, item, id)}
              >
                {item}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
