import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text,
  Image,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Toast from "../notify";
import { postRepairDiagnosis } from "./helpers";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
};

const SubmitDiagnosis = ({ onClose, modal, setModal }) => {
  const [note, setNote] = useState("");
  const [isNotify, setIsNotify] = useState(true)
  const [loading, setLoading] = useState(false);
  const handleSubmitDiagnosis = async () => {
    if(note !== "") {
      const data = { repairId: modal.data, diagnosis: note, notifyCustomer: isNotify, };
      setLoading(true);
      return postRepairDiagnosis(data).then((res) => {
        setLoading(false)
        if (res.status === 200) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Device Diagnostics Submitted Successfully!",
            onOpen: true,
            data: `You’ve successfully submitted the device diagnostics report`,
          });
        } else {
          Toast(res.message, "error");
        }
      });
    }
    else {
      Toast("Enter the device diagnosis", "error")
    }
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Submit Device Diagnostics`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          top="0"
          right="0"
          _focus={{ border: "none", outline: "none", background: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem 1rem">
          <Text mb="2" color="#989898">Device Diagnostics</Text>
          <textarea
            style={{
              padding: "2.5% 3%",
              borderRadius: "5px",
              border: "1px solid #989898",
              transition: "300ms ease-in-out",
              marginBottom: "4%",
              resize: "none",
              height: "158px",
              width: "100%",
            }}
            className="slHover"
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
          <Flex align="center" mb="5">
            <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
            <Flex flex="1">Notify Customer?</Flex>
          </Flex>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Box w="100%">
              <Button
                type="button"
                onClick={() => handleSubmitDiagnosis()}
                style={buttonStyles}
                textAlign="center"
                width={"100%"}
              >
                {loading ? (
                  <Spinner color="#fff" emptyColor="lightgrey" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default SubmitDiagnosis;
