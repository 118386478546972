import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Image,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const ExtendPlan = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
  
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
  
    const id = modal.data[0]
    const details = modal.data[1]
  
    useEffect(() => {
      txt.current.value = details?.duration ?? "Select duration"
      txt2.current.value = details?.price ?? ""
      txt4.current.value = details?.planType ?? "Select plan type"
    }, [])
  
    async function sendFunc() {
      if(txt.current.value !== "" && txt2.current.value !== "" && txt4.current.value !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "plan/admin/extend-validity", {
          planId: id,
          duration: txt.current.value,
          price: txt2.current.value,
          product: txt3.current.value,
          planType: txt4.current.value,
          notifyCustomer: isNotify,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Plan Validity Extended Successfully",
            onOpen: true,
            data: `You’ve successfully extended this plan validity`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Fill in the necessary details", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Extend Plan Validity`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Text mb="2">Duration</Text>
            <select
                style={{
                    width: "100%",
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #E3E6EB",
                    transition: "300ms ease-in-out",
                    marginBottom: "4%",
                }}
                className="slHover rSelect"
                ref={txt}
            >
                <option value="">Select duration</option>
                {
                    [6, 12].map((item, index) => (
                        <option key={index} value={item}>{item+" months"}</option>
                    ))
                }
            </select>
  
            <InputHolderUser label="Price" type="number" placeholder="The amount paid by the customer for the renewal" inpRef={txt2} />

            <Text mb="2">Product</Text>
            <select
                style={{
                    width: "100%",
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #E3E6EB",
                    transition: "300ms ease-in-out",
                    marginBottom: "4%",
                }}
                className="slHover rSelect"
                ref={txt3}
            >
                <option value="device_protection_plan">Device Protection Plan</option>
            </select>

            <Text mb="2">Plan Type</Text>
            <select
                style={{
                    width: "100%",
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #E3E6EB",
                    transition: "300ms ease-in-out",
                    marginBottom: "4%",
                }}
                className="slHover rSelect"
                ref={txt4}
            >
                <option value="">Select plan type</option>
                {
                    ["lite", "basic", "premium", "custom", "pro"].map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                    ))
                }
            </select>
  
            <Box>
              <Flex align="center" mb="6">
                <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                <Flex flex="1">Notify Customer?</Flex>
              </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="auto"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default ExtendPlan;
  