import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import { brands_constants } from "../helper";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const CreateOrder = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const [aType, setAType] = useState("buy")
    const [dt, setDt] = useState("");
    const [isSingle, setIsSingle] = useState(0)
    const txt1 = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
    const txt5 = useRef("")
    const txt6 = useRef("")
    const txt7 = useRef("")
    const txt8 = useRef("")
    const txt9 = useRef("")
    const txt10 = useRef("")
    const txt11 = useRef("")
    const txt12 = useRef("")
    const txt13 = useRef("")
    const txt14 = useRef("")
    const uid = useRef("")
  
    async function sendFunc() {
      var a = txt1.current?.value
      var b = txt2.current?.value
      var c = txt3.current?.value
      var d = txt4.current?.value
      var e = txt5.current?.value
      var f = txt6.current?.value
      var g = txt7.current?.value
      var h = txt8.current?.value
      var i = txt9.current?.value
      var j = txt10.current?.value
      var k = txt11.current?.value
      var l = txt12.current?.value
      var m = txt13.current?.value
      var n = txt14.current?.value
      var u = uid.current?.value
      if(isSingle === 0) {
        if(a === "buy" || a === "lease-to-own") {
          if(a !== "" && b !== "" && e !== "" && f !== "") {
            setLoading(true)
            const res = await AuthConnect("post", "order/submit", {
              type: a,
              deviceType: b,
              deviceBrand: e,
              deviceModel: f,
              email: l,
              phoneNumber: m,
              address: n,
              name: k,
              uid: u,
              notifyCustomer: isNotify,
            })
            if(res?.success) {
              onClose();
              return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully submitted this order`,
              });
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
          }
          else {
            Toast("Fill all the fields", "error")
          }
        }


        else if(a === "trade-in") { 
          if(a !== "" && b !== "" && c !== "" && d !== "") {
            setLoading(true)
            const res = await AuthConnect("post", "order/submit", {
              type: a,
              deviceType: b,
              currentDevices: c,
              desiredDevices: d,
              email: l,
              phoneNumber: m,
              address: n,
              name: k,
              uid: u,
              notifyCustomer: isNotify,
            })
            if(res?.success) {
              onClose();
              return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully submitted this order`,
              });
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
          }
          else {
            Toast("Fill all the fields", "error")
          }
        }


        else {   
          if(a !== "" && b !== "" && e !== "" && f !== "" && g !== "" && h !== "") {
            setLoading(true)
            const res = await AuthConnect("post", "order/submit", {
              type: a,
              deviceType: b,
              deviceBrand: e,
              deviceModel: f,
              issue: g,
              description: h,
              email: l,
              phoneNumber: m,
              address: n,
              name: k,
              uid: u,
              notifyCustomer: isNotify,
            })
            if(res?.success) {
              onClose();
              return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully submitted this order`,
              });
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
          }
          else {
            Toast("Fill all the fields", "error")
          }
        }
      }

      else {
        if(a !== "" && i !== "" && j !== "") {
          setLoading(true)
          const res = await AuthConnect("post", "order/submit", {
            type: a,
            description: j,
            email: l,
            phoneNumber: m,
            address: n,
            name: k,
            uid: u,
            notifyCustomer: isNotify,
          })
          if(res?.success) {
            onClose();
            return setModal({
              modalId: "SUCCESS",
              messageTitle: res?.message,
              onOpen: true,
              data: `You’ve successfully submitted this order`,
            });
          }
          else {
              Toast(res?.message, "error")
          }
          setLoading(false)
        }
        else {
          Toast("Fill all the fields", "error")
        }
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Create Order`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    <Text mb="2">What is the order type?</Text>
                    <select
                        style={{
                            width: "100%",
                            padding: "2.5% 3%",
                            borderRadius: "5px",
                            outlineColor: "#49A05C",
                            border: "1px solid #E3E6EB",
                            transition: "300ms ease-in-out",
                            marginBottom: "5%",
                        }}
                        className="slHover rSelect"
                        onChange={(e) => setAType(e.target.value)}
                        ref={txt1}
                    >
                        {
                            [{name: "buy", show: "Buy Device"},{name: "lease-to-own", show: "Lease to Own"},/*{name: "repair", show: "Repair Device"},*/{name: "trade-in", show: "Trade In"}].map((item, index) => (
                                <option key={index} value={item.name}>{item.show}</option>
                            ))
                        }
                    </select>
                    <Text mb="2">How many devices will you like to purchase?</Text>
                    <Flex align="center" mb="5%">
                        {
                            ["Single", "Multiple"].map((item, index) => (
                                <Flex key={index} padding="8px 15px" bg={index === isSingle ? "#118E3E" : "#F2F2F2"} color={index === isSingle ? "#fff" : "#000"} borderRadius="20px" mr={index === 0 && "4"} fontSize="14px" transition="100ms ease-in-out" align="center" cursor="pointer" onClick={() => setIsSingle(index)}>
                                    {
                                        index === isSingle ?
                                        <Flex w="10px" h="10px" borderRadius="100%" justify="center" align="center" border="1px solid #fff">
                                            <Flex w="5px" h="5px" bg="#fff" borderRadius="100%"></Flex>
                                        </Flex>
                                        :
                                        <Flex w="10px" h="10px" borderRadius="100%" border="1px solid #959595"></Flex>
                                    }
                                    <Text ml="2">{item}</Text>
                                </Flex>
                            ))
                        }
                    </Flex>
                    {
                      isSingle === 0 ?
                      <>
                        <InputHolderUser label="What is the device type?" type="text" placeholder="Enter device type" inpRef={txt2} />
                        {
                          aType === "trade-in" ?
                          <>
                            <InputHolderUser label="What device do you want to swap?" type="text" placeholder="Enter brand & model e.g Tecno Camon 19" inpRef={txt3} />
                            <InputHolderUser label="What new device will you like to get?" type="text" placeholder="Enter brand & model e.g Tecno Camon 19" inpRef={txt4} />
                          </>
                          :
                          <>
                              <InputHolderUser label="What is the device brand?" type="text" placeholder="Enter device brand" inpRef={txt5} />
                              <InputHolderUser label="What is the device model?" type="text" placeholder="Enter device model" inpRef={txt6} />
                              {aType === "repair" && 
                              <>
                              <Text mb="2">What is the device issue?</Text>
                              <select
                                style={{
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                  outlineColor: "#E3E6EB",
                                  border: "1px solid #E3E6EB",
                                  transition: "300ms ease-in-out",
                                  marginBottom: "4%",
                                  width: "100%",
                                }}
                                className="slHover rSelect"
                                ref={txt7}
                              >
                                <option value=""></option>
                                {dt === "phone" || dt === "tablet"
                                  ? brands_constants.PHONE_ISSUES.map((item, index) => (
                                      <option key={index}>{item}</option>
                                    ))
                                  : dt === "laptop"
                                  ? brands_constants.LAPTOP_ISSUES.map((item, index) => (
                                      <option key={index}>{item}</option>
                                    ))
                                  : dt === "desktop" &&
                                    brands_constants.DESKTOP_ISSUES.map((item, index) => (
                                      <option key={index}>{item}</option>
                                    ))}
                              </select>
                              <Text mb="2">Issue Description</Text>
                              <Textarea
                              padding="15px 20px"
                              placeholder="Enter the device issue description"
                              mb="4%"
                              ref={txt8}
                              borderColor="#E3E6EB"
                              _focus={{ border: "1px solid #49A05C" }}
                              _hover={{ border: "1px solid #49A05C" }}
                              sx={{ "::placeholder": { color: "#989898" } }}
                              outlineColor="#E3E6EB"
                              w="100%"
                              resize="none"
                              transition="300ms ease-in-out"
                              borderRadius="5px"
                              h="120px"
                              ></Textarea>
                              </>}
                          </>
                        }
                      </>
                      :
                      <>
                        <InputHolderUser label={aType === "buy" ? "Total number of devices you’ll like to purchase" : aType === "lease-to-own" ? "Total number of devices you’ll like to lease" : aType === "trade-in" ? "Total number of devices you’ll like to swap" : "Total number of devices you’ll like to repair"} type="text" placeholder="Enter number of devices" inpRef={txt9} />
                        <Text mb="2">Description</Text>
                        <Textarea
                        padding="15px 20px"
                        placeholder="Enter the device description"
                        mb="4%"
                        ref={txt10}
                        borderColor="#E3E6EB"
                        _focus={{ border: "1px solid #49A05C" }}
                        _hover={{ border: "1px solid #49A05C" }}
                        sx={{ "::placeholder": { color: "#989898" } }}
                        outlineColor="#E3E6EB"
                        w="100%"
                        resize="none"
                        transition="300ms ease-in-out"
                        borderRadius="5px"
                        h="120px"
                        ></Textarea>
                      </>
                    }
                    <InputHolderUser label="Uid" type="text" placeholder="Enter customer’s uid" inpRef={uid} />
                    <InputHolderUser label="Email" type="text" placeholder="Enter customer’s email address" inpRef={txt12} />
                    <InputHolderUser label="Phonenumber" type="tel" placeholder="Enter Enter Phonenumber" inpRef={txt13} />
                    <InputHolderUser label="Address" type="text" placeholder="Enter customer’s address" inpRef={txt14} />
                </Box>
                <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default CreateOrder;
  