import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Toast from "../components/notify";
import { processTransaction } from "./helpers/request";

// axios.defaults.withCredentials = true;

export async function AuthConnect(type, url, data) {
  //AXIOS CLIENT HEADER
  const authToken = sessionStorage.getItem("GampToken");
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      accesstoken: authToken,
    },
  });

  //CONNECT FUNCTION
  try {
    const response =
      type === "post"
        ? await client.post(url, data)
        : type === "delete"
        ? await client.delete(url)
        : await client.get(url);
    if (response.data.success === true) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status === 401) {
      Toast("Hello your session has timed out, Kindly login again", "warning");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.replace("/");
      }, 3000);
    } else if (error?.response?.status) {
      Toast(error?.response?.data?.message, "error");
    } else {
      Toast("Cannot connect at the moment, please try again", "error");
    }
  }
}

export async function Connect(type, url, data) {
  //AXIOS CLIENT HEADER
  const authToken = sessionStorage.getItem("GampToken");
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      accesstoken: authToken,
    },
  });

  //CONNECT FUNCTION
  try {
    const response =
      type === "post" ? await client.post(url, data) : await client.get(url);
    if (response.data.success === true) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status === 401) {
      Toast("Hello your session has timed out, Kindly login again", "warning");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.replace("/");
      }, 3000);
    } else if (error.response?.status) {
      return error.response.data.message;
    } else {
      return "Cannot connect at the moment, please try again";
    }
  }
}

export async function SignIn(type, url, data) {
  //AXIOS CLIENT HEADER
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });

  //CONNECT FUNCTION
  try {
    const response =
      type === "post" ? await client.post(url, data) : await client.get(url);
    if (response.data.success === true) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status) {
      Toast(error.response.data.message, "error");
    } else {
      Toast("Cannot connect at the moment, please try again", "error");
    }
  }
}

export const requireAuth = (Component) => {
  return (props) => {
    const auth = sessionStorage.getItem("GampAuth");
    const history = useHistory();

    useEffect(() => {
      if (auth === null) {
        history.replace("/");
      }
    }, []);

    return <Component {...props} />;
  };
};

export async function Activate(menuId, activationId, update, setLoading) {
  setLoading(menuId);
  const res = await AuthConnect("post", "activation/admin/approve", {
    activationId: activationId,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function ActivateViewScreen(activationId, setLoading) {
  setLoading(true);
  const res = await AuthConnect("post", "activation/admin/approve", {
    activationId: activationId,
  });
  if (res?.success) {
    Toast(res?.message, "success");
  }
  setLoading(false);
}

export async function Deactivate(menuId, activationId, update, setLoading) {
  setLoading(menuId);
  const res = await AuthConnect("post", "activation/admin/reject", {
    activationId: activationId,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function ValidateClaim(menuId, activationId, update, setLoading) {
  setLoading(menuId);
  const res = await AuthConnect("post", "claim/admin/validate", {
    claimId: activationId,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function RejectClaim(menuId, activationId, update, setLoading) {
  setLoading(menuId);
  const res = await AuthConnect("post", "claim/admin/reject", {
    claimId: activationId,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function CoverageClaim(
  menuId,
  activationId,
  estimatedCoverage,
  update,
  setLoading
) {
  setLoading(menuId);
  const res = await AuthConnect("post", "claim/admin/validate", {
    claimId: activationId,
    estimatedCoverage: estimatedCoverage,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function InvalidateClaim(
  menuId,
  activationId,
  update,
  setLoading
) {
  setLoading(menuId);
  const res = await AuthConnect("post", "claim/admin/invalidate", {
    claimId: activationId,
  });
  if (res?.success) {
    await update();
    Toast(res?.message, "success");
  }
  setLoading("");
}

export async function PriceSubmit(setModal, setLoading, menuData, update) {
  const { repairId } = menuData;

  setModal({
    modalId: "SUBMIT_REPAIR_REPAIR_COST",
    onOpen: true,
    data: repairId,
    refreshFunc: update,
  });
  // setLoading(menuId);
  // const res = await AuthConnect("post", "repair/admin/price/submit", {
  //   repairId: activationId,
  //   price: price,
  // });
  // if (res?.success) {
  //   await update();
  //   Toast(res?.message, "success");
  // }
  setLoading("");
}

export async function RepairStatus(
  menuId,
  activationId,
  update,
  statusNo,
  setLoading
) {
  setLoading(menuId);
  const res = await AuthConnect("post", "admin/update/status", {
    repairId: activationId,
    statusNo: statusNo,
  });
  if (res?.success) {
    await update();
    Toast(res?.data?.status, "success");
  }
  setLoading("");
}

export async function VerifyTransaction(menuId, reference, setLoading, status) {
  setLoading(menuId);
  if (status === "failed" || status === "success") {
    return Toast("This function is not longer available.", "error");
  }
  const res = await processTransaction({ reference });

  if (res?.message) {
    Toast(res?.message, "success");
  }
  setLoading("");
}



export async function VerifyTransactionNew(reference, setModal, setLoading) {
  setLoading(reference)
  const res = await processTransaction({ reference });
  if (res?.status === 200) {
    setModal({
      modalId: "SUCCESS",
      onOpen: true,
      data: "You’ve successfully verified this transaction",
      messageTitle: "Transaction Verified Successfully!",
    })
  }
  else {
    Toast(res?.message, "error");
  }
  setLoading("")
}
