import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner, Image, 
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Table,
    MenuButton,
    Menu,
    MenuList,
    MenuItem, } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";
import acIcon from "../assets/svgs/acIcon.svg";


export default function ViewRepayment({ id, setModal, details }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    async function loadOrderDevice() {
        setLoading(true)
        const res = await AuthConnect("get", "order/repayment?orderId=" + id)
        setData(res?.data?.docs)
        setLoading(false)
    }
    useEffect(() => {
        loadOrderDevice()
    }, [])
    
    const tableHeader = ["S/N", "Payment date", "Amount", "Due date", "Status"]

    const status = (data) => {
        return (
          <Flex>
            <Flex
              py="1"
              px="8"
              bg={
                data === "active" ||
                data === "fixed and delivered" ||
                data === "fixed" ||
                data === "processing" ||
                data === "approved" ||
                data === "refunded" ||
                data === "customer refunded" ||
                data === "paid" ||
                data === "delivered" ||
                data === "success"
                  ? "#D1FAE5"
                  : data === "pending" ||
                    data === "trial" ||
                    data === "awaiting credit assessment" ||
                    data === "update guarantor" ||
                    data === "incomplete" ||
                    data === "in progress" ||
                    data === "awaiting your payment"
                  ? "#FDEACF"
                  : "#FE928033"
              }
              color={
                data === "active" ||
                data === "fixed and delivered" ||
                data === "fixed" ||
                data === "processing" ||
                data === "approved" ||
                data === "refunded" ||
                data === "paid" ||
                data === "delivered" ||
                data === "customer refunded" ||
                data === "success"
                  ? "#10B981"
                  : data === "pending" ||
                    data === "trial" ||
                    data === "awaiting credit assessment" ||
                    data === "update guarantor" ||
                    data === "incomplete" ||
                    data === "in progress" ||
                    data === "awaiting your payment"
                  ? "#FFA500"
                  : "#FF0000"
              }
              borderRadius="12px"
              align="center"
              fontSize="12px"
              textTransform="capitalize"
              w="auto"
            >
              <Flex
                w="6px"
                h="6px"
                bg={
                  data === "active" ||
                  data === "fixed and delivered" ||
                  data === "fixed" ||
                  data === "processing" ||
                  data === "approved" ||
                  data === "refunded" ||
                  data === "customer refunded" ||
                  data === "paid" ||
                  data === "delivered" ||
                  data === "success"
                    ? "#10B981"
                    : data === "pending" ||
                      data === "trial" ||
                      data === "awaiting credit assessment" ||
                      data === "update guarantor" ||
                      data === "incomplete" ||
                      data === "in progress" ||
                      data === "awaiting your payment"
                    ? "#FFA500"
                    : "#FF0000"
                }
                borderRadius="100%"
                mr="2"
              ></Flex>
              {data}
            </Flex>
          </Flex>
        );
      };

    return (
        <Flex w="100%">
            <Flex w="100%" overflowX="scroll" className="noScrollBar" direction="column">
                <Flex><Flex 
                    style={{
                        background: "#03A63C",
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: "700",
                        justifyContent: "center",
                        padding: "9px 20px",
                        borderRadius: "4px",
                    }} mb="8" onClick={() => setModal({
                        modalId: "ORDER_REPAYMENT",
                        onOpen: true,
                        data: [id, details?.repayId],
                        state: "orders",
                        refreshFunc: () => {},
                      })
                    }>Make Next Repayment</Flex></Flex>
                <Table w="100%">
                    <Thead>
                        <Tr bg="#FBFBFB">
                        {tableHeader.map((item, index) => (
                            <Th key={index} w={index === 0 ? data?.length > 0 ? "40px" : "auto" : "auto"} textTransform="capitalize">{item}</Th>
                        ))
                        }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            loading ?
                            <Tr><Flex w="100%" h="100%" justify="center" align="center" py="200px">
                                <Spinner size="md" color="#00B600" emptyColor="grey" />
                            </Flex></Tr>
                            :
                            data?.length > 0 ? 
                            data.map((item, index) => (
                                <Tr key={index} background={index % 2 === 0 ? "#fff" : "#FBFBFB"}>
                                    <Td>{index + 1}</Td>
                                    <Td>{item?.paymentDate?.substr(0, 10)}</Td>
                                    <Td>{"₦"+item?.amountDue?.toLocaleString()}</Td>
                                    <Td>{item?.dueDate?.substr(0, 10)}</Td>
                                    <Td>{status(item?.status)}</Td>
                                </Tr>
                            ))
                            :
                            <Tr><Flex w="100%" h="100%" justify="center" align="center">
                                <Text fontWeight="700" my="100px">No Repayments Yet</Text>
                            </Flex></Tr>
                        }
                    </Tbody>  
                </Table>
            </Flex>
        </Flex>
    )
}