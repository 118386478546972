import {
  Box,
  Button,
  Flex,
  Input,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { registerAdmin } from "./helpers";
import Toast from "../notify";

const AddAdmin = ({ onClose, modal, setModal }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
  };
  const initialErrorState = {
    state: false,
    msg: "",
  };
  const [passType, setPassType] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(initialErrorState);
  const [values, setValues] = useState(initialValues);
  const handleTextValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = (e) => {
    setLoading(true);
    registerAdmin(values).then((res) => {
      setLoading(false);
      if (!res.status) {
        Toast(res?.message, "error")
      }
      else {
        return setModal({
          modalId: "SUCCESS",
          title: "Register Admin",
          messageTitle: <Text textAlign="center">Admin Registered Successfully!</Text>,
          onOpen: true,
          data: res?.message,
        });
      }
    });
  };

  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Add Admin`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          _focus={{ border: "none", outline: "none" }}
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.7rem 1rem">
          <Flex w="100%" direction="column">
            <Box w="100%" mb="1rem">
              <Text>First Name</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  name="firstName"
                  onChange={handleTextValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Last Name</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  name="lastName"
                  onChange={handleTextValues}
                />
              </Box>
            </Box>
            <Text mb="2">Select Role*</Text>
            <select
              style={{
                padding: "2.5% 3%",
                borderRadius: "5px",
                outlineColor: "#49A05C",

                transition: "300ms ease-in-out",
                marginBottom: "4%",
              }}
              className="slHover rSelect"
              name="role"
              onChange={handleTextValues}
            >
              <option value="">Select role</option>
              <option value="admin">Admin</option>
              <option value="support">Support</option>
            </select>
            <Box w="100%" mb="1rem">
              <Text>Email</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  name="email"
                  onChange={handleTextValues}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Phone Number</Text>
              <Box>
                <Input
                  type="number"
                  placeholder=""
                  name="phoneNumber"
                  onChange={handleTextValues}
                />
              </Box>
            </Box>
            <Box>
              <Text>Password</Text>
              <>
                <Input
                  type={passType ? "password" : "text"}
                  required
                  p="2%"
                  name="password"
                  mb="7"
                  onChange={handleTextValues}
                  _focus={{ borderColor: "#49A05C" }}
                  outlineColor="#49A05C"
                />
                <i
                  className={`mdi ${
                    passType ? "mdi-eye-off-outline" : "mdi-eye-outline"
                  }`}
                  style={{
                    color: "rgb(220,220,230)",
                    marginLeft: "-5%",
                    marginTop: "1.75%",
                    cursor: "pointer",
                    position: "absolute",
                  }}
                  onClick={() =>
                    passType ? setPassType(false) : setPassType(true)
                  }
                ></i>
              </>
            </Box>

            <Button
              bg="#49A05C"
              p="2%"
              w="100%"
              border="none"
              color="white"
              _hover={{ cursor: "pointer" }}
              type="submit"
              borderRadius="2px"
              mb="8"
              isLoading={loading}
              width={"100%"}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default AddAdmin;
