import react, { useEffect, useState } from "react";
import { Flex, Box, Spacer, Text, Button, Icon } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ViewOverview from "./viewOverview";
import ViewHistory from "./viewHistory";
import ViewNotes from "./viewNotes";
import ViewFeedback from "./viewFeedback";
import ViewDetailsTable from "./Common/ViewDetails/ViewDetailsTable";
import ViewSalesHistory from "./viewSalesHistory";
import ViewUpload from "./viewUpload";
import ViewDevice from "./viewDevice";
import { AuthConnect } from "../util/util";
import ViewRepayment from "./viewRepayment";

export default function ViewDetailsData({
  viewType,
  details,
  selected,
  setModal,
  tokenId,
  modal,
  type,
  isPartner,
}) {
  return (
    <Flex
      w="100%"
      direction="column"
      h="100%"
      bg="#fff"
      boxShadow="0px 7px 20px rgba(0, 0, 0, 0.2)"
      borderRadius="4px"
      py="6"
      px="5"
      mt="8"
      mb="12"
    >
      {viewType === "plans" ? (
        <Plans
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          type={type}
          isPartner={isPartner}
        />
      ) : viewType === "activations" ? (
        <Activations
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          modal={modal}
          type={type}
          isPartner={isPartner}
        />
      ) : viewType === "repairs" ? (
        <Repairs
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          type={type}
        />
      ) : viewType === "claims" ? (
        <Claims
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          type={type}
          isPartner={isPartner}
        />
      ) : viewType === "users" ? (
        <Users
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
          isPartner={isPartner}
        />
      ) : viewType === "withdrawals" ? (
        <Withdrawals
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
        />
      ) : viewType === "orders" ? (
        <Orders
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
        />
      ) : viewType === "sales" ? (
        <Sales
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
        />
      ) : viewType === "technicians" ? (
        <Technicians
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
        />
      ) : viewType === "transactions" ? (
        <Transactions
          viewType={viewType}
          details={details}
          selected={selected}
          setModal={setModal}
          tokenId={tokenId}
          type={type}
        />
      ) : (
        <></>
      )}
    </Flex>
  );
}

export function Plans({ viewType, details, selected, setModal, type, isPartner }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview viewType={viewType} details={details} type={type} isPartner={isPartner} />
      ) : selected === 1 ? (
        <ViewDetailsTable
          type="linked-device"
          setModal={setModal}
          uid={details.planId}
        />
      ) : selected === 2 ? (
        <ViewDetailsTable
          type="claims"
          setModal={setModal}
          uid={details.planId}
        />
      ) : selected === 3 ? (
        <ViewHistory id={details?.planId} amt={details?.price} />
      ) : selected === 4 ? (
        <ViewNotes tid={details?.planId} />
      ) : 
      <ViewUpload tid={details?.planId} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Repairs({ viewType, details, selected, setModal, type }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          type={type}
        />
      ) : selected === 1 ? (
        <ViewHistory id={details?.repairId} amt={details?.price} />
      ) : selected === 2 ? (
        <ViewNotes tid={details?.repairId} />
      ) : selected === 3 ? (
        <ViewFeedback />
      ) : <ViewUpload tid={details?.repairId} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Activations({
  viewType,
  details,
  selected,
  setModal,
  modal,
  type,
  isPartner
}) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          modal={modal}
          type={type}
          isPartner={isPartner}
        />
      ) : selected === 1 ? (
        <ViewHistory id={details?.activationId} amt={details?.price} />
      ) : selected === 2 ? (
        <ViewNotes tid={details?.activationId} />
      ) : <ViewUpload tid={details?.activationId} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Claims({ viewType, details, selected, setModal, type, isPartner }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          type={type}
          isPartner={isPartner}
        />
      ) : selected === 1 ? (
        <ViewHistory id={details?.claimId} amt={details?.price} />
      ) : selected === 2 ? (
        <ViewNotes tid={details?.claimId} />
      ) : <ViewUpload tid={details?.claimId} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Users({
  viewType,
  details,
  selected,
  setModal,
  tokenId,
  type,
  isPartner
}) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          type={type}
          isPartner={isPartner}
        />
      ) : selected === 1 ? (
        <ViewDetailsTable type="plans" setModal={setModal} uid={details.uid} />
      ) : selected === 2 ? (
      <ViewDetailsTable
        type="linked-device"
        setModal={setModal}
        uid={details.uid}
      />
      ) : selected === 3 ? (
      <ViewDetailsTable
        type="claims"
        setModal={setModal}
        uid={details.uid}
      />
      ) : selected === 4 ? (
        <ViewNotes tid={details?.uid} />
      ) : <ViewUpload tid={details?.uid} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Withdrawals({ viewType, details, selected, setModal, type }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          type={type}
        />
      ) : selected === 1 ? (
        <ViewHistory id={details?.withdrawalId} amt={details?.amount} />
      ) : (
        <ViewNotes tid={details?.withdrawalId} />
      )}
    </Flex>
  );
}


export function Orders({ viewType, details, selected, setModal, type }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview
          viewType={viewType}
          details={details}
          setModal={setModal}
          type={type}
        />
      ) : selected === 1 ? (
        <ViewHistory id={details?.orderId} amt={details?.totalCharge} />
      ) : selected === 2 ? (
        <ViewNotes tid={details?.orderId} />
      ) : selected === 3 ? <ViewDevice id={details?.orderId} setModal={setModal} /> : (selected === 4 && details?.type === "lease-to-own") || (selected === 4 && details?.type === "lease to own") ? <ViewRepayment id={details?.orderId} setModal={setModal} details={details} /> : <ViewUpload tid={details?.orderId} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Sales({ viewType, details, selected, setModal, type }) {
  const [oData, setOData] = useState([]);
  const dataSet = [];
  async function dataFunc() {
    const res = await AuthConnect(
      "get",
      "sale/admin/fetch/overview/" + details?.uid
    );
    const res2 = await AuthConnect("get", "user/fetch/" + details?.uid);
    dataSet.push(details);
    dataSet.push(res?.data?.business ?? res?.data?.user);
    dataSet.push(res2?.data);
    setOData(dataSet);
  }
  useEffect(() => {
    dataFunc();
  }, []);
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview viewType={viewType} details={oData} type={type} />
      ) : selected === 1 ? (
        <ViewSalesHistory id={details?.uid} />
      ) : <ViewUpload tid={details?.uid} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Technicians({ viewType, details, selected, setModal, type }) {
  return (
    <Flex w="100%" h="100%">
      {selected === 0 ? (
        <ViewOverview viewType={viewType} details={details} type={type} />
      ) : selected === 1 ? (
        <ViewHistory id={details?.uid} planId />
      ) : selected === 2 ? (
        <ViewHistory id={details?.uid} />
      ) : selected === 3 ? (
        <ViewFeedback />
      ) : <ViewUpload tid={details?.uid} uid={details?.uid} setModal={setModal} type={type} />}
    </Flex>
  );
}

export function Transactions({ viewType, details, selected, setModal, type }) {
  return (
    <Flex w="100%" h="100%">
      <ViewOverview
        viewType={viewType}
        details={details}
        setModal={setModal}
        type={type}
      />
    </Flex>
  );
}
