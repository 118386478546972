import ExtraActionThIcon from "../../components/Common/ExtraActionThIcon";

export const tableHeaderActivations = [
  "Date Purchased",
  "Email",
  "Device",
  "Verified",
  "Claimed",
  "Status",
  "Action",
];
