import { Icon } from "@chakra-ui/react";
import Status, { BoolBadge } from "./Status";
import {
  fetchClaimsWithPlanId,
  fetchPlansActivation,
  fetchUsersPlans,
} from "../../util/helpers/request";

export const DropdownIcon = (props) => (
  <Icon
    viewBox="0 0 16 9"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M6 7.5L0 1.87467L2.00094 0L6 3.75066L9.99906 0L12 1.87467L6 7.5Z"
      fill={props.fill}
    />
  </Icon>
);

export const SearchIcon = (props) => (
  <Icon viewBox="0 0 15 15" {...props}>
    <path
      d="M6.5 13C2.91707 13 0 10.0829 0 6.5C0 2.91707 2.91707 0 6.5 0C10.0829 0 13 2.91707 13 6.5C13 10.0829 10.0829 13 6.5 13ZM6.5 0.951219C3.43707 0.951219 0.951219 3.44341 0.951219 6.5C0.951219 9.55659 3.43707 12.0488 6.5 12.0488C9.56293 12.0488 12.0488 9.55659 12.0488 6.5C12.0488 3.44341 9.56293 0.951219 6.5 0.951219Z"
      fill="#82868C"
    />
    <path
      d="M13.7475 14.4975C13.5575 14.4975 13.3675 14.4275 13.2175 14.2775L11.2175 12.2775C10.9275 11.9875 10.9275 11.5075 11.2175 11.2175C11.5075 10.9275 11.9875 10.9275 12.2775 11.2175L14.2775 13.2175C14.5675 13.5075 14.5675 13.9875 14.2775 14.2775C14.1275 14.4275 13.9375 14.4975 13.7475 14.4975Z"
      fill="#82868C"
    />
  </Icon>
);

export const getUsersPlans = async (id) => {
  let result;
  result = await fetchUsersPlans(id);
  return result;
};

export const getUserLinkedDevices = async (id) => {
  let result;
  result = await fetchPlansActivation(id);
  return result;
};

export const getUserClaims = async (data) => {
  let result;
  result = await fetchClaimsWithPlanId(data);
  return result;
};

export const pageTitle = (path) => {
  if (path && path.split("/").includes("plans")) {
    return "Protection Plans";
  }
  if (path && path.split("/").includes("claims")) {
    return "Claims";
  }
  if (path && path.split("/").includes("activations")) {
    return "Protected Devices";
  }
  if (path && path.split("/").includes("users")) {
    return "Users";
  }
  if (path && path.split("/").includes("repairs")) {
    return "Repairs";
  }
  if (path && path.split("/").includes("transactions")) {
    return "Transactions";
  }
  if (path && path.split("/").includes("settings")) {
    return "Settings";
  }
  if (path && path.split("/").includes("voucher-centre")) {
    return "Voucher Centre";
  }
  if (path && path.split("/").includes("withdrawals")) {
    return "Withdrawals";
  }
  if (path && path.split("/").includes("sales")) {
    return "Sales";
  }
  if (path && path.split("/").includes("orders")) {
    return "Orders";
  }
  if (path && path.split("/").includes("technicians")) {
    return "Technicians";
  }
  if (path && path.split("/").includes("metrics")) {
    return "Dashboard";
  }
  if (path && path.split("/").includes("business")) {
    return "Business";
  }
  // 	console.log();
  // 	switch (path) {
  // 		case path.split('/').includes('plans'):
  // 			return 'Protection Plans';
  // 		case '/dashboard/users':
  // 			return 'Users';
  // 		case '/dashboard/claims':
  // 			return 'Claims';
  // 		default:
  // 			return '';
  // 	}
  // }
};

export const boolBadgeSwitch = (bool) => {
  return <BoolBadge bool={bool} />;
};

export const status = (status, type) => {
  switch (status) {
    case status:
      return <Status status={status} type={type} />;

    case undefined:
      if (type === "user") {
        return <Status status={"inactive"} type={type} />;
      }
    default:
      return <></>;
  }
};
