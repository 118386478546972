export const encodeQueryString = (query) => {
  try {
    while (query.includes(" ") || query.includes("+")) {
      query = query.replace(" ", "%20");
      query = query.replace("+", "%2B");
    }
    return query;
  } catch (err) {
    return query;
  }
};
