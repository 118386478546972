import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Image,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import download from "../../assets/svgs/download.svg"
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const LinkMultipleDevices = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const txt = useRef("")
    const txt2 = useRef("")

    
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }
  
    async function sendFunc() {
        if(txt2.current.value !== "") {
            setLoading(true)
            const formData = new FormData()
            formData.append("planId", txt2.current.value)
            formData.append("file", selectedFile)
            const res = await AuthConnect("post", "activation/create/multiple/upload", formData)
            if(res?.success) {
                onClose();
                return setModal({
                modalId: "SUCCESS",
                messageTitle: res?.message,
                onOpen: true,
                data: `You’ve successfully confirmed this payment`,
                });
            }
            setLoading(false)
        }
        else {
            Toast("kindly enter the Plan ID", "error")
        }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Link Multiple Devices`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    <InputHolderUser label="Plan ID" type="text" placeholder="Enter the Plan ID" inpRef={txt2} />
                    <input type="file" style={{ width: "0", opacity: "0" }} ref={txt} accept="text/csv" onChange={handleFileSelect} />
                    {
                        selectedFile !== null &&
                        <>
                            <Text mb="4" color="#161C26" fontWeight="500">{selectedFile?.name}</Text>
                        </>
                    }
                    <Flex w="100%"><Flex fontSize={["14px", "16px"]} fontWeight="600" bg="#03A63C" padding="10px 17px" cursor="pointer" align="center" borderRadius="5px" color="#fff" onClick={() => txt.current.click()}><Image src={download} w="22px" h="22px" mr="3" /> Choose File</Flex></Flex>
                </Box>
              <Box w="100%" mt="12">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      opacity={selectedFile === null ? ".6" : "1"}
                      onClick={() => selectedFile !== null && sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default LinkMultipleDevices;
  