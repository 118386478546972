import react, { useEffect, useState } from 'react';
import { Flex, Text, Spinner } from '@chakra-ui/react';
import { AuthConnect } from "../util/util";


export default function ViewFeedback() {
    return (
        <Flex w="100%" h="100%" pb="150px" fontWeight="bold" justify="center" align="center">
            <Text>No feedback</Text>
        </Flex>
    )
}