import ExtraActionThIcon from "../../components/Common/ExtraActionThIcon";

export const tableHeaderClaims = [
  "Created at",
  "Customer's Email",
  "Device",
  "Amount (₦)",
  "Technical Partner",
  "Claim Status",
  "Action",
];
export const tableHeaderProtectionPlans = [
  "Date Purchased",
  "Email",
  "Plan Type",
  "Device(s)",
  "Activated Device(s)",
  "Status",
  "Action",
];
export const tableHeaderUser = [
  "Date Purchased",
  "Email",
  "Phone Number",
  "Name",
  "Role",
  "Status",
  "Action",
];
export const viewDetailsTableHeaderUser = [
  "Date Purchased",
  "Email",
  "Phone Number",
  "Name",
  "Role",
  "Status",
];
