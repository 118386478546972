import React, { useState } from "react";
import { Input, Box, Text, Textarea } from "@chakra-ui/react";

function InputHolderUser(props) {
  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")
  return (
    <Box mb="4%" fontFamily="Nunito Sans" w="100%">
      <Text mb="2">{props.label}</Text>
      {props.type !== "textarea" ? (
        <Input
          type={props.type}
          padding="20px 20px"
          ref={props?.inpRef}
          id={props.id}
          name={props.name}
          borderColor="#E3E6EB"
          // _focus={{ border: "1px solid #49A05C" }}
          _hover={{ border: "1px solid "+partnerBg }}
          _focus={{ border: "1px solid "+partnerBg }}
          sx={{ "::placeholder": { color: "#989898" } }}
          outlineColor="#E3E6EB"
          w="100%"
          transition="300ms ease-in-out"
          borderRadius="5px"
          outline="none!important"
          boxShadow="none!important"
          {...props}
        />
      ) : (
        <Textarea
          padding="20px 20px"
          ref={props?.inpRef}
          borderColor="#E3E6EB"
          id={props.id}
          _focus={{ border: "1px solid #49A05C" }}
          _hover={{ border: "1px solid #49A05C" }}
          sx={{ "::placeholder": { color: "#989898" } }}
          outlineColor="#E3E6EB"
          resize="none"
          h="100px"
          {...props}
          w="100%"
          transition="300ms ease-in-out"
          borderRadius="5px"
        />
      )}
    </Box>
  );
}

export default InputHolderUser;
