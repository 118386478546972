import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { brands_constants } from "../helper";
import { linkaDevice } from "./helpers";
import Toast from "../notify";

const LinkDevice = ({ setModal, modal, onClose }) => {
  const initialValues = {
    deviceType: "",
    deviceBrand: "",
    deviceModel: "",
    uniqueDeviceNumber: "",
    fullName: "",
    phoneNumber: "",
    email: "",
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const handleValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    let data = { ...values, token: modal.data };
    const {
      uniqueDeviceNumber,
      deviceBrand,
      deviceModel,
      deviceType,
      fullName,
      phoneNumber,
      email,
    } = values;
    if (!uniqueDeviceNumber || !deviceBrand || !deviceModel || !deviceType) {
      Toast(`Please fill all the fields`, "error");
    } else if (
      (fullName === "" && phoneNumber === "" && email === "") ||
      fullName === "" ||
      phoneNumber === "" ||
      email === ""
    ) {
      let d = {
        uniqueDeviceNumber,
        deviceBrand,
        deviceModel,
        deviceType,
        token: modal.data,
      };
      setLoading(true);
      linkaDevice(d).then((res) => {
        setLoading(false);
        if (res.status == 200) {
          return setModal({
            modalId: "SUCCESS",
            title: "Success",
            onOpen: true,
            data: res.message,
          });
        } else {
          Toast(res.message, "error");
        }
      });
    } else {
      setLoading(true);
      linkaDevice(data).then((res) => {
        setLoading(false);
        if (res.status == 200) {
          return setModal({
            modalId: "SUCCESS",
            title: "Success",
            onOpen: true,
            data: res.message,
          });
        } else {
          Toast(res.message, "error");
        }
      });
    }
  };
  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
        fontFamily="Nunito Sans"
      >
        <Text w="100%" fontWeight="600">
          {`Link a Device`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          _focus={{ border: "none", outline: "none" }}
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.7rem 1rem">
          <Flex w="100%" direction="column">
            <Box w="100%" mb="1rem">
              <Text>Select Device Type*</Text>
              <Box>
                <select
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                  }}
                  name="deviceType"
                  className="slHover rSelect"
                  onChange={(e) => handleValues(e)}
                >
                  <option value=""></option>
                  <option value="phone">Phone</option>
                  <option value="laptop">Laptop</option>
                  <option value="desktop">Desktop</option>
                </select>
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Select Device Brand*</Text>
              <Box>
                <select
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                  }}
                  name="deviceBrand"
                  className="slHover rSelect"
                  onChange={(e) => handleValues(e)}
                >
                  <option value=""></option>
                  {values.deviceType === "phone"
                    ? brands_constants.PHONE_BRANDS.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))
                    : values.deviceType === "laptop"
                    ? brands_constants.LAPTOP_BRANDS.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))
                    : values.deviceType === "desktop" &&
                      brands_constants.DESKTOP_BRANDS.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                </select>
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Device Model*</Text>
              <Box>
                <Input
                  type="text"
                  placeholder=""
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="deviceModel"
                  onChange={(e) => handleValues(e)}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Unique Device Number*</Text>
              <Box>
                <Input
                  type="text"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  placeholder=""
                  _focus={{ border: " 1px solid #49a05c" }}
                  name="uniqueDeviceNumber"
                  onChange={(e) => handleValues(e)}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Full Name</Text>
              <Box>
                <Input
                  type="text"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  placeholder=""
                  _focus={{ border: "1px solid #49a05c" }}
                  name="fullName"
                  onChange={(e) => handleValues(e)}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Phone Number</Text>
              <Box>
                <Input
                  type="text"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  placeholder=""
                  _focus={{ border: "1px solid #49a05c" }}
                  name="phoneNumber"
                  onChange={(e) => handleValues(e)}
                />
              </Box>
            </Box>
            <Box w="100%" mb="1rem">
              <Text>Email Address</Text>
              <Box>
                <Input
                  type="text"
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    outlineColor: "#49A05C",
                    border: "1px solid #959595",
                    transition: "300ms ease-in-out",
                    width: "100%",
                    height: "47px",
                  }}
                  placeholder=""
                  _focus={{ border: "1px solid #49a05c" }}
                  name="email"
                  onChange={(e) => handleValues(e)}
                />
              </Box>
            </Box>
            <Button
              bg="#49A05C"
              p="2%"
              w="100%"
              border="none"
              color="white"
              _hover={{ cursor: "pointer" }}
              type="submit"
              borderRadius="2px"
              mb="8"
              isLoading={loading}
              width={"100%"}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default LinkDevice;
