import {
  Flex,
  ModalCloseButton,
  Text,
  ModalBody,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { AuthConnect } from "../../util/util";
import { useState, useRef, useEffect } from "react";
import Toast from "../notify";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
};

const UserContacted = ({ onClose, modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const inp = useRef("");

  async function sendUserContacted(data) {
    setLoading(true);
    const res = await AuthConnect(
      "post",
      modal.data[1] === "repairs"
        ? "repair/admin/contact/customer"
        :  modal.data[1] === "orders"
        ? "order/admin/contact/customer"
        : "claim/admin/contact/customer",
      modal.data[1] === "repairs"
        ? {
            repairId: modal.data[0],
            reason: inp.current.value,
          }
        : modal.data[1] === "orders"
        ? {
            orderId: modal.data[0],
            reason: inp.current.value,
          }
        : {
            claimId: modal.data[0],
            reason: inp.current.value,
          }
    );
    if (res?.success) {
      Toast("Admin Contacted Customer Successfully", "success");
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 2rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`User Contacted`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _focus={"none"}
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0.5rem">
          <form>
            <Box>
              <Text mb="2" fontSize="14px" lineHeight="25px" color="#3A3C40">
                Reason (Optional)
              </Text>
              <textarea
                style={{
                  padding: "2.5% 3%",
                  borderRadius: "5px",
                  border: "1px solid #989898",
                  transition: "300ms ease-in-out",
                  resize: "none",
                  height: "158px",
                  width: "100%",
                }}
                placeholder="State a reason if any"
                className="slHover"
                ref={inp}
                // onChange={(e) => setNote(e.target.value)}
              ></textarea>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w="100%">
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width={"100%"}
                      onClick={() => {
                        sendUserContacted();
                      }}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </>
  );
};

export default UserContacted;
