import React, { useState, useEffect, useRef } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  handleCalculation,
  handleSelect,
  postRejectActivation,
  postRejectClaim,
  rejectReasons,
} from "./helpers";
import { capitalizeFirstWord } from "../../util/helpers";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#E90000",
  color: "#fff",
};

const selectStyle = {
  webkitAppearance: "none",
  mozAppearance: "none",
  background: "transparent",
  backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "99%",
  backgroundPositionY: "13px",
  border: "1px solid #dfdfdf",
  borderRadius: "2px",
  padding: "1rem 0.5rem",
  width: "100%",
};

const RejectClaimModal = ({ onClose, setModal, modal }) => {
  const { register, handleSubmit } = useForm();
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const textValue = useRef("");
  const onSubmit = () => {
    const { data } = modal;
    if (modal.state === "claims") {
      const payload = {
        claimId: data,
        notifyCustomer: true,
      };
      setLoading(true);
      postRejectClaim(payload).then((res) => {
        if (res.status) {
          setLoading(false);
          onClose();
          return setModal({
            modalId: "SUCCESS",
            title: "Success",
            onOpen: true,
            data: res.message,
          });
        }
      });
    } else {
      const payload = {
        activationId: data,
        reason: textValue.current.value,
        notifyCustomer: true,
      };
      setLoading(true);
      postRejectActivation(payload).then((res) => {
        if (res.status) {
          setLoading(false);
          onClose();
          return setModal({
            modalId: "SUCCESS",
            title: "Success",
            onOpen: true,
            data: res.message,
          });
        }
      });
    }
  };

  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Reject ${modal.state}`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem 1rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text as="label" fontSize="15px" lineHeight="25px" color="#3A3C40">
              Reason for Rejecting {capitalizeFirstWord(modal.state)}
            </Text>
            {modal.state === "claims" ? (
              <>
                <select
                  onChange={(e) => handleSelect(e, setSelectedValue)}
                  {...register("rejectStatus")}
                  style={selectStyle}
                >
                  {rejectReasons.map((item, i) => {
                    return (
                      <>
                        <option value={item.value}>{item.value}</option>
                      </>
                    );
                  })}
                </select>
              </>
            ) : (
              <>
                <textarea
                  style={{
                    padding: "2.5% 3%",
                    borderRadius: "5px",
                    border: "1px solid #989898",
                    transition: "300ms ease-in-out",
                    marginBottom: "4%",
                    resize: "none",
                    height: "158px",
                    width: "100%",
                  }}
                  className="slHover"
                  ref={textValue}
                ></textarea>
              </>
            )}

            <Box w="100%" mt="15px">
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Box w="100%">
                  <Button
                    type="submit"
                    isLoading={loading}
                    _hover={{ background: "#E9000010" }}
                    style={buttonStyles}
                    textAlign="center"
                    width={"100%"}
                  >
                    Reject
                  </Button>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </>
  );
};

export default RejectClaimModal;
