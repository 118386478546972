import { useAppDispatch, useAppSelector } from '../hooks/hooks';

export const useAppContext = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector((state) => state.app);
	return [state, dispatch];
};

export const useDashboardContext = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector((state) => state.dashboard);
	return [state, dispatch];
};

export const useViewDetailsContext = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector((state) => state.viewDetails);
	return [state, dispatch];
};
