import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  
  
  
  const VerifyClaimConfirm = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

    const buttonStyles = {
      outline: "none",
      border: "none",
      padding: "1rem 2rem",
      borderRadius: "5px",
      background: partnerBg,
      color: "#fff",
    };
  
    async function sendFunc() {
      setLoading(true)
      const res = await AuthConnect("post", "claim/admin/verify", {
          claimId: modal.data,
          notifyCustomer: isNotify,
      })
      if(res?.success) {
          onClose();
            return setModal({
            modalId: "SUCCESS",
            messageTitle: res?.message,
            onOpen: true,
            data: "You've successfully approved this claim",
          });
      }
      setLoading(false)
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Approve Claim`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
            <Text mb="2" textAlign="left" fontWeight="bold">Are you sure you want to approve this claim?</Text>
              <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>
              <Box w="100%" mt="12">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: partnerBg }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default VerifyClaimConfirm;
  