import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const ConfirmRePayment = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const txt = useRef("")
  
    async function sendFunc() {
      var a = txt.current.value
      if(a !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "order/admin/confirm/repayment", {
          orderId: modal.data,
          amountPaid: a,
          notifyCustomer: isNotify,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: res?.message,
            onOpen: true,
            data: `You’ve successfully confirmed this repayment`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Enter the amount paid", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Confirm Order Repayment`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    <InputHolderUser label="Amount Paid" type="number" placeholder="Enter Amount Paid" inpRef={txt} />
                </Box>
                <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default ConfirmRePayment;
  