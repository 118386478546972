import {
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { updateUserPhoneNumber } from "../../util/helpers/request";
import Toast from "../notify";
import { CustomDefaultInput, editEmailFn } from "./helpers";

const EditPhoneNumber = ({ onClose, modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const initialState = { phoneNumber: "" };
  const [values, setValue] = useState(initialState);
  const handleValues = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    let data;
    data = { ...values, uid: modal.data.uid };
    setLoading(true);
    updateUserPhoneNumber(data).then((res) => {
      return setModal({
        modalId: "SUCCESS",
        title: "Success",
        messageTitle: <Text textAlign="center">Phone Number Updated!</Text>,
        onOpen: true,
        data: res?.message,
      });
    });
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Edit Phone Number`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <CustomDefaultInput
            title="Old Phone Number"
            value={""}
            onChange={handleValues}
            name="oldPhoneNumber"
            placeholder={modal.data.phoneNumber}
            readOnly
          />
          <CustomDefaultInput
            title="New Phone Number"
            value={values.phoneNumber}
            onChange={handleValues}
            name="phoneNumber"
            // placeholder={"Please enter a valid email"}
          />

          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default EditPhoneNumber;
