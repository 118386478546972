import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "./util";
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";
import gampArt from "../assets/svgs/gamp-art.svg";
import logo from "../assets/svgs/newAdminLogo.svg";
import AuthSection from "../components/AuthSection";
import AuthRecovery from "../components/AuthRecovery";
import pwbgamp from "../assets/svgs/pwbgamp.svg"
import axaLogo from "../assets/pngs/axa.png"
import loginImg from "../assets/pngs/loginImg.png"
import { useRouter } from "./router";

function Auth() {
  const router = useRouter()
  const type = router.query.type;
  const history = useHistory()

  return (
    <Flex w="100%" minH="100vh" justify="center" align={type === "complete_signup" ? "flex-start" : "center"} bg="rgba(244, 244, 244, 1)" py="12" overflowY={type === "complete_signup" && "scroll"}>
      <Flex bg="#fff" w="980px" boxShadow="0px 4px 8px -2px rgba(16, 24, 40, 0.1)">

        <Flex w="50%" px="10" py="60px" direction="column">
          <Text fontSize="28px" mb="12" fontWeight="700" color="rgba(22, 28, 38, 1)" textAlign="center">{type === "forgotpass" ? "Get a new password" : type === "changepass" ? "Choose a new password" : type === "complete_signup" ? "Complete Setting Up Your Account" : "Admin Dashboard"}</Text>
          <AuthSection type={type} />
          <Flex w="100%" justify="center"><Image src={pwbgamp} mt="3" w="100px" /></Flex>
        </Flex>

        <Flex w="50%" backgroundImage={loginImg} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center left"></Flex>
      </Flex>
    </Flex>
  );
}

export default Auth;
