import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Claims from "../../pages/NewClaims";
import Repairs from "../../pages/NewRepairs";
import Activations from "../../pages/activations/newIndex";
import ProtectionPlan from "../../pages/protectionPlans";
import Transactions from "../../pages/NewTransactions";
import User from "../../pages/users/newIndex";
import Withdrawals from "../../pages/NewWithdrawals";
import Sales from "../../pages/NewSales";
import Technicians from "../../pages/NewTechnicians";
import Header from "./Header";
import ViewDetails from "../viewDetails";
import MetricsAnalytics from "../../pages/metrics";
import Business from "../../pages/business";
import Orders from "../../pages/orders";
import { memo, useState } from "react";
import $ from "jquery";
import Settings from "../../pages/others/settings";
import Voucher from "../../pages/others/voucher";
import MetricsDashboard from "../../pages/others/metrics";

const RightPanel = ({ selected, expanded, setModal, modal, isPartner }) => {
  const [showIni, setShowIni] = useState(false);
  $(document).ready(function () {
    $("#testKz").click(function () {
      setShowIni(true);
    });

    $("#testDz").click(function () {
      setShowIni(false);
    });

    $("#btn32326").click(function () {
      $("#disp242424area2").hide();
      $("#disp242424area1").show();
      setShowIni(false);
    });
  });

  return (
    <Flex
      direction="row"
      w={!expanded ? "100%" : isPartner ? "100%" : "78%"}
      bg="#FBFBFB"
      transition=".2s width ease-in-out"
      position="relative"
      overflowX="hidden"
      h="100%"
      overflowY="scroll"
    >
      <Flex
        direction="column"
        w="100%"
        h="100%"
        justify="space-between"
        position="relative"
      >
        <Header selected={selected} isPartner={isPartner} />
        <Flex direction="column" p="50px 20px 0px" w="100%" position="relative">
          <Text id="testKz"></Text>
          <Text id="testDz"></Text>
          <Flex w="100%" direction="column" id="disp242424area1">
            <Switch>
              <Route
                path="/partner/dashboard/plans"
                render={() => (
                  <ProtectionPlan
                    type="plans"
                    setModal={setModal}
                    isPartner={isPartner}
                  />
                )}
              />
              <Route
                path="/partner/dashboard/activations"
                render={() => (
                  <Activations
                    type="activations"
                    setModal={setModal}
                    isPartner={isPartner}
                  />
                )}
              />
              <Route
                path="/partner/dashboard/claims"
                render={() => (
                  <Claims
                    type="claims"
                    setModal={setModal}
                    isPartner={isPartner}
                  />
                )}
              />
              <Route
                path="/partner/dashboard/users"
                render={() => <User type="user" setModal={setModal} isPartner={isPartner} />}
              />
              <Route
                path="/partner/dashboard/settings"
                render={() => <Settings setModal={setModal} />}
              />
              <Route
                path="/partner/dashboard/voucher-centre"
                render={() => <Voucher setModal={setModal} />}
              />
              <Route
                path="/partner/dashboard/metrics"
                render={() => <MetricsDashboard setModal={setModal} />}
              />
              <Redirect strict from="/partner/dashboard" to="/partner/dashboard/plans" />
            </Switch>
          </Flex>
          <Flex w="100%" direction="column" id="disp242424area2" display="none">
            {showIni ? (
              <ViewDetails modal={modal} setModal={setModal} isPartner={isPartner} />
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RightPanel;
