import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const TransferRepair = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
  
    async function sendFunc() {
      if(txt.current.value !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "repair/admin/transfer", {
          repairId: modal.data,
          newUid: txt2.current.value,
          email: txt3.current.value,
          phoneNumber: txt4.current.value,
          reason: txt.current.value,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Repair Transferred Successfully",
            onOpen: true,
            data: `You’ve successfully transferred this repair`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Fill in the necessary details", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Transfer Repair`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <InputHolderUser label="New Uid" type="text" placeholder="Uid of the customer to receive the plan" inpRef={txt2} />
            <InputHolderUser label="Email" type="text" placeholder="" inpRef={txt3} />
            <InputHolderUser label="Phone number" type="number" placeholder="" inpRef={txt4} />
            <Box>
                <Box>
                    <Text mb="2">Reason</Text>
                    <Textarea
                    padding="25px 20px"
                    ref={txt}
                    borderColor="#E3E6EB"
                    _focus={{ border: "1px solid #49A05C" }}
                    _hover={{ border: "1px solid #49A05C" }}
                    sx={{ "::placeholder": { color: "#989898" } }}
                    outlineColor="#E3E6EB"
                    w="100%"
                    resize="none"
                    transition="300ms ease-in-out"
                    borderRadius="5px"
                    ></Textarea>
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="auto"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default TransferRepair;
  