import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import { CustomInputNew } from "./helpers";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const RepairEstimate = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const txt = useRef("")
  
    async function sendFunc() {
      if(txt.current.value !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "repair/admin/estimate/submit", {
          repairId: modal.data,
          repairEstimate: txt.current.value,
          notifyCustomer: isNotify,
        })
        if(res.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Repair Estimate Submitted Successfully!",
            onOpen: true,
            data: `You’ve successfully submitted the repair estimate`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Enter a value", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Submit Repair Estimate`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
              <CustomInputNew
                title="Repair Estimate"
                placeholder={""}
                inpRef={txt}
              />
              <Flex align="center" mt="5" mb="5">
                <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                <Flex flex="1">Notify Customer?</Flex>
              </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w="100%">
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width={"100%"}
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default RepairEstimate;
  