import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const SoldBy = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const txt = useRef("")
    const txt2 = useRef("")
  
    async function sendFunc() {
      var a = txt.current.value
      var b = txt2.current.value
      if(a !== "" && b !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "plan/admin/sale/attribute", {
          planId: modal.data,
          reason: a,
          soldBy: b,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Sale Attributed Successfully!",
            onOpen: true,
            data: `You’ve successfully attributed this sale`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Enter the sales email and reason", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Attributes Sales to Plan`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                    
                    <InputHolderUser label="Sold By" type="text" placeholder="Enter email, phone number or uid" inpRef={txt2} />
                    <Text mb="2">Reason</Text>
                    <Textarea
                    padding="25px 20px"
                    ref={txt}
                    borderColor="#E3E6EB"
                    _focus={{ border: "1px solid #49A05C" }}
                    _hover={{ border: "1px solid #49A05C" }}
                    sx={{ "::placeholder": { color: "#989898" } }}
                    outlineColor="#E3E6EB"
                    w="100%"
                    resize="none"
                    transition="300ms ease-in-out"
                    borderRadius="5px"
                    h="80px"
                    ></Textarea>
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default SoldBy;
  