import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
    Image,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
  import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const UpdateOrder = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [isNotify, setIsNotify] = useState(true)
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
    const txt5 = useRef("")
    const txt6 = useRef("")
    const txt7 = useRef("")
    const [aType, setAType] = useState("")

    useEffect(() => {
        if(modal.data[1]?.upfrontPayment !== undefined) {
            txt2.current.value = modal.data[1]?.upfrontPayment
        }
        if(modal.data[1]?.type !== undefined) {
            txt4.current.value = modal.data[1]?.type
            if(modal.data[1]?.type === "lease-to-own") {
              setAType("lease-to-own")
              if(modal.data[1]?.duration !== undefined) {
                setTimeout(function() {
                  txt.current.value = modal.data[1]?.duration
                }, 100)
              }
            }

            if(modal.data[1]?.type === "lease to own") {
              setAType("lease to own")
              if(modal.data[1]?.duration !== undefined) {
                setTimeout(function() {
                  txt.current.value = modal.data[1]?.duration
                }, 100)
              }
            }

            if(modal.data[1]?.type === "trade-in") {
              setAType("trade-in")
              if(modal.data[1]?.tradeInValuation !== undefined) {
                setTimeout(function() {
                  txt5.current.value = modal.data[1]?.tradeInValuation
                }, 100)
              }
              else {
                setTimeout(function() {
                  txt5.current.value = 0
                }, 100)
              }
              
              if(modal.data[1]?.currentDevices !== undefined) {
                setTimeout(function() {
                  txt6.current.value = modal.data[1]?.currentDevices
                }, 100)
              }
              
              if(modal.data[1]?.desiredDevices !== undefined) {
                setTimeout(function() {
                  txt7.current.value = modal.data[1]?.desiredDevices
                }, 100)
              }
            }
        }
        if(modal.data[1]?.vat !== undefined) {
            txt3.current.value = modal.data[1]?.vatRate
        }
    }, [])
  
    async function sendFunc() {
      var a = txt.current.value
      var b = txt2.current.value
      var c = txt3.current.value
      var d = txt4.current?.value
      var e = txt5.current?.value
      var f = txt6.current?.value
      var g = txt7.current?.value
      if(d !== "") {
        setLoading(true)
        const res = await AuthConnect("post", "order/details/admin/update", (aType === "lease-to-own" || aType === "lease to own") ? {
          orderId: modal.data[0],
          type: d,
          duration: a,
          upfrontPayment: b,
          vatRate: c,
          notifyCustomer: isNotify,
        } : (aType === "trade-in") ? {
          orderId: modal.data[0],
          type: d,
          upfrontPayment: b,
          vatRate: c,
          tradeInValuation: e,
          currentDevices: f,
          desiredDevices: g,
          notifyCustomer: isNotify,
        } : {
          orderId: modal.data[0],
          type: d,
          upfrontPayment: b,
          vatRate: c,
          notifyCustomer: isNotify,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: res?.message,
            onOpen: true,
            data: `You’ve successfully updated this order details`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Select the type", "error")
      }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Updates Order Details`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                  <Text mb="2">Type</Text>
                    <select
                        style={{
                            width: "100%",
                            padding: "2.5% 3%",
                            borderRadius: "5px",
                            outlineColor: "#49A05C",
                            border: "1px solid #E3E6EB",
                            transition: "300ms ease-in-out",
                            marginBottom: "5%",
                        }}
                        className="slHover rSelect"
                        onChange={(e) => setAType(e.target.value)}
                        ref={txt4}
                    >
                      <option value=""></option>
                      <option>purchase</option>
                      <option>lease-to-own</option>
                      <option>trade-in</option>
                    </select>
                    {(aType === "lease-to-own" || aType === "lease to own") && <InputHolderUser label="Duration" type="number" placeholder="Enter number of months for repayments" inpRef={txt} />}
                    <InputHolderUser label="Upfront Payment" type="number" placeholder="Enter amount for upfront payment" inpRef={txt2} />
                    <InputHolderUser label="VAT Rate (%)" type="number" placeholder="Enter VAT rate" inpRef={txt3} />
                    {(aType === "trade-in") && 
                    <>
                      <InputHolderUser label="Trade-In Valuation" type="number" placeholder="Amount gamp valued the original device" inpRef={txt5} />
                      <InputHolderUser label="Current Device" type="text" placeholder="Enter brand & model e.g Tecno Camon 19" inpRef={txt6} />
                      <InputHolderUser label="Desired Device" type="text" placeholder="nter brand & model e.g Tecno Camon 19" inpRef={txt7} />
                    </>}
                </Box>
                <Flex align="center" mt="5" mb="5">
              <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
              <Flex flex="1">Notify Customer?</Flex>
            </Flex>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default UpdateOrder;
  