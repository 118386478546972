import {
	Flex,
	ModalCloseButton,
	Text,
	ModalBody,
	Box,
	Button,
	Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { AuthConnect } from '../../util/util';
import { useState, useRef, useEffect } from 'react';
import Toast from '../notify';

const buttonStyles = {
	outline: 'none',
	border: 'none',
	padding: '1rem 2rem',
	borderRadius: '5px',
	background: '#03A63C',
	color: '#fff',
};

const AddNote = ({ onClose, modal, setModal }) => {
	const [loading, setLoading] = useState(false)
	const inp = useRef("")

	async function sendData(data) {
        if(inp.current.value !== "") {
            setLoading(true);
            const res = await AuthConnect('post', 'note/admin/add', {
            tid: modal.data,
            note: inp.current.value,
            });
            if (res?.success) {
            Toast('Note Added Successfully', 'success');
            setLoading(false);
            onClose()
            } else {
            setLoading(false);
            }
        }
        else {
            Toast("Kindly add a note", "error");
        }
	  }

	return (
		<>
			<Flex
				w='100%'
				justifyContent='space-between'
				alignItems='center'
				p='1rem 2rem'
				borderBottom='none'>
				<Text w='100%' fontWeight='600' fontSize='18px' lineHeight='22px'>
					{`Add a Note`}
				</Text>
				<ModalCloseButton
					outline='none'
					bg='#fff'
					border='none'
					w='fit-content'
					position='relative'
					right='0rem'
					top='0rem'
					_focus={'none'}
                    _hover={{ bg: "none", color: "#03A63C" }}
				/>
			</Flex>
			<ModalBody>
				<Box p='1rem 0.5rem'>
					<form>
						<Box w="100%">
							<Text mb="2" fontSize='14px' lineHeight='25px' color='#3A3C40'>
                                Kindly type your note in the box below
							</Text>
							<textarea
								style={{
                                    padding: "2.5% 3%",
                                    borderRadius: "5px",
                                    border: "1px solid #989898",
                                    transition: "300ms ease-in-out",
                                    marginBottom: "4%",
                                    resize: "none",
                                    height: "158px",
                                    width: "100%"
                                }}
								className='slHover'
								ref={inp}
							></textarea>
                            <Flex w="100%" justify="space-between" align="center" mt="3">
                                <Flex
                                w="auto"
                                bg="#fff"
                                borderRadius="5px"
                                py="2"
                                px="9"
                                color="#03A63C"
                                cursor="pointer"
                                onClick={() => onClose()}
                                border="1px solid #03A63C"
                                >
                                Cancel
                                </Flex>
                                <Flex
                                w="auto"
                                bg="#03A63C"
                                borderRadius="5px"
                                py="2"
                                px="9"
                                color="#fff"
                                cursor="pointer"
                                onClick={() => sendData()}
                                border="1px solid #03A63C"
                                >
                                {loading ? (
                                    <Spinner color="#fff" emptyColor="lightgrey" />
                                ) : (
                                    "Add Note"
                                )}
                            </Flex>
                          </Flex>
						</Box>
					</form>
				</Box>
			</ModalBody>
		</>
	);
};

export default AddNote;
