import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Toast from "../notify";
import { CustomDefaultInput, editSerialNumberFn } from "./helpers";

const EditSerialNumber = ({ onClose, modal, setModal }) => {
  const initialState = { reason: "", uniqueDeviceNumber: "" };
  const [values, setValue] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const handleValues = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };
  const handleSubmit = () => {
    let data;
    data = { ...values, activationId: modal.data };
    setLoading(true);
    return editSerialNumberFn(data)
      .then((res) => {
        setLoading(false);
        if (res.status == 200 || 204) {
          return setModal({
            modalId: "SUCCESS",
            title: "SUCCESS",
            onOpen: true,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast(err.message ?? "Ooops, something went wrong!", "error");
      });
  };
  return (
    <>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Edit Serial Number`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          _hover={{ bg: "none", color: "#03A63C" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem">
          <CustomDefaultInput
            title="New IMEI Number"
            value={values.uniqueDeviceNumber}
            onChange={handleValues}
            name="uniqueDeviceNumber"
            // placeholder={"Please enter a valid email"}
          />
          <>
            <Text
              color="#3A3C40"
              fontSize="14px"
              ml="7px"
              lineHeight="25px"
              fontWeight="400"
            >
              Reason
            </Text>
            <textarea
              style={{
                padding: "2.5% 3%",
                borderRadius: "5px",
                border: "1px solid #989898",
                transition: "300ms ease-in-out",
                marginBottom: "4%",
                resize: "none",
                height: "158px",
                width: "100%",
              }}
              onChange={handleValues}
              className="slHover"
              name="reason"
            ></textarea>
          </>
          <Button
            bg="#49A05C"
            p="2%"
            mt="1rem"
            w="100%"
            border="none"
            color="white"
            _hover={{ cursor: "pointer" }}
            type="submit"
            borderRadius="5px"
            isLoading={loading}
            width={"100%"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default EditSerialNumber;
