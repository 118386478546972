import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  
  const buttonStyles = {
    outline: "none",
    border: "none",
    padding: "1rem 2rem",
    borderRadius: "5px",
    background: "#03A63C",
    color: "#fff",
  };
  
  const AddDevice = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const [dArr, setDArr] = useState([])
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")
    const txt4 = useRef("")
    const txt5 = useRef("")
  
    async function sendFunc() {
      if(dArr.length > 0) {
        setLoading(true)
        const res = await AuthConnect("post", "order/admin/devices/add", {
            orderId: modal.data[0],
            devices: dArr,
        })
        if(res?.success) {
          modal.data[1]?.loadOrderDevice()
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: res?.message,
            onOpen: true,
            data: `You’ve successfully added devices`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Kindly enter the device(s) details", "error")
      }
    }

    function addArr() {
        var a = txt.current.value
        var b = txt2.current.value
        var c = txt3.current.value
        var d = txt4.current.value
        var e = txt5.current.value
        if(a !== "" && b !== "" && c !== "" && d !== "" && e !== "") {
            var n1 = {device: a, qty: parseInt(b), class: c, DPPPerUnit: parseInt(d), costPerUnit: parseInt(e)}
            var n2 = [].concat(n1, dArr)
            setDArr(n2)
            txt.current.value = ""
            txt2.current.value = ""
            txt3.current.value = ""
            txt4.current.value = ""
            txt5.current.value = ""
        }
        else {
            Toast("Kindly fill all fields", "error")
        }
    }
  
    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
            {`Add Device`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: "#03A63C" }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            
            <Box>
                <Box>
                    <Flex w="100%" align="center">   
                        <Flex flex="1"><InputHolderUser label="Device Brand & Model" type="text" placeholder="Enter the device brand and model" inpRef={txt} /></Flex>
                        <Flex flex="1" mx="4"><InputHolderUser label="Quantity Ordered" type="text" placeholder="Enter the quantity" inpRef={txt2} /></Flex>
                        <Flex flex="1" direction="column">
                            <Text mb="2">Device Class</Text>
                            <select
                                style={{
                                    width: "100%",
                                    padding: "2.5% 3%",
                                    borderRadius: "5px",
                                    outlineColor: "#49A05C",
                                    border: "1px solid #E3E6EB",
                                    transition: "300ms ease-in-out",
                                    marginBottom: "5%",
                                }}
                                ref={txt3}
                                className="slHover rSelect"
                            >
                                <option>Select the device class</option>
                                {
                                    ["new", "pre-owned"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))
                                }
                            </select>
                        </Flex>
                    </Flex>
                    <Flex w="100%" align="center"> 
                        <Flex flex="1" mr="4"><InputHolderUser label="Cost per Unit" type="text" placeholder="Enter the cost per unit" inpRef={txt5} /></Flex>  
                        <Flex flex="1"><InputHolderUser label="Dpp per Unit" type="text" placeholder="Enter the dpp per unit" inpRef={txt4} /></Flex>
                    </Flex>
                </Box>
                <Flex w="100%" justify="flex-end" mb="4">
                    <Flex>
                        <Button
                            _hover={{ background: "#03A63C" }}
                            style={buttonStyles}
                            textAlign="center"
                            width="100%"
                            onClick={() => addArr()}
                        >
                            Add <Text fontSize="20px" ml="2"><i className="mdi mdi-plus"></i></Text>
                        </Button>
                    </Flex>
                </Flex>
                {
                    dArr.length > 0 &&
                    <>
                        <Text mt="5" fontWeight="600">Devices</Text>         
                        <Flex direction="column" w="100%" overflowY="scroll" maxH="150px" mt="4">
                            {
                                dArr.map((item, index) => (
                                    <Flex w="100%" align="center" key={index} py="2" borderTop={index === 0 && "1px solid #E3E6EB"} pl="3" borderBottom="1px solid #E3E6EB" borderLeft="1px solid #E3E6EB" borderRight="1px solid #E3E6EB" bg={index % 2 !== 0 ? "#fff" : "#FBFBFB"}>
                                        <Flex flex="1"><Flex flex="1">{item.device}</Flex> <Flex flex="1">{item.qty}</Flex> <Flex flex="1">{item.class}</Flex> <Flex flex="1">{item.dppPerUnit?.toLocaleString()}</Flex> <Flex flex="1">{"₦"+item.costPerUnit?.toLocaleString()}</Flex></Flex>
                                        <Flex cursor="pointer" mr="4" 
                                        onClick={() => {
                                            var filtered = dArr.filter(function (value, i, arr) {
                                                return value !== item;
                                            });
                                            setDArr(filtered);
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                        <path d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999337 16.5493 1 16V3C0.71667 3 0.479004 2.904 0.287004 2.712C0.0950036 2.52 -0.000663206 2.28267 3.4602e-06 2C3.4602e-06 1.71667 0.0960036 1.479 0.288004 1.287C0.480004 1.095 0.717337 0.999337 1 1H5C5 0.71667 5.096 0.479003 5.288 0.287003C5.48 0.0950034 5.71734 -0.000663206 6 3.46021e-06H10C10.2833 3.46021e-06 10.521 0.0960036 10.713 0.288004C10.905 0.480004 11.0007 0.717337 11 1H15C15.2833 1 15.521 1.096 15.713 1.288C15.905 1.48 16.0007 1.71734 16 2C16 2.28334 15.904 2.521 15.712 2.713C15.52 2.905 15.2827 3.00067 15 3V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM3 3V16H13V3H3ZM5 13C5 13.2833 5.096 13.521 5.288 13.713C5.48 13.905 5.71734 14.0007 6 14C6.28334 14 6.521 13.904 6.713 13.712C6.905 13.52 7.00067 13.2827 7 13V6C7 5.71667 6.904 5.479 6.712 5.287C6.52 5.095 6.28267 4.99934 6 5C5.71667 5 5.479 5.096 5.287 5.288C5.095 5.48 4.99934 5.71734 5 6V13ZM9 13C9 13.2833 9.096 13.521 9.288 13.713C9.48 13.905 9.71734 14.0007 10 14C10.2833 14 10.521 13.904 10.713 13.712C10.905 13.52 11.0007 13.2827 11 13V6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99934 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99934 5.71734 9 6V13Z" fill="black"/>
                                        </svg>
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </Flex>
                    </>
                }
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  px="25%"
                  justify="center"
                >
                    <Button
                      _hover={{ background: "#03A63C" }}
                      style={buttonStyles}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default AddDevice;
  