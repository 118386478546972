import React, { memo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { AuthConnect, requireAuth } from "../util/util";
import {
  Flex,
  Input,
  Button,
  Box,
  Text,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import NewSidebar from "../components/NewSidebar";
import NewSidebarMenu from "../components/NewSidebarMenu";
import RightPanel from "../components/RightPanel/RightPanel";
import Mfa from "./MFA";
import Mfa2 from "./MFA/index2";
import { fetchQRCode, verifyCode } from "./MFA/helper";
import x from "../assets/svgs/x-close.svg";
import { useAppContext, useViewDetailsContext } from "../util/reducers";
import { fetchHotlistSummary } from "../util/helpers/request";
import { setHotlistSummary } from "../util/reducers/defaultSlice";

function Dashboard({ setModal, modal }) {
  const initialState = {};
  const router = useHistory();
  const mfa = sessionStorage.getItem("GampEnabledMFA");
  const [expanded, setExpanded] = useState(true);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [enableMFA, setEnableMFA] = useState(true);
  const [step, setStep] = useState(0);
  const [selected, setSelectedRoute] = useState(initialState);
  const [selectedItem, setSelectedItem] = useState(router.location.pathname.includes("plans") ? "Protection Plans" : router.location.pathname.includes("activations") ? "Activations" : router.location.pathname.includes("claims") ? "Claims" : router.location.pathname.includes("users") ? "Users" : router.location.pathname.includes("metrics") ? "Dashbaord" : router.location.pathname.includes("settings") ? "Settings" : "Protection Plans");
  const [loadingMFA, setLoadingMFA] = useState(false);
  const isPartner = true
  const [loadingVerifyMFA, setLoadingVerifyMFA] = useState(false);
  const [html, setHTML] = useState({ __html: "" });
  const [state] = useViewDetailsContext();
  const [, dispatch] = useAppContext();
  
  

  const menuItems = [
          { name: "Dashboard", link: "/partner/dashboard/metrics" },
          { name: "Users", link: "/partner/dashboard/users" },
          {
            name: "Protection Plans",
            link: "/partner/dashboard/plans",
          },
          { name: "Activations", link: "/partner/dashboard/activations" },
          { name: "Claims", link: "/partner/dashboard/claims" },
          { name: "Voucher", link: "/partner/dashboard/voucher-centre" },
          { name: "Settings", link: "/partner/dashboard/settings" },
        ] 
  useEffect(() => {
    setSelectedItem(router.location.pathname.includes("plans") ? "Protection Plans" : router.location.pathname.includes("activations") ? "Activations" : router.location.pathname.includes("claims") ? "Claims" : router.location.pathname.includes("users") ? "Users" : router.location.pathname.includes("metrics") ? "Dashbaord" : router.location.pathname.includes("settings") ? "Settings" : router.location.pathname.includes("voucher") ? "Voucher" : "Protection Plans")
  }, [selectedItem])

  useEffect(() => {
    let sub, numberedAlerts, object;
    sub = true;
    numberedAlerts = [];
    if (sub) {
      setLoadingMFA(true);
      fetchQRCode().then((res) => {
        setHTML({ __html: res.response?.data });
        setLoadingMFA(false);
      });
      fetchHotlistSummary().then((res) => {
        object = res?.data;
        if (res.status) {
          dispatch(setHotlistSummary(object));
        }
      });
    }
    return () => {
      sub = false;
    };
  }, []);

  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      setSelectedRoute(state.viewType);
    }
    return () => {
      sub = false;
    };
  }, [state.viewType]);

  const toggleExpanded = () => {
    if (expanded) {
      if (expandedMenu) {
        setExpandedMenu(false);
        setTimeout(() => {
          setExpanded(false);
        }, 500);
      } else {
        setExpanded(false);
      }
    } else {
      setExpanded(true);
    }
  };

  const toggleExpandedMenu = () => {
    return setExpandedMenu(!expandedMenu);
  };

  useEffect(() => {
    let sub;
    sub = true;
    if (sub) {
      if (!expanded) {
        setExpandedMenu(false);
      }
    }
    return () => {
      sub = false;
    };
  }, [expanded]);

  useEffect(() => {
    let sub;
    sub = true;

    if (sub) {
      if (mfa === "false") {
        setEnableMFA(false);
      } else {
        setEnableMFA(true);
      }
    }

    return () => {
      sub = false;
    };
  }, []);

  const handleNav = (path) => {
    return router.push(path);
  };
  const handleLogout = async () => {
    const req = await AuthConnect("post", "auth/logout", { logout: true });
    if (req.success === true) {
      sessionStorage.clear();
      localStorage.clear();
      router.replace("/");
    }
  };


  return (
    <Flex
      m="0"
      p="0"
      w="100%"
      h="100vh"
      overflow="hidden"
      className="noScrollBar"
    >
      <Flex
          bg="rgba(244, 244, 244, 1)"
          w="100%"
          h="100%"
          justify={isPartner ? "flex-start" : "center"}
          align="flex-start"
        >
          <NewSidebarMenu
            expanded={expanded}
            expandedMenu={expandedMenu}
            toggleExpandedMenu={toggleExpandedMenu}
            menuItems={menuItems}
            setSelectedRoute={setSelectedRoute}
            selectedItem={selectedItem}
            handleNav={handleNav}
            isPartner={isPartner}
            setSelectedItem={setSelectedItem}
          />
          <RightPanel
            menuItems={menuItems}
            selected={selected}
            expanded={expanded}
            setModal={setModal}
            modal={modal}
            isPartner={isPartner}
          />
        </Flex>
    </Flex>
  );
}

export default requireAuth(memo(Dashboard));
