import react, { useEffect, useState } from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import ViewDetailsData from "./viewDetailsData";
import { sortForOne } from "./helper";
import { useGetData } from "../pages/protectionPlans/helper";
import { useDashboardContext, useViewDetailsContext } from "../util/reducers";
import { status } from "./viewOverview";

export default function ViewDetails({ setModal, modal, isPartner }) {
  /*const type = sessionStorage.getItem("cpageType")
  const ddata = JSON.parse(sessionStorage.getItem("cpageDetails"))
  const history = useHistory();
  const [viewDetailsState, viewDetailsDispatch] = useViewDetailsContext();
  const [, handleSearchInput] = useGetData(type);*/

  const [viewDetailsState, viewDetailsDispatch] = useViewDetailsContext();
  const type = viewDetailsState.viewType;

  const { handleSearchInput } = useGetData(type);
  const initialState = {
    viewType: viewDetailsState.viewType,
    details: viewDetailsState.viewDetails,
  };
  const [values, setValues] = useState(initialState);
  const ptnrName = sessionStorage.getItem("PartnerName")

  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useDashboardContext();
  const planTabs = [
    "Details",
    "Protected Devices",
    "Claims",
    "History",
    "Notes",
  ];
  const repairTabs = [
    "Details",
    "History",
    "Notes",
    "Customer Feedback",
    "Upload",
  ];
  const claimsTabs = ["Details", "History", "Notes", "Upload"];
  const activationsTabs = ["Details", "History", "Notes", "Upload"];
  const userTabs = [
    "Details",
    "Plans",
    "Notes",
  ];
  const withdrawalTabs = ["Details", "History", "Notes"];
  const orderTabs =
    values.details?.type === "lease-to-own" ||
    values.details?.type === "lease to own"
      ? ["Details", "History", "Notes", "Devices", "Repayment", "Upload"]
      : ["Details", "History", "Notes", "Devices", "Upload"];
  const salesTabs = ["Details", "Sales History", "Upload"];
  const techniciansTabs = [
    "Details",
    "Repairs History",
    "Sales History",
    "Customer Feedback",
    "Upload",
  ];
  const tabHeader =
    values.viewType === "plans"
      ? planTabs
      : values.viewType === "activations"
      ? activationsTabs
      : values.viewType === "repairs"
      ? repairTabs
      : values.viewType === "claims"
      ? claimsTabs
      : values.viewType === "users"
      ? userTabs
      : values.viewType === "withdrawals"
      ? withdrawalTabs
      : values.viewType === "orders"
      ? orderTabs
      : values.viewType === "sales"
      ? salesTabs
      : values.viewType === "technicians"
      ? techniciansTabs
      : [];
  const { tokenId } = useParams();
  useEffect(() => {
    setValues({
      ...values,
      viewType: viewDetailsState.viewType,
      details: viewDetailsState.viewDetails,
    });
   

  }, [viewDetailsState]);
  
  const fetchSpecificData = async (type, tokenId, state) => {
    if (Object.keys(values.details ?? "").length === 0) {
      
      sortForOne(
        type,
        tokenId,
        state,
        viewDetailsDispatch,
        values,
        setValues,
        setLoading
      );
      // setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSpecificData(type, tokenId, state);
  }, [state]);

  const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

  return (
    <Flex w="100%" minH="100vh" direction="column">
      {loading ? (
        <Flex w="100%" h="100%" justify="center" align="center" pb="150px">
          <Spinner color="#00B600" emptyColor="grey" />
        </Flex>
      ) : (
        <>
          <Text id="upArea"></Text>

          {/**********PAGE HEADER******** */}
          <Flex align="center" fontWeight="500" mt="-10px">
            <Flex
              fontSize="13px"
              cursor="pointer"
              mr="3"
              color={partnerBg}
              id="btn32326"
              align="center"
            >
              <Text fontSize="28px">
                <i className="mdi mdi-chevron-left"></i>
              </Text>
              {values.viewType === "plans"
                ? "Protection Plans"
                : values.viewType === "activations"
                ? "Protected Devices"
                : values.viewType === "repairs"
                ? "Repairs"
                : values.viewType === "claims"
                ? "Claims"
                : values.viewType === "users"
                ? "Users"
                : values.viewType === "withdrawals"
                ? "Withdrawals"
                : values.viewType === "sales"
                ? "Sales"
                : values.viewType === "orders"
                ? "Orders"
                : values.viewType === "technicians"
                ? "Technicians"
                : values.viewType === "transactions"
                ? "Transactions"
                : ""}
            </Flex>

            <Text color="#3A3C40"> - </Text>
            <Text fontSize="13px" color="#3A3C40" ml="3">
              {values.viewType === "withdrawals"
                ? values.details?.history[0].actionBy
                : values.details?.email}
            </Text>
            {values.viewType !== "users" && <Text ml="6">{(values.viewType === "users" && values.details?.status === "activated") ? status("full") : status(values.details?.status)}</Text>}
          </Flex>

          <Flex align="center" mt="12">
            {tabHeader.map((item, index) => (
              <Flex
                key={index}
                padding="10px 20px"
                cursor="pointer"
                color={index === selected ? partnerBg : "#82868C"}
                mr="3"
                fontSize="13px"
                borderRadius="4px"
                boxShadow="0px 7px 20px rgba(0, 0, 0, 0.24)"
                onClick={() => setSelected(index)}
                transition="200ms ease-in-out"
              >
                {item}
              </Flex>
            ))}
          </Flex>

          <ViewDetailsData
            viewType={values.viewType}
            details={values.details}
            selected={selected}
            setModal={setModal}
            modal={modal}
            type={type}
            isPartner={isPartner}
          />
        </>
      )}
    </Flex>
  );
}
