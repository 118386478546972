import React from "react";
import { useLocation } from 'react-router-dom';
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";



function NotFound() {
	const location = useLocation()
	return (
		<Flex align="center" justify="center">
			<Box pt="5%" w="100%" align="center">
				The page <Box as="code" style={{ color: "red", margin: "0 1%", cursor: "pointer" }}>{location.pathname}</Box> cannot be found.
			</Box>
		</Flex>
	)
}



export default NotFound;
