import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Box,
  Spinner,
  Input,
  InputLeftElement,
  InputGroup,
  Image,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  CustomInput,
  getRepairRepairCost,
} from "./helpers";
import _ from "lodash";
import Toast from "../notify";
import checkBoxOn from "../../assets/svgs/newCheckboxOn.svg";
import checkBoxOff from "../../assets/svgs/newCheckboxOff.svg";

const buttonStyles = {
  outline: "none",
  border: "none",
  padding: "1rem 2rem",
  borderRadius: "5px",
  background: "#03A63C",
  color: "#fff",
  marginTop: "5px",
};

const UpdateCost = ({ onClose, modal, setModal }) => {
  const [totalPrice, setTotalPrice] = useState(_.toNumber(`0.00`));
  const [loading, setLoading] = useState(false);
  const [isNotify, setIsNotify] = useState(true)
  const initialState = {
    repairCost: modal.data[1]?.repairCost,
    discount: modal.data[1]?.discount,
    deliveryFee: modal.data[1]?.deliveryFee,
    amount: modal.data[1]?.totalCharge,
  };
  const [value, setValue] = useState(initialState);
  const onChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    const { repairCost, discount, deliveryFee } = value;

    const payload = {
      repairCost: parseFloat(repairCost),
      discount: parseFloat(discount),
      deliveryFee: parseFloat(deliveryFee),
      repairId: modal.data[0],
      notifyCustomer: isNotify,
    };

    getRepairRepairCost(payload).then(async (res) => {
      if (res.status === 200) {
        await modal?.refreshFunc();
        onClose();
        return setModal({
          modalId: "SUCCESS",
          title: "Update Repair Price",
          messageTitle: "Repair Price Updated Successfully!",
          onOpen: true,
          data: `You’ve successfully updated the repair price`,
        });
      }
      else {
        Toast(res?.message, "error")
      }
      setLoading(false);
    });
  };

  /** *@const {Number} */
  const calulatePrice = async (repairCost, discount, deliveryFee) => {
    return new Promise((resolve, reject) => {
      let cost = _.toNumber(repairCost);
      let gampDiscount = _.toNumber(discount);
      let deliveryFees = _.toNumber(deliveryFee);
      let afterFees = _.add(cost, deliveryFees);

      resolve(_.subtract(afterFees, gampDiscount));
    });
  };

  useEffect(() => {
    if (value.repairCost && value.deliveryFee && value.discount) {
      calulatePrice(value.repairCost, value.discount, value.deliveryFee).then(
        (total) => {
          setTotalPrice(total);
        }
      );
    }
  }, [value]);

  return (
    <Flex direction="column" pb="40px" fontFamily="Montserrat">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="1rem 1.5rem"
        borderBottom="none"
      >
        <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px">
          {`Update Price`}
        </Text>
        <ModalCloseButton
          outline="none"
          bg="#fff"
          _focus={{ outline: "none" }}
          border="none"
          w="fit-content"
          position="relative"
          right="0rem"
          top="0rem"
          _hover={{ bg: "none", color: "#03A63C" }}
        />
      </Flex>
      <ModalBody>
        <Box p="1rem 0rem 0rem" mt="1rem">
          <form>
            <CustomInput
              title="Repair cost"
              value={value.repairCost}
              onChange={onChange}
              name="repairCost"
              placeholder={"0.00"}
            />
            <CustomInput
              title="Discount"
              value={value.discount}
              onChange={onChange}
              name="discount"
              placeholder={"0.00"}
            />
            <CustomInput
              title="Delivery Fee"
              value={value.deliveryFee}
              onChange={onChange}
              name="deliveryFee"
              placeholder={"0.00"}
            />
            <Flex mt="40px">
              <CustomInput
                title="Total Charge"
                value={totalPrice ?? "0.00"}
                onChange={onChange}
                name="amount"
              />
            </Flex>
            <Flex align="center" mt="5" mb="5">
                <Image cursor="pointer" onClick={() => isNotify ? setIsNotify(false) : setIsNotify(true)} src={isNotify ? checkBoxOn : checkBoxOff} w='20px' h="20px" mr="2.5" />
                <Flex flex="1">Notify Customer?</Flex>
              </Flex>
            <Button
              style={buttonStyles}
              textAlign="center"
              width={"100%"}
              isLoading={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Box>
      </ModalBody>
    </Flex>
  );
};

export default UpdateCost;
