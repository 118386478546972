import {
    Flex,
    ModalCloseButton,
    Text,
    ModalBody,
    Box,
    Button,
    Spinner,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { AuthConnect } from "../../util/util";
  import { useState, useRef, useEffect } from "react";
  import Toast from "../notify";
  import InputHolderUser from "../inputHolderUser";
  

  
  
  
  const ExportData = ({ onClose, modal, setModal }) => {
    const [loading, setLoading] = useState(false)
    const txt = useRef("")
    const txt2 = useRef("")
    const txt3 = useRef("")

    const partnerBg = sessionStorage.getItem("PartnerPrimaryColor")

    const buttonStyles = {
      outline: "none",
      border: "none",
      padding: "1rem 2rem",
      borderRadius: "5px",
      color: "#fff",
    };
  
    async function sendFunc() {
      var a = txt.current.value
      var b = txt2.current.value
      var c = txt3.current.value
      if(a !== "" && b !== "" && c !== "") {
        setLoading(true)
        const res = await AuthConnect("post", modal.state === "plans" ? "plan/partner/admin/export" : modal.state === "claims" ? "claim/partner/admin/export" : modal.state === "activations" ? "activation/partner/admin/export" : "user/partner/admin/export", {
            status: a,
            startDate: b,
            endDate: c,
        })
        if(res?.success) {
          onClose();
          return setModal({
            modalId: "SUCCESS",
            messageTitle: "Successful!",
            onOpen: true,
            data: `Export has been sent to your email`,
          });
        }
        setLoading(false)
      }
      else {
        Toast("Kindly fill in all details", "error")
      }
    }

    const statusType = modal.state === "plans" ? ["all", "active", "expired", "suspended"] : modal.state === "claims" ? ["all", "rejected", "ongoing", "delivered", "fixed and delivered"] : modal.state === "activations" ? ["all", "active", "expired"] : ["all", "active"]

    return (
      <>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="1rem 2rem"
          borderBottom="none"
        >
          <Text w="100%" fontWeight="600" fontSize="18px" lineHeight="22px" textTransform="capitalize">
            {`Export ${modal.state === "activations" ? "Protected Devices" : modal.state} Data`}
          </Text>
          <ModalCloseButton
            outline="none"
            bg="#fff"
            border="none"
            w="fit-content"
            position="relative"
            right="0rem"
            top="0rem"
            _focus={"none"}
            _hover={{ bg: "none", color: partnerBg }}
          />
        </Flex>
        <ModalBody>
          <Box p="1rem 0.5rem">
            <Box>
                <Box>
                <Text mb="2">Status</Text>
                    <select
                        style={{
                            width: "100%",
                            padding: "2.5% 3%",
                            borderRadius: "5px",
                            outlineColor: "#49A05C",
                            border: "1px solid #E3E6EB",
                            transition: "300ms ease-in-out",
                            marginBottom: "4%",
                        }}
                        className="slHover rSelect"
                        ref={txt}
                    >
                        <option value="">Select Status</option>
                        {
                            statusType.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))
                        }
                    </select>
                    <InputHolderUser label="Start Date" type="date" placeholder="Enter the start date" inpRef={txt2} />
                    <InputHolderUser label="End Date" type="date" placeholder="Enter the end date" inpRef={txt3} />
                </Box>
              <Box w="100%" mt="15px">
                <Flex
                  w="100%"
                  justify="center"
                >
                    <Button
                      style={buttonStyles}
                      bg={partnerBg}
                      textAlign="center"
                      width="100%"
                      onClick={() => sendFunc()}
                      _focus={"none"}
                      _hover={{ bg: partnerBg }}
                    >
                      {loading ? (
                        <Spinner color="#fff" emptyColor="lightgrey" />
                      ) : (
                        "Yes, Proceed"
                      )}
                    </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </>
    );
  };
  
  export default ExportData;
  